import React, { Component } from 'react'
import pdfImg from "../../../images/defPdf.png"
import { connect } from 'react-redux';
import { insertNewData,  } from '../../../actions/MainAction';
import { changeStateValue, resetStateValue } from '../../../redux/MainReducer';

export class MsgFilePreview extends Component {
  state={
    disable:false
  }
  closebtn=()=>{
    this.props.resetStateValue({ name:'messageFileSend' })
  }
  sendBtn=(data)=>{
    if(data?.file !== ''){
        this.setState({disable:true})
        let formData = new FormData();
        formData.append(data?.type, data?.file);
        formData.append("text", data?.file?.name);
        formData.append("group_id", this.props.group_id);
        if(data?.replied_id && data?.replied_id !=""){
            formData.append("reply", data?.replied_id );
        }
        this.props.insertNewData('messages', formData)
        .then(resp=>{
          this.setState({disable:false})
            this.props.getAfterChange();
            this.closebtn();
            this.props.onClose()
        })
    }
  }
  render() {
    const {messageFileSend} = this.props;
    const {disable} = this.state;
    return (
      <div className='background_shadow flex items-center justify-center'>
        <div className='bg-white p-[20px] rounded-[10px] w-[500px] max-h-[70vh] overflow-auto'>
          {
            messageFileSend?.type === "image"?
            <img className='rounded-[6px] w-full' src={messageFileSend?.preview} />:
            messageFileSend?.type === "pdf"?
            <img className='w-full' src={pdfImg} />:
            <audio src={messageFileSend?.preview} className='listening-audio' controls />
          }
            <div className='flex justify-between mt-[10px]'>
                <button disabled={disable} onClick={this.closebtn.bind(this)} className='p-[6px] text-[14px] pt-[4px] pb-[4px] text-white font-semibold bg-red rounded-[6px]'>{'Close'}</button>
                <button disabled={disable} onClick={this.sendBtn.bind(this, messageFileSend)} className='p-[6px] text-[14px] pt-[4px] pb-[4px] text-white font-semibold bg-blue rounded-[6px]'>{'Send'}</button>
            </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  messageFileSend: state.Data.messageFileSend,
});
const mapDispatchToProps = {insertNewData, changeStateValue, resetStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(MsgFilePreview)
