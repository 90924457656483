import React, { Component } from 'react'

export class InfoCard extends Component {
  render() {
    const {title, value} = this.props;
    return (
        <div>
            <span className='text-[14px] txt-light-black font-[400]'>{title}</span>
            <div className='bg-gray-100 p-[8px] rounded-[7px]'>
                <span className='text-[14px]'>{value}</span>
            </div>
        </div>
    )
  }
}

export default InfoCard
