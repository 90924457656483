import React, { Component } from 'react'
import MessagesUsers from '../messages/components/MessagesUsers'
import { getMesages } from '../../actions/MainAction'
import { connect } from 'react-redux'
import MessageBox from '../messages/MessageBox'

export class TeacherMessages extends Component {
  state={
    messages:[],
    student_id:''
  }
  onClickMsg=(data)=>{
    this.props.getMesages({page:1, message_type:"student_box", student_id:data.student_id})
    .then(resp=>{
      this.setState({messages:resp, student_id: data?.student_id})
    })
  }
  getAfterChange=()=>{
    this.props.getMesages({page:1, message_type:"student_box", student_id:this.state.student_id})
    .then(resp=>{
      this.setState({messages:resp})
    })
  }
  render() {
    const {messages, student_id} = this.state;
    return (
      <div className='w-full h-screen flex gap-[50px] pt-[20px] pb-[20px] pr-[50px]'>
        <MessagesUsers student_id={student_id} onClickMsg={this.onClickMsg.bind(this)} />
        <div className='w-full h-screen'>
          {
            messages.length !==0?
            <MessageBox height={"h-[95vh]"} student_id={student_id} getAfterChange={this.getAfterChange.bind(this)} messagesParams={messages} />:
            <div className='bg-white h-[95vh] rounded-[10px] flex justify-center items-center'>
              <h3 className='txt-blue text-[24px] font-semibold'>Zəhmət olmasa qrup seçin.</h3>
            </div>
          }
          
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({});
const mapDispatchToProps = {getMesages}
export default connect(mapStateToProps, mapDispatchToProps)(TeacherMessages)
