import React, { Component } from 'react'
import { connect } from 'react-redux';
import { insertNewData } from '../../../../actions/MainAction';
import { getStudentPrograms } from '../../../../actions/MainAction';
import Card5 from '../../../../components/public_components/cards/Card5';
import { bigAlert, confirmAlert, mixinAlert } from "../../../../actions/SweetAlerts";
import Card9 from "../../../..//components/public_components/cards/Card9"
import { getStartFrom } from '../../../../actions/getOtherFunctions';
import LoadMoreBtn from '../../../../components/LoadMoreBtn';
import { Link } from 'react-router-dom';
import { withHooks } from '../../../../actions/withHooks';
import Search from '../../../../components/Search';
export class ProgramsPage extends Component {
    state={
      programs:[],
      btnDisable:false,
      page:1,
      search:''
    }
    componentDidMount(){
      this.props.getStudentPrograms(1,this.props.student_id, "")
      .then(resp=>{
        this.setState({programs: resp})
      })
    }
   

    onClickApply=(programId)=>{
      confirmAlert("warning", "", "Əminsiniz?", "Bəli, müraciət et.", "Ləğv et.")
      .then(alertResp=>{
          if(alertResp === "success"){
            this.setState({btnDisable:true})
            let formData = new FormData();
            formData.append("student_id", this.props.student_id);
            formData.append("program_id", programId);
            this.props.insertNewData("apply-program", formData)
            .then(resp=>{
              mixinAlert("success", "Müraciət edildi.");
              this.setState({btnDisable:false});
              bigAlert("", "Müraciətiniz qəbul olundu. Təhsil nümayəndəsi yoxlanış etdikdən sonra müraciətinizə baxacaq və sizə geri dönüş edəcək.", "")
              if(this.props.user?.role == "student"){
                this.props.navigate("/applied-programs")
              }else{
                this.props.navigate(`/program-requirements/${this.props.student_id}/${programId}`)
              }
              // 
              this.props.getStudentPrograms(this.state.page, this.props.student_id, this.state.search)
              .then(resp=>{
                this.setState({programs: resp})
              })
            })
          }
      })

     
    }

    loadMore=(page)=>{
      this.setState({page})
      this.props.getStudentPrograms(page,this.props.student_id, this.state.search)
      .then(resp=>{
        this.setState({programs: resp})
      })
    }

    onSearchData=(search)=>{
     this.setState({search:search})
      this.props.getStudentPrograms(this.state.page,this.props.student_id, search)
      .then(resp=>{
        this.setState({programs: resp})
      })
    }
    redirectPage=(data)=>{
      this.props.navigate(`/program-requirements/${data?.student_id}/${data?.program_id}`)
    }
  render() {
    const {programs, btnDisable} = this.state;
    const {user} = this.props;
    let result = programs.reduce(function (r, a) {
        r[a.university_id] = r[a.university_id] || [];
        r[a.university_id].push(a);
        return r;
    }, {});
    // console.log(programs)
    return (
      <div className='mb-[30px]' >
        <div className='flex justify-center'>
          <Search largWidth={true} onSearchData={this.onSearchData.bind(this)} />
        </div>
        {
          programs.length ==0?
          <div className='bg-white text-center flex justify-center items-center h-[80vh] shadow rounded-[10px] mt-[20px] ml-[20px] mr-[20px]'>
            <h3 className='text-[25px]'>Sizin təhsil məlumatlarınıza əsasən uyğun proqram tapılmadı. Daha yaxşı nəticələr əldə etmək üçün  <Link to={user?.role == "student"?"/info":`/students/${this.props.student_id}`} className='text-blue-700'>profilinizi</Link> yeniləyin və təkrar yoxlamaya göndərin. Məsələn imtahan nəticələriniz varsa, daxil edin, başqa ölkə seçin və sair.</h3>
          </div>:null
        }
        <div className='flex flex-col gap-[20px] mt-[20px]'>
          {
              [...Object.values(result)].sort((a,b)=>a[0]?.local_ranking - b[0]?.local_ranking)?.map((data, i)=>{
              return(
                  <Card9 key={i} 
                   
                      data={data} 
                      degree={data?.degree?.name}
                      university={data?.university?.name}
                      btn={true}
                      onClickBtn={(id)=>this.onClickApply(id)}
                      btnDisable={btnDisable}
                      onRedirectBtn={this.redirectPage.bind(this)}
                  />
              )
              })
          }
          </div>

          {/* {
                programs?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={programs?.length} />:null
            } */}
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  user: state.Data.user
});
const mapDispatchToProps = {insertNewData, getStudentPrograms}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ProgramsPage))
