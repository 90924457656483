import React, { Component } from 'react'
import DashAdvImg from '../../components/DashAdvImg'
import DashPaymentComponent from '../../components/DashPaymentComponent'
import DashTeacher from '../../components/DashTeacher'
import DashAppliedComp from '../../components/DashAppliedComp'
import { getTeacherApplicationStatus } from '../../actions/MainAction'
import { connect } from 'react-redux'

export class TeacherDashboard extends Component {
  componentDidMount(){
    this.props.getTeacherApplicationStatus()
  }
  render() {
    const {teacherApplicationStatus} = this.props;
    // console.log(teacherApplicationStatus)
    return (
      <div>
        <div className='grid grid-cols-[8fr_4fr] gap-[20px]'>
            <DashAdvImg />
            <DashPaymentComponent />
        </div>
        <div className='mt-[40px] grid grid-cols-3 gap-[20px] max-[1350px]:grid-cols-2'>
            <DashAppliedComp link="student-info" options={teacherApplicationStatus?.applied_students} title="Müraciət olunanlar" />
            <DashAppliedComp options={teacherApplicationStatus?.accepted_students} title="Qəbul olunanlar" />
            <DashTeacher />
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  teacherApplicationStatus: state.Data.teacherApplicationStatus
});
const mapDispatchToProps = {getTeacherApplicationStatus}
export default connect(mapStateToProps, mapDispatchToProps)(TeacherDashboard)
