import React, { Component } from 'react'

export class DropGraduateSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="#6C6C6C"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="m2 10 10-5.5L22 10l-4.054 2.23M2 10l4.054 2.23M2 10v6m4 1.5v-5c0-.092.018-.182.054-.27M6 17.5c0 1.105 2.686 2 6 2s6-.895 6-2m-12 0c0-1.105 2.686-2 6-2s6 .895 6 2m0 0v-5a.715.715 0 0 0-.054-.27m0 0a.87.87 0 0 0-.044-.091c-.51-.932-2.958-1.639-5.902-1.639s-5.393.707-5.903 1.639a.875.875 0 0 0-.043.09"
        />
      </svg>
    )
  }
}

export default DropGraduateSvg
