import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withHooks } from '../../../actions/withHooks';
import Header from '../../../components/header/Header';
import { getSingleData} from '../../../actions/MainAction';
import { changeStateValue } from '../../../redux/MainReducer';
import Documents from './application_pages/Documents';
import Trackings from './application_pages/Trackings';
import Expencess from './application_pages/Expencess';
import StudentExpences from './StudentExpences';
import { mixinAlert } from '../../../actions/SweetAlerts';
export class StudentProgramRequirements extends Component {
    state={
        programData:'',
        section:'tracking'
    }
    componentDidMount(){
        this.props.getSingleData(`programs/${this.props.params.program_id}`)
        .then(resp=>{
            if(resp == ""){
                mixinAlert("warning", "İxtisas tapılmadı")
                this.props.navigate("/")
            }else{
                this.setState({programData:resp})
            }
            
        })
       
    }
  render() {
    const {programData, section} = this.state;
    const {user} = this.props;
    // console.log(programData)
    return (
        <div className='w-full  pl-[27px] pr-[27px]'>
            <Header title={programData?.name} />
            <div>
                <h3 className='txt-dark-blue font-semibold'>{programData?.university?.name}</h3>
            </div>
            <div className='flex items-center gap-[20px] mt-[20px]'>
                <button onClick={()=>this.setState({section: "tracking"})} className='bg-white text-[14px] font-semibold shadow p-[6px_9px] rounded-[5px]'>Proses İzləmə</button>
                <button onClick={()=>this.setState({section: "documents"})} className='bg-white text-[14px] font-semibold shadow p-[6px_9px] rounded-[5px]'>Sənədlər</button>
                {
                    user?.role !="student"?
                     <button onClick={()=>this.setState({section: "expencess"})} className='bg-white text-[14px] font-semibold shadow p-[6px_9px] rounded-[5px]'>Xərclər</button>:null
                }
               
            </div>
            {
                section === "documents"?
                <Documents />:
                section === "tracking"?
                <Trackings />:
                section === "expencess"?
                <Expencess />:null
            }
            
          
         
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
   user: state.Data.user
});
const mapDispatchToProps = {getSingleData, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(StudentProgramRequirements))
