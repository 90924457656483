import React, { Component } from 'react'
import { connect } from 'react-redux';
import {changeStateValue} from "../../../redux/MainReducer";
import EyeSvg from '../../svg/EyeSvg';
import EyeCloseSvg from '../../svg/EyeCloseSvg';
import CheckRoundedSvg from '../../svg/CheckRoundedSvg';
import GreenCheckSvg from '../../svg/GreenCheckSvg';
import InpCheckSvg2 from '../../svg/InpCheckSvg2';
import UploadExamSvg from '../../svg/UploadExamSvg';
import DeleteSvg from '../../svg/DeleteSvg';
import CheckSvg from '../../svg/CheckSvg';
export class ExamInput extends Component {
  state={
    inpType:'password',
    base64:'',
    visible:false
  }
    changeInpValue(onChangeParent, minValue, maxValue, step, e){
      if(minValue == "" && maxValue == ""){
        this.props.changeStateValue({
            name: e.target.name, 
            value: e.target.value
        })
        onChangeParent(e.target.name, e.target.value);
      }else{
        if(!e.target.name?.includes("ielts")){
          if(e.target.value <= maxValue){
            this.props.changeStateValue({
              name: e.target.name, 
              value: e.target.value
            })
            onChangeParent(e.target.name, e.target.value);
          }
        }else if(e.target.name?.includes("ielts")){
          if(
              +e.target.value === 3 ||
              +e.target.value === 3.5 ||
              +e.target.value === 4 || 
              +e.target.value ===4.5 || 
              +e.target.value ===5 ||
              +e.target.value ===5.5 ||
              +e.target.value ===6 ||
              +e.target.value ===6.5 ||
              +e.target.value ===7 ||
              +e.target.value ===7.5 ||
              +e.target.value ===8 ||
              +e.target.value ===8.5 ||
              +e.target.value ===9 ||
              e.target.value ===''
            ){
              this.props.changeStateValue({
                name: e.target.name, 
                value: e.target.value
              })
              onChangeParent(e.target.name, e.target.value);
            }
        }
        
      }
        
    }

    getBase64(file){
        return new Promise((res, rej)=>{
            let reader = new FileReader();
            reader.onload = () => {
                res(reader.result);
            }
            reader.readAsDataURL(file)
        })
       }

    uploadImage=(name,image_value_name, e)=>{
      
        this.getBase64(e.target.files[0])
        .then(resp=>{
            this.props.changeStateValue({
                name: name,
                value: e.target.files[0]
            })
            this.props.changeStateValue({
                name: image_value_name,
                value:resp
            })
        }) 
    }
    removeImg=(name, image_name)=>{
        this.props.changeStateValue({
            name: name,
            value: ''
        })
        this.props.changeStateValue({
            name: image_name,
            value: ''
        })
    }
  render() {
    const {type = "text", name="", value="", title="", required=false, onChangeParent=()=>{}, 
            minValue="", maxValue="", step=1, bg="bg-gray-100 ", bigInp=false, svg, 
            requiredSvg=false, placeholder="", image_name="", image_value="", image_value_name=""} = this.props;
    const {visible} = this.state
            // console.log(image_name, image_value)
    return (
      <div className='flex flex-col justify-center items-start bg-white   p-[10px] rounded-[10px] border border-gray-500'>
          <h4 onClick={()=>this.setState({visible:!visible})} className='text-center text-[18px] font-semibold w-full text-[#1A1A1A] cursor-pointer flex justify-center gap-[15px]'>{title} {value!=""?<CheckSvg />:null}</h4>
          {
            visible?
            <div className='flex flex-col mt-[10px]'>
               <input  onChange={this.changeInpValue.bind(this, onChangeParent, minValue, maxValue, step)} placeholder={placeholder !=""?placeholder:title!=""?title:''} id={name} name={name} value={value} type={type} className={`${bg}  ${bigInp?"h-[29px] text-[12px] text-[#868686] pr-[40px]":"h-[35px]  text-[15px]"}  rounded-[6px] p-[5px] pl-[12px] outline-none w-full`} />
            
               <div className='flex items-center pr-[10px] w-full justify-center'>
                {
                    image_value ==""?
                    <label htmlFor={name+"10"} className='cursor-pointer inline-flex items-center gap-[10px] text-[14px] mt-[10px]'>
                        <UploadExamSvg size={20} />
                        Sertifikatı yüklə
                    </label>:
                    <div className='inline-flex items-center gap-[6px] mt-[10px]'>
                        <img src={image_value} className='w-[20px] h-[20px] rounded-[6px]' />
                        <button className='cursor-pointer' onClick={this.removeImg.bind(this, image_value_name, image_name)}>
                            <DeleteSvg />
                        </button>
                    </div>
                }
                
                <input type='file' onChange={this.uploadImage.bind(this, image_name, image_value_name)} className='hidden' id={name+"10"} />
            </div>
            </div>:null
          }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(ExamInput)


