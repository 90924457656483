import React, { Component } from 'react'
import AdminUniByCountry from '../AdminUniByCountry'
import { getSingleData } from '../../../actions/MainAction'
import { connect } from 'react-redux'
import { withHooks } from '../../../actions/withHooks'

export class SchUni extends Component {
  state={
    schData:''
  }
  componentDidMount(){
    this.props.getSingleData(`country-scholarship/${this.props.params.schId}`)
    .then(resp=>{
      this.setState({schData: resp})
    })
  }
  render() {
    const {schData} = this.state;
    return (
      <div>
        <AdminUniByCountry header={schData?.name} addSchBtn={true} moreLink={`${window.location.pathname}`} />
      </div>
    )
  }
}
const mapStateToProps = (state) =>({});
const mapDispatchToProps = {getSingleData}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(SchUni))
