import React, { Component } from 'react'

export class NavStudentSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={18}
        viewBox="0 0 256 256"
      >
        <path d="m224 64-96 32-96-32 96-32Z" opacity={0.2} />
        <path d="m226.53 56.41-96-32a8.007 8.007 0 0 0-5.06 0L29.624 56.36l-.01.003-.144.048c-.028.01-.053.025-.08.035a7.914 7.914 0 0 0-1.012.428c-.037.02-.076.035-.113.055a7.973 7.973 0 0 0-.931.583 7.884 7.884 0 0 0-.193.144 8.091 8.091 0 0 0-.82.711c-.023.025-.044.051-.067.075a8.031 8.031 0 0 0-.661.783c-.042.056-.085.11-.125.167a8.009 8.009 0 0 0-.567.93c-.026.05-.048.1-.073.15a7.94 7.94 0 0 0-.405.977c-.01.031-.024.061-.034.093a7.95 7.95 0 0 0-.263 1.085c-.013.077-.024.154-.035.23A8.029 8.029 0 0 0 24 64v80a8 8 0 0 0 16 0V75.1l33.585 11.195a63.972 63.972 0 0 0 20.43 87.897A95.881 95.881 0 0 0 47.53 211.63a8 8 0 1 0 13.402 8.738 80.023 80.023 0 0 1 134.133 0 8 8 0 1 0 13.403-8.738 95.88 95.88 0 0 0-46.484-37.437 63.972 63.972 0 0 0 20.43-87.898L226.53 71.59a8 8 0 0 0 0-15.18ZM176 120a48 48 0 1 1-86.651-28.45l36.121 12.04a8.007 8.007 0 0 0 5.06 0l36.121-12.04A47.854 47.854 0 0 1 176 120Zm-9.298-45.334c-.02.008-.04.013-.06.02L128 87.567l-38.642-12.88c-.02-.008-.041-.014-.061-.021L57.298 64 128 40.433 198.702 64Z" />
      </svg>
    )
  }
}

export default NavStudentSvg
