import React, { Component } from 'react'
import MsgMicSvg from '../../../components/svg/MsgMicSvg'
import MsgAttachSvg from '../../../components/svg/MsgAttachSvg'
import MsgSendSvg from '../../../components/svg/MsgSendSvg'
import { connect } from 'react-redux'
import { insertNewData } from '../../../actions/MainAction'
import AttachContainer from './AttachContainer'
export class Footer extends Component {
  state={
    text:"",
    btnDisable:false,
    attachVisible:false
  }
  sendBtn=(text, messagesParams)=>{
    if(text?.trim() !==""){ 
      this.setState({btnDisable:true})
      let params = {
        group_id:messagesParams?.id,
        text:text,
        reply: this.props.replayData?.id
      }
      this.props.insertNewData("messages", params)
      .then(resp=>{
        this.props.getAfterChange();
        this.setState({btnDisable:false, text:''});
        this.props.resetReply()
      })
    }
  }
  onKeyDownInp=(text, messagesParams,e)=>{
    if(e.keyCode == 13 && !e.shiftKey){
      this.sendBtn(text, messagesParams)
    }
  }
  render() {
    const {text, btnDisable, attachVisible} = this.state;
    const {messagesParams, replayData="", onClickRecord=()=>{}} = this.props;
    return (
      <div className='flex justify-between items-center mt-[12px] gap-[15px] pl-[12px] pr-[12px] border-t  pt-[20px] border-t-[#E4E4E7]'>
         {attachVisible? <div onClick={(e)=> this.setState({attachVisible:false})} className='absolute w-full h-[100vh] top-0 left-0 z-20'></div>:null}
         <button className='bg-[#E4E4E7] w-[39px] min-w-[39px] h-[39px] rounded-[4px] inline-flex justify-center items-center' onClick={()=>onClickRecord()}>
                <MsgMicSvg />
            </button>
        <textarea onKeyDown={this.onKeyDownInp.bind(this, text, messagesParams)} value={text} onChange={(e)=>this.setState({text: e.target.value})} className='bg-[#F4F4F5] h-[39px] rounded-[4px] text-[#71717A] w-[100%] outline-none pl-[10px] text-gray-500 resize-none pt-[5px] text-[14px]' placeholder='Mesaj yaz...'></textarea>
        <div className='flex items-center gap-[15px]'>
           
            <div className='relative'>
                <button className='bg-[#E4E4E7] w-[39px] h-[39px] rounded-[4px] inline-flex justify-center items-center' onClick={()=>this.setState({attachVisible:true})} >
                    <MsgAttachSvg />
                </button>
                {attachVisible?<AttachContainer onClose={()=>this.setState({attachVisible: false})} replayData={replayData} />:null}
            </div>
            <button disabled={btnDisable} onClick={this.sendBtn.bind(this, text, messagesParams)} className='bg-[#14B8A6] max-[450px]:hidden w-[85px] h-[39px] inline-flex items-center justify-center rounded-[4px] text-[14px] font-regular text-white pr-[3px] pt-[2px] gap-[5px]'>
                Göndər
                <MsgSendSvg />
            </button>
            <button disabled={btnDisable} onClick={this.sendBtn.bind(this, text, messagesParams)} className='bg-[#14B8A6] hidden max-[450px]:inline-flex w-[35px] h-[39px]  items-center justify-center rounded-[4px] text-[14px] font-regular text-white pr-[3px] pt-[2px] gap-[5px]'>
                
                <MsgSendSvg />
            </button>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  
});
const mapDispatchToProps = {insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(Footer)
