import React, { Component } from 'react'
import Header from './components/Header'
import Input from './components/Input'
import InpSvg from '../svg/InpSvg'
import WorldSvg from '../svg/WorldSvg'
import DropSearch from './components/DropSearch'
import CitySvg from '../svg/CitySvg'
import { changeStateValue, resetStateValue } from '../../redux/MainReducer'
import { connect } from 'react-redux'
import { getPersonalInformation, getStudentComplete, getWorldCountries, insertNewData } from '../../actions/MainAction'
import { mixinAlert } from '../../actions/SweetAlerts'

export class StudentAddressModal extends Component {
    state={
        btnDisable:false
    }
    closeBtn=()=>{
        this.props.resetStateValue({name:"studentAddressData"})
    }

    saveDataBtn=(data)=>{
        if(data?.address !="" && data?.country !="" && data?.city !=""){
            this.setState({btnDisable:true});
            let formData = new FormData();
            for(const property in data){
                formData.append(property, data[property]);
            }
            this.props.insertNewData("student-personal-information", formData)
            .then(resp=>{
                this.setState({btnDisable:false});
                if(resp=="success"){
                    this.props.getStudentComplete()
                    this.closeBtn();
                    this.props.getPersonalInformation();
                    data.afterCallFunction();
                    // mixinAlert("success", "Address saved successfully")
                }else{
                    mixinAlert("error", "Xəta baş verdi");
                }
            })
        }else{
            mixinAlert("warning", "Zəhmət olmasa bütün xanaları doldurun");
        }
    }
   
    componentDidMount(){
        this.props.getWorldCountries()
    }
  render() {
    const {studentAddressData, worldCountries} = this.props;
    const {btnDisable} = this.state;
    let options = [];
    for(const data of worldCountries){
        if(data?.name?.common !="Armenia"){
            options.push({
                label: data?.name?.common,
                value: data?.name?.common
            })
        }
    }
    return (
      <div className='background_shadow flex justify-center items-center '>
        <div className='bg-white rounded-[10px] w-[600px] max-[750px]:ml-[20px] max-[750px]:mr-[20px]'>
            <Header title="Ünvan məlumatlarınız"  closeBtn={this.closeBtn.bind(this)} />
            <div className='p-[20px]'>
                <div>
                    <Input 
                        bigInp svg={<InpSvg />} bg="bg-[#F3F4F6]"
                        requiredSvg
                        name={`studentAddressData.address`}  
                        value={studentAddressData?.address} 
                        title={`Ünvan`}  />
                </div>
                <div className='grid grid-cols-2 gap-[20px] max-[600px]:grid-cols-1 mt-[20px]'>
                    <Input 
                        bigInp svg={<CitySvg />} bg="bg-[#F3F4F6]"
                        requiredSvg
                        name={`studentAddressData.city`}  
                        value={studentAddressData?.city} 
                        title={`Şəhər`}  />
                    <DropSearch svg={<WorldSvg />} bigDrop 
                        name="studentAddressData.country"  
                        value={studentAddressData?.country} 
                        placeholder="Ölkə" 
                        options={options} 
                        title="Ölkə" />
                  
                </div>

                <div className='flex justify-end mt-[20px]'>
                    <button disabled={btnDisable} onClick={this.saveDataBtn.bind(this, studentAddressData)} className='h-[48px] w-[142px] p-[0_10px] bg-blue text-white rounded-[5px] font-regular text-[20px] max-[400px]:text-[16px] max-[400px]:h-[30px] max-[400px]:pl-[5px] max-[400px]:pr-[5px] max-[310px]:text-[14px]'>Yadda saxla</button>
                </div>
            </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    studentAddressData: state.Data.studentAddressData,
    worldCountries: state.Data.worldCountries,
});
const mapDispatchToProps = {changeStateValue, resetStateValue, getWorldCountries,getStudentComplete, insertNewData, getPersonalInformation}

export default connect(mapStateToProps, mapDispatchToProps)(StudentAddressModal)
