import React, { Component } from 'react'

export class CardRightSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        fill="none"
      >
        <rect width={32} height={32} fill="#F4F4F5" rx={8} />
        <path
          fill="#18181B"
          d="m22.592 16.404-5.063 5.063a.873.873 0 0 1-.184.114.525.525 0 0 1-.22.044.54.54 0 0 1-.395-.167.54.54 0 0 1-.168-.395v-4.5h-6.75a.54.54 0 0 1-.395-.168A.54.54 0 0 1 9.25 16a.54.54 0 0 1 .167-.396.54.54 0 0 1 .396-.166h6.75v-4.5a.54.54 0 0 1 .167-.396.54.54 0 0 1 .395-.167c.082 0 .155.015.22.044.064.03.126.067.184.114l5.063 5.063c.047.058.085.12.114.184.03.065.044.138.044.22a.525.525 0 0 1-.044.22.873.873 0 0 1-.114.184Z"
        />
      </svg>
    )
  }
}

export default CardRightSvg
