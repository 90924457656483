import React, { Component } from 'react'

export class InpSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={15}
        height={15}
        viewBox="0 0 48 48"
        fill="#6C6C6C"
      >
        <defs>
          <style>
            {".a{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round}"}
          </style>
        </defs>
        <path
          d="M39.5 30.867V6.5a2 2 0 0 0-2-2h-27a2 2 0 0 0-2 2v35a2 2 0 0 0 2 2h27a2 2 0 0 0 2-2v-1.469"
          className="a"
        />
        <path
          d="M37.134 37.66 21.288 21.775V17.25h4.632l15.785 15.828M44.315 37.985a1.623 1.623 0 0 0 0-2.291l-2.61-2.616-4.57 4.582 2.61 2.616a1.614 1.614 0 0 0 2.284 0ZM13 10.5h22M13 17.25h8.288M32.652 24H35M13 24h10.508M13 30.75h16.989M13 37.5h22"
          className="a"
        />
      </svg>
    )
  }
}

export default InpSvg
