import React, { Component } from 'react'
import SectionHeader from '../../../components/public_components/SectionHeader'
import { getCountryByScholarhip } from '../../../actions/PublicActions';
import { connect } from 'react-redux';
import Card2 from '../../../components/public_components/cards/Card2';

export class ScholarshipCountries extends Component {

    componentDidMount(){
        this.props.getCountryByScholarhip();
    }
  render() {
    const {countryBySch} = this.props;
    // console.log(countryBySch)
    return (
        <section>
            <div className='mt-[60px]'>
                <SectionHeader text1="Dövlət Təqaüdlü ölkələr" text2="" />
            </div>
            <div className='max-w-[1200px] ml-auto mr-auto mt-[70px] pb-[80px] max-[1240px]:ml-[20px] max-[1240px]:mr-[20px]'>
                <div className='grid-full-220'>
                {
                        [...countryBySch]?.map((data, i)=>{
                            return(
                                <Card2
                                    key={i}
                                    data={data}
                                    path={"scholarhip-country"}
                                />
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
  }
}
const mapStateToProps = (state) =>({
    countryBySch: state.Data.countryBySch
});
const mapDispatchToProps = {getCountryByScholarhip}
export default connect(mapStateToProps, mapDispatchToProps)(ScholarshipCountries)
