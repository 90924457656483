import React, { Component } from 'react'
import StarSvg from '../../components/public_components/svg/StarSvg'
import CardOClockSvg from '../../components/public_components/svg/CardOClockSvg'
import img from "../../images/consultingImg.png"
import CheckSvg from '../../components/public_components/svg/CheckSvg'
import WarningSvg from '../../components/public_components/svg/WarningSvg'
export class ConsultingPage extends Component {
    componentDidMount(){
        window.scrollTo(0,0)
    }
  render() {
    return (
      <div className='mt-[80px] bg-[#fff]'>
        
        <section className='bg-[#193695]'>
            <div className='max-w-[1200px] ml-auto mr-auto max-[1240px]:ml-[20px] max-[1240px]:mr-[20px]'>
                <div className='flex jsutify-between gap-[103px] items-center pt-[93px] pb-[64px]'>
                    <div className='w-[742px]'>
                        <div className='flex items-center gap-[5px]'>
                            <StarSvg />
                            <StarSvg />
                            <StarSvg />
                            <StarSvg />
                            <StarSvg />
                        </div>
                        <h1 className='font-semibold text-[60px] text-white mt-[20px] leading-[60px]'>CELT Study Abroad</h1>
                        <p className='font-semibold text-[18px] text-white mt-[10px]'>Consulting service</p>
                        <div className='h-[0.4px] w-full bg-[rgba(255,255,255,0.5)] mt-[64px]'></div>
                        <span className='inline-flex gap-[15px] items-center text-white font-medium text-[14px] mt-[43px]'>
                            <CardOClockSvg color="#FFA619" />
                            Monthly payment
                        </span>
                        <h2 className='font-semibold text-[50px] text-white mt-[20px] leading-[60px]'><span className='text-[#EE7100]'>VIP</span> Medium</h2>
                    </div>
                    <div className='flex flex-col items-center'>
                        <img src={img} alt="Consulting image" className='w-[363px] h-[225px]' />
                        <span className='inline-block text-white mt-[24px] text-white font-medium text-[50px]'>$8750</span>
                        <button className='bg-[#EE7100] w-full text-white h-[49px] rounded-full text-[18px] font-medium mt-[32px]'>Buy now</button>
                    </div>
                </div>  
            </div>
        </section>
        <section className='pt-[113px] pb-[73px]'>
            <div className='max-w-[1200px] ml-auto mr-auto max-[1240px]:ml-[20px] max-[1240px]:mr-[20px]'>
                <h2 className='text-[50px] font-semibold'>Top 1-50 universities in the world</h2>
                <div className='mt-[66px]'>
                    <ul className='flex flex-col gap-[20px]'>
                        <li className='flex items-center gap-[16px]'>
                            <CheckSvg />
                            <span className='text-[rgba(0,0,0,0.6)] text-[18px] font-semibold'>Top 1-50 in the world according to QS World Ranking</span>
                        </li>
                        <li className='flex items-center gap-[16px]'>
                            <CheckSvg />
                            <span className='text-[rgba(0,0,0,0.6)] text-[18px] font-semibold'>Top 1-50 in the world according to QS World Ranking</span>
                        </li>
                    </ul>
                </div>

                <div className='p-[40px_65px] bg-[#fcfcd9] rounded-[25px] flex items-center gap-[85px] mt-[77px]'>
                    <WarningSvg />
                    <p className='text-[20px] font-regular'>Additional fees not highlighted in the list required by the country, university or embassy must be met by the student.</p>
                </div>
            </div>
        </section>
        
      </div>
    )
  }
}

export default ConsultingPage
