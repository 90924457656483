import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue } from '../../redux/MainReducer';
import { adminRoles, contentRoles, managerRoles, studentRoles, teacherRoles, xtmRoles } from './roleLinks';
import NavLink from './NavLink';
export class Navigation extends Component {
    closeNav=()=>{
        const nav = document.querySelector('#navBtnCheckox');
          if(nav){
            nav.checked = false;
            this.props.changeStateValue({name:'navBtn', value:true})
          }
      }
  render() {
    const {user} = this.props;
    let navs = [];
    if(user?.role == "admin"){
        navs = adminRoles
    }else if(user?.role == "xtm"){
      navs = xtmRoles
    }else if(user?.role == "manager"){
      navs = managerRoles
    }else if(user?.role == "teacher"){
      navs = teacherRoles
    }else if(user?.role == "student"){
      navs = studentRoles
    }else if(user?.role == "content_manager"){
      navs = contentRoles
    }
    return (
        <nav style={{ height: 'calc(100vh - 210px)' }} onClick={this.closeNav.bind(this)} className='pt-[16px] overflow-auto nav_scroll pb-[30px]'>
        {
          navs.map((data, i)=>{
            return(
              <div key={i}>
                <ul className='pl-[53px]  flex flex-col gap-[2px] '>
                  {
                    data?.pages?.map((page, j)=>{
                      return(
                        <NavLink key={i+""+j} title={page?.title} svg={page?.svg} path={page?.path} />
                      )
                    })
                  }
                </ul>
                {/* { data?.part !== ""? <span className='pl-[26px] txt-dark-gray text-[10px] font-regular'>{data?.part}</span>:null  }
                <ul className='p-[13px] flex flex-col gap-[2px]'>
                  {
                    data?.pages?.map((page, j)=>{
                      return(
                        <NavLink key={i+""+j} title={page?.title} svg={page?.svg} path={page?.path} />
                      )
                    })
                  }
                </ul>
                { data?.line?<div className='h-[1px] bg-light-gray ml-[24px] mr-[24px]'></div>:null } */}
              </div>
            )
          })
        }
      </nav>
    )
  }
}
const mapStateToProps = (state) =>({
    user: state.Data.user
})
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(Navigation)
