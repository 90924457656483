import React, { Component } from 'react'
import Header from '../../components/header/Header'
import Search from '../../components/Search'
import Buttons from '../../components/Buttons'
import { connect } from 'react-redux'
import { changeStateValue } from '../../redux/MainReducer';
import { getExams } from '../../actions/MainAction';
import Card1 from '../../components/cards/Card1'
import LoadMoreBtn from '../../components/LoadMoreBtn'
export class AdminExamsPage extends Component {
  state={
    page:1,
    search:''
  }
  openModal=(data)=>{
    this.props.changeStateValue({
      name:'adminModal',
      value:{
        position: true,
        id: data?.id?data?.id:'',
        name: data?.name?data?.name:'',
        type: data?.type,
        min_value: data?.min_value?data?.min_value:'',
        max_value: data?.max_value?data?.max_value:'',
        step: data?.step?data?.step:'',
        afterCallFunction:this.props.getExams,
        afterCallParams:[this.state.page, this.state.search],
        typeOption:[{label:"Language", value:"1"}, {label:"Admission", value:"0"}],
        requireds: ["name", "type", "min_value", "max_value", "step"],
        url:'exams',
        modalTitle:`${data?.id?'Edit':'Add'} Exam`
      }
    })
  }

  componentDidMount(){
    this.props.getExams(1, '')
  }

  afterCallFunction=()=>{
    this.props.getExams(this.state.page, this.state.search)
  }
  render() {
    const {exams} = this.props;
    return (
        <div className='w-full  pl-[27px] pr-[27px]'>
            <Header title="exams" />
            <div className='flex justify-end mt-[30px]'>
                <Buttons onClick={this.openModal.bind(this, '')} options={[{title:"Add a exam", value:0}]} selected={0} />
            </div>
          
            <div className='grid-full-250 mt-[30px]'>
              {
                exams?.map((data, i)=>{
                  return(
                    <Card1 
                      key={i}
                      name={data?.name}
                      onClickEdit={this.openModal.bind(this, data)}
                      deletePath={`exams/${data?.id}`}
                      afterCallFunction={this.afterCallFunction.bind(this)}
                    />
                  )
                })
              }
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
  exams: state.Data.exams
});
const mapDispatchToProps = {changeStateValue, getExams}
export default connect(mapStateToProps, mapDispatchToProps)(AdminExamsPage)




