import React, { Component } from 'react'
import Trash2Svg from '../../../components/svg/Trash2Svg'
import MsgSendSvg from '../../../components/svg/MsgSendSvg';
import { endRecording, startRecording } from '../../../actions/RecordAudio';
import { connect } from 'react-redux';
import { insertNewData } from '../../../actions/MainAction';
let second = 0;
let minute = 0;
let interval;
export class MsgRecordAudio extends Component {
    state={
        timer:'00:00',
        disable:false
    }
    removerecord=()=>{
        second=0;
        minute=0;
        clearInterval(interval);
        this.props.onClickRecord();
        endRecording();
    }
    componentWillUnmount(){
        this.removerecord()
    }
    componentDidMount(){
        this.startTimer()
        startRecording();
    }
    getTimerFormst=(time)=>{
        if(time < 10){
            return `0${time}`;
        }else{
            return time;
        }
    }
    startTimer =  ()=>{
        this.setState({timer: `00:00`})
        interval = setInterval(()=>{
           
            if(second<60){
                second++;
            }else{
                minute++;
                second = 0;
            }
            this.setState({timer: `${this.getTimerFormst(minute)}:${this.getTimerFormst(second)}`})
        }, 1000);
       
    }
    sendBtn=(data)=>{
        clearInterval(interval);
        this.setState({disable:true})
        const result = endRecording();
        let formData = new FormData();
        formData.append('group_id', this.props.group_id);
        if(this.props.reply && this.props.reply != ''){
          formData.append('reply', this.props.reply?.id);
        }else{
          formData.append('reply', '');
        }
        formData.append("audio", result?.audioName);
        formData.append('text', result?.audioName.name);
        let url = "messages";
       
        this.props.insertNewData(url, formData)
        .then(resp=>{ 
            this.setState({disable:false})
            this.props.getAfterChange()
            this.removerecord();
        })
    }
  render() {
    const {timer, disable} = this.state;
    const {data, reply} = this.props;
    return (
        <div className='h-[64px] flex items-center mt-[10px]  pt-[20px] border-t border-t-[#E4E4E7] pl-[10px] pr-[10px]'>
            <div className='flex bg-[#F5F9FF] w-full mt-[20px] rounded-[6px] flex items-center justify-between pl-[20px] pr-[20px] mb-[20px] h-[40px]'>
                <button onClick={this.removerecord.bind(this)}>
                    <Trash2Svg />
                </button>
                <span className='font-semibold text-[14px]'>{timer}</span>
                <button disabled={disable} onClick={this.sendBtn.bind(this, data, reply)} className='bg-blue w-[25px] h-[25px] inline-flex items-center justify-center rounded-full pr-[3px] pt-[2px]'>
                    <MsgSendSvg />
                </button>
            </div>
        </div>
      
    )
  }
}
const mapStateToProps = (state)=>({});
const mapDispatchToProps = {insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(MsgRecordAudio)
