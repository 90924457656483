import React, { Component } from 'react'

export class EduBackSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={69}
        height={80}
        fill="none"
      >
        <path
          fill="#FBEDD9"
          d="m2.335 19.706 28.682-18.07a5 5 0 0 1 5.233-.06l30.318 18.145A5 5 0 0 1 69 24.01v33.617a5 5 0 0 1-2.595 4.384L36.083 78.646a5 5 0 0 1-4.905-.054L2.5 62.032a5 5 0 0 1-2.5-4.33V23.936a5 5 0 0 1 2.335-4.23Z"
        />
        <path
          stroke="#EE7100"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M32.167 27.25H22.25v9.917h9.917V27.25ZM47.75 27.25h-9.916v9.917h9.917V27.25ZM47.75 42.833h-9.916v9.917h9.917v-9.917ZM32.167 42.833H22.25v9.917h9.917v-9.917Z"
        />
      </svg>
    )
  }
}

export default EduBackSvg
