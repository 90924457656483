import React, { Component } from 'react'
import { changeStateValue } from '../../redux/MainReducer'
import { connect } from 'react-redux'
import UserSvg from '../svg/UserSvg';
import LogOutSvg from '../svg/LogOutSvg';
import { withHooks } from '../../actions/withHooks';
import { Link } from 'react-router-dom';
export class Headerbar extends Component {

    logOutBtn=()=>{
        localStorage.removeItem("celtGoMainToken");
        this.props.changeStateValue({
            name:'user',
            value: ""
        })
        this.props.navigate("/")
    }
  render() {
    const {user} = this.props;
    return (
        <div className='h-[61px] p-[10px] bg-white rounded-[30px] flex items-center gap-[20px] headerbar'>
            <div className='flex items-center gap-[15px]'>
                <Link to={'/profile'}>
                {
                    !user?.image_full_url || user?.image_full_url ===""? <UserSvg />: <img className='w-[30px] h-[30px] rounded-full' src={user?.image_full_url} />
                }
                </Link>
                <button onClick={this.logOutBtn.bind(this)}>
                    <LogOutSvg />
                </button>
                {/* <label htmlFor='navBtnCheckox' onClick={()=>this.props.changeStateValue({name:'navBtn', value:false})} className='hidden max-[1100px]:inline-flex items-center w-[30px] h-[30px]'>
                    {navBtn? <MenuBarSvg />: <MenuCloseSvg /> }
                </label> */}
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    user: state.Data.user,
})
const mapDispatchToProps = { changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(Headerbar))
