import React, { Component } from 'react'
import Header from './components/Header'
import { changeStateValue, resetStateValue } from '../../redux/MainReducer';
import { connect } from 'react-redux';
import Input from './components/Input';
import InpUserSvg from '../svg/InpUserSvg';
import InputPhone from './components/InputPhone';
import PhoneSvg from '../svg/PhoneSvg';
import { mixinAlert } from '../../actions/SweetAlerts';
import { insertNewDataParams } from '../../actions/MainAction';

export class ApplyRankingModal extends Component {
    closeModal=()=>{
        this.props.resetStateValue({name:"rankingApplyModal"})
    }

    sendBtn=(data)=>{
        if(data?.name !="" && this.props.phoneValidFormat){
            let formData = new FormData();
            formData.append("name", data.name)
            formData.append("phone_number", data.phone_number)
            formData.append(data?.rank_property, data.id)
            formData.append("type", "send_code")
            this.props.insertNewDataParams("public-ranking-apply", formData)
            .then(resp=>{
                if(resp?.data?.code == "send"){
                    this.props.resetStateValue({name:"rankingApplyModal"})
                    this.props.resetStateValue({name:"rankingApplyConfirmModal"})
                    mixinAlert("success", "Müraciətiniz təsdiq olundu")
                }else{
                    this.props.changeStateValue({
                        name:"rankingApplyConfirmModal",
                        value:{
                            ...data,
                            position:true,
                            code:resp?.data?.code
                        }
                    })
                }
            })
        }else{
            mixinAlert("warning", "Please fill the all fields")
        }
    }
  render() {
    const {rankingApplyModal, phoneValidFormat} = this.props;
    // console.log(rankingApplyModal)
    return (
        <div className='background_shadow flex justify-center items-center'>
            <div className='bg-white w-[700px] max-h-[70vh] rounded-[10px] shadow'>
                <Header title={"Müraciət et"} closeBtn={this.closeModal.bind(this)} />
                <div style={{ height:'auto', maxHeight: 'calc(70vh - 50px)' }} className='p-[20px]'>
                    <div className='grid grid-cols-2 gap-[20px] max-[640px]:grid-cols-1'>
                        <Input requiredSvg bigInp svg={<InpUserSvg />}
                            name={`rankingApplyModal.name`}  
                            value={rankingApplyModal?.name} 
                            title={`Ad Soyad`}  />
                    
                        <InputPhone requiredSvg bigInp svg={<PhoneSvg />}
                            name={`rankingApplyModal.phone_number`}  
                            value={rankingApplyModal?.phone_number} 
                            title={`Phone number`}  />
                    </div>
                    <div className='mt-[30px] flex justify-end'>
                        <button onClick={this.sendBtn.bind(this, rankingApplyModal)} className='h-[48px] w-[102px] p-[0_10px] bg-blue text-white rounded-[5px] font-regular text-[20px]'>Göndər</button>
                    </div>
                </div>
               
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    rankingApplyModal: state.Data.rankingApplyModal,
    phoneValidFormat: state.Data.phoneValidFormat,
});
const mapDispatchToProps = {changeStateValue, insertNewDataParams, resetStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(ApplyRankingModal)
