import React, { Component } from 'react'
import MessageChatUser from './MessageChatUser'
import { Link } from 'react-router-dom'
import { getMessagesActiveChats } from '../actions/MainAction'
import { connect } from 'react-redux'

export class DashTeacher extends Component {
  componentDidMount(){
    this.props.getMessagesActiveChats()
  }
  render() {
    const {messagesActiveUsers} = this.props;
    return (
      <div className='bg-white rounded-[4px] bg-white p-[41px_32px_32px_15px]'>
        <h2 className='text-[#303972] text-[24px] font-bold'>Mesajlar</h2>
        <div>
          {
            messagesActiveUsers?.map((data, i) => {
              if(i<3){
                return (
                  <MessageChatUser key={i} data={data} />
                )
              }
            })
          }
        </div>
        <Link to={'/staff-messages'} className='bg-[rgb(77,68,181,0.1)] w-full inline-flex justify-center items-center h-[77px] mt-[20px] rounded-[4px] font-semibold text-[18px] text-[#4D44B5]'>
            Hamısına bax
        </Link>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  messagesActiveUsers: state.Data.messagesActiveUsers
});
const mapDispatchToProps = {getMessagesActiveChats}
export default connect(mapStateToProps, mapDispatchToProps)(DashTeacher)

