import React, { Component } from 'react'

export class UserInfoEditSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={12}
        height={15}
        fill="none"
      >
        <path
          fill="#464255"
          d="M11.148 7.016v-.03L9.375 3.852V1.625a.906.906 0 0 0-.271-.667.906.906 0 0 0-.666-.27H2.811a.906.906 0 0 0-.666.27.906.906 0 0 0-.271.667v2.227L.103 6.986v.03a.547.547 0 0 0-.081.19 1.048 1.048 0 0 0-.022.22c0 .107.017.207.051.3.034.093.08.178.14.256l5.068 6.592c.039.049.09.09.154.125a.44.44 0 0 0 .212.051.44.44 0 0 0 .212-.051.492.492 0 0 0 .154-.125l5.083-6.592c.049-.078.09-.163.125-.256a.861.861 0 0 0 .022-.52 1.714 1.714 0 0 0-.073-.205v.015Zm-2.71-5.391V3.5H2.811V1.625h5.626ZM5.624 8.188a.678.678 0 0 1-.498-.206.678.678 0 0 1-.205-.498c0-.195.068-.36.205-.498a.678.678 0 0 1 .498-.205c.195 0 .361.069.498.205a.678.678 0 0 1 .205.498.678.678 0 0 1-.205.498.678.678 0 0 1-.498.205Zm.469 4.716V9.052c.342-.098.622-.29.842-.579.22-.288.33-.618.33-.989 0-.449-.162-.835-.484-1.157a1.579 1.579 0 0 0-1.157-.483c-.45 0-.835.16-1.157.483a1.579 1.579 0 0 0-.484 1.157c0 .371.11.701.33.99.22.287.496.48.828.578h.014v3.852L.938 7.426l1.684-2.989h6.006l1.684 2.989-4.218 5.478Z"
        />
      </svg>
    )
  }
}

export default UserInfoEditSvg
