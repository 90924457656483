import React, { Component } from 'react'
import Header from '../../components/header/Header';
import ProgramsPage from '../teacher_role/students/pages/ProgramsPage';
import { connect } from 'react-redux';
import { withHooks } from '../../actions/withHooks';
import AvailableProgramLoader from '../../components/AvailableProgramLoader';
export class AvailablePrograms extends Component {
  state={
    loadPrograms:false
  }
  componentDidMount(){
    const path = window.location?.search;
    // console.log(path)
    if(path === "?check_available"){
      this.setState({loadPrograms:true})
      setTimeout(()=>{
        this.setState({loadPrograms:false})
        this.props.navigate("/available-programs");

      }, 2000)
    }
  }
  render() {
    const {user} = this.props;
    const {loadPrograms} = this.state
    return (
      <div className='w-full  pl-[27px] pr-[27px]'>
        {/* <Header title="Available programs" /> */}
        <div className='mt-[40px]'>
          {
            !loadPrograms?
             <ProgramsPage student_id={user?.id} />:null
          }
         
        </div>
        { loadPrograms? <AvailableProgramLoader />:null }
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  user: state.Data.user
})
export default connect(mapStateToProps)(withHooks(AvailablePrograms))
