import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts'
import { deleteData, getTop20Rankings } from '../../actions/MainAction'
import { connect } from 'react-redux'
import { changeStateValue } from '../../redux/MainReducer'
import Buttons from '../../components/Buttons'
import Top300Rank from '../../components/sections/Top300Rank'
import Top20Rank from '../../components/sections/Top20Rank'

export class AdminTop20RankingsPage extends Component {

    openModal = (data) => {
        this.props.changeStateValue({
            name:'adminModal',
            value:{
              position: true,
              id: data?.id?data?.id:'',
              ranking: data?.ranking?data?.ranking:'',
              country_id: data?.country_id?data?.country_id:'',
              university: data?.university?data?.university:'',
              afterCallFunction:this.props.getTop20Rankings,
              afterCallParams:[],
              requireds: ["ranking","university", "country_id"],
              url:'top20-rankings',
              modalTitle:`${data?.id?'Edit':'Add'} Top 20 ranking`
            }
          })
    }
    onDelete = (id) => {
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, delete it")
        .then(alertResp=>{
            if(alertResp === "success"){
            this.props.deleteData(`top20-rankings/${id}`)
            .then(resp=>{
                if(resp == "success"){
                    this.props.getTop20Rankings();
                    mixinAlert("success", "Data deleted successfully")
                }else{
                    mixinAlert("error", "Something went wrong")
                }
            })
            }
        })
    }
  render() {
    return (
        <div className='w-full  pl-[27px] pr-[27px]'>
            <Header title="Top 20" />
            <div className='flex justify-end mt-[30px]'>
                <Buttons onClick={this.openModal.bind(this, '')} options={[{title:"Add a top 20", value:0}]} selected={0} />
            </div>
            <div className='mt-[50px]'>
                <Top20Rank onDelete={this.onDelete.bind(this)} onEdit={this.openModal.bind(this)} />
            </div>
      </div>
    )
  }
}

const mapStateToProps = (state) =>({});
const mapDispatchToProps = {deleteData, getTop20Rankings, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(AdminTop20RankingsPage)



