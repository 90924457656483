import React, { Component } from 'react'
import { getCGOPrograms, getCountries, insertNewData } from '../../actions/MainAction';
import { connect } from 'react-redux';
import Header from '../../components/header/Header';
import DropSearch from '../../components/modals/components/DropSearch';
import { getDropValue } from '../../actions/getOtherFunctions';
import { ranks } from '../../actions/rank';

export class AddProgramAuto extends Component {
  state={
    page:'',
    programs:[],
    country:'',
    btnDisable:false
  }
    componentDidMount(){
        this.props.getCountries(10, '')
    }
    onSelectCountry=( id)=>{

      this.setState({country:id})
    }
    // getPrograms=(page, name)=>{
    //   if(page !="" && name !=""){
    //     this.props.getCGOPrograms(name, page)
    //     .then(resp=>{
    //       this.setState({programs:resp})
    //     })
    //   }
    // }
    addProgram=(programs, country)=>{
      // console.log(country)
      if(country !=""){
        this.setState({btnDisable:true});
        this.props.insertNewData(`add-uni-auto`, {params: JSON.stringify(ranks), country_id: country})
        .then(resp=>{
          this.setState({btnDisable:false})
          // console.log(resp);
        })
      }
      // console.log(ranks)
      // if(programs.length !=0){
      //   let formData = new FormData();
      //   formData.append("programs", JSON.stringify(programs))
      //   formData.append("country", country)
      //   this.props.insertNewData("add-prog-auto", formData)
      //   .then(resp=>{
      //     // console.log(resp+" "+this.state.page)
      //   })
      // }
    }
  render() {
    const {countries} = this.props;
    const {page, programs, country, btnDisable} = this.state
    // console.log(programs)
    return (
       <div className='w-full  pl-[27px] pr-[27px]'>
            <Header title="Programs" />
            <div className='flex gap-[20px] white-search items-end'>
                <DropSearch options={getDropValue(countries, "name", "id")} title="Select a country" selectData={this.onSelectCountry.bind(this)} />
                {/* <input type='number' className='h-[35px]' placeholder='Page' value={page} onChange={(e)=>this.setState({page: e.target.value})} /> */}
                {/* <button onClick={this.getPrograms.bind(this, page, country)}>get</button> */}
                <button disabled={btnDisable} onClick={this.addProgram.bind(this, programs, country)}>Add</button>
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    countries: state.Data.countries
});
const mapDispatchToProps = {getCountries, getCGOPrograms, insertNewData, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(AddProgramAuto)
