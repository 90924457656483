import React, { Component } from 'react'
import Header from '../../../components/header/Header'
import { withHooks } from '../../../actions/withHooks'
import { connect } from 'react-redux';
import { getSingleData } from '../../../actions/MainAction';
import HeaderBtns from './components/HeaderBtns';
import ProgramsPage from './pages/ProgramsPage';
import AppliedPrograms from './pages/AppliedPrograms';
import StudentMessage from './pages/StudentMessage';
import WishList from './pages/WishList';
import StudentInfo from './pages/StudentInfo';
export class StudentInfoPage extends Component {
  state={
    studentData:'',
    page:"available_programs"
  }
  componentDidMount(){
  
    this.props.getSingleData(`users/${this.props.params?.id}`)
      .then(resp=>{
        this.setState({studentData:resp})
      });
  }
  render() {
    const {studentData, page} = this.state;
    return (
      <div className='w-full  pl-[27px] pr-[27px]'>
        <Header title={studentData?.full_name} />
        <div className='mt-[20px]'>
          <HeaderBtns onChangePage={(page)=>this.setState({page})} />
        </div>
        <div className='mt-[20px]'>
          {
            page==="available_programs"?
            <ProgramsPage student_id={this.props.params.id} />:
            page==="applied_programs"?
            <AppliedPrograms student_id={this.props.params.id} />:
            page==="wish_list"?
            <WishList student_id={this.props.params.id} />:
            page==="info"?
            <StudentInfo student_id={this.props.params.id} />:
            page==="message"?
            <StudentMessage student_id={this.props.params.id} />:null
          }
          
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({});
const mapDispatchToProps = {getSingleData}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(StudentInfoPage))
