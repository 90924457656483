import React, { Component } from 'react'
import AppliedPrograms from '../teacher_role/students/pages/AppliedPrograms'
import Header from '../../components/header/Header'
import { connect } from 'react-redux'

export class StudentAppliedPrograms extends Component {
  render() {
    const {user} = this.props;
    return (
        <div className='w-full  pl-[27px] pr-[27px]'>
        {/* <Header title="Applied programs" /> */}
        <div className='mt-[40px]'>
            <AppliedPrograms student_id={user?.id} />
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    user: state.Data.user
})
export default connect(mapStateToProps)(StudentAppliedPrograms)
