import React, { Component } from 'react'
import SectionHeader from '../../components/public_components/SectionHeader'
import Footer from './sections/Footer'
import EmployabilityRankingSection from './sections/EmployabilityRankingSection'
import CitiesRankingSection from './sections/CitiesRankingSection'
import { Link } from 'react-router-dom'
import Card3 from '../../components/cards/Card3'
import { Helmet } from 'react-helmet'
import UniRankSection from './sections/UniRankSection'

export class UniversityRanks extends Component {
    state={
        page:'emp'
    }

    componentDidMount(){
        window.scrollTo(0,0)
    }
  render() {
    const {page} = this.state;
    return (
        <div className='mt-[80px] bg-[#F6F8FB]'>
            <Helmet>
                <title>{"Top Universitetlər | CELTgo.com - Dünyanın Ən Yaxşı Universitetləri"}</title>
                <meta property="og:locale" content="az_AZ" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Top Universitetlər | CELTgo.com - Dünyanın Ən Yaxşı Universitetləri" />
                <meta property="og:description" content="CELTgo.com ilə dünyanın ən yaxşı universitetlərini kəşf edin. Təhsil və karyera məqsədləriniz üçün ideal universitetlər haqqında ətraflı məlumat əldə edin." />
                <meta name="description" content={"CELTgo.com ilə dünyanın ən yaxşı universitetlərini kəşf edin. Təhsil və karyera məqsədləriniz üçün ideal universitetlər haqqında ətraflı məlumat əldə edin."}/>
                <meta name="keywords" content={"top universitetlər, ən yaxşı universitetlər, dünyanın ən yaxşı universitetləri, xaricdə təhsil universitetləri, ən yüksək reytinqli universitetlər, təhsil üçün universitet seçimi, CELTgo universitetlər"}/>
                <meta property="og:url" content="https://celtgo.com/rank-universities" />
                <meta property="og:site_name" content="CELT Colleges" />
                <meta property="article:publisher" content="https://www.facebook.com/celtscollege" />
                <meta property="article:modified_time" content="2024-06-06T08:56:25+00:00" />
                <meta property="og:image" content="https://celtgo.com/static/media/celtLogo6.05ddd884642befe11a4e.png" />
                <meta property="og:image:width" content="900" />
                <meta property="og:image:height" content="600" />
                <meta property="og:image:type" content="image/jpeg" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Top Universitetlər | CELTgo.com - Dünyanın Ən Yaxşı Universitetləri" />
                <meta name="twitter:description" content="CELTgo.com ilə dünyanın ən yaxşı universitetlərini kəşf edin. Təhsil və karyera məqsədləriniz üçün ideal universitetlər haqqında ətraflı məlumat əldə edin." />
                <meta name="twitter:site" content="@celtcolleges" />
                <meta name="twitter:label1" content="Est. reading time" />
                <meta name="twitter:data1" content="7 minutes" />
            </Helmet>
           <div className='h-[90vh] max-[1100px]:h-auto'>
           <UniRankSection />
           </div>
            <Footer />
      </div>
    )
  }
}

export default UniversityRanks
