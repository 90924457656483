import React, { Component } from 'react'
import Header from './components/Header'
import { connect } from 'react-redux';
import { changeStateValue } from '../../redux/MainReducer';
export class SeeTextModal extends Component {
    closeModal=()=>{
        this.props.changeStateValue({
            name:'seeTextModalData',
            value:{
                position:false,
                text:''
            }
        })
    }
    closeModalShadow(e){
        if(typeof e.target.className == 'string' && e.target.className?.includes("background_shadow")) this.closeModal();
    }
  render() {
    const {seeTextModalData} = this.props;
    return (
        <div onClick={this.closeModalShadow.bind(this)} className='background_shadow flex justify-center items-center max-sm:pl-[20px] max-sm:pr-[20px]'>
            <div className='bg-[#F5F5F5] w-[600px]  shadow rounded-[10px] '>
                <Header closeBtn={this.closeModal.bind(this)} title={'Tam mətn'} />
                <div className='p-[20px] max-h-[60vh] overflow-auto'>
                    <p dangerouslySetInnerHTML={{ __html: seeTextModalData?.text }}></p>
                </div>
            </div>
        
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    seeTextModalData: state.Data.seeTextModalData
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(SeeTextModal)
