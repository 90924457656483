import React, { Component } from 'react'
import img from "../../../images/def_user.png"
import DotsSvg from '../../../components/svg/DotsSvg';
import { getLinkFormat } from './getLinks';
import PdfSvg from '../../../components/svg/PdfSvg';
import Tooltip from './Tooltip';
export class ToCard extends Component {
  state={
    dotVisible:false
  }
  render() {
    const {userImg="", role, 
        userName, forward=false, 
        forwardUser, forwardMsg, msg, 
        date, color, forwardColor,
        msgImage="",
        msgAudio="",
        msgPdf="",
        data, onReply=()=>{},noReply=false,getAfterChange=()=>{}
    } = this.props;
    const {dotVisible} = this.state;
    return (
      <div className='flex gap-[12px] max-[520px]:gap-[5px] items-start'>
         {dotVisible? <div onClick={()=>this.setState({dotVisible:false})} className='absolute w-full h-[100vh] top-0 left-0 z-20'></div>:null}
         <div className='flex flex-col items-center'>
          <img alt="user image" className='max-[520px]:w-[20px] max-[520px]:h-[20px] w-[32px] h-[32px] rounded-[6px]' onError={(e)=>{e.target.src = img}} src={userImg && userImg !== ""? userImg:img} />
          <span className='flex text-[10px] font-medium w-[100%]  text-[#707070] ml-[9px]'>{date}</span>
         </div>
         
         <div className='rounded-[12px]  rounded-tl-[0px] max-w-[60%] break-all flex max-[490px]:max-w-[80%] flex-col items-start'>
             
              <div className='bg-[#E8E8E8]  p-[7px] rounded-[5px] mt-[2px] items-start flex flex-col pr-[15px]'>
                {
                    forward?
                    <div className={`bg-[#F5F9FF] w-[100%] rounded-[5px]  p-[7px] mb-[5px]`}>
                        <span className={`${forwardColor} text-[12px]`}>{forwardUser}</span>
                        <p  className='text-[#5C5C5C] text-[11px]'>{forwardMsg}</p>
                    </div>:null
                }
                <span className='text-[#27272A] text-[12px] font-bold'>{userName}</span>
                {
                    msgImage && msgImage !== ""?
                    <a href={msgImage}  download={true} target='_blank'>
                        <img className='rounded-[6px] w-[500px] mb-[10px] max-[490px]:w-[100%]' src={msgImage} />
                    </a>:
                    msgAudio && msgAudio !== ""?
                    <div className='w-[300px] msg-audio'>
                        <audio className='bg-white  listening-audio '  src={msgAudio} controls />
                    </div>:
                    msgPdf && msgPdf !==""?
                    <a download={true} target='_blank' className='inline-flex items-center bg-[#F5F9FF] p-[4px] rounded-[6px] text-[13px] gap-[4px]' href={msgPdf}><PdfSvg /> {msg}</a>:
                    <pre className='text-[14px] whitespace-break-spaces text-[#71717A] font-regular break-all' dangerouslySetInnerHTML={{ __html: getLinkFormat(msg) }}></pre>
                }
                
              </div>
             
         </div>
         <div className='relative'>
                {
                    !noReply?
                      <button onClick={()=>this.setState({dotVisible:true})}>
                        <DotsSvg />
                    </button>:null
                }
                
                {
                    dotVisible?
                    <Tooltip getAfterChange={()=>getAfterChange()} onClose={()=> this.setState({dotVisible:false})} onReply={()=>{onReply(); this.setState({dotVisible:false})}} data={data} />:null
                }
              </div>
      </div>
    )
  }
}

export default ToCard
