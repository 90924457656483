import React, { Component } from 'react'

export class OpenFileSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={22}
        height={22}
        viewBox="0 0 512 512"
        fill="var(--blue)"
      >
        <g fillRule="evenodd">
          <path d="m220.75 85.334 43.511 34.405-30.377 30.377L205.917 128H85.334v210.774l39.354-125.44H384v-.001h96l-64.75 213.334H42.667V85.334H220.75ZM422.458 256H156.021l-40.167 128h267.771l38.833-128Z" />
          <path
            fillRule="nonzero"
            d="M426.667 42.667V192H384v-76.497L307.504 192h-60.34L353.83 85.333h-76.496V42.668h149.333Z"
          />
        </g>
      </svg>
    )
  }
}

export default OpenFileSvg
