import React, { Component } from 'react'

export class ProfileCheckSvg extends Component {
  render() {
    const {color = "#14B8A6"} = this.props;
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="none"
        >
            <path
            fill={color}
            d="m14.764 6.225-.378-.413a1.217 1.217 0 0 1-.343-.835 8.14 8.14 0 0 1-.018-.545c-.011-.34-.04-.692-.088-1.055a1.603 1.603 0 0 0-.474-.95 1.602 1.602 0 0 0-.95-.474 11.29 11.29 0 0 0-1.054-.088c-.188 0-.37-.006-.545-.017a1.455 1.455 0 0 1-.44-.088 1.218 1.218 0 0 1-.395-.255l-.413-.378a9.174 9.174 0 0 0-.8-.677 1.577 1.577 0 0 0-.975-.325c-.364 0-.689.108-.976.325a9.174 9.174 0 0 0-.8.677l-.413.378a1.218 1.218 0 0 1-.395.255 1.455 1.455 0 0 1-.44.088 8.195 8.195 0 0 1-.545.017c-.34.012-.691.041-1.054.088-.364.047-.68.205-.95.475-.27.27-.427.586-.474.949-.047.363-.076.715-.088 1.055 0 .187-.006.369-.018.545a1.455 1.455 0 0 1-.088.439c-.046.129-.131.26-.254.396-.124.134-.25.272-.378.413-.235.246-.46.512-.677.8A1.577 1.577 0 0 0 .016 8c0 .363.108.688.325.976.217.287.442.553.677.8l.378.412a1.217 1.217 0 0 1 .342.835c.012.176.018.358.018.545.012.34.04.692.088 1.055.047.363.205.68.474.95.27.269.586.427.95.474.363.047.714.076 1.054.088.188 0 .37.006.545.017.176.012.322.041.44.088.129.047.26.132.395.255l.413.378c.246.234.513.46.8.677.287.217.612.325.976.325.363 0 .688-.108.975-.325a9.17 9.17 0 0 0 .8-.677l.413-.378a1.22 1.22 0 0 1 .396-.255 1.46 1.46 0 0 1 .44-.088c.175-.011.357-.017.544-.017.34-.012.691-.041 1.055-.088.363-.047.68-.205.949-.475.27-.27.428-.586.475-.949.046-.363.076-.715.087-1.055 0-.187.006-.369.018-.545a1.216 1.216 0 0 1 .343-.835l.378-.413c.234-.246.46-.512.676-.8.217-.287.326-.612.326-.975s-.109-.688-.326-.976a9.186 9.186 0 0 0-.676-.8Zm-3.657.492L7.17 10.654a.874.874 0 0 1-.185.115.525.525 0 0 1-.22.043.525.525 0 0 1-.22-.043.874.874 0 0 1-.184-.115L4.674 8.967a.875.875 0 0 1-.114-.185.525.525 0 0 1-.044-.22.54.54 0 0 1 .167-.395A.54.54 0 0 1 5.078 8c.082 0 .155.015.22.044.064.03.126.067.184.114L6.766 9.46l3.533-3.55a.873.873 0 0 1 .184-.115.526.526 0 0 1 .22-.044.54.54 0 0 1 .396.167.54.54 0 0 1 .167.396.525.525 0 0 1-.044.22.874.874 0 0 1-.115.184Z"
            />
        </svg>
    )
  }
}

export default ProfileCheckSvg
