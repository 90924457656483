import React, { Component } from 'react'

export class Buttons extends Component {
  render() {
    const {options=[], selected=0, onClick=()=>{}, padding=false} = this.props;
    return (
      <div className='flex flex-wrap gap-[20px] items-center'>
        {
            options?.map((item, i)=>{
                return(
                    <button onClick={()=>onClick(item)} className={`h-[25px] ${!padding?"w-[110px]": "pl-[7px] pr-[7px]" }  ${i === selected? "bg-blue text-white": "bg-white txt-blue border-[1px] border-blue-700"} rounded-[3px] font-bold text-[10px]`} key={i}>{item?.title}</button>
                )
            })
        }
      </div>
    )
  }
}

export default Buttons
