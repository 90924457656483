import React, { Component } from 'react'
import Header from '../../components/header/Header'
import Search from '../../components/Search'
import Buttons from '../../components/Buttons'
import { connect } from 'react-redux'
import { changeStateValue } from '../../redux/MainReducer';
import { getUniversities, insertNewData } from '../../actions/MainAction';
import Card1 from '../../components/cards/Card1'
import LoadMoreBtn from '../../components/LoadMoreBtn'
import { withHooks } from '../../actions/withHooks'
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts'
export class AdminUniByCountry extends Component {
  state={
    page:1,
    search:''
  }
  openModal=(data)=>{
    this.props.changeStateValue({
      name:'adminModal',
      value:{
        position: true,
        id: data?.id?data?.id:'',
        name: data?.name?data?.name:'',
        image: data?.image?data?.image:'',
        image_full_url: data?.image_full_url?data?.image_full_url:'',
        country_id: data?.country_id?data?.country_id:'',
        state_id: data?.state_id?data?.state_id:'',
        city_id: data?.city_id?data?.city_id:'',
        type_id: data?.type_id?data?.type_id:'',
        ranking: data?.ranking?data?.ranking:'',
        local_ranking: data?.local_ranking?data?.local_ranking:'',
        bachelour_start: data?.bachelour_start?data?.bachelour_start:'',
        master_start: data?.master_start?data?.master_start:'',
        pre_master_start: data?.pre_master_start?data?.pre_master_start:'',
        phd_start: data?.phd_start?data?.phd_start:'',
        afterCallFunction:this.props.getUniversities,
        afterCallParams:[this.state.page, this.state.search,"", this.props.params.id],
        requireds: ["name", "country_id", "city_id", "type_id"],
        url:'university',
        modalTitle:`${data?.id?'Edit':'Add'} university`
      }
    })
  }

  componentDidMount(){
    this.props.getUniversities(1, '',"", this.props.params.id)
  }

  onSearchData=(search)=>{
    this.setState({search})
    this.props.getUniversities(this.state.page, search, "", this.props.params.id)
  }
  loadMore=(page)=>{
    this.setState({page})
    this.props.getUniversities(page, this.state.search,"", this.props.params.id)
  }

  afterCallFunction=()=>{
    this.props.getUniversities(this.state.page, this.state.search,"", this.props.params.id)
  }
  onClickSch=(id, type)=>{
    confirmAlert("warning", "Are you sure?", "You cannot change it", `Yes, ${type} it`)
    .then(respAlert=>{
      this.props.insertNewData(`sch-by-uni`, {uni_id:id, type:type, scholarship_id: this.props.params.schId})
      .then(resp=>{
        if(resp == "success"){
          mixinAlert("success", "Data saved successfully");
        }else{
          mixinAlert("error", "Something went wrong");
        }
      })
    })
  }
  render() {
    const {universities, addSchBtn=false, moreLink="", header="", user} = this.props;
    // console.log(moreLink)
    return (
        <div className='w-full  pl-[27px] pr-[27px]'>
            <Header title={`${header !=""?header+" - ":""}Universities`} />
            <div className='flex justify-end mt-[30px]'>
                <Buttons onClick={this.openModal.bind(this, '')} options={[{title:"Add a university", value:0}]} selected={0} />
            </div>
            <div className='flex justify-center mt-[20px]'>
                <Search onSearchData={this.onSearchData.bind(this)} largWidth={true} />
            </div>

            <div className='grid-full-250 mt-[30px]'>
              {
                universities?.map((data, i)=>{
                  return(
                    <Card1 
                      key={i}
                      image={data?.image_full_url}
                      name={data?.name}
                      country={data?.country?.name}
                      type={data?.type?.name}
                      onClickEdit={this.openModal.bind(this, data)}
                      deletePath={user?.role == "admin"?`university/${data?.id}`:""}
                      afterCallFunction={this.afterCallFunction.bind(this)}
                      moreLink={moreLink == ""?`/add-programs/${data?.id}`:moreLink+`/${data?.id}`}
                      addSchBtn={addSchBtn}
                      onClickSch={this.onClickSch.bind(this, data?.id)}
                    />
                  )
                })
              }
            </div>
            {
                universities?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={universities?.length} />:null
            }
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
  universities: state.Data.universities,
  user: state.Data.user,
});
const mapDispatchToProps = {changeStateValue, getUniversities, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(AdminUniByCountry))

