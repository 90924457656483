import React, { Component } from 'react'

export class CardDegreeSvg extends Component {
  render() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={14}
            height={12}
            fill="none"
        >
            <path
            fill="#7D7D7D"
            d="m2.958 1.625.196.39.241.485H5.25v2.188h-3.5V1.624h1.208Zm.27-.875H1.313a.438.438 0 0 0-.437.438v3.937a.438.438 0 0 0 .438.438h4.375a.437.437 0 0 0 .437-.438V2.062a.437.437 0 0 0-.438-.437h-1.75L3.623.991A.438.438 0 0 0 3.228.75Zm6.73.875.196.39.241.485h1.855v2.188h-3.5V1.624h1.207Zm.27-.875H8.314a.438.438 0 0 0-.438.438v3.937a.437.437 0 0 0 .438.438h4.374a.438.438 0 0 0 .438-.438V2.062a.438.438 0 0 0-.438-.437h-1.75l-.314-.634a.437.437 0 0 0-.394-.241Zm-7.27 6.563.196.389.241.486H5.25v2.187h-3.5V7.312h1.208Zm.27-.875H1.313a.438.438 0 0 0-.437.437v3.938a.438.438 0 0 0 .438.437h4.375a.438.438 0 0 0 .437-.438V7.75a.437.437 0 0 0-.438-.438h-1.75l-.314-.634a.438.438 0 0 0-.394-.24Zm6.73.875.196.389.241.486h1.855v2.187h-3.5V7.312h1.207Zm.27-.875H8.314a.438.438 0 0 0-.438.437v3.938a.438.438 0 0 0 .438.437h4.374a.438.438 0 0 0 .438-.438V7.75a.438.438 0 0 0-.438-.438h-1.75l-.314-.634a.437.437 0 0 0-.394-.24Z"
            />
        </svg>
    )
  }
}

export default CardDegreeSvg
