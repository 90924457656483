import React, { Component } from 'react'

export class BlueCircileSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={39}
        height={39}
        fill="none"
      >
        <g filter="url(#a)" opacity={0.15}>
          <circle cx={19.5} cy={14.5} r={7.5} fill="url(#b)" />
        </g>
        <defs>
          <linearGradient
            id="b"
            x1={19.5}
            x2={19.5}
            y1={7}
            y2={22}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#7AD3FF" />
            <stop offset={1} stopColor="#4FBAF0" />
          </linearGradient>
          <filter
            id="a"
            width={39}
            height={39}
            x={0}
            y={0}
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy={5} />
            <feGaussianBlur stdDeviation={6} />
            <feColorMatrix values="0 0 0 0 0.929412 0 0 0 0 0.968627 0 0 0 0 0.988235 0 0 0 0.3 0" />
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_155_559" />
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow_155_559"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    )
  }
}

export default BlueCircileSvg
