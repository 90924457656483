import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { getPathConvert } from '../../../actions/getOtherFunctions';

export class Card2 extends Component {
  render() {
    const {data, path="country"} = this.props;
    // console.log(data)
    return (
      <Link to={`/${path}/${getPathConvert(data?.name)}`} className='flex flex-col items-center'>
        <img className='w-[221px] h-[221px] rounded-full ' src={data?.image_full_url} alt={data?.name} />
        <h3 className='text-[20px] font-regular text-center mt-[25px]'>{data?.name}</h3>
        <p className='text-[rgba(0,0,0,0.5)] text-[16px] font-regular text-center'>{data?.university_count} Universitet</p>
      </Link>
    )
  }
}

export default Card2
