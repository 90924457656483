import React, { Component } from 'react'
import { getNotFeeUniversities } from '../../actions/MainAction';
import { changeStateValue } from '../../redux/MainReducer';
import { connect } from 'react-redux';
import Header from '../../components/header/Header';
import Buttons from '../../components/Buttons';
import Card1 from '../../components/cards/Card1';
import LoadMoreBtn from '../../components/LoadMoreBtn';

export class NotFeeUniversitiesPage extends Component {
    state={
        page:1
    }
    componentDidMount(){
        this.props.getNotFeeUniversities(1)
    }
    openModal=(data)=>{
        this.props.changeStateValue({
          name:'adminModal',
          value:{
            position: true,
            id: data?.id?data?.id:'',
            name: data?.name?data?.name:'',
            image: data?.image?data?.image:'',
            image_full_url: data?.image_full_url?data?.image_full_url:'',
            country_id: data?.country_id?data?.country_id:'',
            state_id: data?.state_id?data?.state_id:'',
            city_id: data?.city_id?data?.city_id:'',
            type_id: data?.type_id?data?.type_id:'',
            ranking: data?.ranking?data?.ranking:'',
            local_ranking: data?.local_ranking?data?.local_ranking:'',
            bachelour_start: data?.bachelour_start?data?.bachelour_start:'',
            master_start: data?.master_start?data?.master_start:'',
            pre_master_start: data?.pre_master_start?data?.pre_master_start:'',
            phd_start: data?.phd_start?data?.phd_start:'',
            afterCallFunction:this.props.getUniversities,
            afterCallParams:[this.state.page, this.state.search],
            requireds: ["name", "country_id", "city_id", "type_id"],
            url:'university',
            modalTitle:`${data?.id?'Edit':'Add'} university`
          }
        })
      }
      loadMore=(page)=>{
        this.setState({page})
        this.props.getNotFeeUniversities(page)
      }
    
      afterCallFunction=()=>{
        this.props.getNotFeeUniversities(this.state.page)
      }
  render() {
    const {notFeeUni} = this.props;
    console.log(notFeeUni)
    return (
        <div className='w-full  pl-[27px] pr-[27px]'>
            <Header title="Not fee universities" />
            <div className='flex justify-end mt-[30px]'>
                <Buttons onClick={this.openModal.bind(this, '')} options={[{title:"Add a university", value:0}]} selected={0} />
            </div>

            <div className='grid-full-250 mt-[30px]'>
            {
                notFeeUni?.map((data, i)=>{
                return(
                    <Card1
                    key={i}
                    image={data?.image_full_url}
                    name={data?.name}
                    country={data?.country?.name}
                    type={data?.type?.name}
                    onClickEdit={this.openModal.bind(this, data)}
                    afterCallFunction={this.afterCallFunction.bind(this)}
                    moreLink={`/add-programs/${data?.id}`}
                    userLink={`/univerisy-programs/${data?.id}`}
                    />
                )
                })
            }
            </div>
            {
                notFeeUni?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={notFeeUni?.length} />:null
            }
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    notFeeUni: state.Data.notFeeUni
});
const mapDispatchToProps = {getNotFeeUniversities, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(NotFeeUniversitiesPage)
