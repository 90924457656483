import React, { Component } from 'react'
import { changeStateValue, resetStateValue } from '../../redux/MainReducer';
import { insertNewDataParams } from '../../actions/MainAction';
import { connect } from 'react-redux';
import { mixinAlert } from '../../actions/SweetAlerts';
import WhatsappSvg from '../public_components/svg/WhatsappSvg';

export class RankingConfirmModal extends Component {
    state={
        otp1:'',
        otp2:'',
        otp3:'',
        otp4:'',
        otp1Ref: React.createRef(),
        otp2Ref: React.createRef(),
        otp3Ref: React.createRef(),
        otp4Ref: React.createRef(),
    }
    verifyNumber=(data, otp1, otp2, otp3, otp4)=>{
        if(otp1 !="" && otp2 !="" && otp3 !="" && otp4  !="" ){
            let code = `${otp1}${otp2}${otp3}${otp4}`;
            if(+code != data?.code){
                mixinAlert("warning", "Daxil etdiyiniz OTP codu səhfdir")
                return
            }
          let formData = new FormData();
          formData.append("name", data.name)
          formData.append("phone_number", data.phone_number)
          formData.append(data?.rank_property, data.id)
          formData.append("type", "accept")
          formData.append("code", `${otp1}${otp2}${otp3}${otp4}`);
          // console.log(formData.get("code"))
          this.props.insertNewDataParams("public-ranking-apply", formData)
          .then(resp=>{
            if(resp?.msg == 'success'){
              this.props.resetStateValue({name:"rankingApplyModal"})
              this.props.resetStateValue({name:"rankingApplyConfirmModal"})
              mixinAlert("success", "Müraciətiniz təsdiq olundu")
            }else{
              mixinAlert("error", "Verification code is incorrect");
              this.setState({ otp1:'', otp2:'', otp3:'', otp4:'',})
            }
          })
        }else{
          mixinAlert("warning", "Verification code must be 6 characters")
        }
    }
    onChangeOtp=(index,value, ref="", backRef="",e)=>{
        if(value=="" || e.nativeEvent?.inputType == "deleteContentBackward"){
            this.setState({[`otp${index}`]:e.target.value})
        }
        if(e.nativeEvent?.inputType == "deleteContentBackward"){
            if(backRef !=""){
            backRef?.current?.focus()
            }
        }else{
            if(ref !== ""){
            ref?.current?.focus();
            }
        }
    }
    onPaste=(e)=>{
      e.preventDefault();
      const value = e.clipboardData.getData('text');
      if(value.length ==4 && !isNaN(+value) && typeof +value == "number"){
        this.setState({otp1: value[0], otp2: value[1], otp3: value[2], otp4: value[3],})
        this.state.otp4Ref?.current?.focus()
      }
    }
  render() {
    const {otp1, otp2, otp3, otp4, otp1Ref, otp2Ref, otp3Ref, otp4Ref} = this.state;
    const {rankingApplyConfirmModal} = this.props;
    // console.log(rankingApplyConfirmModal)
    return (
        <div className='background_shadow flex items-center justify-center'>
        <div className='w-[435px] bg-white shadow rounded-[10px] max-[475px]:ml-[20px] max-[475px]:mr-[20px] '>
        <h3 className='text-[24px] text-center mt-[26px] text-center flex items-center gap-[10px] justify-center '><WhatsappSvg /> Nömrənizə gələn kodu daxil edin.</h3>
          <div className='pl-[57px] pr-[57px] pb-[28px]'>
           
              <div className='flex justify-between items-center gap-[20px] mt-[20px]'>
                <input type='number' onPaste={this.onPaste.apply(this)} ref={otp1Ref} onChange={this.onChangeOtp.bind(this,1, otp1, otp2Ref, "")} value={otp1} className='w-[48px] h-[48px] bg-[#F9F9F9] rounded-[8px] border border-[#EEEEEE] outline-none text-center text-[#868686] text-[18px]' />
                <input type='number' onPaste={(e)=>e.preventDefault()} ref={otp2Ref} onChange={this.onChangeOtp.bind(this,2, otp2, otp3Ref, otp1Ref)} value={otp2} className='w-[48px] h-[48px] bg-[#F9F9F9] rounded-[8px] border border-[#EEEEEE] outline-none text-center text-[#868686] text-[18px]' />
                <input type='number' onPaste={(e)=>e.preventDefault()} ref={otp3Ref} onChange={this.onChangeOtp.bind(this,3, otp3, otp4Ref, otp2Ref)} value={otp3} className='w-[48px] h-[48px] bg-[#F9F9F9] rounded-[8px] border border-[#EEEEEE] outline-none text-center text-[#868686] text-[18px]' />
                <input type='number' onPaste={(e)=>e.preventDefault()} ref={otp4Ref} onChange={this.onChangeOtp.bind(this,4, otp4, "", otp3Ref)} value={otp4} className='w-[48px] h-[48px] bg-[#F9F9F9] rounded-[8px] border border-[#EEEEEE] outline-none text-center text-[#868686] text-[18px]' />
              </div>
            {/* <input value={verifyModalData?.code} onChange={(e)=>this.props.changeStateValue({name:'verifyModalData.code', value:e.target.value})} type='number' className='bg-gray-100 w-full h-[35px] mt-[40px] rounded-[10px] text-[14px] pl-[5px]' placeholder='6 digit number' /> */}
            <div className='flex justify-between items-center mt-[30px]'>
              <button onClick={()=>window.location.reload()} className='bg-orange h-[48px] w-[150px] text-white text-[16px] font-regular rounded-[8px]'>
              Bağla
              </button>
              <button onClick={this.verifyNumber.bind(this, rankingApplyConfirmModal, otp1, otp2, otp3, otp4)} className='bg-blue h-[48px] w-[150px] text-white text-[16px] font-regular rounded-[8px]'>
              Təsdiqlə
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    rankingApplyConfirmModal: state.Data.rankingApplyConfirmModal
});
const mapDispatchToProps = {changeStateValue, resetStateValue, insertNewDataParams}
export default connect(mapStateToProps, mapDispatchToProps)(RankingConfirmModal)
