import React, { Component } from 'react'
import logo from "../../images/logo/celtLogo6.png"
import { Link } from 'react-router-dom'
export class Logo extends Component {
  render() {
    return (
        <div className='flex justify-center pt-[26px] '>
            <Link to='/'>
                <img className='w-[210px]' src={logo} />
            </Link>
        </div>
    )
  }
}

export default Logo
