import React, { Component } from 'react'
import DateBtn from './DateBtn'
import moment from 'moment';

export class BookingDateComponent extends Component {
  render() {
    const {selectedDay, times} = this.props;
    // console.log(moment(new Date(selectedDay)).format('dddd'))
    return (
      <div className='mt-[25px]'>
        <h4 className='font-[300] text-[15px]'>{moment(new Date(selectedDay)).format('dddd')}, {moment(new Date(selectedDay)).format('MMM DD')}</h4>
        <div className='flex flex-col gap-[20px] mt-[50px] overflow-auto max-h-[50vh]'>
          {
            times?.map((data, i)=>{
              return(
                <DateBtn
                  key={i}
                  time={data}
                />
              )
            })
          }
        </div>
      </div>
    )
  }
}

export default BookingDateComponent
