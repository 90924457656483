import React, { Component } from 'react'
import Header from './components/Header'
import UploadExamSvg from '../svg/UploadExamSvg'
import { deleteData, getPersonalInformation, getStudentComplete, insertNewData } from '../../actions/MainAction'
import { changeStateValue, resetStateValue } from '../../redux/MainReducer'
import { connect } from 'react-redux'
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts'
import Pdf2Svg from '../svg/Pdf2Svg'

export class StudentPassportModal extends Component {

    closeBtn=()=>{
        this.props.resetStateValue({name:"studentPassportModalData"})
    }

    uploadFile=(e)=>{
        // console.log(e.target.files[0])
        let formData = new FormData();
        formData.append("image", e.target.files[0])
        formData.append("file_type", e.target.files[0].type)
        formData.append("student_id", this.props.studentPassportModalData?.student_id)
        this.props.insertNewData("student-passport-information", formData)
        .then(resp=>{
            if(resp === "success"){
                mixinAlert("success", "Passportunuz uğurla yadda saxlanıldı")
                this.props.getPersonalInformation( this.props.studentPassportModalData?.student_id);
                this.props.getStudentComplete()
            }
        })
    }

    deleteItem=(id)=>{
        confirmAlert("warning", "Əminsiniz?", "Sildikdən sonra geri qaytarmaq olmayacaq", "Bəli", "Xeyir")
        .then(alertResp=>{
            if(alertResp === "success"){
            this.props.deleteData(`student-passport-information/${id}`)
            .then(resp=>{
                if(resp == "success"){
                    this.props.getPersonalInformation( this.props.studentPassportModalData?.student_id);
                    mixinAlert("success", "Passportunuz uğurla silindi")
                    this.props.getStudentComplete()
                }else{
                    mixinAlert("error", "Xəta baş verdi")
                }
            })
            }
        })
    }
  render() {
    const {studentPersonalInformation} = this.props;
    return (
      <div className='background_shadow flex justify-center items-center'>
            <div className='bg-white rounded-[10px] w-[600px] max-[750px]:ml-[20px] max-[750px]:mr-[20px]'>
                <Header title="Pasportunuzu yükləyin"  closeBtn={this.closeBtn.bind(this)} />
                <div className='p-[20px] flex flex-col justify-center items-center w-full'>
                    <label htmlFor='cvFileUpload' className='cursor-pointer bg-gray-100 inline-flex w-[80px] h-[80px] justify-center items-center rounded-full'>
                        <UploadExamSvg />
                    </label>
                    <input onChange={this.uploadFile.bind(this)} id="cvFileUpload" type='file' accept='image/*, application/pdf' className='hidden' />
                </div>
                <div className='p-[20px] flex gap-[20px] flex-wrap'>
                    {
                        studentPersonalInformation?.passport?.map((data, i) => {
                            if(data?.file_type == "application/pdf"){
                               return(
                                   <div className='flex flex-col justify-between items-center gap-[10px]'>
                                        <a key={i} className='inline-block' target='_blank' href={data?.image_full_url}>
                                            <Pdf2Svg width={70} height={70} />
                                        </a>
                                        <button onClick={this.deleteItem.bind(this, data?.id)} className='text-[12px] text-white bg-red font-semibold p-[5px_12px] rounded-full'>
                                            Sil
                                        </button>
                                   </div>
                                ) 
                            }else{
                                return(
                                    <div className='flex flex-col justify-between items-center gap-[10px]'>
                                         <a key={i} className='inline-block' target='_blank'  href={data?.image_full_url}>
                                            <img src={data?.image_full_url} className='max-w-[80px] ' />
                                        </a>
                                        <button  onClick={this.deleteItem.bind(this, data?.id)} className='text-[12px] text-white bg-red font-semibold p-[5px_12px] rounded-full'>
                                            Sil
                                        </button>
                                    </div>
                                )
                            }
                            
                        })
                    }
                </div>
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    studentPersonalInformation: state.Data.studentPersonalInformation,
    studentPassportModalData: state.Data.studentPassportModalData,
});
const mapDispatchToProps = {insertNewData, changeStateValue, resetStateValue,getStudentComplete, getPersonalInformation, deleteData}
export default connect(mapStateToProps, mapDispatchToProps)( StudentPassportModal)
