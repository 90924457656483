import React, { Component } from 'react'
import DisciplinesSection from './sections/DisciplinesSection'
import NewsLetterSection from './sections/NewsLetterSection'
import Footer from './sections/Footer'

export class DisciplinesPage extends Component {
  componentDidMount(){
    window.scrollTo(0,0)
  }
  render() {
    return (
        <div className='mt-[80px] bg-[#fff]'>
            <DisciplinesSection loadMoreBtn={true} page="1" />
            {/* <NewsLetterSection /> */}
            <Footer />
        </div>
    )
  }
}

export default DisciplinesPage
