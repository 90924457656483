import React, { Component } from 'react'
import Header from './components/Header'
import { connect } from 'react-redux';
import Input from './components/Input';
import { changeStateValue } from '../../redux/MainReducer';
import ImageSvg from '../svg/ImageSvg';
import UserModalFooter from './components/UserModalFooter';
import InputPhone from './components/InputPhone';
import UploadSvg2 from '../svg/UploadSvg2';
import InpUserSvg from '../svg/InpUserSvg';
import PhoneSvg from '../svg/PhoneSvg';
import EmailSvg from '../svg/EmailSvg';
import DOBSvg from '../svg/DOBSvg';
export class UserModal extends Component {
    getBase64(file){
        return new Promise((res, rej)=>{
            let reader = new FileReader();
            reader.onload = () => {
                this.props.changeStateValue({name: "userModal.image_full_url", value: reader.result})
                res("success");
            }
            reader.readAsDataURL(file)
        })
    }
    setImg(e){
        this.getBase64(e.target.files[0])
        .then(resp=>{
            if(resp === "success"){
                this.props.changeStateValue({name: "userModal.image", value: e.target.files[0]})
            }
        })
    }

    closeModal=()=>{
        this.props.changeStateValue({name:"userModal", value:{position:false}})
    }
  render() {
    const {userModal} = this.props;
    return (
        <div className='background_shadow flex justify-center items-center'>
            <div className='bg-white w-[700px] max-h-[70vh] rounded-[10px] shadow max-[750px]:ml-[20px] max-[750px]:mr-[20px]'>
                <Header title={userModal?.modalTitle} closeBtn={this.closeModal.bind(this)} />
                <div style={{ height:'auto', maxHeight: 'calc(70vh - 100px)' }} className='p-[20px] overflow-auto '>
                    <div className='flex justify-center'>
                        <label htmlFor='profileImgId' className='cursor-pointer inline-flex items-center flex-col gap-[5px]'>
                            {
                                userModal?.image_full_url?
                                <img alt={userModal?.full_name} src={userModal?.image_full_url} className='w-[99px] h-[99px] rounded-full' />:
                                <div className='w-[99px] h-[99px] bg-gray-300 rounded-full flex items-center justify-center'>
                                    <ImageSvg />
                                </div>
                            }
                            <span className='text-black font-regular text-[12px] inline-flex items-center gap-[3px]'><UploadSvg2 /> {"Profil şəkli yüklə"}</span>
                        </label>
                    </div>
                    <input onChange={this.setImg.bind(this)} accept='image/*' id='profileImgId' type='file' style={{ display:'none' }} />
                    <div  className='grid grid-cols-2 gap-[20px] mt-[20px] max-[750px]:grid-cols-1 '>
                        {userModal?.first_name || userModal?.first_name == "" ? <Input requiredSvg bigInp svg={<InpUserSvg />}  name="userModal.first_name" value={userModal?.first_name} title={'Adınız'}  />:null}
                        {userModal?.last_name || userModal?.last_name == "" ? <Input requiredSvg bigInp svg={<InpUserSvg />}  name="userModal.last_name" value={userModal?.last_name} title={'Soyadınız'}  />:null}
                        {userModal?.email || userModal?.email == "" ? <Input requiredSvg bigInp svg={<EmailSvg />}  type="email" name="userModal.email" value={userModal?.email} title={'Email'}  />:null}
                        {userModal?.date_of_birth || userModal?.date_of_birth == "" ? <Input requiredSvg bigInp svg={<DOBSvg />}  type="date" name="userModal.date_of_birth" value={userModal?.date_of_birth} title={'Doğum tarixi'}  />:null}
                        {userModal?.phone_number || userModal?.phone_number == "" ? <InputPhone requiredSvg bigInp svg={<PhoneSvg />} type="tel" name="userModal.phone_number" value={userModal?.phone_number} title={'Mobil nömrəniz'}  />:null}
                        {userModal?.password || userModal?.password == "" ? <Input type="password" requiredSvg bigInp svg={<InpUserSvg />} name="userModal.password" value={userModal?.password} title={'Şifrəniz'}  />:null}
                    </div>
                    <UserModalFooter closeBtn={this.closeModal.bind(this)} data={userModal} />
                </div>
               
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    userModal: state.Data.userModal
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(UserModal)
