import React, { Component } from 'react'
import { getUniversityRankings } from '../../actions/MainAction';
import { connect } from 'react-redux';

export class UniversityRankings extends Component {
    state={
        country_id:2
    }
    componentDidMount(){
        this.props.getUniversityRankings(2);
    }

    getData=(id)=>{
        this.setState({
            country_id:id
        })
        this.props.getUniversityRankings(id);
        this.props.onChangeCountry(id)
    }
  render() {
    const {universityRankings, user, onDelete=()=>{}, onEdit=()=>{}, countries} = this.props;
    const {country_id} = this.state;
    // console.log(universityRankings)
    return (
      <div className='w-full'>
         <div className='flex gap-[20px] flex-wrap'>
            {
                countries?.map((data, i) => {
                    return (
                        <button onClick={this.getData.bind(this, data?.id)} key={i} className={`text-[12px] p-[5px_9px] rounded-[10px] border border-[var(--blue)] ${country_id == data?.id? "bg-blue text-white ":"text-blue "}`}>{data?.name}</button>
                    )
                })
            }
        </div>
        <table className='w-full bg-white rounded-[10px] mt-[20px]'>
            <thead>
                <tr>
                    <th className='w-1/3 border-r text-[18px] font-semibold text-center h-[40px]'>Ranking</th>
                    <th className='w-1/3 border-r text-[18px] font-semibold text-center h-[40px]'>University</th>
                    <th className='w-1/3 text-[18px] font-semibold text-center h-[40px]'>Country</th>
                    {
                        user?.role == "admin"?
                        <th className='w-1/3 text-[18px] font-semibold text-center h-[40px] border-l'>Action</th>:null
                    }
                    
                </tr>
            </thead>
            <tbody>
                {
                    universityRankings?.map((data, i) => {
                        return(
                            <tr className='border-t  h-[40px]' key={i}>
                                <td className='text-center border-r text-[14px]'>{data?.ranking}</td>
                                <td className='text-center border-r text-[14px]'>{data?.university}</td>
                                <td className='text-center text-[14px]'>{data?.country?.name}</td>
                                
                                {
                                    user?.role == "admin"?
                                    <td className='text-center text-[14px] flex items-center gap-[20px] pr-[20px] border-l h-[40px] pl-[20px]'>
                                        <button onClick={()=>onEdit(data)} className='bg-blue text-white text-[12px] p-[4px_8px] rounded-[10px]'>Edit</button>
                                        <button onClick={()=>onDelete(data?.id)} className='bg-red text-white text-[12px] p-[4px_8px] rounded-[10px]'>Delete</button>
                                    </td>:null
                                }
                                
                            </tr>
                        )
                    })
                }
               
            </tbody>
        </table>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
    universityRankings: state.Data.universityRankings,
    user: state.Data.user,
    countries: state.Data.countries,
});
const mapDispatchToProps  = {getUniversityRankings}
export default connect(mapStateToProps, mapDispatchToProps)(UniversityRankings)


