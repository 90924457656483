import React, { Component } from 'react'
import {connect} from "react-redux"
import { getPublicCountries } from '../../../actions/PublicActions';
import { mixinAlert } from '../../../actions/SweetAlerts';
import { changeStateValue } from '../../../redux/MainReducer';
import SelectBtn from '../../../components/apply/SelectBtn';
export class EduBackCountry extends Component {
  state={
    page:1,
    contRef: React.createRef()
  }
   componentDidMount(){
    this.props.getPublicCountries(5)
   }
 
   selectCountry=(id) =>{
    let countries = [...this.props.applyDetails?.countries];
    const findIndex = countries.findIndex(x=>x == id);
    if(findIndex === -1){
      if(countries.length <5){
        countries.push(id)
        this.props.changeStateValue({
          name: "applyDetails.countries",
          value: countries
        })
      }else{
        mixinAlert("info", "5 dən çox ölkə seçə bilməzsiniz");
      }
      
    }else{
      countries.splice(findIndex, 1);
      this.props.changeStateValue({
        name: "applyDetails.countries",
        value: countries
      })
    }
   }

   loadMoreBtn=(page, elementRef)=>{
    this.setState({page:page});
    setTimeout(()=>{
      if (elementRef.current) {
        elementRef.current.scrollTop = elementRef.current.scrollHeight;
      }
    }, 300)
   }
  render() {
    const {publicCountries, applyDetails} = this.props;
    const {page, contRef} = this.state;
    // console.log(publicCountries)
    return (
      <div>
        <div ref={contRef} className='grid grid-cols-2 gap-[25px] mt-[40px] max-[640px]:grid-cols-1 max-h-[30vh] overflow-auto scroll-smooth'>
          {
            [...publicCountries]?.filter(a=>a.university_count !=0).sort((x, y)=> {  return y?.university_count - x?.university_count}).map((data, i)=>{
              if(i<page*4){
                return(
                  <SelectBtn key={i} 
                    img={data?.flag_full_url} 
                    title={data?.name} 
                    onClick={this.selectCountry.bind(this, data?.id)}
                    check={applyDetails?.countries.includes(data?.id)} />
                    
                )
              }
              
            })
          }
        </div>
        <div className='flex justify-end items-center mt-[27px] '>
        {
          Math.ceil(publicCountries?.length/4) > page?
          <button onClick={this.loadMoreBtn.bind(this, page+1, contRef)} className='text-[#1E398F] font-medium text-[14px]'>
            Daha çox göstər...
          </button>:
          <button onClick={this.loadMoreBtn.bind(this, 1, contRef)}  className='text-[#1E398F] font-medium text-[14px]'>
            Daha az göstər...
          </button>
        }
        </div> 
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    publicCountries: state.Data.publicCountries,
    applyDetails: state.Data.applyDetails,
});
const mapDispatchToProps = {getPublicCountries, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(EduBackCountry)
