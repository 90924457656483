import React, { Component } from 'react'

export class WorldSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="none"
      >
        <path
          fill="#6C6C6C"
          d="M8 0a8 8 0 1 0 8 8 8.01 8.01 0 0 0-8-8Zm6.398 5.02H11.23a10.398 10.398 0 0 0-2.018-3.975 7.082 7.082 0 0 1 5.185 3.975Zm.66 2.98c.001.69-.1 1.378-.3 2.04h-3.322c.23-1.35.23-2.73 0-4.08h3.322c.2.662.301 1.35.3 2.04ZM8 14.889a8.626 8.626 0 0 1-1.191-1.526 9.745 9.745 0 0 1-1.056-2.383h4.494a9.748 9.748 0 0 1-1.056 2.383A8.626 8.626 0 0 1 8 14.89Zm-2.475-4.85a10.916 10.916 0 0 1 0-4.078h4.95a10.917 10.917 0 0 1 0 4.078h-4.95ZM.941 8c0-.69.1-1.378.3-2.04h3.323a12.19 12.19 0 0 0 0 4.08H1.242C1.042 9.377.94 8.69.942 8ZM8 1.111c.453.463.853.974 1.191 1.526a9.748 9.748 0 0 1 1.056 2.383H5.753a9.744 9.744 0 0 1 1.056-2.383A8.627 8.627 0 0 1 8 1.11Zm-1.213-.066A10.399 10.399 0 0 0 4.77 5.02H1.602a7.082 7.082 0 0 1 5.185-3.975ZM1.602 10.98H4.77a10.399 10.399 0 0 0 2.018 3.974 7.083 7.083 0 0 1-5.185-3.974Zm7.608 3.974a10.398 10.398 0 0 0 2.02-3.974h3.168a7.082 7.082 0 0 1-5.185 3.974H9.21Z"
        />
      </svg>
    )
  }
}

export default WorldSvg
