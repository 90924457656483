import React, { Component } from 'react'

export class CheckSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={17}
        height={17}
        fill="none"
      >
        <path
          fill="#EE7100"
          fillRule="evenodd"
          d="M0 8.5a8.5 8.5 0 1 1 17 0 8.5 8.5 0 0 1-17 0Zm8.015 3.638 4.894-6.118-.884-.707-4.173 5.215-2.956-2.463-.725.87 3.844 3.203Z"
          clipRule="evenodd"
        />
      </svg>
    )
  }
}

export default CheckSvg
