import React, { Component } from 'react'

export class SectionHeader extends Component {
  render() {
    const {text1="", text2=""} = this.props;
    return (
      <div className='flex justify-center items-center gap-[5px]'>
        <h2 className='text-[44px] font-semibold leading-[70px] max-[450px]:text-[30px] max-[450px]:leading-[35px] max-[450px]:text-center'>{text1} <span className='text-[#1E398F]'>{text2}</span></h2>
      </div>
    )
  }
}

export default SectionHeader
