import React, { Component } from 'react'

export class DocSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        width={56}
        height={56}
        fill="#5fcde3"
        stroke="#5fcde3"
        viewBox="0 0 512 512"
      >
        <path d="M256 0C114.615 0 0 114.615 0 256s114.615 256 256 256 256-114.615 256-256S397.385 0 256 0zm134.062 375.438c0 20.193-16.37 36.562-36.562 36.562h-195c-20.193 0-36.562-16.37-36.562-36.562V136.562c0-20.193 16.37-36.562 36.562-36.562H256c20.193 0 36.562 16.37 36.562 36.562v24.375c0 20.193 16.37 36.562 36.562 36.562H353.5c20.193 0 36.562 16.37 36.562 36.562v141.377z" />
        <path d="M323.031 253.562H188.969c-10.096 0-18.281 8.185-18.281 18.281s8.185 18.281 18.281 18.281h134.062c10.096 0 18.281-8.185 18.281-18.281s-8.184-18.281-18.281-18.281zM323.031 326.688H188.969c-10.096 0-18.281 8.185-18.281 18.281s8.185 18.281 18.281 18.281h134.062c10.096 0 18.281-8.185 18.281-18.281s-8.184-18.281-18.281-18.281z" />
      </svg>
    )
  }
}

export default DocSvg
