import React, { Component } from 'react'
import StudentExpences from '../StudentExpences';
import { withHooks } from '../../../../actions/withHooks';
import { deleteData, getStudentMainExpences } from '../../../../actions/MainAction';
import { connect } from 'react-redux';
import { changeStateValue } from '../../../../redux/MainReducer';
import ManatSvg from '../../../../components/svg/ManatSvg';
import OpenFileSvg from '../../../../components/svg/OpenFileSvg';
import CardEditSvg from '../../../../components/svg/CardEditSvg';
import DeleteSvg from '../../../../components/svg/DeleteSvg';
import { confirmAlert, mixinAlert } from '../../../../actions/SweetAlerts';
import { getMoneyFormat } from '../../../../actions/getOtherFunctions';

export class Expencess extends Component {
    state={
        type:'outs'
    }

    componentDidMount(){
        this.props.getStudentMainExpences(this.props.params.student_id)
    }

    openModal=(data, type)=>{
        this.props.changeStateValue({
            name:"expencessModalData",
            value:{
                position:true,
                id:data?.id?data?.id:'',
                title:data?.title?data?.title:'',
                student_id:this.props.params.student_id,
                amount:data?.amount?data?.amount:'',
                file:data?.file?data?.file:'',
                file_full_url:data?.file_full_url?data?.file_full_url:'',
                file_type:data?.file_type?data?.file_type:'',
                type:data?.type?data?.type:type
            }
        })
    }
    deleteBtn=(id)=>{
        confirmAlert("warning", "Əminsiniz", "Sildikdən sonra geri gətirə bilmiyəcəksiniz", "Bəli, sil")
        .then(alertResp=>{
            if(alertResp === "success"){
            this.props.deleteData( `student-expencess/${id}`)
            .then(resp=>{
                if(resp == "success"){
                    this.props.getStudentMainExpences(this.props.params.student_id)
                    mixinAlert("success", "Xərc uğurla silindi")
                }else{
                    mixinAlert("error", "Xəta baş verdi")
                }
            })
            }
        })
    }
    getData=(arr, type)=>{
        return arr.filter(x=>x.type == type)
    }

    getTotal(arr){
        let total=0;
        for(const data of arr ){
            total+= +data.amount
        }
        return total
    }
  render() {
    const {type} = this.state;
    const {studentMainExpences, user} = this.props;
    // console.log(studentMainExpences)
    return (
      <div className='mt-[20px] w-full'>
        <div className='flex mt-[20px] items-center gap-[20px]'>
            <button onClick={()=>this.setState({type: "outs"})} className={`${type === "outs"?"bg-blue text-white":"bg-white txt-blue"} text-[14px] font-semibold p-[5px_8px] shadow rounded-[6px]`}>
                Xərclər
            </button>
            <button onClick={()=>this.setState({type: "incomes"})} className={`${type === "incomes"?"bg-blue text-white":"bg-white txt-blue"} text-[14px] font-semibold p-[5px_8px] shadow rounded-[6px]`}>
                Gəlirlər
            </button>
        </div>
        <div className='w-full p-[5px] bg-white rounded-[10px] mt-[20px]'>
            <div className='flex items-center gap-[20px]'>
                {
                    user?.role == "xtm"?
                    <button onClick={this.openModal.bind(this, "", type)} className='border border-[var(--blue)] txt-blue p-[3px_8px] rounded-[6px] text-[12px] font-semibold'>
                        Əlavə et
                    </button>:null
                }
               
                <h2 className='txt-blue text-[18px] font-semibold'>Toplam Məbləğ: {getMoneyFormat(this.getTotal(this.getData(studentMainExpences, type)))}</h2>
            </div>
           
            <table className='w-full bg-white mt-[10px]'>
                <thead className=''>
                    <tr className='h-[40px] bg-[#F6F6F6]'>
                        <th  className='w-1/4 text-left pl-[10px] rounded-l-[10px]'>Başlıq</th>
                        <th  className='w-1/4 text-left pl-[10px]'>Məbləğ</th>
                        <th  className='w-1/4 text-left pl-[10px]'>Müqavilə</th>
                        {
                            user?.role == "xtm"?
                            <th  className='w-1/4 text-left pl-[10px] rounded-r-[10px]'>Redaktə</th>:null
                        }
                      
                    </tr>
                </thead>
                <tbody>
                    {
                        this.getData(studentMainExpences, type)?.map((data, i)=>{
                            if(data?.type == type){
                                return (
                                    <tr className={`h-[40px] ${i%2!==0?"bg-[#F6F6F6]":""}`}>
                                        <td className='w-1/4 h-[30px] pl-[10px] text-[14px] font-regular rounded-l-[10px]'>{data?.title}</td>
                                        <td className='w-1/4 h-[30px] pl-[10px] text-[14px] font-regular inline-flex pt-[10px] items-center gap-[5px]'> <ManatSvg /> {getMoneyFormat(data?.amount)} </td>
                                        <td className='w-1/4 h-[30px] pl-[10px] text-[14px] font-regular'>
                                            <a href={data?.file_full_url} target='_blank'>
                                            <OpenFileSvg />
                                            </a>
                                        </td>
                                        {
                                            user?.role == "xtm"?
                                            <td className='w-1/4 h-[30px] pl-[10px] text-[14px] font-regular rounded-r-[10px] inline-flex items-center gap-[10px] pt-[10px]'>
                                                <button onClick={this.openModal.bind(this, data, type)} className='bg-blue-100 w-[25px] h-[25px] inline-flex justify-center items-center rounded-[5px]'>
                                                    <CardEditSvg />
                                                </button>
                                                <button onClick={this.deleteBtn.bind(this, data?.id)} className='bg-red-100 w-[25px] h-[25px] inline-flex justify-center items-center rounded-[5px]'>
                                                    <DeleteSvg />
                                                </button>
                                            </td>:null
                                        }
                                       
                                    </tr>
                                )   
                            }
                        })
                    }
                </tbody>
            </table>
        </div>

      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    studentMainExpences: state.Data.studentMainExpences,
    user: state.Data.user,
});
const mapDispatchToProps = {getStudentMainExpences, changeStateValue, deleteData}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(Expencess))
