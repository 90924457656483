import React, { Component } from 'react'

export class PersInfoSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={69}
        height={80}
        fill="none"
      >
        <path
          fill="#DFEDF7"
          d="m2.335 19.706 28.682-18.07a5 5 0 0 1 5.233-.06l30.318 18.145A5 5 0 0 1 69 24.01v33.617a5 5 0 0 1-2.595 4.384L36.083 78.646a5 5 0 0 1-4.905-.054L2.5 62.032a5 5 0 0 1-2.5-4.33V23.936a5 5 0 0 1 2.335-4.23Z"
        />
        <path
          fill="#1B8BE3"
          d="M44.365 49.834h-.423c-.881 0-1.839.214-2.625-.06-.942-.328-.514-1.514-.851-2.285-1.17-2.684-3.768-4.255-6.8-3.959-2.61.257-4.93 2.343-5.393 5.046-.177 1.044-.548 1.353-1.586 1.283a32.885 32.885 0 0 0-4.546 0c-1.04.075-1.125-.347-1.076-1.2.326-5.68 4.762-10.719 10.64-12.03 7.899-1.762 15.963 4.262 16.291 12.146.032.782-.082 1.167-.987 1.076-.873-.087-1.762-.019-2.644-.019v.002ZM34.516 35.328c-3.158-.004-5.713-2.54-5.721-5.678-.009-3.114 2.563-5.707 5.696-5.745 3.143-.038 5.808 2.627 5.768 5.772-.04 3.18-2.557 5.658-5.745 5.654l.002-.003Z"
        />
        <path
          fill="#1B8BE3"
          d="M39.917 49.948c.036 2.82-2.577 5.449-5.407 5.436-2.8-.01-5.347-2.544-5.383-5.357-.036-2.82 2.578-5.448 5.408-5.436 2.8.011 5.347 2.544 5.383 5.357Zm-2.173-1.45c-.038-.314-.269-.443-.438-.61-.259-.257-.439-.05-.614.127-.6.595-1.189 1.2-1.797 1.787-.426.413-.822 1.032-1.324 1.144-.508.114-.838-.633-1.23-1.01-.329-.318-.51-.193-.776.057-.294.28-.311.479 0 .765.52.474 1.03.965 1.49 1.497.383.44.635.364 1.006-.021 1.074-1.116 2.184-2.197 3.276-3.294.147-.148.284-.305.41-.44l-.003-.003Z"
        />
      </svg>
    )
  }
}

export default PersInfoSvg
