import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withHooks } from '../../actions/withHooks';
import { getEduDegree, getPrograms } from '../../actions/MainAction';
import Header from '../../components/header/Header';
import Search from '../../components/Search';
import LoadMoreBtn from '../../components/LoadMoreBtn';
import Card1 from '../../components/cards/Card1';
import { changeStateValue } from '../../redux/MainReducer';
import ProgramInfoModal from '../../components/modals/ProgramInfoModal';

export class AdminProgramsByUniversity extends Component {
    state={
        page:1,
        search:'',
        degree_id:'',
    }
    componentDidMount(){
        this.props.getEduDegree();
        this.props.getPrograms(this.state.page, this.state.search,this.props.params.id, this.state.degree_id, "", "", "", "all")
    }
    onSearchData=(search) => {
        this.setState({search})
        this.props.getPrograms(this.state.page, search,this.props.params.id, this.state.degree_id, "", "", "", "all")
    }
    getProgramByDegree=(id)=>{
        this.setState({degree_id:id})
        this.props.getPrograms(this.state.page, this.state.search,this.props.params.id, id, "", "", "", "all")
    }
    loadMore=(page)=>{
        this.setState({page:page})
        this.props.getPrograms(page, this.state.search,this.props.params.id, this.state.degree_id, "", "", "", "all")
    }
    getDiscipliones=(disciplines)=>{
        let arr = [];
        disciplines?.map((data)=>{
            arr.push(data?.discipline?.name)
        });
        return arr.join(", ")
    }

    getReqValues=(req)=>{
        let arr = [];
        for(const property in req){
            if(property != "created_at" && property != "program_id" && property != "id"  && property !="required_education_degree_id" && property != "updated_at"){
                if(req[property]){
                    arr.push({
                        name: property.toUpperCase(),
                        value: req[property]
                    })
                }
            }
        }
        return arr;
    }
    openModal=(data)=>{
        this.props.changeStateValue({
            name:"programInfoModal",
            value:{
                position: true,
                name: data?.name,
                start_date: data?.start_date,
                deadline: data?.deadline,
                degree: data?.degree?.name,
                disciplines: this.getDiscipliones(data?.disciplines),
                language: data?.language?.name,
                required_degree: this.props.educationDegree?.find(x=> x.id == data?.requirements?.required_education_degree_id)?.name,
                requireds: this.getReqValues(data?.requirements)
            }
        })
    }
  render() {
    const {educationDegree, programs, programInfoModal} = this.props;
    const {degree_id} = this.state;
    // console.log(programInfoModal)
    return (
        <div className='w-full  pl-[27px] pr-[27px]'>
            <Header title={"Programs"} />
            <div className='flex justify-center mt-[20px]'>
                <Search onSearchData={this.onSearchData.bind(this)} largWidth={true} />
            </div>
            <div className='flex items-center gap-[20px] mt-[20px]'>
            {
                educationDegree.map((data, i)=>{
                return(
                    <button onClick={this.getProgramByDegree.bind(this, data?.id)} className={`${degree_id == data?.id? "bg-[var(--blue)] text-white":"border border-[var(--blue)] text-[var(--blue)]"} text-[12px] p-[4px_7px] rounded-full`}>
                    {data?.name}
                    </button>
                )
                })
            }
                <button onClick={this.getProgramByDegree.bind(this, "")} className={`border border-[var(--blue)] text-[var(--blue)] text-[12px] p-[4px_7px] rounded-full`}>
                    Reset
                </button>
            </div>
            <div className='grid-full-250 mt-[30px]'>
                {
                    programs?.map((data, i)=>{
                        return(
                            <Card1
                                    key={i}
                                    image={data?.image_full_url}
                                    nameFull={data?.name}
                                    country={data?.university?.name}
                                    onClickEdit={this.openModal.bind(this, data)}
                                
                            />
                        )
                    })
                }
            </div>
            {
                programs?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={programs?.length} />:null
            }
            {
                programInfoModal?.position? <ProgramInfoModal />:null
            }
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    educationDegree: state.Data.educationDegree,
    programs: state.Data.programs,
    programInfoModal: state.Data.programInfoModal,
});
const mapDispatchToProps = {getEduDegree, getPrograms, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(AdminProgramsByUniversity))
