import React, { Component } from 'react'
import img from "../images/searchingGif.gif"
export class AvailableProgramLoader extends Component {
  render() {
    return (
      <div className='background_shadow flex justify-center items-center'>
            <div className='bg-white p-[20px] shadow rounded-[10px] w-[500px] flex flex-col items-center'>
                <div className='bg-red-200 flex relative justify-center items-center'>
                  <img className='w-[200px]' src={img} />
                  <span className='absolute bg-white text-[12px] mb-[10px] font-regular text-gray-600'>Axtarılır</span>
                </div>
                <h3 className='text-center text-[20px] font-semibold'>Biz sizin təhsil bacarıqlarınıza uyğun olan proqramları toplayırıq. Coach hazır olana qədər, zəhmət olmasa gözləyin...</h3>
            </div>
      </div>
    )
  }
}

export default AvailableProgramLoader
