import React, { Component } from 'react'

export class CardDispSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={17}
        fill="none"
      >
        <path
          fill="#88888c"
          d="M7.999 1.833c-3.674 0-6.667 2.994-6.667 6.667 0 3.673 2.993 6.667 6.667 6.667 3.673 0 6.666-2.994 6.666-6.667 0-3.673-2.993-6.667-6.666-6.667Zm3.04 5.554-2.74 2.06c-.014.006-.034.013-.047.026-.02.007-.033.014-.053.02a.427.427 0 0 1-.2.054.485.485 0 0 1-.194-.047.325.325 0 0 0-.046-.02l-.04-.02-2-1.373a.502.502 0 0 1 .567-.827l1.7 1.167 2.453-1.84a.5.5 0 0 1 .6.8Z"
        />
      </svg>
    )
  }
}

export default CardDispSvg
