import React, { Component } from 'react'
import SectionHeader from '../../../components/public_components/SectionHeader'
import { connect } from 'react-redux';
import { getCityRankings } from '../../../actions/MainAction';
import RankingTable from './RankingTable';

export class CitiesRankingSection extends Component {
    componentDidMount(){
        this.props.getCityRankings()
    }
  render() {
    const {cityRankings, headerNone=false} = this.props;
    // console.log(cityRankings)
    return (
        <section className={`${!headerNone? "pt-[100px] bg-[#F5F5F5] ":""} `}>
            <div className='mt-[60px]'>
                {
                     !headerNone?
                 <SectionHeader text1="Ən yaxşı" text2="Şəhərlər" />    :null
                }
                
                <div className='max-w-[1200px] ml-auto mr-auto mt-[40px] pb-[80px] max-[1240px]:ml-[20px] max-[1240px]:mr-[20px]'>
                    <RankingTable
                        options={cityRankings} 
                        column1Title={"Sıralama"}
                        column2Title={"Şəhər"}
                        column3Title={"Ölkə"}
                        column1Property={"ranking"}
                        column2Property={"city"}
                        column3Property={"country"}
                        rank_property={"city_rank_id"}
                        count={3}
                        />
                </div>
                
            </div>
        
        </section>
    )
  }
}
const mapStateToProps = (state) =>({
    cityRankings: state.Data.cityRankings
});
const mapDispatchToProps= {getCityRankings}
export default connect(mapStateToProps, mapDispatchToProps)(CitiesRankingSection)
