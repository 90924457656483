import React, { Component } from 'react'
import { withHooks } from '../actions/withHooks'
import ErrorSvg from './svg/ErrorSvg'
import { resetStateValue } from '../redux/MainReducer'
import { connect } from 'react-redux'

export class NotFoundModal extends Component {
    closeData=()=>{
        this.props.resetStateValue({ name:'notFoundModal' })
        this.props.navigate("/")
    }
    componentDidMount(){
      setTimeout(()=>{
        this.props.navigate("/")
      }, 100)
    }
  render() {
    return (
        <div className='background_shadow flex items-center justify-center'>
            <div className='bg-white p-[20px] rounded-[12px] shadow max-w-[400px] w-[400px] flex flex-col items-center gap-[10px]'>
                <ErrorSvg />
                <h1 className='text-gray-500 font-semibold text-[26px]'>{404}</h1>
                <p className='text-center'>Məlumat tapılmadı.</p>
                <button onClick={this.closeData.bind(this)}  className='bg-blue text-[14px] rounded-[6px] h-[25px] w-[60px] text-white font-semibold text-center pt-[2px]'>OK</button>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    notFounModal: state.Data.notFounModal
});
const mapDispatchToProps = {resetStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(NotFoundModal))
