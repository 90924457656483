import React, { Component } from 'react'

export class TimeSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        data-id="details-item-icon"
        viewBox="0 0 10 10"
        width={20}
        height={20}
      >
        <path
          fill="none"
          stroke="#1a1a1a9c"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M.5 5a4.5 4.5 0 1 0 9 0 4.5 4.5 0 1 0-9 0Z"
        />
        <path
          fill="none"
          stroke="#1a1a1a9c"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M5 3.269V5l1.759 2.052"
        />
      </svg>
    )
  }
}

export default TimeSvg
