export function getLinkFormat(msg=""){
    if(msg?.includes(' ')){
        const arr = msg.split(' ');
        arr.forEach((data, i) => {
            if(data.includes('.')){
               if( data.split('.')[1]!== ''){
                if(data?.includes('http')){
                    const newLink = `<a style="color: #027eb5;" href="${data}" target="_blank">${data}</a>`;
                    arr[i] = newLink;
                }else{
                    const newLink = `<a style="color: #027eb5;" href="https://${data}" target="_blank">${data}</a>`;
                    arr[i] = newLink;
                }
                 
               
               }
               
            }
        })
        return arr.join(' ')
       
    }else{
        if(msg?.includes('.')){
            if( msg.split('.')[1]!== ''){
                if(msg?.includes('http')){
                     const newLink = `<a href="${msg}" style="color: #027eb5;" target="_blank">${msg}</a>`;
                     return newLink
                }else{
                    const newLink = `<a href="https://${msg}" style="color: #027eb5;" target="_blank">${msg}</a>`;
                    return newLink
                }
                
                
            }
           
        }else{
            return msg
        }
    }
}