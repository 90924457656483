import React, { Component } from 'react'
import Header from '../../components/header/Header'
import Search from '../../components/Search'
import Buttons from '../../components/Buttons'
import { connect } from 'react-redux'
import { changeStateValue } from '../../redux/MainReducer';
import { getCurrencies } from '../../actions/MainAction';
import Card1 from '../../components/cards/Card1'
import LoadMoreBtn from '../../components/LoadMoreBtn'
export class AdminCurrencies extends Component {
  state={
    page:1,
    search:''
  }
  openModal=(data)=>{
    this.props.changeStateValue({
      name:'adminModal',
      value:{
        position: true,
        id: data?.id?data?.id:'',
        name: data?.name?data?.name:'',
        afterCallFunction:this.props.getCurrencies,
        afterCallParams:[this.state.page, this.state.search],
        requireds: ["name"],
        url:'currency',
        modalTitle:`${data?.id?'Edit':'Add'} currency`
      }
    })
  }

  componentDidMount(){
    this.props.getCurrencies(1, '')
  }

 
  afterCallFunction=()=>{
    this.props.getCurrencies(this.state.page, this.state.search)
  }
  render() {
    const {currencies} = this.props;
  
    return (
        <div className='w-full  pl-[27px] pr-[27px]'>
            <Header title="Currency" />
            <div className='flex justify-end mt-[30px]'>
                <Buttons onClick={this.openModal.bind(this, '')} options={[{title:"Add a currency", value:0}]} selected={0} />
            </div>
            <div className='grid-full-250 mt-[30px]'>
              {
                currencies?.map((data, i)=>{
                  return(
                    <Card1 
                      key={i}
                    //   image={data?.image_full_url}
                      name={data?.name}
                      onClickEdit={this.openModal.bind(this, data)}
                      deletePath={`currency/${data?.id}`}
                      afterCallFunction={this.afterCallFunction.bind(this)}
                    />
                  )
                })
              }
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
  currencies: state.Data.currencies
});
const mapDispatchToProps = {changeStateValue, getCurrencies}
export default connect(mapStateToProps, mapDispatchToProps)(AdminCurrencies)




