import React, { Component } from 'react'
import AdvertisingSection from './student_dash/AdvertisingSection'
import { getStudentDashData } from '../../actions/MainAction';
import { connect } from 'react-redux';
import StudentProgramInfo from './student_dash/StudentProgramInfo';
import DocumentInfo from './student_dash/DocumentInfo';

export class StudentDashboard extends Component {
  componentDidMount(){
    this.props.getStudentDashData()
  }
  render() {
    const {studentDashData} = this.props;
    // console.log(studentDashData)
    return (
      <div>
        <AdvertisingSection />
        <div className='mt-[40px] grid grid-cols-3 gap-[20px] max-[1720px]:grid-cols-2 max-[1200px]:grid-cols-1'>
          <StudentProgramInfo emptyText="Təklif olunan ixtisas tapılmadı. Zəhmət olmasa təhsil profilinizi yeniləyin" link="/available-programs" options={studentDashData?.available_programs} title="Təklif olunanlar" />
          <StudentProgramInfo emptyText="Hələlik heç bir universitetə müraciət olunmayıb. Təklif olunan universitetlərdən seçib müraciət edin." link="/program-requirements/:student_id/:program_id" options={studentDashData?.applied_programs} title="Müraciət olunanlar" />
          <DocumentInfo options={studentDashData?.requirements} />
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  studentDashData: state.Data.studentDashData
});
const mapDispatchToProps = {getStudentDashData}
export default connect(mapStateToProps, mapDispatchToProps)(StudentDashboard)
