import React, { Component } from 'react'
import Input from '../modals/components/Input'
import { connect } from 'react-redux';
import DropSearch from '../modals/components/DropSearch';
import { getDropValue, getStudyMode } from '../../actions/getOtherFunctions';
import MultiSelect from '../modals/components/MultiSelect';

export class AddProgramBody extends Component {
    state={
        bodyVisible:false,
        commonVisible:false,
        requirementVisible:false,
        scholarshipVisible:false
    }
  render() {
    const {data, 
            onChangeParent=()=>{}, 
            educationDegree, 
            languages, 
            disciplines,
            type="all",
            addAllPrograms=()=>{},
            exams,
            highSchoolExams,
            checked=false,
            onChangeChecked=()=>{}
    } = this.props;
    const {bodyVisible, commonVisible, requirementVisible, scholarshipVisible} = this.state;
    // console.log(highSchoolExams)
    return (
      <div className='bg-white p-[10px] rounded-[10px]'>
        {
            type == "all"?
            <div className='flex justify-between items-center mb-[20px]'>
               <div className='flex items-center gap-[10px]'>
                    <input onChange={()=>onChangeChecked()} checked={checked} type='checkbox' />
                    <div className='w-[300px]'>
                            <Input onChangeParent={(name, value)=>onChangeParent(name, value)} name="name" value={data?.name} title="Name" required={true} />
                    </div>
                   <div className='min-w-[500px]'>
                   <MultiSelect selected={data?.disciplines} defaultNamePermission={false} onChangeParent={(name, value)=>onChangeParent(name, value)} placeholder={"Select a discipline"} options={getDropValue(disciplines, "name","id")} name="disciplines" title={"Select a discipline"} />
                   </div>
               </div>
                <button onClick={()=>this.setState({bodyVisible: !bodyVisible})} className='bg-blue text-white text-[12px] p-[4px_7px] rounded-[6px]'>{bodyVisible?'Hide':'Show'} Body</button>
            </div>:null
        }
        
        {
            bodyVisible || type=="default"?
            <div>
                <div className='border p-[10px] rounded-[10px]'>
                    <div className='cursor-pointer' onClick={()=>this.setState({commonVisible: !commonVisible, requirementVisible:false, scholarshipVisible:false})}>
                        <h3 className='text-[14px]'>Part 1</h3>
                    </div>
                    {
                    commonVisible?
                     <div className='grid grid-cols-4 gap-[20px] mt-[20px]'>
                        <DropSearch required name="degree_id"  onChangeParent={(name, value)=>onChangeParent(name, value)}  value={data?.degree_id} placeholder="Select a Degree" options={getDropValue(educationDegree, "name","id")} title="Select a Degree" /> 
                        <DropSearch required name="language_id"  onChangeParent={(name, value)=>onChangeParent(name, value)}  value={data?.language_id} placeholder="Select a Language" options={getDropValue(languages, "name","id")} title="Select a Language" />
                        <DropSearch required name="study_mode"  onChangeParent={(name, value)=>onChangeParent(name, value)}  value={data?.study_mode} placeholder="Select a study mode" options={getStudyMode()} title="Select a study mode" />
                        <MultiSelect selected={data?.disciplines} defaultNamePermission={false} onChangeParent={(name, value)=>onChangeParent(name, value)} placeholder={"Select a discipline"} options={getDropValue(disciplines, "name","id")} name="disciplines" title={"Select a discipline"} />
                        <Input onChangeParent={(name, value)=>onChangeParent(name, value)} name="start_date" type="date" value={data?.start_date} title="Start Date" required={true} />
                        <Input onChangeParent={(name, value)=>onChangeParent(name, value)} name="deadline" type="date" value={data?.deadline} title="Deadline" required={true} />
                        <Input onChangeParent={(name, value)=>onChangeParent(name, value)} name="duration" type="number" value={data?.duration} title="Duration" required={true} />
                    </div>:null
                    }
                </div>
                
               
                <div className='border p-[10px] rounded-[10px] mt-[20px]'>
                    <div className='cursor-pointer' onClick={()=>this.setState({requirementVisible: !requirementVisible, commonVisible:false, scholarshipVisible:false})}>
                        <h3 className='text-[14px]'>Part 2</h3>
                    </div>
                    {
                        requirementVisible?
                        <>
                        <span className='inline-block mt-[20px] mb-[20px] text-[13px]'>Requirements</span>
                        <div className='grid grid-cols-4 gap-[20px]'>
                            <DropSearch required name="required_education_degree_id"  onChangeParent={(name, value)=>onChangeParent(`requirements.${name}`, value)}  value={data?.requirements?.required_education_degree_id} placeholder="Select a required degree" options={getDropValue(educationDegree?.filter(x=>x.id ==1|| x.id==3 || x.id == 4 || x.id == 5), "name","id")} title="Select a required degree" /> 
                            <Input onChangeParent={(name, value)=>onChangeParent(`requirements.${name}`, value)} name="gpa" type="number" value={data?.requirements?.gpa} title="GPA"  />
                            {
                                data?.requirements?.required_education_degree_id==1 || data?.requirements?.required_education_degree_id==2?
                                <>
                                {
                                    highSchoolExams?.map((highExam, j)=>{
                                        if(highExam?.grades.length === 0){
                                            return(
                                                 <Input minValue={highExam?.min_value} maxValue={highExam?.max_value} step={1} onChangeParent={(name, value)=>onChangeParent(`requirements.${highExam?.name?.replaceAll(" ", '').toLowerCase()}`, value)} name={`${highExam?.name?.replaceAll(" ", '').toLowerCase()}`}  value={data?.requirements[highExam?.name?.replaceAll(" ", '').toLowerCase()]} title={highExam?.name}  />
                                            )
                                        }else{
                                            return(
                                                <DropSearch  
                                                    name={highExam?.name?.replaceAll(" ", '_').toLowerCase()}  
                                                    onChangeParent={(name, value)=>onChangeParent(`requirements.${highExam?.name?.replaceAll(" ", '_').toLowerCase()}`, value)}  
                                                    value={data?.requirements[highExam?.name?.replaceAll(" ", '_').toLowerCase()]} 
                                                    placeholder={`Select a ${highExam?.name}`} 
                                                    options={getDropValue(highExam?.grades, "grade","grade")} 
                                                    title={`Select a ${highExam?.name}`} /> 
                                            )
                                        }
                                    })
                                }
                                </>:null
                            }
                            {
                                exams?.map((exam, j)=>{
                                    return(
                                        <Input key={j} 
                                            minValue={exam?.min_value} 
                                            maxValue={exam?.max_value} 
                                            step={exam?.step}
                                            onChangeParent={(name, value)=>onChangeParent(`requirements.${exam?.name?.toLowerCase()?.replaceAll(" ", "_")}`, value)} 
                                            name={`${exam?.name?.toLowerCase()?.replaceAll(" ", "_")}`}  
                                            value={data?.requirements[`${exam?.name?.toLowerCase()?.replaceAll(" ", "_")}`]} 
                                            title={exam?.name}  />
                                    )
                                })
                            }
                        </div>
                        </>:null
                    }
                </div>
                <div  className='border p-[10px] rounded-[10px] mt-[20px]'>
                    <div className='cursor-pointer' onClick={()=>this.setState({scholarshipVisible: !scholarshipVisible, commonVisible:false, requirementVisible:false})}>
                        <h3 className='text-[14px]'>Part 3</h3>
                    </div>
                    {
                        scholarshipVisible?
                        <>
                        <span className='inline-block mt-[20px] mb-[20px] text-[13px]'>Scholarship</span>
                        <div className='grid grid-cols-4 gap-[20px]'>
                            <DropSearch required name="req_edu_degree_id"  onChangeParent={(name, value)=>onChangeParent(`scholarship.${name}`, value)}  value={data?.scholarship?.req_edu_degree_id} placeholder="Select a required degree" options={getDropValue(educationDegree, "name","id")} title="Select a required degree" /> 
                            <Input onChangeParent={(name, value)=>onChangeParent(`scholarship.${name}`, value)} name="amount" type="text" value={data?.scholarship?.amount} title="Amount"  />
                            <Input onChangeParent={(name, value)=>onChangeParent(`scholarship.${name}`, value)} name="gpa" type="number" value={data?.scholarship?.gpa} title="GPA"  />
                            {
                                data?.scholarship?.req_edu_degree_id==1 || data?.scholarship?.req_edu_degree_id==2?
                                <>
                                {
                                    highSchoolExams?.map((highExam, j)=>{
                                        if(highExam?.grades.length === 0){
                                            return(
                                                 <Input minValue={highExam?.min_value} maxValue={highExam?.max_value} step={1} onChangeParent={(name, value)=>onChangeParent(`scholarship.${highExam?.name?.replaceAll(" ", '').toLowerCase()}`, value)} name={`${highExam?.name?.replaceAll(" ", '').toLowerCase()}`}  value={data?.scholarship[highExam?.name?.replaceAll(" ", '').toLowerCase()]} title={highExam?.name}  />
                                            )
                                        }else{
                                            return(
                                                <DropSearch required name={highExam?.name?.replaceAll(" ", '_').toLowerCase()}  onChangeParent={(name, value)=>onChangeParent(`scholarship.${highExam?.name?.replaceAll(" ", '_').toLowerCase()}`, value)}  value={data?.scholarship[highExam?.name?.replaceAll(" ", '_').toLowerCase()]} placeholder={`Select a ${highExam?.name}`} options={getDropValue(highExam?.grades, "grade","grade")} title={`Select a ${highExam?.name}`} /> 
                                            )
                                        }
                                    })
                                }
                                </>:null
                            }
                             {
                                exams?.map((exam, j)=>{
                                    return(
                                        <Input key={j} 
                                            minValue={exam?.min_value} 
                                            maxValue={exam?.max_value} 
                                            step={exam?.step}
                                            onChangeParent={(name, value)=>onChangeParent(`scholarship.${exam?.name?.toLowerCase()}`, value)} 
                                            name={`${exam?.name?.toLowerCase()}`}  
                                            value={data?.scholarship[`${exam?.name?.toLowerCase()}`]} 
                                            title={exam?.name}  />
                                    )
                                })
                            }
                        </div>
                        </>:null
                    }
                </div>
                {
                    type == "default"?
                    <div className='flex justify-end mt-[20px]'>
                        <button onClick={()=>addAllPrograms()} className='bg-blue text-white text-[12px] p-[4px_7px] rounded-[6px]'>Save All</button>
                    </div>:null
                }
        </div>:null
        }
       
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    educationDegree: state.Data.educationDegree,
    languages: state.Data.languages,
    disciplines: state.Data.disciplines,
    exams: state.Data.exams,
    highSchoolExams: state.Data.highSchoolExams,
})
export default connect(mapStateToProps)(AddProgramBody)
