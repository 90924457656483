import React, { Component } from 'react'
import moment from 'moment';
// import { getColor, getRole } from '../../actions/getRole';
import { connect } from 'react-redux';
import Header from './components/Header';
import ToCard from '../../pages/messages/components/ToCard';
import { getColor, getRole } from '../../actions/getOtherFunctions';

export class MessagePinnedModal extends Component {
    closeModal=()=>{
        this.props.closeModalBtn()
    }
    closeModalShadow(e){
        if(typeof e.target.className == 'string' && e.target.className?.includes("background_shadow")) this.closeModal();
    }
  render() {
    const {messages, lang} = this.props;
    return (
        <div onClick={this.closeModalShadow.bind(this)} className='background_shadow flex justify-center items-center max-sm:pl-[20px] max-sm:pr-[20px]'>
            <div className='bg-[#F5F5F5] w-[600px]  shadow rounded-[10px] '>
                <Header closeBtn={this.closeModal.bind(this)} title={'Sabitlənmiş mesajlar'} />
                <div className='p-[20px] max-h-[60vh] overflow-auto'>
                    {
                        messages?.map((item, i)=>{
                            const data = item?.message;
                            return(
                                <ToCard
                                        key={i}
                                        userImg={data.user?.image_full_url}
                                        msg={data?.text}
                                        date={moment(data?.created_at).format("DD/MM/YYYY HH:mm")}
                                        forward={false}
                                        userName={data?.user?.full_name}
                                        role={getRole(data?.user?.role, lang)}
                                        color={getColor(data?.user?.role)}
                                      
                                        msgImage={data?.image_full_url}
                                        msgAudio={data?.audio_full_url}
                                        msgPdf={data?.file_full_url}
                                        onReply={()=>{}}
                                        data={data}
                                        noReply={true}
                                    />
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({

})
export default connect(mapStateToProps)(MessagePinnedModal)
