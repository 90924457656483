import React, { Component } from 'react'

export class NavApplySvg extends Component {
  render() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={18}
            height={18}
            viewBox="0 0 16 16"
        >
    <path
      d="M5 11a2 2 0 0 0 2-2 2 2 0 0 0-2-2 2 2 0 0 0-2 2 2 2 0 0 0 2 2Zm0-3a1 1 0 0 1 1 1 1 1 0 0 1-1 1 1 1 0 0 1-1-1 1 1 0 0 1 1-1Zm2.5 4h-5A2.5 2.5 0 0 0 0 14.5 1.5 1.5 0 0 0 1.5 16h7a1.5 1.5 0 0 0 1.5-1.5A2.5 2.5 0 0 0 7.5 12Zm1 3h-7a.5.5 0 0 1-.5-.5A1.5 1.5 0 0 1 2.5 13h5A1.5 1.5 0 0 1 9 14.5a.5.5 0 0 1-.5.5ZM16 2.5v5a2.5 2.5 0 0 1-2.5 2.5h-2.793l-1.853 1.854A.5.5 0 0 1 8.5 12a.493.493 0 0 1-.191-.038A.5.5 0 0 1 8 11.5v-2a.5.5 0 0 1 .5-.5.5.5 0 0 1 .5.5v.793l1.146-1.147A.5.5 0 0 1 10.5 9h3A1.5 1.5 0 0 0 15 7.5v-5A1.5 1.5 0 0 0 13.5 1h-7A1.5 1.5 0 0 0 5 2.5v3a.5.5 0 0 1-.5.5.5.5 0 0 1-.5-.5v-3A2.5 2.5 0 0 1 6.5 0h7A2.5 2.5 0 0 1 16 2.5ZM8.146 6.146 10.293 4H8.5a.5.5 0 0 1-.5-.5.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .191.038.506.506 0 0 1 .271.271A.5.5 0 0 1 12 3.5v3a.5.5 0 0 1-.5.5.5.5 0 0 1-.5-.5V4.707L8.854 6.854A.5.5 0 0 1 8.5 7a.5.5 0 0 1-.354-.146.5.5 0 0 1 0-.708Z"
      data-name="Path 44"
    />
  </svg>
    )
  }
}

export default NavApplySvg
