import React, { Component } from 'react'
import Header from '../components/header/Header'
import { connect } from 'react-redux';
import { insertNewData, checkLoginUser } from '../actions/MainAction';
import ProfileEditCard from '../components/ProfileEditCard';
import { changeStateValue } from '../redux/MainReducer';
import ImageSvg from '../components/svg/ImageSvg';
export class ProfilePage extends Component {
  state={
    activeIndex:0,
    base64:''
  }
  onClickSave=(data)=>{
    let formData = new FormData();

    for(const property in data){
      if(data[property]){
        this.props.changeStateValue({
          name: `user.${property}`,
          value: data[property]
        })
        formData.append(property, data[property])
      }
    }
    formData.append("_method", "put")
    this.props.insertNewData(`users/${this.props.user?.id}`, formData)
    .then(resp=>{
      if(resp === "success"){
        this.props.checkLoginUser();
        this.setState({activeIndex:0, base64:''})
      }
    })

  }
  getBase64(file){
    return new Promise((res, rej)=>{
        let reader = new FileReader();
        reader.onload = () => {
            this.setState({base64: reader.result})
            res("success");
        }
        reader.readAsDataURL(file)
    })
}
setImg(e){
    this.getBase64(e.target.files[0])
    .then(resp=>{
        if(resp === "success"){
            this.props.changeStateValue({name: "user.image", value: e.target.files[0]})
        }
    })
}
  render() {
    const {user} = this.props;
    const {activeIndex, base64} = this.state;
    // console.log( user?.image_full_url)
    return (
      <div className='w-full  pl-[27px] pr-[27px]'>
        <Header title="Profile" />
        <div className='flex flex-col items-center '>
          <div>
            <div className='flex justify-center flex-col items-center'>
                <label htmlFor='profileImgId' className='cursor-pointer inline-flex items-center flex-col gap-[5px]'>
                    {
                        base64 !==""?
                        <img alt={user?.full_name} src={base64} className='w-[99px] h-[99px] rounded-full' />:
                        user?.image_full_url?
                        <img alt={user?.full_name} src={user?.image_full_url} className='w-[99px] h-[99px] rounded-full' />:
                        <div className='w-[99px] h-[99px] bg-gray-300 rounded-full flex items-center justify-center'>
                            <ImageSvg />
                        </div>
                    }
                    {/* <span className='txt-blue font-bold text-[14px]'>{"Upload Image"}</span> */}
                </label>
                {
                  base64 !==""?
                  <div className='mt-[10px]'>
                    <button onClick={this.onClickSave.bind(this, {image: user?.image})} className='p-[8px_24px] rounded-full bg-blue text-white text-[15px]'>Save</button>
                  </div>:null
                }
                
            </div>
            <input onChange={this.setImg.bind(this)} accept='image/*' id='profileImgId' type='file' style={{ display:'none' }} />
          </div>
          <ProfileEditCard 
            name1="first_name" 
            name2="last_name" 
            title={"Adınız"} 
            value={user?.full_name} 
            value1={user?.first_name} 
            value2={user?.last_name} 
            onClickSave={this.onClickSave.bind(this)}
            index={1}
            activeIndex={activeIndex}
            onChangeVisible={(index)=>{this.setState({activeIndex:index})}}
            />
          <ProfileEditCard 
            name1="email" 
            title={"Email"} 
            value={user?.email} 
            value1={user?.email} 
            onClickSave={this.onClickSave.bind(this)}
            index={2}
            activeIndex={activeIndex}
            onChangeVisible={(index)=>{this.setState({activeIndex:index})}}
            />
          <ProfileEditCard 
            name1="phone_number" 
            title={"Mobil nömrə"} 
            value={user?.phone_number} 
            value1={user?.phone_number} 
            onClickSave={this.onClickSave.bind(this)}
            index={3}
            type="tel"
            activeIndex={activeIndex}
            onChangeVisible={(index)=>{this.setState({activeIndex:index})}}
            />
          <ProfileEditCard 
            name1="date_of_birth" 
            title={"Doğum tarixi"} 
            value={user?.date_of_birth} 
            value1={user?.date_of_birth} 
            onClickSave={this.onClickSave.bind(this)}
            index={4}
            type="date"
            activeIndex={activeIndex}
            onChangeVisible={(index)=>{this.setState({activeIndex:index})}}
            />
          <ProfileEditCard 
            name1="password" 
            title={"Şifrə"} 
            value={user?.password} 
            value1={user?.password} 
            onClickSave={this.onClickSave.bind(this)}
            index={5}
            type="password"
            activeIndex={activeIndex}
            onChangeVisible={(index)=>{this.setState({activeIndex:index})}}
            />
          
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  user: state.Data.user
});
const mapDispatchToProps = {insertNewData, changeStateValue, checkLoginUser}
export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage)
