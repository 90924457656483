import React, { Component } from 'react'
import Top3 from '../../../images/rankings/top3.png';
import Top5 from '../../../images/rankings/top5.png';
import Top10 from '../../../images/rankings/top10.png';
import Top20 from '../../../images/rankings/top20.png';
import Top500More from '../../../images/rankings/top500+.png';
import Top50 from '../../../images/rankings/top50.png';
import Top100 from '../../../images/rankings/top100.png';
import Top200 from '../../../images/rankings/top200.png';
import Top500 from '../../../images/rankings/top500.png';
export class Card1 extends Component {
    getSvg(item){
        if(item<=3){
          return Top3 
          
        }else if( item<=5 &&  item>3){
          return Top5 
        }else if(item<=10 &&  item>5){
          return Top10 
        }else if( item<=20 &&  item>10){
          return Top20 
        }else if( item<=50 &&  item>20){
          return Top50 
        }else if(item<=100 &&  item>50){
          return Top100 
        }else if(item<=200 &&  item>100){
          return Top200 
        }else if(item<=500 &&  item>200){
          return Top500
        }else{
          return Top500More
        }
      }
  render() {
    const {data} = this.props;
    return (
        <div className='bg-white shadow rounded-[12px] relative'>
        <div className="absolute top-3  inset-x-3 flex justify-start">
           <img style={{ width: '26px' }} src={this.getSvg(data?.ranking)} />
         </div>
       <img src={data?.inst_image?.image_full_url} alt={data?.inst_name} className='w-full h-[191px] rounded-t-[12px]' />
       <div style={{ clipPath:'polygon(0 0, 100% 90%, 100% 100%, 0 100%)', width:'100%',height:'60px',position:'relative', backgroundColor:'white', marginTop:'-56px'}}></div>
       <div className='w-[71px] h-[71px] bg-white rounded-full flex items-center justify-center p-[4px] mt-[-70px] ml-[auto] mr-auto relative'>
           <img src={data?.user_image} alt={data?.first_name} className='w-full h-full rounded-[50%]' />
       </div>
       <div className='mt-[10px] relative pl-[20px] pr-[20px] pb-[25px] '>
           <h3 className='txt-blue text-[18px] font-bold text-center'>{data?.first_name} {data?.last_name?.substring(0,1)}...</h3>
           <h4 className='text-[#2C2C2C] text-[14px] font-bold text-center'>{data?.inst_name}</h4>
           <span className='text-[#595959] text-[12px] font-medium text-center block'>{data?.program_name}</span>
       </div>
     </div>
    )
  }
}

export default Card1
