import React, { Component } from 'react'

import { connect } from 'react-redux'
import { changeStateValue } from '../../../redux/MainReducer';
import PdfWhiteSvg from '../../../components/svg/PdfWhiteSvg';
import MusicSvg from '../../../components/svg/MusicSvg';
import ImgSvg from '../../../components/svg/ImgSvg';
export class AttachContainer extends Component {
    getBase64(file){
        return new Promise((res, rej)=>{
            let reader = new FileReader();
            reader.onload = ()=>{
                res(reader.result)
            }
            reader.readAsDataURL(file)
        })
    }
    getMsgFile(type,e){
        this.getBase64(e.target.files[0])
        .then(resp=>{
            this.props.changeStateValue({
                name: "messageFileSend",
                value: {
                    position:true,
                    file:e.target.files[0],
                    preview:resp,
                    type:type,
                    replied_id:this.props.replayData?.id?this.props.replayData?.id:'',
                }
            })
        })
        this.props.onClose()
    }
  render() {
    // console.log(this.props.replayData)
    return (
        <div className='absolute z-50 h-[100px] w-[50px]  bottom-[45px] left-[-6px] flex flex-col items-center gap-[10px]'>
            <label htmlFor='msgPdfInp' className='bg-blue inline-flex w-[27px] h-[27px] items-center justify-center rounded-full cursor-pointer'>
                <PdfWhiteSvg />
            </label>
            <label htmlFor='msgAudioInp' className='bg-blue inline-flex w-[27px] h-[27px] items-center justify-center rounded-full cursor-pointer'>
                <MusicSvg />
            </label>
            <label htmlFor='msgImgInp' className='bg-blue inline-flex w-[27px] h-[27px] items-center justify-center rounded-full cursor-pointer'>
                <ImgSvg />
            </label>

            <input type='file' onChange={this.getMsgFile.bind(this, "pdf")} id='msgPdfInp' className='hidden' accept='application/pdf' />
            <input type='file' onChange={this.getMsgFile.bind(this, "audio")} id='msgAudioInp' className='hidden' accept='audio/mp3, audio/mp4' />
            <input onChange={this.getMsgFile.bind(this, "image")} type='file' id='msgImgInp' className='hidden' accept='image/png, image/jpg, image/jpeg' />
        </div>
    )
  }
}
const mapStateToProps = (state) =>({

});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(AttachContainer)
