import React, { Component } from 'react'
import AppliedSvg from '../public_components/svg/AppliedSvg';
import ProfileCheckSvg from '../svg/ProfileCheckSvg';
import userImg from "../../images/def_user.png"
import SideLogOutSvg from '../svg/SideLogOutSvg';
import { connect } from 'react-redux';
import { withHooks } from '../../actions/withHooks';
import { changeStateValue } from '../../redux/MainReducer';
import { getStudentComplete } from '../../actions/MainAction';
import defImg from "../../images/def_user.png"
import { Link } from 'react-router-dom';
export class UserInfo extends Component {
  componentDidMount(){
    this.props.getStudentComplete()
  }
  logOutBtn=()=>{
    localStorage.removeItem("celtGoMainToken");
    this.props.changeStateValue({
        name:'user',
        value: ""
    })
    this.props.navigate("/")
}

  render() {
    const {user,profileComplete} = this.props;
    return (
      <div className="flex flex-col items-center mt-[20px]">
       <div className='flex justify-between w-full items-center pl-[30px] pr-[30px]'>
        <div className='w-[48px]'></div>
        <Link to={'/profile'}>
          <img src={user?.image_full_url ?user?.image_full_url:defImg} onError={(e)=>{e.target.src=defImg}} className='w-[90px] h-[90px] rounded-full' />
        </Link>
        <button onClick={this.logOutBtn.bind(this)} className='w-[48px] h-[48px] rounded-[15px] inline-flex justify-center items-center bg-[#F6F5FA]'>
          <SideLogOutSvg />
        </button>
       </div>
        <h3 className='text-[18px] font-medium text-[#464255] '><Link className='flex  items-center gap-[10px]' to={'/profile'}>{user?.full_name} <ProfileCheckSvg /> </Link></h3>
        {
          user?.role == "student"?
          <div className='w-full flex justify-center mt-[14px] flex-col items-center'>
            <span className='h-[9px] w-[80%] inline-flex items-center border border-[#ACBDF4] rounded-[10px] bg-[#D0DBFF]'>
                <span style={{ width: `${profileComplete}%` }} className={`h-[7px] bg-[#193695] rounded-[10px]`}></span>
            </span>
            <div className='flex  w-[80%] justify-between items-center mt-[2px]'>
                <span className='text-[#8A8A8A] font-medium text-[8px]'>Profiliniz {profileComplete}% tamamlanıdı. {profileComplete<100? "Daha çox tamamlayın, daha yaxşı nəticə alın.":""}</span>
                {/* <span className='text-[#8A8A8A] font-medium text-[8px]'>updated 10 days ago</span> */}
            </div>
        </div>:null
        }
        
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  profileComplete: state.Data.profileComplete
});
const mapDispatchToProps = {changeStateValue, getStudentComplete}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(UserInfo))
