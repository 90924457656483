import React, { Component } from 'react'

export class CardTitleSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 96 96"
      >
        <title />
        <path d="M90 0H30a5.997 5.997 0 0 0-6 6v18H6a5.997 5.997 0 0 0-6 6v48a18.02 18.02 0 0 0 18 18h60a18.02 18.02 0 0 0 18-18V6a5.997 5.997 0 0 0-6-6ZM12 36h12v42a6 6 0 0 1-12 0Zm72 42a6.012 6.012 0 0 1-6 6H34.895A17.792 17.792 0 0 0 36 78V12h48Z" />
        <path d="M54 36h12a6 6 0 0 0 0-12H54a6 6 0 0 0 0 12ZM66 48H54a6 6 0 0 0 0 12h12a6 6 0 0 0 0-12Z" />
      </svg>
    )
  }
}

export default CardTitleSvg
