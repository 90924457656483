import React, { Component } from 'react'
import { deleteData, getDisciplines, getPrograms, getSingleData, insertNewData } from '../../actions/MainAction';
import { connect } from 'react-redux';
import { withHooks } from '../../actions/withHooks';
import Header from '../../components/header/Header';
import ProgDisciplineCard from '../../components/ProgDisciplineCard';
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts';
import LoadMoreBtn from '../../components/LoadMoreBtn';

export class AdminSingleDiscipline extends Component {
    state={
        disciplineData:'',
        page:1,
        modalPosition:false,
        program_name:'',
        program_id:''
    }
    componentDidMount(){
        this.props.getSingleData(`discipline/${this.props.params.id}`)
        .then(resp=>{
            this.setState({disciplineData: resp})
        })
        this.props.getPrograms(1, '', '', '', this.props.params.id)
        this.props.getDisciplines(10, '')
    }

    onDeleteDiscipline=(id)=>{
      confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, delete it")
      .then(alertResp=>{
          if(alertResp === "success"){
          this.props.deleteData(`delete-iscipline/${id}`)
          .then(resp=>{
              if(resp == "success"){
                  this.props.getPrograms(this.state.page, '', '', '', this.props.params.id)
                  mixinAlert("success", "Deleted successfully")
              }else{
                  mixinAlert("error", "Something went wrong")
              }
          })
        }
      })
    }
    onDeleteProgram=(id)=>{
    
      confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, delete it")
      .then(alertResp=>{
          if(alertResp === "success"){
          this.props.deleteData(`programs/${id}`)
          .then(resp=>{
              if(resp == "success"){
                  this.props.getPrograms(this.state.page, '', '', '', this.props.params.id)
                  mixinAlert("success", "Deleted successfully")
              }else{
                  mixinAlert("error", "Something went wrong")
              }
          })
        }
      })
    }

    loadMore=(page)=>{
      this.setState({page})
      this.props.getPrograms(page, '', '', '', this.props.params.id)
    }
    addDiscipline=(program_id, id)=>{
      confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, add it")
      .then(alertResp=>{
          if(alertResp === "success"){
            let formData = new FormData();
            formData.append("program_id", program_id);
            formData.append("discipline_id", id);
          this.props.insertNewData(`add-discipline`, formData)
          .then(resp=>{
              if(resp == "success"){
                  this.props.getPrograms(this.state.page, '', '', '', this.props.params.id)
                  mixinAlert("success", "added successfully")
                  this.setState({modalPosition:'', program_id:'', program_name:''})
              }else{
                  mixinAlert("error", "Something went wrong")
              }
          })
        }
      })
    }
  render() {
    const {disciplineData, modalPosition, program_id, program_name} = this.state;
    const {programs, disciplines} = this.props;
    return (
        <div className='w-full  pl-[27px] pr-[27px]'>
            <Header title={disciplineData?.name}/>
            <div className='w-full mt-[20px] flex flex-col gap-[20px] mb-[40px]'>
              {
                programs?.map((data, i)=>{
                  return(
                    <ProgDisciplineCard 
                      key={i} 
                      data={data} 
                      onDeleteDiscipline={this.onDeleteDiscipline.bind(this)}
                      onDeleteProgram={this.onDeleteProgram.bind(this)}
                      openModal={(program_id, program_name)=>this.setState({modalPosition:true, program_id, program_name})}
                    />
                  )
                })
              }
               {
                programs?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={programs?.length} />:null
            }
            </div>
            {
              modalPosition?
              <div className='background_shadow flex justify-center items-center'>
              <div className='p-[10px] bg-white rounded-[10px] w-[600px] '>
                <h3 className='text-[19px] text-center'>{program_name}</h3>
                <ul className='flex flex-col gap-[20px] max-h-[70vh] overflow-auto'>
                  {
                    disciplines.map((data, i)=>{
                      return(
                        <li className='flex justify-between items-center border-b pb-[10px]' key={i}>{data?.name} <button onClick={this.addDiscipline.bind(this, program_id, data?.id)} className='bg-blue text-[12px] text-white p-[4px_8px] rounded-full'>Add</button></li>
                      )
                    })
                  }
                </ul>
                <div>
                  <button onClick={()=>this.setState({modalPosition:'', program_id:'', program_name:''})} className='bg-red mt-[10px] text-[12px] text-white p-[4px_8px] rounded-full'>Close</button>
                </div>
              </div>
            </div>:null
            }
           
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  programs: state.Data.programs,
  disciplines: state.Data.disciplines,
});
const mapDispatchToProps = {getSingleData, getPrograms, deleteData, getDisciplines, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(AdminSingleDiscipline))
