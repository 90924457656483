import React, { Component } from 'react'

export class CheckRoundedSvg extends Component {
  render() {
    const {color="#ABABAB", size=26} = this.props;
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
        >
            <path
            fill={color}
            d="M18.28 9.22a.75.75 0 0 1 0 1.06l-7 7a.75.75 0 0 1-1.06 0l-3-3a.75.75 0 0 1 1.06-1.06l2.47 2.469 6.47-6.469a.75.75 0 0 1 1.06 0Zm7.22 3.53A12.75 12.75 0 1 1 12.75 0 12.765 12.765 0 0 1 25.5 12.75Zm-1.5 0A11.25 11.25 0 1 0 12.75 24 11.262 11.262 0 0 0 24 12.75Z"
            />
        </svg>
    )
  }
}

export default CheckRoundedSvg
