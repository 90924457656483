import React, { useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import Dashboard from './pages/Dashboard'
import LoginPage from './pages/LoginPage'
import { useDispatch, useSelector } from 'react-redux'
import { checkLoginUser } from './actions/MainAction'
import SideBar from './components/sidebar/SideBar'
import AdminCountriesPage from './pages/admin_role/AdminCountriesPage'
import AdminStatesPage from './pages/admin_role/AdminStatesPage'
import AdminModal from './components/modals/AdminModal'
import Loader from './components/Loader'
import ErrorPage from './components/ErrorPage'
import AdminCitiesPage from './pages/admin_role/AdminCitiesPage'
import AdminUniversityType from './pages/admin_role/AdminUniversityType'
import AdminUniversityPage from './pages/admin_role/AdminUniversityPage'
import AdminDisciplinePage from './pages/admin_role/AdminDisciplinePage'
import AdminEducationDegreePage from './pages/admin_role/AdminEducationDegreePage'
import AdminLanguagePage from './pages/admin_role/AdminLanguagePage'
import AdminProgramsPage from './pages/admin_role/AdminProgramsPage'
import AdminXTMPage from './pages/admin_role/AdminXTMPage'
import Managers from './pages/xtm_role/Managers'
import Teachers from './pages/manager_role/Teachers'
import Students from './pages/teacher_role/Students'
import AdminAddProgramsAll from './pages/admin_role/AdminAddProgramsAll'
import AdminExamsPage from './pages/admin_role/AdminExamsPage'
import AdminHighSchoolExamPage from './pages/admin_role/AdminHighSchoolExamPage'
import MainPage from './pages/public_pages/MainPage'
import Header from './components/public_components/Header'
import ConsultingPage from './pages/public_pages/ConsultingPage'
import SingleCountryPage from './pages/public_pages/SingleCountryPage'
import AcceptedStudents from './pages/public_pages/AcceptedStudents'
import CountriesPage from './pages/public_pages/CountriesPage'
import DisciplinesPage from './pages/public_pages/DisciplinesPage'
import SingleUniversityPage from './pages/public_pages/SingleUniversityPage'
import EditStudentMain from './pages/teacher_role/students/EditStudentMain'
import UserModal from './components/modals/UserModal'
import EducationBackgroundModal from './components/modals/EducationBackgroundModal'
import StudyDestinationModal from './components/modals/StudyDestinationModal'
import StudentExamsModal from './components/modals/StudentExamsModal'
import StudentInfoPage from './pages/teacher_role/students/StudentInfoPage'
import AdminApplyRequirementsPage from './pages/admin_role/AdminApplyRequirementsPage'
import StudentProgramRequirements from './pages/teacher_role/students/StudentProgramRequirements'
import SeeTextModal from './components/modals/SeeTextModal'
import AvailablePrograms from './pages/student_role/AvailablePrograms'
import StudentAppliedPrograms from './pages/student_role/StudentAppliedPrograms'
import StudentInfo from './pages/student_role/StudentInfo'
import StudentMessage from './pages/student_role/StudentMessage'
import StudentWishListPage from './pages/student_role/StudentWishListPage'
import AdminExpencesCategories from './pages/admin_role/AdminExpencesCategories'
import AdminCurrencies from './pages/admin_role/AdminCurrencies'
import StudentExpencesModal from './components/modals/StudentExpencesModal'
import ProfilePage from './pages/ProfilePage'
import AdminCostumerReviewPage from './pages/admin_role/AdminCostumerReviewPage'
import AdminBlogsPage from './pages/admin_role/AdminBlogsPage'
import BlogPage from './pages/public_pages/BlogPage'
import SingleBlogPage from './pages/public_pages/SingleBlogPage'
import BookingPage from './pages/public_pages/BookingPage'
import AdminBookingInfo from './pages/admin_role/AdminBookingInfo'
import StudentApplyPage from './pages/public_pages/StudentApplyPage'
import VerifyModal from './components/modals/VerifyModal'
import AddProgramAuto from './pages/admin_role/AddProgramAuto'
import AddRankingAuto from './pages/admin_role/AddRankingAuto'
import moment from 'moment'
import StudentNewReuiremenmtModal from './components/modals/StudentNewReuiremenmtModal'
import StudentAddressModal from './components/modals/StudentAddressModal'
import StudentCvModal from './components/modals/StudentCvModal'
import StudentPassportModal from './components/modals/StudentPassportModal'
import AdminSingleDiscipline from './pages/admin_role/AdminSingleDiscipline'
import AdminBookingDate from './pages/admin_role/AdminBookingDate'
import CityRankingPage from './pages/admin_role/CityRankingPage'
import UniversityRankingPage from './pages/admin_role/UniversityRankingPage'
import EmployeeRankingPage from './pages/admin_role/EmployeeRankingPage'
import SubjectRankingPage from './pages/admin_role/SubjectRankingPage'
import AdminSubjectsPage from './pages/admin_role/AdminSubjectsPage'
import SubjectPages from './pages/public_pages/SubjectPages'
import SingleSubjectsPage from './pages/public_pages/SingleSubjectsPage'
import ContactPage from './pages/public_pages/ContactPage'
import ApplyRankingModal from './components/modals/ApplyRankingModal'
import RankingConfirmModal from './components/modals/RankingConfirmModal'
import CitiesRankingPage from './pages/public_pages/CitiesRankingPage'
import EmployabilityRankingPage from './pages/public_pages/EmployabilityRankingPage'
import RankingAppliesPage from './pages/admin_role/RankingAppliesPage'
import UniversityRanks from './pages/public_pages/UniversityRanks'
import AdminApplicationTrackings from './pages/admin_role/AdminApplicationTrackings'
import AdminAccomadationTrackings from './pages/admin_role/AdminAccomadationTrackings'
import AdminVisaTrackings from './pages/admin_role/AdminVisaTrackings'
import ExpencessModal from './components/modals/ExpencessModal'
import TeacherMessages from './pages/teacher_role/TeacherMessages'
import AdminTop300RankingsPage from './pages/admin_role/AdminTop300RankingsPage'
import AdminTop20RankingsPage from './pages/admin_role/AdminTop20RankingsPage'
import Top20Universities from './pages/public_pages/Top20Universities'
import Top300Universities from './pages/public_pages/Top300Universities'
import NotFoundModal from './components/NotFoundModal'
import MenuSvg from './components/public_components/svg/MenuSvg'
import AdminContentManagers from './pages/admin_role/AdminContentManagers'
import AdminAdvertising from './pages/admin_role/AdminAdvertising'
import CeltAzAppliesPage from './pages/admin_role/CeltAzAppliesPage'
import TeacherStudens from './pages/teacher_role/TeacherStudens'
import AddAboutAuto from './pages/admin_role/AddAboutAuto'
import AdminUniByCountry from './pages/admin_role/AdminUniByCountry'
import AdminCountryScholarship from './pages/admin_role/AdminCountryScholarship'
import SchCountries from './pages/admin_role/country_scholarship/SchCountries'
import SchUni from './pages/admin_role/country_scholarship/SchUni'
import SchPrograms from './pages/admin_role/country_scholarship/SchPrograms'
import EducationPlanModal from './pages/public_pages/education_plan/EducationPlanModal'
import AdminProgramsByUniversity from './pages/admin_role/AdminProgramsByUniversity'
import AdminSeoGenerate from './pages/admin_role/AdminSeoGenerate'
import SingleSchCountryPage from './pages/public_pages/SingleSchCountryPage'
import SingleSchUniPage from './pages/public_pages/SingleSchUniPage'
import AdminEmptyUniversities from './pages/admin_role/AdminEmptyUniversities'
import ForgotPasswordPage from './pages/public_pages/ForgotPasswordPage'
import EmailPhoneIncorrectModal from './components/modals/EmailPhoneIncorrectModal'
import RedirectSch from './pages/public_pages/RedirectSch'
import NotFeeUniversitiesPage from './pages/manager_role/NotFeeUniversitiesPage'
import NotFoundPage from './pages/NotFoundPage'

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(state=> state.Data.user);
  const loader = useSelector(state=> state.Data.loader);
  const adminModal = useSelector(state=> state.Data.adminModal);
  const userModal = useSelector(state=> state.Data.userModal);
  const errorPageData = useSelector(state=> state.Data.errorPageData);
  const eduBackModal = useSelector(state=> state.Data.eduBackModal);
  const studyDestinationModal = useSelector(state=> state.Data.studyDestinationModal);
  const studentExamsModal = useSelector(state=> state.Data.studentExamsModal);
  const seeTextModalData = useSelector(state=> state.Data.seeTextModalData);
  const studentExpences = useSelector(state=> state.Data.studentExpences);
  const verifyModalData = useSelector(state=> state.Data.verifyModalData);
  const admissionModal = useSelector(state=> state.Data.admissionModal);
  const programRequirementModal = useSelector(state=> state.Data.programRequirementModal);
  const studentAddressData = useSelector(state=> state.Data.studentAddressData);
  const studentCvModal = useSelector(state=> state.Data.studentCvModal);
  const studentPassportModalData = useSelector(state=> state.Data.studentPassportModalData);
  const rankingApplyModal = useSelector(state=> state.Data.rankingApplyModal);
  const rankingApplyConfirmModal = useSelector(state=> state.Data.rankingApplyConfirmModal);
  const expencessModalData = useSelector(state=> state.Data.expencessModalData);
  const loaderLong = useSelector(state=> state.Data.loaderLong);
  const notFoundModal = useSelector(state=> state.Data.notFoundModal);
  const educationPlanModalData = useSelector(state=> state.Data.educationPlanModalData);
  const incorrectModalData = useSelector(state=> state.Data.incorrectModalData);
  useEffect(()=>{
    document.addEventListener("wheel", (event) => {  
      if (document.activeElement.type === "number") {  
          document.activeElement.blur(); 
      }  
    }); 
    if(localStorage.getItem("celtGoMainToken")){
      dispatch(checkLoginUser())
      .then(resp=>{
        if(resp == "error"){
          // navigate('/login')
        }
      })
    }else{
      // navigate('/login')
    }
   
  },[]);
  // console.log(user)
  return (
    <div  className={user!==""?'flex ':''}>
      {
        user == ""?
        <div className='pt-[0.1px]'>
        <Header />
        <Routes>
            <Route path='/' element={<MainPage />} />
            <Route path='/login' element={<LoginPage />} />
            <Route path='/consulting' element={<ConsultingPage />} />
            <Route path='/country/:id' element={<SingleCountryPage />} />
            <Route path='/accepted-students' element={<AcceptedStudents />} />
            <Route path='/countries' element={<CountriesPage />} />
            <Route path='/disciplines' element={<DisciplinesPage />} />
            <Route path='/subjects' element={<SubjectPages />} />
            <Route path='/subjects/:id' element={<SingleSubjectsPage />} />
            <Route path='/university/:id' element={<SingleUniversityPage />} />
            <Route path='/blog' element={<BlogPage />} />
            <Route path='/blog/:id' element={<SingleBlogPage />} />
            <Route path='/booking' element={<BookingPage />} />
            <Route path='/contact' element={<ContactPage />} />
            <Route path='/city-rankings' element={<CitiesRankingPage />} />
            <Route path='/employability-rankings' element={<EmployabilityRankingPage />} />
            <Route path='/rank-universities' element={<UniversityRanks />} />
            <Route path='/top-20-universities' element={<Top20Universities />} />
            <Route path='/top-300-universities' element={<Top300Universities />} />
            <Route path='/scholarhip-country/:id' element={<SingleSchCountryPage />} />
            <Route path='/scholarship-university/:id' element={<RedirectSch />} />
            <Route path='/scholarships/:id' element={<SingleSchUniPage />} />
            <Route path='/forgot-password' element={<ForgotPasswordPage />} />
            <Route path='/*' element={<NotFoundPage />} />
            {/* <Route path='/apply' element={<StudentApplyPage />} /> */}
        </Routes>
        </div>
        :null
      }
      {user!==""?<SideBar />:null}
      {
        user !==""?
       <div className='ml-[370px] max-[950px]:ml-[0px] w-full'>
          <div className='min-[950px]:hidden flex justify-end pr-[20px] mt-[20px]'>
            <label htmlFor='navBtnCheckox' className='cursor-pointer'>
              <MenuSvg />
            </label>
          </div>
         <Routes>
          <Route path='/' element={<Dashboard />} />
          <Route path='/profile' element={<ProfilePage />} />
          <Route path='/login' element={<LoginPage />} />
          <Route path='/admin-countries' element={<AdminCountriesPage />} />
          <Route path='/admin-country/:id' element={<AdminUniByCountry />} />
          <Route path='/admin-states' element={<AdminStatesPage />} />
          <Route path='/admin-cities' element={<AdminCitiesPage />} />
          <Route path='/admin-uni-type' element={<AdminUniversityType />} />
          <Route path='/admin-universities' element={<AdminUniversityPage />} />
          <Route path='/admin-discipline' element={<AdminDisciplinePage />} />
          <Route path='/admin-discipline/:id' element={<AdminSingleDiscipline />} />
          <Route path='/admin-edu-degree' element={<AdminEducationDegreePage />} />
          <Route path='/admin-language' element={<AdminLanguagePage />} />
          <Route path='/admin-programs' element={<AdminProgramsPage />} />
          <Route path='/admin-xtm' element={<AdminXTMPage />} />
          <Route path='/admin-content-manager' element={<AdminContentManagers />} />
          <Route path='/admin-exams' element={<AdminExamsPage />} />
          <Route path='/admin-high-school-exams' element={<AdminHighSchoolExamPage />} />
          <Route path='/admin-apply-requirements' element={<AdminApplyRequirementsPage />} />
          <Route path='/add-programs/:id' element={<AdminAddProgramsAll />} />
          <Route path='/add-expences-categories' element={<AdminExpencesCategories />} />
          <Route path='/admin-currencies' element={<AdminCurrencies />} />
          <Route path='/admin-costumer-review' element={<AdminCostumerReviewPage />} />
          <Route path='/admin-blogs' element={<AdminBlogsPage />} />
          <Route path='/admin-booking-info' element={<AdminBookingInfo />} />
          <Route path='/admin-prog-auto' element={<AddProgramAuto />} />
          <Route path='/admin-rank-auto' element={<AddRankingAuto />} />
          <Route path='/admin-booking' element={<AdminBookingDate />} />
          <Route path='/admin-subject' element={<AdminSubjectsPage />} />
          <Route path='/admin-ranking-applies' element={<RankingAppliesPage />} />

          <Route path='/admin-application-track' element={<AdminApplicationTrackings />} />
          <Route path='/admin-accomadation-track' element={<AdminAccomadationTrackings />} />
          <Route path='/admin-visa-track' element={<AdminVisaTrackings />} />


          <Route path='/managers' element={<Managers />} />
          <Route path='/teachers' element={<Teachers />} />
          <Route path='/teacher/:id' element={<TeacherStudens />} />
          <Route path='/students' element={<Students />} />
          <Route path='/students/:id' element={<EditStudentMain />} />
          <Route path='/student-info/:id' element={<StudentInfoPage />} />
          <Route path='/program-requirements/:student_id/:program_id' element={<StudentProgramRequirements />} />
          <Route path='/staff-messages' element={<TeacherMessages />} />


          <Route path='/available-programs' element={<AvailablePrograms />} />
          <Route path='/applied-programs' element={<StudentAppliedPrograms />} />
          <Route path='/info' element={<StudentInfo />} />
          <Route path='/message' element={<StudentMessage />} />
          <Route path='/wish-list' element={<StudentWishListPage />} />


          <Route path='/admin-city-ranking' element={<CityRankingPage />} />
          <Route path='/admin-university-ranking' element={<UniversityRankingPage />} />
          <Route path='/admin-employee-ranking' element={<EmployeeRankingPage />} />
          <Route path='/admin-subject-ranking' element={<SubjectRankingPage />} />
          <Route path='/admin-top300-ranking' element={<AdminTop300RankingsPage />} />
          <Route path='/admin-top20-ranking' element={<AdminTop20RankingsPage />} />
          <Route path='/admin-advertising' element={<AdminAdvertising />} />
          <Route path='/admin-celt-az-applies' element={<CeltAzAppliesPage />} />
          <Route path='/admin-uni-about' element={<AddAboutAuto />} />
          <Route path='/admin-country-scholarship' element={<AdminCountryScholarship />} />
          <Route path='/sch/:id' element={<SchCountries />} />
          <Route path='/sch/:schId/:id' element={<SchUni />} />
          <Route path='/sch/:schId/:countryId/:id' element={<SchPrograms />} />
          <Route path='/univerisy-programs/:id' element={<AdminProgramsByUniversity />} />
          <Route path='/admin-seo' element={<AdminSeoGenerate />} />
          <Route path='/admin-empty-uni' element={<AdminEmptyUniversities />} />
          <Route path='/content-not-fee' element={<NotFeeUniversitiesPage />} />
          <Route path='/*' element={<NotFoundPage />} />
        </Routes>
       </div>:null
      }
      
      
      {rankingApplyModal?.position? <ApplyRankingModal />:null}
      {rankingApplyConfirmModal?.position? <RankingConfirmModal />:null} 
      {/*  */}
      {adminModal?.position? <AdminModal />:null}
      {userModal?.position? <UserModal />:null}
      {errorPageData?.position? <ErrorPage />:null}
      {notFoundModal? <NotFoundModal />:null}
      {eduBackModal?.position ? <EducationBackgroundModal />:null}
      {studyDestinationModal?.position? <StudyDestinationModal />:null}
      {studentExamsModal?.position? <StudentExamsModal />:null}
      {seeTextModalData?.position? <SeeTextModal />:null}
      {studentExpences?.position? <StudentExpencesModal />:null}
      {admissionModal?.position? <StudentApplyPage />:null}
     
      {programRequirementModal?.position? <StudentNewReuiremenmtModal />:null}
      {studentAddressData?.position? <StudentAddressModal />:null}
      {studentCvModal?.position? <StudentCvModal />:null}
      {studentPassportModalData?.position ? <StudentPassportModal />:null}
      {expencessModalData?.position ? <ExpencessModal />:null}
      {educationPlanModalData?.position ? <EducationPlanModal />:null}
      {verifyModalData?.position? <VerifyModal />:null}
      {incorrectModalData?.position? <EmailPhoneIncorrectModal />:null}
      {loader || loaderLong?<Loader />:null}
     
    </div>
  )
}

export default App
