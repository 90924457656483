import React, { Component } from 'react'

export class MsgSearchSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={11}
        height={12}
        fill="none"
      >
        <circle cx={5} cy={5} r={4.3} stroke="#2B3674" strokeWidth={1.4} />
        <path
          stroke="#2B3674"
          strokeLinecap="round"
          strokeWidth={1.4}
          d="M10.01 11 8 8.99"
        />
      </svg>
    )
  }
}

export default MsgSearchSvg
