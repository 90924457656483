import React, { Component } from 'react'

export class SendMailSvg extends Component {
  render() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={20}
            height={20}
            fill="none"
        >
            <path
            fill="#fff"
            d="M.54 10.327c-.746.392-.715.965.068 1.275l2.318.917c.783.311 1.969.142 2.632-.377l10.07-7.94c.662-.52.732-.444.158.171l-7.96 8.52c-.577.614-.407 1.367.379 1.671l.272.106c.785.304 2.066.814 2.847 1.132l2.571 1.047c.782.317 1.616-.057 1.838-.87l3.92-14.406c.221-.813-.209-1.158-.955-.767L.54 10.327ZM6.903 19.759c-.045.137 1.586-2.363 1.586-2.363.462-.704.2-1.545-.58-1.864l-1.78-.728c-.778-.319-1.152.055-.83.834 0 0 1.652 3.98 1.604 4.12Z"
            />
        </svg>
    )
  }
}

export default SendMailSvg
