import React, { Component } from 'react'
import {connect} from "react-redux"
import { getBookingInfo } from '../../actions/MainAction';
import Header from '../../components/header/Header';
import Buttons from '../../components/Buttons';
import { changeStateValue } from '../../redux/MainReducer';
import Card1 from '../../components/cards/Card1';
export class AdminBookingInfo extends Component {
  componentDidMount(){
    this.props.getBookingInfo();
  }
  openModal=(data)=>{
    this.props.changeStateValue({
      name:'adminModal',
      value:{
        position: true,
        id: data?.id?data?.id:'',
        title: data?.title?data?.title:'',
        image: data?.image?data?.image:'',
        image_full_url: data?.image_full_url?data?.image_full_url:'',
        name: data?.name?data?.name:'',
        time: data?.time?data?.time:'',
        text: data?.description?data?.description:'',
        data_position: data?.position?data?.position:'',
        afterCallFunction:this.props.getBookingInfo,
        afterCallParams:[],
        requireds: ["title", "text", "time", "name", "data_position",],
        url:'booking-info',
        modalTitle:`${data?.id?'Edit':'Add'} Info`
      }
    })
  }
  render() {
    const {bookingInfo} = this.props;
    // console.log(bookingInfo)
    return (
      <div className='w-full  pl-[27px] pr-[27px]'>
        <Header title="Booking Info" />
        <div className='flex justify-end mt-[30px]'>
            <Buttons onClick={this.openModal.bind(this, '')} padding={true} options={[{title:"Add a booking info", value:0}]} selected={0} />
        </div>
        <div className='grid-full-250 mt-[30px]'>
              {
              bookingInfo!=''?
                <Card1
                  image={bookingInfo?.image_full_url}
                  name={bookingInfo?.name}
                  onClickEdit={this.openModal.bind(this, bookingInfo)}
                 
                />:null
              }
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  bookingInfo: state.Data.bookingInfo
});
const mapDispatchToProps = {getBookingInfo, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(AdminBookingInfo)
