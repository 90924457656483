import React, { Component } from 'react'

export class PrAppSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={56}
        height={56}
        fill="none"
        style={{ minWidth: '56px' }}
      >
        <rect width={56} height={56} fill="#8481FF" fillOpacity={0.2} rx={28} />
        <path
          fill="#8481FF"
          d="M39.25 20.5 28 24.25 16.75 20.5 28 16.75l11.25 3.75Z"
          opacity={0.2}
        />
        <path
          fill="#8481FF"
          d="m39.543 19.621-11.25-3.75a.526.526 0 0 0-.132-.044.828.828 0 0 0-.454.044l-11.25 3.75a.971.971 0 0 0-.44.293.85.85 0 0 0-.204.469v9.492c0 .254.092.474.278.66a.901.901 0 0 0 .659.277.901.901 0 0 0 .66-.278.901.901 0 0 0 .277-.659v-8.086l3.926 1.318a7.44 7.44 0 0 0-1.113 3.955 7.26 7.26 0 0 0 .967 3.677 7.548 7.548 0 0 0 2.549 2.68h.029a11.457 11.457 0 0 0-2.93 1.744 11.165 11.165 0 0 0-2.343 2.652.919.919 0 0 0-.147.498c0 .273.088.497.264.673.175.176.4.264.674.264.156 0 .302-.04.439-.117.137-.078.254-.176.352-.293v-.03c.878-1.347 1.987-2.392 3.325-3.134A8.766 8.766 0 0 1 28 34.562c1.543 0 2.983.372 4.321 1.114 1.338.742 2.447 1.787 3.326 3.134.097.137.21.245.336.323a.85.85 0 0 0 .455.117.901.901 0 0 0 .659-.278.901.901 0 0 0 .278-.66.874.874 0 0 0-.044-.278 1.892 1.892 0 0 0-.103-.249v.03a11.165 11.165 0 0 0-2.343-2.652 11.457 11.457 0 0 0-2.93-1.743 7.46 7.46 0 0 0 2.578-2.68 7.26 7.26 0 0 0 .967-3.677c0-.723-.102-1.421-.308-2.095a8.424 8.424 0 0 0-.835-1.89l.03.03 5.156-1.7c.195-.078.352-.195.469-.351a.901.901 0 0 0 .175-.557.92.92 0 0 0-.175-.542.861.861 0 0 0-.469-.337Zm-5.918 7.442c0 1.562-.547 2.89-1.64 3.984-1.094 1.094-2.422 1.64-3.985 1.64-1.563 0-2.89-.546-3.984-1.64-1.094-1.094-1.641-2.422-1.641-3.985 0-.625.098-1.22.293-1.787a5.626 5.626 0 0 1 .82-1.552h-.029l4.248 1.406c.04.02.083.034.132.044a.828.828 0 0 0 .454-.044l4.248-1.406c.332.468.596.986.791 1.552.195.567.293 1.163.293 1.788ZM28 23.253 19.709 20.5 28 17.746l8.291 2.754L28 23.254Z"
        />
      </svg>
    )
  }
}

export default PrAppSvg
