import React, { Component } from 'react'
import Input from './modals/components/Input'
import InputPhone from './modals/components/InputPhone'

export class ProfileEditCard extends Component {
    state={
        inp1Value:this.props.value1?this.props.value1:'',
        inp2Value:this.props.value2?this.props.value2:'',
    }
    resetData=()=>{
        this.setState({
            inp1Value:this.props.value1?this.props.value1:'',
            inp2Value:this.props.value2?this.props.value2:'',
        })
    }
  render() {
    const {inp1Value, inp2Value} = this.state;
    const {name1="", name2="", onClickSave=()=>{}, title, value="", index, activeIndex, type="text", onChangeVisible=()=>{}} = this.props;
    return (
      <div className='w-[560px] flex flex-col gap-[10px] pb-[20px] border-b border-b-[1px] border-b-gray-300 mt-[20px] max-[600px]:w-full max-[600px]:ml-[20px] max-[600px]:mr-[20px]'>
        <div className='flex justify-between items-center'>
            <span className='text-[#1f2937] text-[16px] font-semibold '>{title}</span>
            {
                activeIndex===index?
                <button onClick={()=>{onChangeVisible(0);this.resetData()}} className='text-[#1f2937] text-[14px] font-semibold '>Ləğv et</button>:
                <button onClick={()=>(onChangeVisible(index))} className='text-[#1f2937] text-[14px] font-semibold '>Dəyiş</button>
            }
            
        </div>
        {
            activeIndex!==index?
            <h3 className='text-[#1f2937] text-[16px] font-regular '>{value}</h3>:
            <div>
                <div className={`grid ${name2?"grid-cols-2":"grid-cols-1"}  items-center justify-between gap-[20px] max-[450px]:grid-cols-1`}>
                    {
                        type == "tel"?
                        <InputPhone bgWhite="bg-inp-tel-white" type={type} value={inp1Value} onChangeParent={(name, value)=>this.setState({inp1Value: value})} bg="bg-white" />:
                        <Input type={type} value={inp1Value} onChangeParent={(name, value)=>this.setState({inp1Value: value})} bg="bg-white" />
                    }
                    
                   {
                    name2?
                    <Input type={type} value={inp2Value} onChangeParent={(name, value)=>this.setState({inp2Value: value})} bg="bg-white" />:null
                   }
                   
                </div>
                <div className='mt-[10px]'>
                    <button onClick={()=>onClickSave({[name1]:inp1Value, [name2]:inp2Value})} className='p-[8px_24px] rounded-full bg-blue text-white text-[15px]'>Yadda saxla</button>
                </div>
            </div>
        }
      </div>
    )
  }
}

export default ProfileEditCard
