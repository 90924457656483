import React, { Component } from 'react'
import SectionHeader from '../../../components/public_components/SectionHeader'
import ArrowLeftSvg from '../../../components/svg/ArrowLeftSvg'
import ArrowRightSvg from '../../../components/svg/ArrowRightSvg'
import { connect } from 'react-redux'
import { getCostumerReview } from '../../../actions/MainAction'
export class ReviewSection extends Component {
    state={
        activeIndex:0,
        contRef: React.createRef()
    }
    componentDidMount(){
        this.props.getCostumerReview()
    }

    changeCard=(arr, index, type, contRef)=>{
        if(type === "left"){
            if(index>0){
                this.setState({activeIndex: index-1})
                for(let i = 0; i< contRef.current?.children?.length;i++){
                    contRef.current.children[i].style.transform = `translate(-${contRef.current?.children[i].getBoundingClientRect().width*(index-1)}px)`
                }
            }
        }else{
            if(index !== arr.length-1){
                for(let i = 0; i< contRef.current?.children?.length;i++){
                    contRef.current.children[i].style.transform = `translate(-${contRef.current?.children[i].getBoundingClientRect().width*(index+1)}px)`
                }
                this.setState({activeIndex: index+1})
            }
        }
    }
  render() {
    const {activeIndex, contRef} = this.state;
    const {costumerReview} = this.props;

    return (
        <section>
            <div className='mt-[60px]'>
                <SectionHeader text1="Customer" text2="reviews" />
            </div>
            <div className='max-w-[1200px] ml-auto mr-auto mt-[50px] pb-[45px] max-[1240px]:ml-[20px] max-[1240px]:mr-[20px]'>
                <div className='flex overflow-hidden' ref={contRef}>
                    {
                        costumerReview?.map((data, i)=>{
                            // if(activeIndex === i){
                                return(
                                    <div  key={i} className='min-w-full transition-[2s]'>
                                        <p className='text-[18px] text-center'>{data?.description}</p>
                                        <div className='mt-[30px] flex justify-between w-[500px] ml-auto mr-auto  items-center'>
                                        <div className='flex items-center gap-[20px]'>
                                                <img className='w-[80px] h-[80px] rounded-full' src={data?.image_full_url}  />
                                                <div>
                                                    <h4 className='text-[18px]'>{data?.user_name}</h4>
                                                    <p className='text-[14px] text-gray-600'>{data?.position}</p>
                                                </div>
                                        </div>
                                        <div className='flex items-center gap-[10px]'>
                                            <button onClick={this.changeCard.bind(this, costumerReview, activeIndex, "left", contRef)} className={`${activeIndex>0? "bg-black":"bg-gray-400"}  p-[2px_5px] rounded-[5px]`}>
                                                <ArrowLeftSvg />
                                            </button>
                                            <button onClick={this.changeCard.bind(this, costumerReview, activeIndex, "right", contRef)} className={`${activeIndex===(costumerReview.length-1)? "bg-gray-400":"bg-black"}  p-[2px_5px] rounded-[5px]`}>
                                                <ArrowRightSvg />
                                            </button>
                                        </div>
                                        </div>
                                    </div>
                                )
                            // }
                        })
                    }
                </div>
                
            </div>
        </section>
    )
  }
}
const mapStateToProps = (state) =>({
    costumerReview: state.Data.costumerReview
});
const mapDispatchToProps = {getCostumerReview}
export default connect(mapStateToProps, mapDispatchToProps)(ReviewSection)
