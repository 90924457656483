import React, { Component } from 'react'
import Select from 'react-select';
import { changeStateValue } from '../../../redux/MainReducer';
import { connect } from 'react-redux';
import { mixinAlert } from '../../../actions/SweetAlerts';
export class MultiSelect extends Component {

    onSelectData=(data, onChangeParent, defaultNamePermission, maxCount="")=>{
      if(maxCount !="" && maxCount < data?.length){
        mixinAlert("warning", `You only select ${maxCount} items`)
        return
      }
      if(defaultNamePermission){
        this.props.changeStateValue({
            name: this.props.name,
            value: data
        });
      }
        onChangeParent(this.props.name, data)
    }
  render() {
    const {options=[], selected=[],title="", placeholder="", onChangeParent=()=>{}, defaultNamePermission=true, bigInp=false, svg="", maxCount="", required=false, reqText="Bu xana vacibdir"} = this.props;
    
    return (
       <div className={bigInp?"bigDrop":""}>
        {title !== ""? <label className={bigInp?"text-[#6C6C6C] text-[12px] font-regular inline-flex items-center gap-[5px]":'text-[14px] txt-light-black'}>{svg}{title}</label>:null}
        
        <Select
            isMulti={true}
            value={selected}
            onChange={(value)=>this.onSelectData(value, onChangeParent, defaultNamePermission, maxCount)}
            options={options}
            placeholder={placeholder}
            className="rounded-2xl"
            maxMenuHeight={200}
        />
        {
          required && selected.length==0?
          <span className='text-[11px] text-red-700'>{reqText}</span>:null
        }
       </div>
    )
  }
}
const mapStateToProps = (state)=>({});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(MultiSelect)
