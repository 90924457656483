import React, { Component } from 'react'
import SinglePageHeader from '../../components/public_components/SinglePageHeader'
import { getSingleData, getSubjectRankings } from '../../actions/MainAction';
import { connect } from 'react-redux';
import { withHooks } from '../../actions/withHooks';
import RankingTable from './sections/RankingTable';
import { Helmet } from 'react-helmet';
import Card5 from '../../components/public_components/cards/Card5';
import { getPathConvert, getStartFee } from '../../actions/getOtherFunctions';
import LoadMoreBtn from '../../components/public_components/LoadMoreBtn';
import Footer from './sections/Footer';

export class SingleSubjectsPage extends Component {
  state={
    subjectData:'',
    page:1,
    subject_id:''
  }
  componentDidMount(){
    this.props.getSingleData(`public-subjects/${this.props.params?.id}?type=name`)
    .then(resp=>{
      if(resp == ""){this.props.navigate("/*")}
        this.setState({subjectData:resp, subject_id:resp?.id});
        this.props.getSubjectRankings(1,resp?.id);
    });
  
    window.scrollTo(0,0)
  }
  loadMore=(page)=>{
    this.setState({page})
    this.props.getSubjectRankings(page, this.state.subject_id);
  }
  render() {
    const {subjectData} = this.state;
    const {subjectRankings} = this.props;
    // console.log(subjectRankings)
    return (
      <div className='mt-[80px] bg-[#F5F5F5]'>
        {
          subjectData !=""?
          <Helmet>
            <title>{`${subjectData?.seo_title}`}</title>
            <meta property="og:locale" content="az_AZ" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={`${subjectData?.seo_title}`} />
            <meta property="og:description" content={subjectData?.seo_description} />
            <meta name="description" content={subjectData?.seo_description}/>
            <meta name="keywords" content={subjectData?.seo_keywords}/>
            <meta property="og:url" content={`https://celtgo.com${window.location.pathname}`} />
            <meta property="og:site_name" content="CELT Colleges" />
            <meta property="article:publisher" content="https://www.facebook.com/celtscollege" />
            <meta property="article:modified_time" content="2024-06-06T08:56:25+00:00" />
            <meta property="og:image" content={`${subjectData?.image_full_url}`} />
            <meta property="og:image:width" content="900" />
            <meta property="og:image:height" content="600" />
            <meta property="og:image:type" content="image/jpeg" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={`${subjectData?.seo_title}`} />
            <meta name="twitter:description" content={subjectData?.seo_description} />
            <meta name="twitter:site" content="@celtcolleges" />
            <meta name="twitter:label1" content="Est. reading time" />
            <meta name="twitter:data1" content="7 minutes" />
          </Helmet>:null
        }
        <SinglePageHeader name={subjectData?.title} image={subjectData?.image_full_url} />
        <section className='mb-[50px]'>
          <div className='max-w-[1200px] ml-auto mr-auto max-[1240px]:ml-[20px] max-[1240px]:mr-[20px] mt-[40px]'>
            <h3 className='text-neutral-800 text-[35px] text-center mb-[30px] font-semibold max-[450px]:text-[30px]'>Ən güclü universitetlər</h3>
            <div className='grid-full-380'>
              {
                subjectRankings?.map((data, i)=>{
                  return(
                    <Card5 key={i} 
                      image={data?.university?.image_full_url}
                      name={data?.university?.name}
                      country={data?.university?.country?.name}
                      city={data?.university?.city?.name}
                      data={data} 
                      path={`/university/${getPathConvert(data?.university?.name)}`} 
                      ranking={data?.ranking}
                      view={true}
                      footer={false}
                      rankTxt="İxtisas üzrə"
                      start_fee={getStartFee(data?.university?.phd_start, data?.university?.master_start, data?.university?.pre_master_star, data?.university?.bachelour_start)}
                    />
                  )
                })
              }
            </div>
            {
              subjectRankings?.length >=20?
              <LoadMoreBtn onLoadData={this.loadMore} count={subjectRankings?.length} />:null
            }
            {/* <RankingTable
              options={subjectRankings} 
              column1Title={"Sıralama"}
              column2Title={"Universitet"}
              column3Title={"Ölkə"}
              column1Property={"ranking"}
              column2Property={"university"}
              column3Property={"country"}
              rank_property={"subject_rank_id"}
              count={3}
            /> */}
            </div>
        </section>
        <Footer />
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  subjectRankings: state.Data.subjectRankings
});
const mapDispatchToProps = {getSingleData, getSubjectRankings}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(SingleSubjectsPage))
