import React, { Component } from 'react'
import CardDispSvg from '../svg/CardDispSvg';
import CardUserSsvg from '../svg/cardUserSsvg';
import CardHeartSvg from '../svg/CardHeartSvg';

export class Card2 extends Component {
  render() {
    const {image, country, discipline, degree, flag, text} = this.props;
    return (
      <div className='bg-white rounded-[16px] p-[16px]'>
        <div className='relative'>
            <div className='w-[33px] h-[23px] bg-white rounded-[6px] inline-flex justify-center items-center absolute top-[13px] left-[13px]'>
                <CardHeartSvg />
            </div>
            <img className='w-full h-[208px] rounded-[8px] max-[400px]:h-[auto]' src={image} alt={country} />
        </div>

        <div className='mt-[24px]'>
            <div className='flex items-center gap-[5px]'>
                <img src={flag} alt={country} className='w-[20px] h-[20px] rounded-full'  />
                <span className='text-[15px] text-[#1D1D1F] font-medium'>{country}</span>
            </div>
            <p className='mt-[16px] text-[#88888c] text-[12px]'>
                {text}ü
            </p>
            <div className='flex items-center gap-[5px] mt-[15px]'>
                <CardDispSvg />
                <span className='text-[12px] text-[#88888c] font-regulars'>Sahə: {discipline}</span>
            </div>
            <div className='flex items-center gap-[5px] mt-[10px]'>
                <CardUserSsvg />
                <span className='text-[12px] text-[#88888c] font-regulars'>Dərəcə: {degree}</span>
            </div>
        </div>
      </div>
    )
  }
}

export default Card2
