import React, { Component } from 'react'
import Footer from './sections/Footer'
import Top300UniversitiesRankingSections from './sections/Top300UniversitiesRankingSections'
import { Helmet } from 'react-helmet'

export class Top300Universities extends Component {
  componentDidMount(){
    window.scrollTo(0,0)
  }
  render() {
    return (
        <div className='bg-[#F5F5F5] mt-[-40px]'>
           <Helmet>
                <title>{"Ən Yaxşı 300 Universitet | CELTgo.com - Dünyanın Top 300 Universiteti"}</title>
                <meta property="og:locale" content="az_AZ" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Ən Yaxşı 300 Universitet | CELTgo.com - Dünyanın Top 300 Universiteti" />
                <meta property="og:description" content="CELTgo.com ilə dünyanın ən yaxşı 300 universitetini kəşf edin. Təhsil keyfiyyəti və qlobal reytinq baxımından ən önəmli universitetlər haqqında geniş məlumat əldə edin." />
                <meta name="description" content={"CELTgo.com ilə dünyanın ən yaxşı 300 universitetini kəşf edin. Təhsil keyfiyyəti və qlobal reytinq baxımından ən önəmli universitetlər haqqında geniş məlumat əldə edin."}/>
                <meta name="keywords" content={"ən yaxşı 300 universitet, top 300 universitet, dünyanın ən yaxşı universitetləri, xaricdə təhsil üçün universitetlər, 300 ən yüksək reytinqli universitet, universitet reytinqləri, CELTgo universitetlər"}/>
                <meta property="og:url" content="https://celtgo.com/top-300-universities" />
                <meta property="og:site_name" content="CELT Colleges" />
                <meta property="article:publisher" content="https://www.facebook.com/celtscollege" />
                <meta property="article:modified_time" content="2024-06-06T08:56:25+00:00" />
                <meta property="og:image" content="https://celtgo.com/static/media/celtLogo6.05ddd884642befe11a4e.png" />
                <meta property="og:image:width" content="900" />
                <meta property="og:image:height" content="600" />
                <meta property="og:image:type" content="image/jpeg" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Ən Yaxşı 300 Universitet | CELTgo.com - Dünyanın Top 300 Universiteti" />
                <meta name="twitter:description" content="CELTgo.com ilə dünyanın ən yaxşı 300 universitetini kəşf edin. Təhsil keyfiyyəti və qlobal reytinq baxımından ən önəmli universitetlər haqqında geniş məlumat əldə edin." />
                <meta name="twitter:site" content="@celtcolleges" />
                <meta name="twitter:label1" content="Est. reading time" />
                <meta name="twitter:data1" content="7 minutes" />
            </Helmet>
            <Top300UniversitiesRankingSections />
            <Footer />
        </div>
    )
  }
}

export default Top300Universities


