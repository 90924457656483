import React, { Component } from 'react'
import Footer from './sections/Footer'
import CitiesRankingSection from './sections/CitiesRankingSection'
import { Helmet } from 'react-helmet'

export class CitiesRankingPage extends Component {
  componentDidMount(){
    window.scrollTo(0,0)
  }
  render() {
    return (
      <div className='bg-[#F5F5F5] mt-[-40px]'>
         <Helmet>
            <title>{"Ən Yaxşı Şəhərlər | CELTgo.com - Xaricdə Təhsil Üçün İdeal Şəhərlər"}</title>
            <meta property="og:locale" content="az_AZ" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Ən Yaxşı Şəhərlər | CELTgo.com - Xaricdə Təhsil Üçün İdeal Şəhərlər" />
            <meta property="og:description" content="Xaricdə təhsil üçün ən yaxşı şəhərləri kəşf edin. CELTgo.com ilə tələbələr üçün ideal olan şəhərlərdə yaşama və təhsil alma imkanları haqqında məlumat əldə edin." />
            <meta name="description" content={"Xaricdə təhsil üçün ən yaxşı şəhərləri kəşf edin. CELTgo.com ilə tələbələr üçün ideal olan şəhərlərdə yaşama və təhsil alma imkanları haqqında məlumat əldə edin."}/>
            <meta name="keywords" content={"ən yaxşı şəhərlər, xaricdə təhsil üçün şəhərlər, təhsil üçün ideal şəhərlər, tələbələr üçün şəhərlər, xaricdə yaşama və təhsil alma, şəhər seçimi xaricdə təhsil"}/>
            <meta property="og:url" content="https://celtgo.com/city-rankings" />
            <meta property="og:site_name" content="CELT Colleges" />
            <meta property="article:publisher" content="https://www.facebook.com/celtscollege" />
            <meta property="article:modified_time" content="2024-06-06T08:56:25+00:00" />
            <meta property="og:image" content="https://celtgo.com/static/media/celtLogo6.05ddd884642befe11a4e.png" />
            <meta property="og:image:width" content="900" />
            <meta property="og:image:height" content="600" />
            <meta property="og:image:type" content="image/jpeg" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="Ən Yaxşı Şəhərlər | CELTgo.com - Xaricdə Təhsil Üçün İdeal Şəhərlər" />
            <meta name="twitter:description" content="Xaricdə təhsil üçün ən yaxşı şəhərləri kəşf edin. CELTgo.com ilə tələbələr üçün ideal olan şəhərlərdə yaşama və təhsil alma imkanları haqqında məlumat əldə edin." />
            <meta name="twitter:site" content="@celtcolleges" />
            <meta name="twitter:label1" content="Est. reading time" />
            <meta name="twitter:data1" content="7 minutes" />
          </Helmet>
          <CitiesRankingSection />
          <Footer />
      </div>
    )
  }
}

export default CitiesRankingPage
