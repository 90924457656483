import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withHooks } from '../../actions/withHooks';
import { getSingleData } from '../../actions/MainAction';
import NewsLetterSection from './sections/NewsLetterSection';
import Footer from './sections/Footer';
import CardReadSvg from '../../components/public_components/svg/CardReadSvg';
import moment from 'moment';
export class SingleBlogPage extends Component {
    state={
        blogData:''
    }
    componentDidMount(){
        this.props.getSingleData(`public-blog/${this.props.params.id}`)
        .then(resp=>{
            if(resp == ""){this.props.navigate("/*")}
            this.setState({blogData:resp})
        });

        window.scrollTo(0,0)
    }
  render() {
    const {blogData} = this.state;
    // console.log(blogData)
    return (

        <div className='mt-[80px] bg-[#F6F8FB]'>
            <div className='pb-[30px] bg-white'>
                <div className='pt-[60px]'>
                    <div className='max-w-[1200px] ml-auto mr-auto max-[1240px]:ml-[20px] max-[1240px]:mr-[20px] pt-[30px]'>
                        <div className='flex gap-[80px] items-center justify-between'>
                            <h2 className='text-[38px] font-semibold'>{blogData?.title}</h2>
                            <img className='w-[50%] rounded-[10px]' src={blogData?.image_full_url} />
                        </div>
                        <div className='flex flex-col gap-[10px] mt-[10px]'>
                            {/* <div className='flex items-center gap-[7px]'>
                                <CardReadSvg />
                                <span className='text-[12px]'>Read count {blogData?.read_count}</span>
                            </div> */}
                            <span className='text-gray-500 text-[14px]'>{moment(blogData?.created_at).format("DD MMM YYYY")}</span>
                        </div>
                        <p className='mt-[50px]' dangerouslySetInnerHTML={{ __html:blogData?.review }}></p>
                    </div>
                </div>
            </div>
            {/* <NewsLetterSection /> */}
            <Footer />
        </div>
      
    )
  }
}
const mapStateToProps = (state) =>({

});
const mapDispatchToProps = {getSingleData}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(SingleBlogPage))
