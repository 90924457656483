import React, { Component } from 'react'

export class ChatCard extends Component {
    render() {
        const {title, last_msg, date, onClickMsg=()=>{}, student_id="", data} = this.props;
        return (
            <div onClick={()=>onClickMsg()} className={`p-[16px_20px] flex ${student_id == data?.student_id? "bg-[#F8FAFC]":""}  justify-between border-b border-[#F1F5F9] group/item hover:bg-[#F8FAFC] cursor-pointer`}>
                <div className="flex items-center gap-[16px]">
                    <div className="flex flex-col">
                        <h4 title="test read" className="txt-light-black text-[14px] font-bold">{title}</h4>
                        <span title="hjh" className="txt-light-black text-[12px] font-regular max-w-[180px] overflow-hidden h-[20px]">{last_msg}</span>
                    </div>
                </div>
                <div className="flex flex-col items-end gap-[5px] justify-between">
                    {/* <span className='bg-orange min-w-[18px] w-[18px] h-[18px] inline-flex items-center justify-center text-[12px] text-white rounded-full'>
                        2
                    </span> */}
                    <span></span>
                    <span className="text-[12px] font-medium txt-light-black">{date}</span>
                </div>
            </div>
        )
    }
}

export default ChatCard
