import React, { Component } from 'react'

export class StarSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={15}
        height={15}
        fill="none"
      >
        <path
          fill="#FFA619"
          d="m7.5 10.794 3.863 2.331-1.025-4.394 3.412-2.956-4.494-.381L7.5 1.25 5.744 5.394l-4.494.381 3.412 2.956-1.024 4.394L7.5 10.794Z"
        />
      </svg>
    )
  }
}

export default StarSvg
