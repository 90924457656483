import React, { Component } from 'react'

export class UserSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        data-id="details-item-icon"
        viewBox="0 0 10 10"
        width={20}
        height={20}
      >
        <path
          fill="none"
          stroke="#1a1a1a9c"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2.75 2.75a2.25 2.25 0 1 0 4.5 0 2.25 2.25 0 1 0-4.5 0ZM9.244 9.5a4.5 4.5 0 0 0-8.488 0Z"
        />
      </svg>
    )
  }
}

export default UserSvg
