import React, { Component } from 'react'
import { getAdvertisings } from '../../../actions/MainAction';
import { connect } from 'react-redux';

export class AdvertisingSection extends Component {
    componentDidMount(){
        this.props.getAdvertisings()
    }
  render() {
    const {advertisingData} = this.props;
    // console.log(advertisingData)
    return (
      <div className='grid-full-440'>
        {
            advertisingData?.map((data, i)=>{
                return(
                    <img className='w-full h-[200px] rounded-[10px]' src={data?.image_full_url} />
                )
            })
        }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    advertisingData: state.Data.advertisingData
});
const mapDispatchToProps = {getAdvertisings}
export default connect(mapStateToProps, mapDispatchToProps)(AdvertisingSection)
