import React, { Component } from 'react'
import Header from '../../components/header/Header'
import Search from '../../components/Search'
import Buttons from '../../components/Buttons'
import { connect } from 'react-redux'
import { changeStateValue } from '../../redux/MainReducer';
import { getVisaTracking } from '../../actions/MainAction';
import Card1 from '../../components/cards/Card1'
import LoadMoreBtn from '../../components/LoadMoreBtn'
export class AdminVisaTrackings extends Component {
  
  openModal=(data)=>{
    this.props.changeStateValue({
      name:'adminModal',
      value:{
        position: true,
        id: data?.id?data?.id:'',
        title: data?.title?data?.title:'',
        afterCallFunction:this.props.getVisaTracking,
        afterCallParams:[],
        requireds: ["title"],
        url:'visa-trackings',
        modalTitle:`${data?.id?'Edit':'Add'} Visa tracking`
      }
    })
  }

  componentDidMount(){
    this.props.getVisaTracking(1, '')
  }

 

  afterCallFunction=()=>{
    this.props.getVisaTracking()
  }
  render() {
    const {visaTrackings} = this.props;
  
    return (
        <div className='w-full  pl-[27px] pr-[27px]'>
            <Header title="Visa Trackings" />
            <div className='flex justify-end mt-[30px]'>
                <Buttons onClick={this.openModal.bind(this, '')} options={[{title:"Add a Visa tracking", value:0}]} selected={0} />
            </div>
           
            <div className='grid-full-250 mt-[30px]'>
              {
                visaTrackings?.map((data, i)=>{
                  return(
                    <Card1 
                      key={i}
                      name={data?.title}
                      onClickEdit={this.openModal.bind(this, data)}
                      deletePath={`visa-trackings/${data?.id}`}
                      afterCallFunction={this.afterCallFunction.bind(this)}
                    />
                  )
                })
              }
            </div>
           
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
  visaTrackings: state.Data.visaTrackings
});
const mapDispatchToProps = {changeStateValue, getVisaTracking}
export default connect(mapStateToProps, mapDispatchToProps)(AdminVisaTrackings)
