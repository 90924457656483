import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { getApplyRanking, insertNewData } from '../../actions/MainAction';
import { connect } from 'react-redux';
import Card1 from '../../components/cards/Card1';
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts';
import moment from 'moment';

export class RankingAppliesPage extends Component {
    componentDidMount(){
        this.props.getApplyRanking()
    }
    getRankingType(city, emp, subject, uni, top20, top300){
        if(city){
            return {
                ranking: city?.ranking,
                title: city?.city,
                country: city?.country?.name,
                type: "City ranking"
            }
        }else if(emp){
            return {
                ranking: emp?.ranking,
                title: emp?.city,
                country: emp?.country?.name,
                type: "Employability ranking"
            }
        }else if(subject){
            return {
                ranking: subject?.ranking,
                title: subject?.university,
                country: subject?.country?.name,
                type: "Subject ranking"
            }
        }else if(uni){
            return {
                ranking: uni?.ranking,
                title: uni?.university,
                country: uni?.country?.name,
                type: "Country ranking"
            }
        }else if(top20){
            return {
                ranking: top20?.ranking,
                title: top20?.university,
                country: top20?.country?.name,
                type: "Top 20 ranking"
            }
        }else if(top300){
            return {
                ranking: top300?.ranking,
                title: top300?.university,
                country: top300?.country?.name,
                type: "Top 300 ranking"
            }
        }
    }

    onClickStatus=(data)=>{
        // console.log(data)
        confirmAlert("warning", "Əminsiniz?", "Statusu dəyişməyinizdən əminsiniz", "Bəli", "Xeyir")
        .then(alertResp=>{
            if(alertResp === "success"){
                let formData = new FormData();
                formData.append("status", data?.status==1?2:1)
                formData.append("_method", "put")
            this.props.insertNewData(`ranking-apply/${data?.id}`, formData)
            .then(resp=>{
                if(resp == "success"){
                    this.props.getApplyRanking();
                    mixinAlert("success", "Uğurla dəyişdirildi.")
                }else{
                    mixinAlert("error", "Xəta baş verdi")
                }
            })
            }
        })
    }
  render() {
    const {applyRankingsData} = this.props;
    // console.log(applyRankingsData)
    return (
        <div className='w-full  pl-[27px] pr-[27px]'>
            {/* <Header title="Ranking applies" /> */}
            <div className='mt-[50px]'>
                <div className='grid-full-250 '>
                    {
                        applyRankingsData?.map((data, i) => {
                            return (
                                <Card1 
                                    key={i}
                                    name={data?.name}
                                    phone_number={data?.phone_number}
                                    ranking_type={this.getRankingType(data?.city_ranking, data?.employability_ranking, data?.subject_ranking, data?.university_ranking, data?.top_20_ranking, data?.top_300_ranking)?.type}
                                    country={this.getRankingType(data?.city_ranking, data?.employability_ranking, data?.subject_ranking, data?.university_ranking, data?.top_20_ranking, data?.top_300_ranking)?.country}
                                    ranking={this.getRankingType(data?.city_ranking, data?.employability_ranking, data?.subject_ranking, data?.university_ranking, data?.top_20_ranking, data?.top_300_ranking)?.ranking}
                                    uni={this.getRankingType(data?.city_ranking, data?.employability_ranking, data?.subject_ranking, data?.university_ranking, data?.top_20_ranking, data?.top_300_ranking)?.title}
                                    footer={false}
                                    rankStatus={data?.status}
                                    rankStatusBtn={true}
                                    onClickStatus={this.onClickStatus.bind(this, data)}
                                    date={moment(data?.created_at).format("DD/MM/YYYY")}
                                />
                            )
                        })
                    }
                </div>
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    applyRankingsData: state.Data.applyRankingsData
});
const mapDispatchToProps = {getApplyRanking, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(RankingAppliesPage)
