import React, { Component } from 'react'
import { getZeroPad } from '../../../actions/getOtherFunctions';

export class Card4 extends Component {
  render() {
    const {data} = this.props;
    return (
      <div className='rounded-[15px] bg-white'>
        <img className='w-full h-[150px] rounded-t-[15px]' src={data?.image_full_url} alt={data?.name} />
        <div className='bg-white relative pl-[22px] pr-[22px] pt-[20px] pb-[18px] rounded-b-[15px]'>
            <div className='w-[57px] h-[57px] shadow bg-white rounded-full absolute flex items-center justify-center flex-col right-[5px] top-[-25px] gap-[0px]'>
                <span className='text-[18px] font-semibold text-[#1E398F]'>{getZeroPad(data?.programs_count)}</span>
                <span className='text-[10px] font-regular text-[#1E398F]'>Prog</span>
            </div>
            <span className='text-[#1E398F] font-bold text-[10px]'>{data?.scholarship_count} Scholarships</span>
            <h3 className='text-[22px] font-semibold '>{data?.name}</h3>
        </div>
      </div>
    )
  }
}

export default Card4
