import React, { Component } from 'react'

export class EmailSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={15}
        height={15}
        fill="none"
      >
        <path
          fill="#6C6C6C"
          d="M13.385 5.235 7.76 1.485a.469.469 0 0 0-.52 0l-5.625 3.75a.469.469 0 0 0-.209.39v6.094a.938.938 0 0 0 .938.937h10.312a.938.938 0 0 0 .938-.937V5.625a.47.47 0 0 0-.209-.39ZM5.667 8.906 2.344 11.25V6.535l3.323 2.371Zm.96.469h1.747l3.318 2.344H3.308l3.318-2.344Zm2.706-.469 3.323-2.371v4.715L9.333 8.906ZM7.5 2.438l4.8 3.2-3.926 2.8H6.628l-3.926-2.8 4.798-3.2Z"
        />
      </svg>
    )
  }
}

export default EmailSvg
