import React, { Component } from 'react'

export class CardUserSsvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={14}
        height={15}
        fill="none"
        style={{ marginLeft:'2px' }}
      >
        <path
          fill="#88888c"
          d="M5.794 7.17a3.206 3.206 0 0 0 3.204-3.196A3.208 3.208 0 0 0 5.794.77a3.2 3.2 0 0 0-3.196 3.204A3.193 3.193 0 0 0 5.794 7.17ZM8.293 8.412H3.716A3.712 3.712 0 0 0 .008 12.12v.59c0 .834.686 1.52 1.52 1.52h8.953c.834 0 1.52-.686 1.52-1.52v-.582a3.72 3.72 0 0 0-3.708-3.716Z"
        />
      </svg>
    )
  }
}

export default CardUserSsvg
