import React, { Component } from 'react'

export class StudDestSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={69}
        height={80}
        fill="none"
      >
        <path
          fill="#E7E6FB"
          d="m2.335 19.706 28.682-18.07a5 5 0 0 1 5.233-.06l30.318 18.145A5 5 0 0 1 69 24.01v33.617a5 5 0 0 1-2.595 4.384L36.083 78.646a5 5 0 0 1-4.905-.054L2.5 62.032a5 5 0 0 1-2.5-4.33V23.936a5 5 0 0 1 2.335-4.23Z"
        />
        <path
          stroke="#4C47AF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M37.833 25.833H26.499a2.833 2.833 0 0 0-2.833 2.834v22.666a2.833 2.833 0 0 0 2.833 2.834h17a2.833 2.833 0 0 0 2.834-2.834v-17l-8.5-8.5Z"
        />
        <path
          stroke="#4C47AF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M37.834 25.833v8.5h8.5M40.667 41.417H29.334M40.667 47.083H29.334M32.167 35.75h-2.833"
        />
      </svg>
    )
  }
}

export default StudDestSvg
