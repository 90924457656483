import React, { Component } from 'react'

export class SvgBackground extends Component {
  render() {
    const {color="#FDDDE0"} = this.props;
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={69}
        height={80}
        fill="none"
      >
        <path
          fill={color}
          d="m2.335 19.706 28.682-18.07a5 5 0 0 1 5.233-.06l30.318 18.145A5 5 0 0 1 69 24.01v33.617a5 5 0 0 1-2.595 4.384L36.083 78.646a5 5 0 0 1-4.905-.054L2.5 62.032a5 5 0 0 1-2.5-4.33V23.936a5 5 0 0 1 2.335-4.23Z"
        />
      </svg>
    )
  }
}

export default SvgBackground
