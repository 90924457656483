import React, { Component } from 'react'
import Header from '../../components/header/Header'
import Search from '../../components/Search'
import Buttons from '../../components/Buttons'
import { connect } from 'react-redux'
import { changeStateValue } from '../../redux/MainReducer';
import { getEduDegree, getPrograms, getUniversities, insertNewData } from '../../actions/MainAction';
import Card1 from '../../components/cards/Card1'
import LoadMoreBtn from '../../components/LoadMoreBtn'
import DropSearch from '../../components/modals/components/DropSearch'
import { getDropValue } from '../../actions/getOtherFunctions'
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts'
export class AdminProgramsPage extends Component {
  state={
    page:1,
    search:'',
    degree_id:'',
    university_id:'',
    active_status:''
  }
  getDisciplineValue=(disciplines)=>{
    if(!disciplines){
        return [];
    }else{
        let arr = [];
        for(const x of disciplines){
            arr.push({label: x?.discipline?.name, value: x?.discipline_id})
        }
        return arr;
    }
  }
  openModal=(data)=>{
    this.props.changeStateValue({
      name:'adminModal',
      value:{
        position: true,
        id: data?.id?data?.id:'',
        name: data?.name?data?.name:'',
        university_id: data?.university_id?data?.university_id:'',
        degree_id: data?.degree_id?data?.degree_id:'',
        language_id: data?.language_id?data?.language_id:'',
        start_date: data?.start_date?data?.start_date:'',
        deadline: data?.deadline?data?.deadline:'',
        duration: data?.duration?data?.duration:'',
        study_mode: data?.study_mode?data?.study_mode:'1',
        scholarship_option: data?.scholarship_option?data?.scholarship_option:'0',
        disciplines: this.getDisciplineValue(data?.disciplines),
        afterCallFunction:this.props.getPrograms,
        afterCallParams:[this.state.page, this.state.search, this.state.university_id, this.state.degree_id],
        requirements:{
          program_id:data?.id?data?.id:'',
          required_education_degree_id: data?.requirements?.required_education_degree_id?data?.requirements?.required_education_degree_id:'',
          gpa: data?.requirements?.gpa?data?.requirements?.gpa:'',
          ib: data?.requirements?.ib?data?.requirements?.ib:'',
          a_level: data?.requirements?.a_level?data?.requirements?.a_level:'',
          ap: data?.requirements?.ap?data?.requirements?.ap:'',
          ielts: data?.requirements?.ielts?data?.requirements?.ielts:'',
          toefl: data?.requirements?.toefl?data?.requirements?.toefl:'',
          gre: data?.requirements?.gre?data?.requirements?.gre:'',
          gmat: data?.requirements?.gmat?data?.requirements?.gmat:'',
          sat: data?.requirements?.sat?data?.requirements?.sat:'',
          dim: data?.requirements?.dim?data?.requirements?.dim:'',
          ossd: data?.requirements?.ossd?data?.requirements?.ossd:'',
          foundation: data?.requirements?.foundation?data?.requirements?.foundation:'',
          ielts_ukvi: data?.requirements?.ielts_ukvi?data?.requirements?.ielts_ukvi:'',
        },
        scholarship:{
          program_id:data?.id?data?.id:'',
          amount: data?.scholarship?.amount?data?.scholarship?.amount:'',
          req_edu_degree_id: data?.scholarship?.req_edu_degree_id?data?.scholarship?.req_edu_degree_id:'',
          gpa: data?.scholarship?.gpa?data?.scholarship?.gpa:'',
          ib: data?.scholarship?.ib?data?.scholarship?.ib:'',
          a_level: data?.scholarship?.a_level?data?.scholarship?.a_level:'',
          ap: data?.scholarship?.ap?data?.scholarship?.ap:'',
          ielts: data?.scholarship?.ielts?data?.scholarship?.ielts:'',
          toefl: data?.scholarship?.toefl?data?.scholarship?.toefl:'',
          gre: data?.scholarship?.gre?data?.scholarship?.gre:'',
          gmat: data?.scholarship?.gmat?data?.scholarship?.gmat:'',
          sat: data?.scholarship?.sat?data?.scholarship?.sat:'',
          dim: data?.scholarship?.dim?data?.scholarship?.dim:'',
          ossd: data?.scholarship?.ossd?data?.scholarship?.ossd:'',
          foundation: data?.scholarship?.foundation?data?.scholarship?.foundation:'',
          ielts_ukvi: data?.scholarship?.ielts_ukvi?data?.scholarship?.ielts_ukvi:'',
        },
        requireds: ["name", "university_id", "language_id",  "degree_id", "start_date"],
        url:'programs',
        modalTitle:`${data?.id?'Edit':'Add'} Program`
      }
    })
  }

  componentDidMount(){
    this.props.getPrograms(1, '')
    this.props.getEduDegree();
    this.props.getUniversities("", "", "all")
  }

  onSearchData=(search)=>{
    this.setState({search})
    this.props.getPrograms(this.state.page, search, this.state.university_id, this.state.degree_id, "", this.state.active_status)
  }
  loadMore=(page)=>{
    this.setState({page})
    this.props.getPrograms(page, this.state.search, this.state.university_id, this.state.degree_id,"", this.state.active_status)
  }

  afterCallFunction=()=>{
    this.props.getPrograms(this.state.page, this.state.search, this.state.university_id, this.state.degree_id, "", this.state.active_status)
  }
  getProgramByDegree=(id)=>{
    this.setState({degree_id:id})
    this.props.getPrograms(this.state.page, this.state.search,this.state.university_id, id, "", this.state.active_status)
  }

  getByUni=(id)=>{
    this.setState({university_id:id})
    this.props.getPrograms(this.state.page, this.state.search, id, this.state.degree_id, "", this.state.active_status)
    // console.log(id)
  }
  onChangeStatus=(id, status)=>{
    if(this.props.user?.role == "admin"){
      let formData = new FormData();
      formData.append("status", status==1?0:1);
      formData.append("_method", "put");

      this.props.insertNewData(`active-deactive-program/${id}`, formData)
      .then(resp=>{
        if(resp == "success"){
          this.afterCallFunction()
          mixinAlert("success", "Status changed successfully");
        }else{
          mixinAlert("error", "Something went wrong")
        }
      })
    }
  }

  changeActiveStatus(active_status){
    this.setState({active_status});
    this.props.getPrograms(this.state.page, this.state.search, this.state.university_id, this.state.degree_id, "", active_status)
  }

  appRoveAll=()=>{
    confirmAlert("warning", "Əminsiniz?", "Approve etdikden sonra geri qaytarmaq olmayacaq", "Bəli", "Xeyir")
    .then(alertResp=>{
        if(alertResp === "success"){
          this.props.insertNewData("update-all-programs", {})
          .then(resp=>{
            if(resp == "success"){
              mixinAlert("success", "Activate all");
              this.afterCallFunction()
            }else{
              mixinAlert("success", "Something went wriong")
            }
          })
        // this.props.deleteData(`student-passport-information/${id}`)
        // .then(resp=>{
        //     if(resp == "success"){
        //         this.props.getPersonalInformation( this.props.studentPassportModalData?.student_id);
        //         mixinAlert("success", "Passportunuz uğurla silindi")
        //         this.props.getStudentComplete()
        //     }else{
        //         mixinAlert("error", "Xəta baş verdi")
        //     }
        // })
        }
    })
  }
  render() {
    const {programs, educationDegree, universities, user} = this.props;
    const {degree_id, active_status} = this.state;
    // console.log(programs)
    return (
        <div className='w-full  pl-[27px] pr-[27px]'>
            <Header title="Programs" />
            <div className='flex justify-end mt-[30px]'>
                <Buttons onClick={this.openModal.bind(this, '')} options={[{title:"Add a program", value:0}]} selected={0} />
            </div>
            <div className='flex justify-center mt-[20px]'>
                <Search onSearchData={this.onSearchData.bind(this)} largWidth={true} />
            </div>
            <div className='white-search mt-[20px] flex gap-[30px]' >
              <DropSearch selectData={this.getByUni.bind(this)} options={getDropValue(universities, "name", "id")} />
              {
                user?.role == "admin"?
                <button onClick={this.appRoveAll.bind(this)} className='p-[6px_9px] bg-blue text-white text-[14px] rounded-[6px]'>Approve all</button>:null
              }
            </div>
            <div className='flex items-center gap-[20px] mt-[20px]'>
              {
                educationDegree.map((data, i)=>{
                  return(
                    <button onClick={this.getProgramByDegree.bind(this, data?.id)} className={`${degree_id == data?.id? "bg-[var(--blue)] text-white":"border border-[var(--blue)] text-[var(--blue)]"} text-[12px] p-[4px_7px] rounded-full`}>
                      {data?.name}
                    </button>
                  )
                })
              }
                <button onClick={this.getProgramByDegree.bind(this, "")} className={`border border-[var(--blue)] text-[var(--blue)] text-[12px] p-[4px_7px] rounded-full`}>
                    Reset
                </button>
            </div>

            {
              user?.role == "admin"?
              <div className='flex items-center gap-[20px] mt-[20px]'>
                <button onClick={this.changeActiveStatus.bind(this, "")} className={`${active_status == ""? "bg-blue text-white": "bg-white txt-blue"} text-[14px] p-[3px_6px] rounded-[5px]`}>
                  All
                </button>
                <button onClick={this.changeActiveStatus.bind(this, "1")} className={`${active_status == "1"? "bg-blue text-white": "bg-white txt-blue"} text-[14px] p-[3px_6px] rounded-[5px]`}>
                  Actives
                </button>
                <button  onClick={this.changeActiveStatus.bind(this, "0")}className={`${active_status == "0"? "bg-blue text-white": "bg-white txt-blue"} text-[14px] p-[3px_6px] rounded-[5px]`}>
                  Inactives
                </button>
              </div>:null
            }
            <div className='grid-full-250 mt-[30px]'>
              {
                programs?.map((data, i)=>{
                  return(
                    <Card1 
                      key={i}
                      image={data?.image_full_url}
                      nameFull={data?.name}
                      country={data?.university?.name}
                      onClickEdit={this.openModal.bind(this, data)}
                      deletePath={`programs/${data?.id}`}
                      afterCallFunction={this.afterCallFunction.bind(this)}
                      activeStatus={data?.status}
                      onChangeStatus={this.onChangeStatus.bind(this, data?.id, data?.status)}
                      activeStatusPosition={true}
                    />
                  )
                })
              }
            </div>
            {
                programs?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={programs?.length} />:null
            }
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
  programs: state.Data.programs,
  educationDegree: state.Data.educationDegree,
  universities: state.Data.universities,
  user: state.Data.user,
});
const mapDispatchToProps = {changeStateValue, getPrograms, getEduDegree, getUniversities, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(AdminProgramsPage)




