import React, { Component } from 'react'
import CirclePercentage from './CirclePercentage'
import GreenCircle from './svg/GreenCircle'
import BlueCircileSvg from './svg/BlueCircileSvg'
import { connect } from 'react-redux'
import { getTeacherComplete } from '../actions/MainAction'
import ManatSvg from './svg/ManatSvg'

export class DashPaymentComponent extends Component {
  componentDidMount(){
    this.props.getTeacherComplete()
  }
  render() {
    const {teacherProfileComplete} = this.props;
    // console.log(teacherProfileComplete)
    return (
      <div className='bg-[#FCFCFC] w-full h-[167px] rounded-[10px] p-[10px]'>
        <h2 className='text-[#303972] font-bold text-[24px]'>Gözlənilən tələbə sayı</h2>
        <div className='flex items-center'>
            <CirclePercentage prcntg={teacherProfileComplete?.complete_percentage}/>
            <div className='flex flex-col gap-[10px]'>
                <div className='flex '>
                    <GreenCircle />
                    <div className='flex flex-col'>
                        <span className='text-[16px] font-regular text-[#1E265E]'>Müqavilə toplamı</span>
                        <span className='text-[16px] font-bold text-[#1E265E] inline-flex items-center gap-[3px]'>{teacherProfileComplete?.total_amount} <ManatSvg /> </span>
                    </div>
                </div>
                <div className='flex '>
                    <BlueCircileSvg />
                    <div className='flex flex-col'>
                        <span className='text-[16px] font-regular text-[#1E265E]'>Gəlir toplamı</span>
                        <span className='text-[16px] font-bold text-[#1E265E] inline-flex items-center gap-[3px]'>~{teacherProfileComplete?.earned_amount}<ManatSvg /></span>
                    </div>
                </div>
            </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  teacherProfileComplete: state.Data.teacherProfileComplete
});
const mapDispatchToProps = {getTeacherComplete}
export default connect(mapStateToProps, mapDispatchToProps)(DashPaymentComponent)
