import React, { Component } from 'react'
import CloseSvg from '../../svg/CloseSvg';

export class Header extends Component {
  render() {
    const {title} = this.props;
    return (
      <div className='flex h-[65px] rounded-t-[9px] border-b items-center justify-center relative'>
        <h3 className='ml-[24px] text-[#032552] font-semibold text-[24px] max-[600px]:text-[18px]'>{title}</h3>
       <div className='bg-white w-[38px] h-[38px] flex justify-center items-center absolute right-[-15px]  top-[-15px] rounded-full'>
          <button onClick={()=>this.props.closeBtn()} className='border border-[var(--blue)] rounded-full w-[30px] h-[30px] inline-flex justify-center items-center'>
            <CloseSvg color="#264EA9" />
          </button>
       </div>
      </div>
    )
  }
}

export default Header
