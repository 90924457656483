import React, { Component } from 'react'

export class WarningSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={100}
        height={88}
        style={{ minWidth:'100px' }}
        fill="none"
      >
        <path
          stroke="#FFBD13"
          strokeLinejoin="round"
          strokeWidth={4}
          d="M50 2.833 2.333 85.167h95.333L50 2.833Z"
        />
        <path
          stroke="#FFBD13"
          strokeLinecap="round"
          strokeWidth={4}
          d="M50 67.833V70m0-36.834.017 21.667"
        />
      </svg>
    )
  }
}

export default WarningSvg
