import React, { Component } from 'react'
import CloseSvg from '../../components/svg/CloseSvg'
import FirstNameComponent from './apply/FirstNameComponent'
import EduBackCountry from './apply/EduBackCountry'
import { changeStateValue, resetStateValue } from '../../redux/MainReducer'
import { connect } from 'react-redux'
import DisciplinePage from './apply/DisciplinePage'
import EducationBackground from './apply/EducationBackground'
import UserInfo from './apply/UserInfo'
import TrackingLine from '../../components/apply/TrackingLine'
import SelectBtn from '../../components/apply/SelectBtn'
import CheckRoundedSvg from '../../components/svg/CheckRoundedSvg'
import BtnRightSvg from '../../components/svg/BtnRightSvg'
import BtnLeftSvg from '../../components/svg/BtnLeftSvg'
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts'
import { insertNewDataParams } from '../../actions/MainAction'
import { withHooks } from '../../actions/withHooks'
import EmailOrPhoneConfirmModal from './apply/EmailOrPhoneConfirmModal'

export class StudentApplyPage extends Component {
 
  changePageNext=(page)=>{
    let otherPage=page
    if(page==="country"){
      if(this.props.applyDetails?.countries?.length !=0){
        otherPage="discipline";
      }else{
        mixinAlert("warning", "Ən azı 1 ölkə seçməlisiniz.")
      }
    }else if(page==="discipline"){
      if(this.props.applyDetails?.disciplines?.length !=0){
        otherPage="edu_back";
      }else{
        mixinAlert("warning", "Ən azı 1 sahə seçməlisiniz.")
      }
     
    }else if(page==="edu_back"){
      if(  
      this.props.applyDetails.educationBackground?.grade !=="" ||
      this.props.applyDetails.educationBackground?.a_level !=="" ||
      this.props.applyDetails.educationBackground?.ossd !=="" ||
      this.props.applyDetails.educationBackground?.ib !=="" ||
      this.props.applyDetails.educationBackground?.foundation !=="" ||
      this.props.applyDetails.educationBackground?.ap !==""){
        // console.log(this.props.applyDetails.educationBackground)
        otherPage="info";
      }else{
        mixinAlert("warning", "Zəhmət olmasa bütün xanaları doldurun")
      }
      
    }else if(page==="info"){
      if(this.props.applyDetails?.information?.first_name.trim() !="" && this.props.applyDetails?.information?.last_name.trim() !="" && this.props.applyDetails?.information?.phone_number.trim() !="" && this.props.phoneValidFormat){
        if(!this.props.phoneValidFormat){
          mixinAlert("warning", "Telefon nömrəniz doğru deyil!");
          return;
        }
        if(!this.props.applyDetails.information?.email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
          mixinAlert("warning", "Elektron poçt ünvanınız doğru deyil!")
          return;
        }
        this.props.insertNewDataParams("check-email-phone", {email: this.props.applyDetails.information?.email, phone_number:this.props.applyDetails.information?.phone_number})
          .then(resp=>{
            if(resp.msg == "success"){
              this.props.changeStateValue({name:"confirmEmailWhatsaapModal", value:true})
            }else{
              // mixinAlert("warning", resp?.data);
              this.props.changeStateValue({
                name:"incorrectModalData",
                value: {
                  position: true,
                  message: resp?.data
                }
              })
            }
          })
        // confirmAlert("warning", "Sizin mobil nömrənizin Whatsapp-ına 4 rəqəmli kod gələcək.","Whatsapp nömrəniz doğrudursa davam edin, ya da geri dönüb nömrənizi düzgün daxil edin.", "Davam et", "Ger dön")
        // .then(respAlert=>{
        //   if(respAlert == "success"){
        //     let formData = new FormData();
        //     for(const property in this.props.applyDetails?.information){
        //         formData.append(property, this.props.applyDetails?.information[property])
        //     }
        //     this.props.insertNewDataParams("send-verify-code", formData)
        //     .then(resp=>{
        //       // console.log(resp)
        //         if(resp?.msg == "success"){
        //           if(resp?.data?.valid){
        //             let formData = new FormData();
        //             formData.append("countries", JSON.stringify(this.props.applyDetails?.countries));
        //             formData.append("disciplines", JSON.stringify(this.props.applyDetails?.disciplines));
        //             formData.append("educationBackground", JSON.stringify(this.props.applyDetails?.educationBackground));
        //             formData.append("information", JSON.stringify(this.props.applyDetails?.information));
        //             formData.append("code", resp?.data?.verify?.code);
        //             formData.append("username", resp?.data?.verify?.username);
        //             if(window.location.search && atob(window.location.search?.replace("?tn=", ""))?.replace("generated", "")?.replace("code", "")){
        //               formData.append("teacher_id", atob(window.location.search?.replace("?tn=", ""))?.replace("generated", "")?.replace("code", ""));
        //             }
        //             this.props.changeStateValue({name:"loaderLong", value:true})
        //             // console.log(formData.get("code"))
        //             this.props.insertNewDataParams("register-student", formData)
        //             .then(resp2=>{
        //               this.props.changeStateValue({name:"loaderLong", value:false})
        //               if(resp2?.msg == 'success'){
        //                 localStorage.setItem("celtGoMainToken", resp2?.data?.token);
        //                 this.props.changeStateValue({name:'user', value:resp2?.data?.user});
        //                 this.props.resetStateValue({name:"verifyModalData"})
        //                 this.props.resetStateValue({name:"applyDetails"})
        //                 this.props.resetStateValue({name:"admissionModal"})
        //                 this.props.navigate("/available-programs?check_available")
        //               }else{
        //                 mixinAlert("error", "Təsdiq kodu yanlışdır");
        //               }
        //             })
        //           }else{
        //             this.props.changeStateValue({
        //               name:"verifyModalData",
        //               value:{
        //                   position:true,
        //                   code:'',
        //                   username: resp?.data?.username
        //               }
        //             })
        //           }
                   
        //         }else{
        //             mixinAlert("error", resp?.data);
        //         }
        //         // console.log(resp)
        //     })
        //   }
        // })
    }else{
        mixinAlert("warning", "Zəhmət olmasa bütün xanaları doldurun");
    }
    }
    this.props.changeStateValue({
      name:'applyDetails.page',
      value: otherPage
    })
  }
  changePagePrev=(page)=>{
    let otherPage=page
    if(page==="discipline"){
      otherPage="country";
    }else if(page==="edu_back"){
      otherPage="discipline";
    }else if(page==="info"){
      otherPage="edu_back";
    }
    this.props.changeStateValue({
      name:'applyDetails.page',
      value: otherPage
    })
  }
  closeBtn=()=>{
    this.props.resetStateValue({name:"verifyModalData"})
    this.props.resetStateValue({name:"applyDetails"})
    this.props.resetStateValue({name:"admissionModal"})
  }

  getTitle(page){
    if(page=="country"){
      return "Hansı ölkədə və ya ölkələrdə oxumaq istəyirsiniz?";
    }else if(page == "discipline"){
      return "Hansı sahədə oxumaq istəyirsiniz?";
    }else if(page == "edu_back"){
      return "Təhsil məlumatınız";
    }else if(page == "info"){
      return "Şəxsi məlumat";
    }
    // 
  }
  render() {
    const {applyDetails, confirmEmailWhatsaapModal} = this.props;
    // console.log(applyDetails)
    return (
      <div className='w-full h-screen flex justify-center items-center fixed background_shadow'>
        <div className='bg-white  rounded-[10px] w-[597px] max-[640px]:ml-[20px] max-[640px]:mr-[20px]'>
          <div className='flex justify-center relative p-[18px_0] border-b'>
            <h2 className='text-[#032552] font-semibold text-[24px]   max-[640px]:text-[14px] text-center'>{this.getTitle(applyDetails?.page)}</h2>
            <div className='absolute right-[-25px] top-[-25px] mt-0 bg-white w-[38px] h-[38px] flex justify-center items-center rounded-full max-[640px]:w-[30px] max-[640px]:h-[30px] max-[640px]:right-[-20px] max-[640px]:top-[-20px]'>
             <button onClick={this.closeBtn.bind(this)} className='w-[30.28px] h-[30.28px] rounded-full border border-[#1E398F] inline-flex justify-center items-center max-[640px]:w-[24px] max-[640px]:h-[24px]'>
              <CloseSvg color="#1E398F" />
             </button>
            </div>
          </div>

          <div className='p-[15px_11px]'>
            <TrackingLine page={applyDetails?.page == "country"?1: applyDetails?.page == "discipline"?2: applyDetails?.page == "edu_back"?3: applyDetails?.page == "info"?4:1} />
            {
            applyDetails?.page == "country"?
            <EduBackCountry  />:
            applyDetails?.page == "discipline"?
            <DisciplinePage  />:
            applyDetails?.page == "edu_back"?
            <EducationBackground  />:
            applyDetails?.page == "info"?
            <UserInfo  />:
           null
          }
            <div className='mt-[45px] flex gap-[25px]'>
              {
                applyDetails?.page !== "country"?
                <button onClick={this.changePagePrev.bind(this, applyDetails?.page)} className='w-full h-[48px] bg-[#EE7100] rounded-[8px] inline-flex justify-center items-center text-white text-[20px] font-regular relative max-[400px]:text-[16px]'>
                  <span className='absolute left-[16px]'>
                    <BtnLeftSvg />
                  </span>
                  <span>Geri</span>
                </button>:null
              }
              
              <button onClick={this.changePageNext.bind(this, applyDetails?.page)} className={`w-full h-[48px] ${ applyDetails?.page === "country"?"bg-[#EE7100]":"bg-[#1E398F]"}  rounded-[8px] inline-flex justify-center items-center text-white text-[20px] font-regular relative max-[400px]:text-[16px]`}>
                <span>Növbəti</span>
                <span className='absolute right-[16px]'>
                  <BtnRightSvg />
                </span>
              </button>
            </div>
          </div>
        </div>
        {confirmEmailWhatsaapModal? <EmailOrPhoneConfirmModal />:null}
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  applyDetails: state.Data.applyDetails,
  phoneValidFormat: state.Data.phoneValidFormat,
  confirmEmailWhatsaapModal: state.Data.confirmEmailWhatsaapModal,
});
const mapDispathToProps = {changeStateValue, insertNewDataParams, resetStateValue}
export default connect(mapStateToProps, mapDispathToProps)(withHooks(StudentApplyPage))
