import React, { Component } from 'react'
import Header from './components/Header'
import { changeStateValue, resetStateValue } from '../../redux/MainReducer';
import { connect } from 'react-redux';
import Input from './components/Input';
import UploadExamSvg from '../svg/UploadExamSvg';
import Pdf2Svg from '../svg/Pdf2Svg';
import { mixinAlert } from '../../actions/SweetAlerts';
import { getStudentMainExpences, insertNewData } from '../../actions/MainAction';

export class ExpencessModal extends Component {
    state={
        btnDisabled:false
    }
    closeModal=()=>{
        this.props.resetStateValue({name:"expencessModalData"})
    }
    getBase64(file){
        return new Promise((res, rej)=>{
            let reader = new FileReader();
            reader.onload = () => {
                res(reader.result);
            }
            reader.readAsDataURL(file)
        })
      }
    
    uploadFile=(e)=>{
      this.getBase64(e.target.files[0])
      .then(resp=>{
        this.props.changeStateValue({name:"expencessModalData.file", value:e.target.files[0]})
        this.props.changeStateValue({name:"expencessModalData.file_full_url", value: resp})
        this.props.changeStateValue({name:"expencessModalData.file_type", value: e.target.files[0]?.type})
      })
      
    }
    removeImg=()=>{
        this.props.changeStateValue({name:"expencessModalData.file", value:""})
        this.props.changeStateValue({name:"expencessModalData.file_full_url", value: ""})
        this.props.changeStateValue({name:"expencessModalData.file_type", value:""})
    }

    saveBtn=(data)=>{
        if(data?.title !="" && data?.file !="" && data?.amount !="" && data?.amount !=0){
            this.setState({btnDisabled:true})
            let formData = new FormData();
            for(const property in data){
                formData.append(property, data[property])
            }
            let url = "student-expencess";
            if(data?.id && data?.id !=""){
                url=`student-expencess/${data?.id}`
                formData.append("_method", "put")
            }
            this.props.insertNewData(url, formData)
            .then(resp=>{
                this.setState({btnDisabled:false})
                if(resp == "success"){
                    mixinAlert("success", "Məbləğ uğurla əlavə edildi")
                    this.closeModal();
                    this.props.getStudentMainExpences(data?.student_id)
                }else{
                    mixinAlert("error", "Xəta baş verdi")
                }
            })
            
        }else{
            mixinAlert("warning", "Zəhmət olmasa bütün xanaları doldurun")
        }
    }
  render() {
    const {expencessModalData} = this.props;
    const {btnDisabled} = this.state;
    console.log(expencessModalData)
    return (
      <div className='background_shadow flex items-center justify-center '>
           <div className='bg-[#F5F5F5] w-[600px]  shadow rounded-[10px] '>
                <Header closeBtn={this.closeModal.bind(this)} title={expencessModalData?.id ==""?"Xərc əlavə et":"Xərci redaktə et"} />
                <div className='p-[20px] grid grid-cols-2 gap-[20px]'>
                    <Input bg="bg-gray-200" type="text" name="expencessModalData.title" value={expencessModalData?.title} title="Başlıq" />
                    <Input bg="bg-gray-200" type="number" name="expencessModalData.amount" value={expencessModalData?.amount} title="Məbləğ" />
                </div>
                <div className='p-[20px] flex flex-col justify-center items-center w-full'>
                    <label htmlFor='cvFileUpload' className='cursor-pointer bg-gray-200 inline-flex w-[80px] h-[80px] justify-center items-center rounded-full'>
                        {
                            expencessModalData?.file !==""?
                            expencessModalData?.file_type ==="application/pdf"?
                            <Pdf2Svg />:
                           <img src={expencessModalData?.file_full_url} className='w-[80px] h-[80px] rounded-full' />:<UploadExamSvg /> 
                            
                        }
                    </label>
                    {
                        expencessModalData?.file !==""?
                        <button onClick={this.removeImg.bind(this)} className='bg-red p-[2px_8px] text-[12px] text-white rounded-[5px] mt-[10px]'>Sil</button>:null
                    }
                    <input onChange={this.uploadFile.bind(this)} id="cvFileUpload" type='file' accept='image/*, application/pdf' className='hidden' />
                </div>
                <div className='p-[20px] flex justify-between items-center'>
                <button disabled={btnDisabled} onClick={this.closeModal.bind(this)} className='bg-orange h-[48px] pl-[10px] pr-[10px]  text-[20px] rounded-[5px] text-white font-regular'>Bağla</button>
                <button disabled={btnDisabled} onClick={this.saveBtn.bind(this,expencessModalData)} className='bg-blue h-[48px] pl-[10px] pr-[10px]  text-[20px] rounded-[5px] text-white font-regular'>Yadda saxla</button>
                </div>
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    expencessModalData: state.Data.expencessModalData
});
const mapDispatchToProps = {changeStateValue, resetStateValue, insertNewData, getStudentMainExpences}
export default connect(mapStateToProps, mapDispatchToProps)(ExpencessModal)
