import React, { Component } from 'react'
import { changeStateValue } from '../../../redux/MainReducer';
import { connect } from 'react-redux';
import { getMessagesActiveChats } from '../../../actions/MainAction';
import ChatSearch from './ChatSearch';
import ChatTypeBtn from './ChatTypeBtn';
import ChatCard from './ChatCard';
import moment from 'moment';
import MsgContats from './MsgContats';

export class MessagesUsers extends Component {
    state={
        chatType:"active",
        search:''
    }

    componentDidMount(){
        this.props.getMessagesActiveChats()
    }
  render() {
    const {messagesActiveUsers, onClickMsg=()=>{}, student_id} = this.props;
    const {chatType, search} = this.state;
    return (
      <div className='bg-white p-[20px_0] rounded-[10px] min-w-[290px]'>
        <h3 className='txt-blue text-[24px] font-regular  p-[0_20px]'>Söhbət</h3>
        <ChatSearch onSearch={(search)=>this.setState({search})} />
        <ChatTypeBtn chatType={chatType} onChangeType={(type)=> this.setState({chatType:type})} />
        <div className='flex flex-col overflow-auto max-h-[70vh] mt-[10px]'>
            {
                chatType == "active"?
                messagesActiveUsers?.map((data, i)=>{
                    if(data?.student?.full_name?.toLowerCase().includes(search?.toLowerCase())){
                        return (
                            <ChatCard 
                                student_id={student_id}
                                key={i}
                                title={data?.student?.full_name}
                                last_msg={data?.last_message}
                                date={moment(data?.last_date).format("DD/MM HH:mm")}
                                onClickMsg={()=>onClickMsg(data)}
                                data={data}
                            />
                        )
                    }
                    
                }):null
            }
            {
               chatType == "contact"?
               <MsgContats search={search} onClickMsg={(data)=>{onClickMsg(data); this.setState({ chatType:"active"})}} />:null
            }
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    messagesActiveUsers: state.Data.messagesActiveUsers
});
const mapDispatchToProps = {changeStateValue, getMessagesActiveChats}
export default connect(mapStateToProps, mapDispatchToProps)(MessagesUsers)
