import React, { Component } from 'react'
import { getDashApplies } from '../actions/MainAction';
import { connect } from 'react-redux';
import NavApplySvg from '../components/svg/NavApplySvg';
import { Link } from 'react-router-dom';
import CardRightSvg from '../components/svg/CardRightSvg';
import NavApply2Svg from '../components/svg/NavApply2Svg';
import BookingSvg from '../components/svg/BookingSvg';

export class StaffDashBoard extends Component {

    componentDidMount(){
        this.props.getDashApplies()
    }
  render() {
    const {dashAppliesData} = this.props;
    // console.log(dashAppliesData)
    return (
      <div>
        <div className='bg-white p-[10px] shadow rounded-[10px] max-w-[500px]'>
            <h3 className='text-[22px] font-semibold'>Müraciətlər</h3>
            <div className='mt-[20px] flex flex-col gap-[10px]'>
                <div className='flex items-center justify-between'>
                    <div className='flex  items-center gap-[10px]'>
                        <div className='bg-blue-100 w-[40px] h-[40px] min-w-[40px] flex justify-center items-center rounded-full'>
                            <NavApplySvg />
                        </div>
                        <h3 className='txt-blue text-[18px]'>Reytinq üçün müraciətlər: {dashAppliesData?.ranking}</h3>
                    </div>
                    <Link to={`/admin-ranking-applies`}>
                        <CardRightSvg />
                    </Link>
                </div>
                <div className='flex items-center justify-between'>
                    <div className='flex  items-center gap-[10px]'>
                        <div className='bg-blue-100 w-[40px] h-[40px] min-w-[40px] flex justify-center items-center rounded-full'>
                            <NavApply2Svg />
                        </div>
                        <h3 className='txt-blue text-[18px]'>CELT Az-dan müraciətlər: {dashAppliesData?.celt_az}</h3>
                    </div>
                    <Link to={`/admin-celt-az-applies`}>
                        <CardRightSvg />
                    </Link>
                </div>
                <div className='flex items-center justify-between'>
                    <div className='flex  items-center gap-[10px]'>
                        <div className='bg-blue-100 w-[40px] h-[40px] min-w-[40px] flex justify-center items-center rounded-full'>
                            <BookingSvg />
                        </div>
                        <h3 className='txt-blue text-[18px]'>Randevu üçün müraciətlər: {dashAppliesData?.booking}</h3>
                    </div>
                    <Link to={`/admin-booking`}>
                        <CardRightSvg />
                    </Link>
                </div>
            </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    dashAppliesData: state.Data.dashAppliesData
});
const mapDispatchToProps = { getDashApplies }
export default connect(mapStateToProps, mapDispatchToProps)(StaffDashBoard)
