import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue } from '../../redux/MainReducer';
import Header from '../../components/header/Header';
import Buttons from '../../components/Buttons';
import { getApplyRequirements } from '../../actions/MainAction';
import Card1 from '../../components/cards/Card1';
export class AdminApplyRequirementsPage extends Component {

    componentDidMount(){
        this.props.getApplyRequirements()
    }

    openModal=(data)=>{
        this.props.changeStateValue({
          name:'adminModal',
          value:{
            position: true,
            id: data?.id?data?.id:'',
            title: data?.title?data?.title:'',
            text: data?.text?data?.text:'',
            afterCallFunction:this.props.getApplyRequirements,
            afterCallParams:[],
            requireds: ["title"],
            url:'apply-requirements',
            modalTitle:`${data?.id?'Edit':'Add'} a apply requirement`
          }
        })
    }
    afterCallFunction=()=>{
        this.props.getApplyRequirements()
    }
  render() {
    const {applyRequirements} = this.props;
    // console.log(applyRequirements)
    return (
        <div className='w-full  pl-[27px] pr-[27px]'>
            <Header title="Apply Requirements" />
            <div className='flex justify-end mt-[30px]'>
                <Buttons onClick={this.openModal.bind(this, '')} padding={true} options={[{title:"Add a apply requirement", value:0}]} selected={0} />
            </div>
            <div className='grid-full-250 mt-[30px]'>
              {
                applyRequirements?.map((data, i)=>{
                  return(
                    <Card1
                      key={i}
                      name={data?.title}
                      onClickEdit={this.openModal.bind(this, data)}
                      deletePath={`apply-requirements/${data?.id}`}
                      afterCallFunction={this.afterCallFunction.bind(this)}
                    />
                  )
                })
              }
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    applyRequirements: state.Data.applyRequirements
});
const mapDispatchToProps = {getApplyRequirements, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(AdminApplyRequirementsPage)
