import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PrAppSvg from '../../../components/svg/PrAppSvg'
import CardRightSvg from '../../../components/svg/CardRightSvg'

export class StudentProgramInfo extends Component {
    getLink(link, student_id, program_id){
        if(link==""){
            return "/"
        }else{
            let newLink=link;
            if(newLink.includes(":student_id")){
                newLink = newLink.replace(":student_id", student_id)
            }
            if(newLink.includes(":program_id")){
                newLink = newLink.replace(":program_id", program_id)
            }
            return newLink
        }
    }
  render() {
    const {title, options=[], link="", emptyText=""} = this.props
    return (
        <div className='bg-white rounded-[4px] bg-white p-[41px_32px_32px_15px]'>
            <h2 className='text-[#303972] text-[24px] font-bold'>{title}</h2>
            {
                options.length ==0?
                <div className=' h-full flex justify-center items-center'>
                    <p className='txt-blue text-[20px] font-semibold text-center'>{emptyText}</p>
                </div>:null

            }
            <div className='flex flex-col gap-[11px] mt-[30px]'>
                {
                    options?.map((data, i)=>{
                        return(
                            <div key={i} className='flex justify-between items-center max-[400px]:border-b'>
                                <div className='flex gap-[16px]'>
                                    <div className='max-[450px]:hidden'>
                                    <PrAppSvg />
                                    </div>
                                    <div>
                                        <h4 className='text-[#18181B] font-bold text-[18px] max-[450px]:text-[15px]'>{data?.name}</h4>
                                        <span className='text-[#52525B] text-[14px] font-regular max-[450px]:text-[12px]'>{data.university?.name}</span>
                                    </div>
                                </div>
                                <Link  to={this.getLink(link, data?.applied_program?.student_id, data?.id)}>
                                    <CardRightSvg />
                                </Link>
                            </div>
                        )
                    })
                }
            
            </div>
        </div>
    )
  }
}

export default StudentProgramInfo
