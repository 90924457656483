import React, { Component } from 'react'
import Header from '../../components/header/Header'
import Search from '../../components/Search'
import Buttons from '../../components/Buttons'
import { connect } from 'react-redux'
import { changeStateValue } from '../../redux/MainReducer';
import { getTeacherSudents } from '../../actions/MainAction';
import Card1 from '../../components/cards/Card1'
import LoadMoreBtn from '../../components/LoadMoreBtn'
import { withHooks } from '../../actions/withHooks'
import defUserImg from "../../images/def_user.png"
import ChangeTNModal from '../../components/modals/ChangeTNModal'
export class TeacherStudens extends Component {
  state={
    page:1,
    search:'',
  }
  openModal=(data)=>{
    this.props.navigate(`/students/${data==""?"create":data?.id}`)
   
  }

  componentDidMount(){
    this.props.getTeacherSudents(1, '', this.props.params.id)
  }

  onSearchData=(search)=>{
    this.setState({search})
    this.props.getTeacherSudents(this.state.page, search, this.props.params.id)
  }
  loadMore=(page)=>{
    this.setState({page})
    this.props.getTeacherSudents(page, this.state.search, this.props.params.id)
  }

  afterCallFunction=()=>{
    this.props.getTeacherSudents(this.state.page, this.state.search, this.props.params.id)
  }

  onChangeBtn=(data)=>{
    this.props.changeStateValue({
      name:"changeTNModalData",
      value:{
        position: true,
        user_id: data?.id,
        staff_id: data?.related_user?.staff_id
      }
    })
  }
  render() {
    const {teacherStudents, user, changeTNModalData} = this.props;
    // console.log(teacherStudents)
    return (
        <div className='w-full  pl-[27px] pr-[27px]'>
            <Header title="Tələbələr" />
            {
              user?.role === "teacher"?
              <div className='flex justify-end mt-[30px]'>
                <Buttons onClick={this.openModal.bind(this, '')} options={[{title:"Tələbə əlavə et", value:0}]} padding={true} selected={0} />
            </div>:null
            }
            
            <div className='flex justify-center mt-[20px]'>
                <Search onSearchData={this.onSearchData.bind(this)} largWidth={true} />
            </div>

            <div className='grid-full-250 mt-[30px]'>
              {
                teacherStudents?.map((data, i)=>{
                  return(
                    <Card1 
                      key={i}
                      image={data?.image_full_url? data?.image_full_url:defUserImg}
                      name={data?.full_name}
                      onClickEdit={this.openModal.bind(this, data)}
                      // deletePath={`users/${data?.id}`}
                      afterCallFunction={this.afterCallFunction.bind(this)}
                      moreLink={`/student-info/${data?.id}`}
                      username={"İstifadəçi adı: "+data?.username}
                      relatedUser={data?.related_user?.staff?.full_name}
                      changeStaffBtn={true}
                      onChangeBtn={this.onChangeBtn.bind(this, data)}
                    />
                  )
                })
              }
            </div>
            {
                teacherStudents?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={teacherStudents?.length} />:null
            }
            {changeTNModalData?.position ? <ChangeTNModal afterCallFunction={this.afterCallFunction.bind(this)} /> :null}
            
            
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    teacherStudents: state.Data.teacherStudents,
  user: state.Data.user,
  changeTNModalData: state.Data.changeTNModalData
});
const mapDispatchToProps = {changeStateValue, getTeacherSudents}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(TeacherStudens))







