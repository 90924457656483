import React, { Component } from 'react'
import MessageBox from '../../../messages/MessageBox'
import { connect } from 'react-redux';
import { getMesages } from '../../../../actions/MainAction';
export class StudentMessage extends Component {
  state={
    messages:[]
  }
  componentDidMount(){
    this.props.getMesages({page:1, message_type:"student_box", student_id:this.props.student_id})
    .then(resp=>{
      this.setState({messages:resp})
    })
  }
  getAfterChange=()=>{
    this.props.getMesages({page:1, message_type:"student_box", student_id:this.props.student_id})
    .then(resp=>{
      this.setState({messages:resp})
    })
  }
  render() {
    const {messages} = this.state;

    return (
      <div>
        <MessageBox student_id={this.props.student_id} getAfterChange={this.getAfterChange.bind(this)} messagesParams={messages} />
      </div>
    )
  }
}
const mapStateToProps = (state)=>({

});
const mapDispatchToProps = {getMesages}
export default connect(mapStateToProps, mapDispatchToProps)(StudentMessage)
