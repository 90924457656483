import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getMessagesContacts } from '../../../actions/MainAction';
import ChatCard from './ChatCard';

export class MsgContats extends Component {
    componentDidMount(){
        this.props.getMessagesContacts()
    }
  render() {
    const {messagesContacts, onClickMsg=()=>{}, search=""} = this.props;
   
    return (
      <>
        {
            messagesContacts?.map((data, i)=>{
                if(data?.full_name.toLowerCase().includes(search?.toLowerCase())){
                    return(
                        <ChatCard
                            key={i}
                            title={data?.full_name}
                            onClickMsg={()=>onClickMsg({student_id: data?.id})}
                            data={data}
                        />
                    )
                }
                
            })
        }
      </>
    )
  }
}
const mapStateToProps = (state) =>({
    messagesContacts: state.Data.messagesContacts
});
const mapDispatchToProps = {getMessagesContacts}
export default connect(mapStateToProps, mapDispatchToProps)(MsgContats)
