import React, { Component } from 'react'

export class EduSvg extends Component {
  render() {
    return (
        <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={13}
    fill="none"
  >
    <path
      fill="#6C6C6C"
      d="M8.694 12.837c-1.713-.02-3.297-.503-4.812-1.274-.372-.19-.508-.411-.5-.829.027-1.312.007-2.624.014-3.937.001-.169-.053-.25-.207-.317-.677-.297-1.347-.61-2.021-.915-.138-.062-.25-.136-.262-.305-.014-.176.085-.272.225-.352C3.576 3.523 6.02 2.136 8.464.748c.157-.09.3-.087.458-.002 2.577 1.386 5.156 2.771 7.735 4.156.138.074.252.16.243.34-.009.176-.12.26-.266.323-.805.345-1.606.7-2.415 1.034-.174.072-.221.164-.22.343.007 1.332 0 2.664.008 3.996.001.253-.091.402-.315.522-1.211.653-2.484 1.117-3.853 1.287-.38.048-.763.061-1.145.09Zm7.12-7.64c-.06-.035-.097-.059-.137-.08-2.281-1.225-4.562-2.45-6.842-3.679-.107-.058-.184-.045-.284.012C6.626 2.544 4.7 3.634 2.775 4.726l-.821.467c.073.04.116.067.163.088 2.15.977 4.299 1.956 6.45 2.928a.375.375 0 0 0 .271-.001c.617-.257 1.229-.526 1.843-.788a.75.75 0 0 1 .26-.071c.242-.007.405.27.267.468a.63.63 0 0 1-.26.19c-.659.29-1.323.568-1.982.86a.623.623 0 0 1-.557-.006c-1.373-.63-2.75-1.254-4.126-1.879-.068-.031-.138-.059-.24-.102v.258c0 1.105.014 2.21-.01 3.314-.006.335.083.509.397.66 1.63.785 3.324 1.203 5.144 1.01 1.303-.137 2.516-.552 3.665-1.168a.241.241 0 0 0 .107-.176c.006-1.242.005-2.483.002-3.724a.209.209 0 0 0-.063-.143c-.306-.253-.276-.493.09-.652l2.233-.964c.064-.028.126-.06.206-.098Z"
    />
  </svg>
    )
  }
}

export default EduSvg
