import React, { Component } from 'react'
import Header from '../../components/header/Header'
import Search from '../../components/Search'
import Buttons from '../../components/Buttons'
import { connect } from 'react-redux'
import { changeStateValue } from '../../redux/MainReducer';
import { getExpencesCategories } from '../../actions/MainAction';
import Card1 from '../../components/cards/Card1'
import LoadMoreBtn from '../../components/LoadMoreBtn'
export class AdminExpencesCategories extends Component {
  state={
    page:1,
    search:''
  }
  openModal=(data)=>{
    this.props.changeStateValue({
      name:'adminModal',
      value:{
        position: true,
        id: data?.id?data?.id:'',
        title: data?.title?data?.title:'',
        afterCallFunction:this.props.getExpencesCategories,
        afterCallParams:[this.state.page, this.state.search],
        requireds: ["title"],
        url:'expences-categories',
        modalTitle:`${data?.id?'Edit':'Add'} Category`
      }
    })
  }

  componentDidMount(){
    this.props.getExpencesCategories(1, '')
  }

 
  afterCallFunction=()=>{
    this.props.getExpencesCategories(this.state.page, this.state.search)
  }
  render() {
    const {expencesCategories} = this.props;
  
    return (
        <div className='w-full  pl-[27px] pr-[27px]'>
            <Header title="Expences Categories" />
            <div className='flex justify-end mt-[30px]'>
                <Buttons onClick={this.openModal.bind(this, '')} options={[{title:"Add a category", value:0}]} selected={0} />
            </div>
          
            <div className='grid-full-250 mt-[30px]'>
              {
                expencesCategories?.map((data, i)=>{
                  return(
                    <Card1 
                      key={i}
                    //   image={data?.image_full_url}
                      name={data?.title}
                      onClickEdit={this.openModal.bind(this, data)}
                      deletePath={`expences-categories/${data?.id}`}
                      afterCallFunction={this.afterCallFunction.bind(this)}
                    />
                  )
                })
              }
            </div>
            
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
  expencesCategories: state.Data.expencesCategories
});
const mapDispatchToProps = {changeStateValue, getExpencesCategories}
export default connect(mapStateToProps, mapDispatchToProps)(AdminExpencesCategories)


