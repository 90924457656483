import React, { Component } from 'react'
import DotsSvg from '../../../components/svg/DotsSvg';
import PdfSvg from '../../../components/svg/PdfSvg';
import MsgReadedSvg from '../../../components/svg/MsgReadedSvg';
import { getLinkFormat } from './getLinks';
import img from "../../../images/def_user.png"
import Tooltip from './Tooltip';
export class FromCard extends Component {
    state={
        dotVisible:false
    }
  render() {
    const {msg, date, forward=false, 
        forwardUser, forwardMsg, userImg="",   
        msgImage="",
        msgAudio="",
        data, 
        msgPdf="", onReply=()=>{}, readStatus=true, getAfterChange=()=>{}} = this.props;
    const {dotVisible} = this.state;
    return (
      <div className='flex gap-[12px] max-[520px]:gap-[5px] items-start justify-end'>
        {dotVisible? <div onClick={()=>this.setState({dotVisible:false})} className='absolute w-full h-[100vh] top-0 left-0 z-20'></div>:null}
        <div className='relative'>
            <button onClick={()=>this.setState({dotVisible:true})}>
                <DotsSvg />
            </button>
            {
                dotVisible?
                <Tooltip getAfterChange={()=>getAfterChange()} onClose={()=> this.setState({dotVisible:false})} data={data} onReply={()=>{onReply(); this.setState({dotVisible:false})}}  left={true} />:null
            }
        </div>
        <div className='bg-[#5E7EE4]  max-w-[70%] max-[490px]:max-w-[80%] p-[7px] rounded-[5px] mt-[2px] items-start flex flex-col pr-[15px]'>
            {
                forward?
                <div className='bg-[#F5F9FF] w-[100%] rounded-[5px]  p-[7px] mb-[5px]'>
                    <span className='text-[#32BEA6] text-[12px]'>{forwardUser}</span>
                    <p  className='text-[#5C5C5C] text-[11px] break-all'>{forwardMsg}</p>
                </div>:null
            }
            
            {
                msgImage && msgImage !== ""?
                <a href={msgImage}  download={true} target='_blank'>
                    <img className='rounded-[6px] w-[500px] mb-[10px] max-[490px]:w-[100%]' src={msgImage} />
                </a>:
                msgAudio && msgAudio !== ""?
                <div className='w-[300px] msg-audio'>
                    <audio className='bg-white  listening-audio '  src={msgAudio} controls />
                </div>:
                msgPdf && msgPdf !==""?
                <a download={true} target='_blank' className='inline-flex items-center bg-[#F5F9FF] p-[4px] rounded-[6px] text-[13px] gap-[4px]' href={msgPdf}><PdfSvg /> {msg}</a>:
                <pre className='text-[14px] whitespace-break-spaces text-[#fff] font-regular text break-all' dangerouslySetInnerHTML={{ __html: getLinkFormat(msg) }}></pre>
            }
            {/* <span className='flex text-[10px] font-medium w-[100%] gap-[5px] justify-end text-[#707070] ml-[9px]'> <MsgReadedSvg color={readStatus?"#53bdeb":"currentColor"} /></span> */}
        </div>
        <div className='flex flex-col items-center'>
            <img alt="user image" className='w-[32px] h-[32px] rounded-[6px] max-[520px]:w-[20px] max-[520px]:h-[20px]' onError={(e)=>{e.target.src = img}} src={userImg && userImg !== ""? userImg:img} />
            <span className='text-[#71717A] text-[12px] font-regular'>{date}</span>
        </div>
        
      </div>
    )
  }
}

export default FromCard
