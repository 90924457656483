import React, { Component } from 'react'
import { getEmployeeRankings } from '../../actions/MainAction';
import { connect } from 'react-redux';

export class EmployeeRanking extends Component {
    componentDidMount(){
        this.props.getEmployeeRankings();
    }
  render() {
    const {employRankings, user, onDelete=()=>{}, onEdit=()=>{}} = this.props;
    // console.log(employRankings)
    return (
      <div className='w-full'>
        <table className='w-full bg-white rounded-[10px]'>
            <thead>
                <tr>
                    <th className='w-1/3 border-r text-[18px] font-semibold text-center h-[40px]'>Ranking</th>
                    <th className='w-1/3 border-r text-[18px] font-semibold text-center h-[40px]'>University</th>
                    <th className='w-1/3 text-[18px] font-semibold text-center h-[40px]'>Country</th>
                    {
                        user?.role == "admin"?
                        <th className='w-1/3 text-[18px] font-semibold text-center h-[40px] border-l'>Action</th>:null
                    }
                    
                </tr>
            </thead>
            <tbody>
                {
                    employRankings?.map((data, i) => {
                        return(
                            <tr className='border-t  h-[40px]' key={i}>
                                <td className='text-center border-r text-[14px]'>{data?.ranking}</td>
                                <td className='text-center border-r text-[14px]'>{data?.city}</td>
                                <td className='text-center text-[14px]'>{data?.country?.name}</td>
                                
                                {
                                    user?.role == "admin"?
                                    <td className='text-center text-[14px] flex items-center gap-[20px] pr-[20px] border-l h-[40px] pl-[20px]'>
                                        <button onClick={()=>onEdit(data)} className='bg-blue text-white text-[12px] p-[4px_8px] rounded-[10px]'>Edit</button>
                                        <button onClick={()=>onDelete(data?.id)} className='bg-red text-white text-[12px] p-[4px_8px] rounded-[10px]'>Delete</button>
                                    </td>:null
                                }
                                
                            </tr>
                        )
                    })
                }
               
            </tbody>
        </table>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
    employRankings: state.Data.employRankings,
    user: state.Data.user,
});
const mapDispatchToProps  = {getEmployeeRankings}
export default connect(mapStateToProps, mapDispatchToProps)(EmployeeRanking)


