import React, { Component } from 'react'
import Header from '../../components/header/Header'
import Search from '../../components/Search'
import Buttons from '../../components/Buttons'
import { connect } from 'react-redux'
import { changeStateValue } from '../../redux/MainReducer';
import { getCities } from '../../actions/MainAction';
import Card1 from '../../components/cards/Card1'
import LoadMoreBtn from '../../components/LoadMoreBtn'
export class AdminCitiesPage extends Component {
  state={
    page:1,
    search:''
  }
  openModal=(data)=>{
    this.props.changeStateValue({
      name:'adminModal',
      value:{
        position: true,
        id: data?.id?data?.id:'',
        name: data?.name?data?.name:'',
        image: data?.image?data?.image:'',
        image_full_url: data?.image_full_url?data?.image_full_url:'',
        country_id: data?.country_id?data?.country_id:'',
        state_id: data?.state_id?data?.state_id:'',
        afterCallFunction:this.props.getCities,
        afterCallParams:[this.state.page, this.state.search],
        requireds: ["name", "country_id"],
        url:'city',
        modalTitle:`${data?.id?'Edit':'Add'} City`
      }
    })
  }

  componentDidMount(){
    this.props.getCities(1, '')
  }

  onSearchData=(search)=>{
    this.setState({search})
    this.props.getCities(this.state.page, search)
  }
  loadMore=(page)=>{
    this.setState({page})
    this.props.getCities(page, this.state.search)
  }

  afterCallFunction=()=>{
    this.props.getCities(this.state.page, this.state.search)
  }
  render() {
    const {cities, user} = this.props;
    return (
        <div className='w-full  pl-[27px] pr-[27px]'>
            <Header title="Cities" />
            <div className='flex justify-end mt-[30px]'>
                <Buttons onClick={this.openModal.bind(this, '')} options={[{title:"Add a city", value:0}]} selected={0} />
            </div>
            <div className='flex justify-center mt-[20px]'>
                <Search onSearchData={this.onSearchData.bind(this)} largWidth={true} />
            </div>

            <div className='grid-full-250 mt-[30px]'>
              {
                cities?.map((data, i)=>{
                  return(
                    <Card1 
                      key={i}
                      image={data?.image_full_url}
                      name={data?.name}
                      country={data?.country?.name}
                      onClickEdit={this.openModal.bind(this, data)}
                      deletePath={user?.role == "admin"? `city/${data?.id}`:""}
                      afterCallFunction={this.afterCallFunction.bind(this)}
                    />
                  )
                })
              }
            </div>
            {
                cities?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={cities?.length} />:null
            }
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
  cities: state.Data.cities,
  user: state.Data.user,
});
const mapDispatchToProps = {changeStateValue, getCities}
export default connect(mapStateToProps, mapDispatchToProps)(AdminCitiesPage)




