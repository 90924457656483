import React, { Component } from 'react'
import { resetStateValue } from '../../redux/MainReducer';
import { connect } from 'react-redux';
import moment from 'moment';

export class ProgramInfoModal extends Component {
    closeModal=(e)=>{
        if(typeof e.target.className == "string" && e.target.className.includes("background_shadow")){
            this.props.resetStateValue({name:"programInfoModal"})
        }
    }
  render() {
    const {programInfoModal} = this.props;
    return (
      <div onClick={this.closeModal.bind(this)} className='background_shadow flex justify-center items-center'>
        <div className='w-[700px] bg-white p-[20px] shadow rounded-[10px] grid grid-cols-2 gap-[20px] max-h-[70vh] overflow-auto'>
            {
                programInfoModal?.name?
                <div>
                    <span className='text-[12px]'>Name</span>
                    <div className='border text-[14px] p-[5px_10px] bg-gray-100 rounded-[6px]'>{programInfoModal?.name}</div>
                </div>:null
            }
            {
                programInfoModal?.degree?
                <div>
                    <span className='text-[12px]'>Degree</span>
                    <div className='border text-[14px] p-[5px_10px] bg-gray-100 rounded-[6px]'>{programInfoModal?.degree}</div>
                </div>:null
            }
            {
                programInfoModal?.language?
                <div>
                    <span className='text-[12px]'>Language</span>
                    <div className='border text-[14px] p-[5px_10px] bg-gray-100 rounded-[6px]'>{programInfoModal?.language}</div>
                </div>:null
            }
            {
                programInfoModal?.disciplines?
                <div>
                    <span className='text-[12px]'>Disciplines</span>
                    <div className='border text-[14px] p-[5px_10px] bg-gray-100 rounded-[6px]'>{programInfoModal?.disciplines}</div>
                </div>:null
            }
            {
                programInfoModal?.start_date?
                <div>
                    <span className='text-[12px]'>Start date</span>
                    <div className='border text-[14px] p-[5px_10px] bg-gray-100 rounded-[6px]'>{moment(programInfoModal?.start_date).format("DD/MM/YYYY")}</div>
                </div>:null
            }
            {
                programInfoModal?.deadline?
                <div>
                    <span className='text-[12px]'>Deadline</span>
                    <div className='border text-[14px] p-[5px_10px] bg-gray-100 rounded-[6px]'>{moment(programInfoModal?.deadline).format("DD/MM/YYYY")}</div>
                </div>:null
            }
            {
                programInfoModal?.required_degree?
                <div>
                    <span className='text-[12px]'>Required education degree</span>
                    <div className='border text-[14px] p-[5px_10px] bg-gray-100 rounded-[6px]'>{programInfoModal?.required_degree}</div>
                </div>:null
            }
            {
                programInfoModal?.requireds?.map((data, i)=>{
                    return(
                        <div key={i}>
                            <span className='text-[12px]'>{data?.name}</span>
                            <div className='border text-[14px] p-[5px_10px] bg-gray-100 rounded-[6px]'>{data?.value}</div>
                        </div>
                    )
                })
            }
        </div>
       
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    programInfoModal: state.Data.programInfoModal
});
const mapDispatchToProps = {resetStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(ProgramInfoModal)
