import React, { Component } from 'react'
import { Link } from 'react-router-dom';
export class Card3 extends Component {
  render() {
    const {title, btnText, url, img} = this.props;

    return (
      <Link to={url} className='bg-white p-[20px] rounded-[10px] shadow flex flex-col gap-[10px] items-center'>
        <img src={img} alt="Image" className='w-[221px] h-[221px] rounded-full ml-auto mr-auto' />
        <h3 className='text-[15px] font-semibold mt-[13px] text-center'>{title}</h3>
        <button className='bg-[var(--blue)] mt-[10px] h-[30px] text-[12px]  rounded-full text-white font-semibold pl-[10px] pr-[10px]'>
            {btnText}
        </button>
      </Link>
    )
  }
}

export default Card3
