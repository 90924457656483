import React, { Component } from 'react'

export class BtnRightSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={17}
        fill="none"
      >
        <path
          fill="#fff"
          d="m12.153 16.824 7.66-7.861a.664.664 0 0 0 0-.926L12.153.176a.63.63 0 0 0-.886.016.664.664 0 0 0-.016.91l6.57 6.743H.638a.63.63 0 0 0-.451.192.664.664 0 0 0 0 .926.63.63 0 0 0 .451.192h17.183l-6.57 6.743a.664.664 0 0 0 .016.91.63.63 0 0 0 .886.016Z"
        />
      </svg>
    )
  }
}

export default BtnRightSvg
