import React, { Component } from 'react'

export class CardUniSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={12}
        height={12}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="#1E398F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M5 8v9.02M9 8v9m6-9v9m4-9v9.02m-14 0c.314-.02.702-.02 1.2-.02h11.6c.498 0 .886 0 1.2.02m-14 0c-.392.023-.67.077-.908.198a2 2 0 0 0-.874.874C3 18.52 3 19.08 3 20.2v.8h18v-.8c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.874-.874c-.238-.121-.516-.175-.908-.199M3 5.5V8h18V5.5L12 3 3 5.5Z"
        />
      </svg>
    )
  }
}

export default CardUniSvg
