import React, { Component } from 'react'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { connect } from 'react-redux';
import { changeStateValue } from '../../../redux/MainReducer';
import {validatePhoneNumberLength, isValidPhoneNumber, isPossiblePhoneNumber} from 'libphonenumber-js'
import InpCheckSvg2 from '../../svg/InpCheckSvg2';
import GreenCheckSvg from '../../svg/GreenCheckSvg';
export class InputPhone extends Component {
  state={
    countryCode:'az'
  }
  
   getValidNumber(phone, code){
    const phoneNumber = isValidPhoneNumber(phone, code.toUpperCase());
    this.props.changeStateValue({name:"phoneValidFormat", value:phoneNumber})
    return phoneNumber
   }
  render() {
    const {name="", value="", title="", required=false, lang, requiredSvg=false, bigInp=false, svg, bgWhite=""} = this.props;
    const {countryCode} = this.state;
    // console.log(!this.getValidNumber(value, countryCode), required)
    return (
      <div className={`${bigInp?"big-phone relative":"" + bgWhite} ${requiredSvg && !this.getValidNumber(value, countryCode)?"big-phone-req":""}`}>
          {title !== "" ? <label htmlFor={name} className={bigInp?"text-[#6C6C6C] text-[12px] font-regular inline-flex items-center gap-[5px]":'text-[14px] txt-light-black'}>{svg} {title}</label>:null }
         <PhoneInput
            country={"az"}
            enableSearch={true}
            value={value}
            placeholder='(00) 000 00 00'
            onChange={(phone, country) => {this.props.changeStateValue({name:name, value:phone});this.setState({countryCode: country?.countryCode})}}
            inputStyle={{ width:'100%'}}
            enableLongNumbers={false}
        />

            {requiredSvg?
            <div className='absolute right-[14px] top-[36px]'>
              {
                !this.getValidNumber(value, countryCode)?
                 <InpCheckSvg2 />:
                 <GreenCheckSvg />
              }
             
            </div>:null
          }
        {
          required && !this.getValidNumber(value, countryCode)?
          <span className='text-[11px] text-red-700'>{"Bu xana vacibdir"}</span>:null
        }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  lang: state.Data.lang
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(InputPhone)
