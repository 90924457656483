import React, { Component } from 'react'
import { getEduDegree, getPrograms, getSingleData, insertNewData } from '../../../actions/MainAction';
import { connect } from 'react-redux';
import Header from '../../../components/header/Header';
import Search from '../../../components/Search';
import { withHooks } from '../../../actions/withHooks';
import LoadMoreBtn from '../../../components/LoadMoreBtn';
import Card1 from '../../../components/cards/Card1';
import { confirmAlert, mixinAlert } from '../../../actions/SweetAlerts';
import CheckSvg from '../../../components/svg/CheckSvg';

export class SchPrograms extends Component {
  state={
    page:1,
    search:'',
    degree_id:'',
    selected_programs:[],
    schData:''
  }
    componentDidMount(){
        this.props.getEduDegree();
        this.props.getPrograms(this.state.page, this.state.search,this.props.params.id, this.state.degree_id, "", "", this.props.params.schId, "all")
        this.props.getSingleData(`country-scholarship/${this.props.params.schId}`)
        .then(resp=>{
          this.setState({schData: resp})
        })
    }
    onSearchData=(search) => {
      this.setState({search})
      this.props.getPrograms(this.state.page, search,this.props.params.id, this.state.degree_id, "", "", this.props.params.schId, "all")
    }
    getProgramByDegree=(id)=>{
      this.setState({degree_id:id})
      this.props.getPrograms(this.state.page, this.state.search,this.props.params.id, id, "", "", this.props.params.schId, "all")
    }


    onClickSch=(id, type)=>{
      confirmAlert("warning", "Are you sure?", "You cannot change it", `Yes, ${type} it`)
      .then(respAlert=>{
        this.props.insertNewData(`sch-by-program`, {programs: JSON.stringify(id), type:type, scholarship_id: this.props.params.schId})
        .then(resp=>{
          if(resp == "success"){
            mixinAlert("success", "Data saved successfully");
            this.props.getPrograms(this.state.page, this.state.search,this.props.params.id, this.state.degree_id, "", "", this.props.params.schId, "all")
            this.setState({selected_programs:[]})
          }else{
            mixinAlert("error", "Something went wrong");
          }
        })
      })
    }

    selectProgram=(id)=>{
      // console.log(id)
      let arr = [...this.state.selected_programs];
      const index = arr.findIndex(x=>x == id);
      // console.log(index)
      if(index !=-1){
        arr.splice(index,1)
      }else{
        arr.push(id)
      }
      this.setState({selected_programs: arr})
    }

    loadMore=(page)=>{
      this.setState({page:page})
      this.props.getPrograms(page, this.state.search,this.props.params.id, this.state.degree_id, "", "", this.props.params.schId, "all")
    }
  render() {
    const {educationDegree, programs, } = this.props;
    const {degree_id, selected_programs, schData} = this.state;
    // console.log(programs)
    return (
        <div className='w-full  pl-[27px] pr-[27px]'>
          <Header title={schData?.name+" - Programs"} />
        <div className='flex justify-center mt-[20px]'>
            <Search onSearchData={this.onSearchData.bind(this)} largWidth={true} />
        </div>
        <div className='flex items-center gap-[20px] mt-[20px]'>
          {
            educationDegree.map((data, i)=>{
              return(
                <button onClick={this.getProgramByDegree.bind(this, data?.id)} className={`${degree_id == data?.id? "bg-[var(--blue)] text-white":"border border-[var(--blue)] text-[var(--blue)]"} text-[12px] p-[4px_7px] rounded-full`}>
                  {data?.name}
                </button>
              )
            })
          }
            <button onClick={this.getProgramByDegree.bind(this, "")} className={`border border-[var(--blue)] text-[var(--blue)] text-[12px] p-[4px_7px] rounded-full`}>
                Reset
            </button>
        </div>
        {
          selected_programs?.length !=0?
          <div className='flex items-center gap-[20px] mt-[20px]'>
              <button onClick={this.onClickSch.bind(this, selected_programs, "add")} className='text-[12px] bg-blue text-white p-[2px] rounded-[5px]'>Add Scholarship</button>  
              <button onClick={this.onClickSch.bind(this, selected_programs, "remove")} className='text-[12px] bg-blue text-white p-[2px] rounded-[5px]'>Remove Scholarship</button>  
          </div>:null
        }
        <div className='grid-full-250 mt-[30px]'>
              {
                programs?.map((data, i)=>{
                  return(
                   <div className='bg-white rounded-[10px]'  key={i}>
                    <div className='flex items-center ml-[10px] mr-[10px] mt-[20px] mb-[-20px] justify-between'>
                      <input onChange={this.selectProgram.bind(this, data?.id)} checked={selected_programs.includes(data?.id)} className='cursor-pointer' type='checkbox' />
                      {
                        data?.country_scholarship?.length !=0?
                        <CheckSvg />:null
                      }
                      
                    </div>
                     <Card1
                    
                      image={data?.image_full_url}
                      nameFull={data?.name}
                      country={data?.university?.name}
                      addSchBtn={true}
                      onClickSch={this.onClickSch.bind(this, [data?.id])}
                    />
                   </div>
                  )
                })
              }
            </div>
            {
                programs?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={programs?.length} />:null
            }
          
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    educationDegree: state.Data.educationDegree,
    programs: state.Data.programs,
});
const mapDispatchToProps = {getEduDegree, getPrograms, insertNewData, getSingleData}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(SchPrograms))
