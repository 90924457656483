import React, { Component } from 'react'
import { changeStateValue } from '../../redux/MainReducer';
import { getUniByScholarhip } from '../../actions/PublicActions';
import { connect } from 'react-redux';
import { withHooks } from '../../actions/withHooks';
import { getSingleData } from '../../actions/MainAction';
import SinglePageHeader from '../../components/public_components/SinglePageHeader';
import LoadMoreBtn from '../../components/public_components/LoadMoreBtn';
import Card5 from '../../components/public_components/cards/Card5';
import { getPathConvert, getStartFee } from '../../actions/getOtherFunctions';
import Footer from './sections/Footer';
import { Helmet } from 'react-helmet';

export class SingleSchCountryPage extends Component {
    state={
        countryData:''
    }
    componentDidMount(){
        this.props.getUniByScholarhip(1,this.props.params.id);
        this.props.getSingleData(`public-country/${this.props.params.id}?type=name`)
        .then(resp=>{
            if(resp == ""){this.props.navigate("/*")}
          this.setState({
            countryData: resp
          })
        })
        window.scrollTo(0,0)
    }
    loadMore=(page)=>{
        this.props.getUniByScholarhip(page,this.props.params.id);
    }
  render() {
    const {uniBySch} = this.props;
    const {countryData} = this.state;
    // console.log(uniBySch)
    return (
        <div className='mt-[80px] bg-[#F5F5F5]'>
             {
                countryData !=""?
                <Helmet>
                    <title>{`${countryData?.seo_title}`}</title>
                    <meta property="og:locale" content="az_AZ" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={`${countryData?.seo_title}`} />
                    <meta property="og:description" content={countryData?.seo_description} />
                    <meta name="description" content={countryData?.seo_description}/>
                    <meta name="keywords" content={countryData?.seo_keywords}/>
                    <meta property="og:url" content={`https://celtgo.com${window.location.pathname}`} />
                    <meta property="og:site_name" content="CELT Colleges" />
                    <meta property="article:publisher" content="https://www.facebook.com/celtscollege" />
                    <meta property="article:modified_time" content="2024-06-06T08:56:25+00:00" />
                    <meta property="og:image" content={`${countryData?.image_full_url}`} />
                    <meta property="og:image:width" content="900" />
                    <meta property="og:image:height" content="600" />
                    <meta property="og:image:type" content="image/jpeg" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content={`${countryData?.seo_title}`} />
                    <meta name="twitter:description" content={countryData?.seo_description} />
                    <meta name="twitter:site" content="@celtcolleges" />
                    <meta name="twitter:label1" content="Est. reading time" />
                    <meta name="twitter:data1" content="7 minutes" />
                </Helmet>:null
                }
             <SinglePageHeader Header name={countryData?.name} image={countryData?.image_full_url} />
             <section className='mb-[50px]'>
                <div className='max-w-[1200px] ml-auto mr-auto max-[1240px]:ml-[20px] max-[1240px]:mr-[20px] mt-[50px]'>
                    <div className='grid-full-380'>
                    {
                        uniBySch?.map((data, i)=>{
                        return(
                            <Card5 key={i} 
                            image={data?.image_full_url}
                            name={data?.name}
                            country={data?.country?.name}
                            city={data?.city?.name}
                            data={data} 
                            path={`/scholarships/${getPathConvert(data?.name)}`} 
                            ranking={data?.local_ranking >600? '': data?.local_ranking}
                            view={true}
                            footer={false}
                            start_fee={getStartFee(data?.phd_start, data?.master_start, data?.pre_master_star, data?.bachelour_start)}
                            registerType="scholarship"
                            />
                        )
                        })
                    }
                    </div>
                    {
                        uniBySch?.length >=20?
                        <LoadMoreBtn onLoadData={this.loadMore} count={uniBySch?.length} />:null
                    }
                </div>
            </section>
            <Footer />
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    uniBySch: state.Data.uniBySch
});
const mapDispatchToProps = {changeStateValue, getUniByScholarhip, getSingleData}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(SingleSchCountryPage))
