import React, { Component } from 'react'

export class MsgAttachSvg extends Component {
  render() {
    return (
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
    >
      <path
        fill="#27272A"
        d="M12.68 7.913c.04.049.072.1.096.154a.438.438 0 0 1 .037.183c0 .068-.013.13-.037.183a.73.73 0 0 1-.095.154l-4.82 4.805a3.267 3.267 0 0 1-2.314.952c-.45 0-.874-.083-1.275-.25-.4-.175-.75-.412-1.047-.71a3.196 3.196 0 0 1-.696-1.047 3.07 3.07 0 0 1-.263-1.274 3.212 3.212 0 0 1 .966-2.315l5.816-5.903a2.19 2.19 0 0 1 .74-.506c.288-.122.593-.183.915-.183.645 0 1.196.23 1.655.689.46.459.689 1.01.689 1.655 0 .322-.061.625-.183.908-.122.283-.29.532-.506.747L6.543 12.06a1.413 1.413 0 0 1-.996.41c-.39 0-.723-.137-.996-.41a1.356 1.356 0 0 1-.41-.996 1.412 1.412 0 0 1 .41-.996l4.878-4.952a.618.618 0 0 1 .16-.117.45.45 0 0 1 .52.095.45.45 0 0 1 .096.52.557.557 0 0 1-.103.147l-4.877 4.966a.438.438 0 0 0-.146.337.45.45 0 0 0 .138.33.45.45 0 0 0 .33.138c.068 0 .13-.012.183-.036a.727.727 0 0 0 .154-.096l5.815-5.903a1.41 1.41 0 0 0 .41-.996c0-.39-.136-.723-.41-.996a1.356 1.356 0 0 0-.996-.41 1.411 1.411 0 0 0-.996.41L3.892 9.407a2.19 2.19 0 0 0-.506.74 2.322 2.322 0 0 0-.183.915c0 .645.23 1.197.689 1.656.459.459 1.01.688 1.655.688.322 0 .625-.06.908-.183.283-.122.532-.29.747-.505l4.805-4.805a.73.73 0 0 1 .154-.095.439.439 0 0 1 .183-.037c.068 0 .13.012.183.037a.73.73 0 0 1 .154.095Z"
      />
    </svg>
    )
  }
}

export default MsgAttachSvg
