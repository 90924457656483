import React, { Component } from 'react'

export class TextSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="none"
      >
        <path
          stroke="#6C6C6C"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeOpacity={0.8}
          strokeWidth={1.5}
          d="M9.335 1.333H4a1.333 1.333 0 0 0-1.333 1.334v10.666a1.333 1.333 0 0 0 1.333 1.334h8a1.333 1.333 0 0 0 1.334-1.334v-8l-4-4Z"
        />
        <path
          stroke="#6C6C6C"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeOpacity={0.8}
          strokeWidth={1.5}
          d="M9.332 1.333v4h4M10.665 8.667H5.332M10.665 11.333H5.332M6.665 6H5.332"
        />
      </svg>
    )
  }
}

export default TextSvg
