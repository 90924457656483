import React, { Component } from 'react'

export class MsgSendSvg extends Component {
  render() {
    return (
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width={14}
      height={15}
      fill="none"
    >
      <path
        fill="#fff"
        d="M13.315 1.185a.794.794 0 0 0-.292-.206.943.943 0 0 0-.367-.073 1.26 1.26 0 0 0-.139.008.366.366 0 0 0-.124.036l.014-.014h-.014L1.143 4.349a.934.934 0 0 0-.484.337.915.915 0 0 0-.19.57.956.956 0 0 0 .527.85h.015L6.02 8.48l2.373 5.01a.934.934 0 0 0 .85.542h.072c.205-.02.381-.09.528-.212a.876.876 0 0 0 .293-.462l3.413-11.25v-.014l.03-.125a.573.573 0 0 0 .014-.124.944.944 0 0 0-.073-.366.793.793 0 0 0-.206-.293ZM9.243 13.079v.015l-2.3-4.864 2.769-2.768a.489.489 0 0 0 .117-.322.456.456 0 0 0-.132-.337.456.456 0 0 0-.337-.132.489.489 0 0 0-.322.117L6.27 7.557l-4.85-2.3 11.236-3.413-3.413 11.235Z"
      />
    </svg>
    )
  }
}

export default MsgSendSvg
