import React, { Component } from 'react'
import ExclamationSvg from '../svg/ExclamationSvg';
import InpCheckSvg from '../svg/InpCheckSvg';
import { changeStateValue } from '../../redux/MainReducer';
import { connect } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import {isValidPhoneNumber} from 'libphonenumber-js'
export class ContactInput extends Component {
  state={
    countryCode:'az'
  }
    getInpValue=(e)=>{
        this.props.changeStateValue({
            name:e.target.name,
            value: e.target.value
        })
    }
    getValidNumber(phone, code){
      const phoneNumber = isValidPhoneNumber(phone, code.toUpperCase());
      this.props.changeStateValue({name:"phoneValidFormat", value:phoneNumber})
      return phoneNumber
     }
  render() {
    const {title, value="", name, svg, type="text"} = this.props;
    const {countryCode}  = this.state;
    // console.log(value !="", value)
    return (
      <div>
        <label className='text-[18px] text-neutral-800'>{title}</label>
        <div className='flex justify-between border-b gap-[10px] pb-[10px] contact_tel'>
          {
            type === "tel"?
            <PhoneInput
              country={"az"}
              enableSearch={true}
              value={value}
              placeholder='(00) 000 00 00'
              onChange={(phone, country) => {this.props.changeStateValue({name:name, value:phone});this.setState({countryCode: country?.countryCode})}}
              inputStyle={{ width:'100%'}}
              enableLongNumbers={false}
            />:
            <input onChange={this.getInpValue.bind(this)} name={name} value={value} className='w-full outline-none text-[#848D8C]' placeholder={title} type={type} />
          }
            
            <div className='flex gap-[5px]'>
                {type === "tel"?
                  !this.getValidNumber(value, countryCode)?
                  <ExclamationSvg />: <InpCheckSvg /> 
                  :
                 value == ""?<ExclamationSvg />: <InpCheckSvg />  }
                
                {svg}
            </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(ContactInput)
