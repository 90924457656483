import React, { Component } from 'react'

export class NavApply2Svg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
      >
        <g data-name="Layer 2">
          <path
            d="M16 .5v5a.5.5 0 0 1-1 0V1h-4.5a.5.5 0 0 1-.5-.5.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5ZM5.5 15H1v-4.5a.5.5 0 0 0-.5-.5.5.5 0 0 0-.5.5v5a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 0-1Zm7-7h-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5ZM12 4H9v3h3ZM8 8.5v4a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5ZM7 9H4v3h3Zm8.5 1a.5.5 0 0 0-.5.5v3.79l-4.15-4.14a.49.49 0 0 0-.7.7L14.29 15H10.5a.5.5 0 0 0 0 1h5a.41.41 0 0 0 .19 0 .51.51 0 0 0 .27-.27.41.41 0 0 0 0-.19v-5a.5.5 0 0 0-.46-.54ZM0 5.5a.5.5 0 0 0 .5.5h5a.41.41 0 0 0 .19 0A.51.51 0 0 0 6 5.69a.41.41 0 0 0 0-.19v-5a.5.5 0 0 0-1 0v3.79L.85.15a.48.48 0 0 0-.7 0 .48.48 0 0 0 0 .7L4.29 5H.5a.5.5 0 0 0-.5.5Z"
            data-name="Layer 1"
          />
        </g>
      </svg>
    )
  }
}

export default NavApply2Svg
