import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { ranks } from '../../actions/rank'
import { insertNewData } from '../../actions/MainAction'
import { connect } from 'react-redux'
export class AddRankingAuto extends Component {

    getRankFormat(data){
        // console.log(data)
        if(String(data)?.includes('-')){
            return String(data)?.split("-")[0]
        }else if(String(data)?.includes('+')){
            return String(data)?.replaceAll("+", "")
        }else{
            return String(data)
        }
    }
    addProgram=(rank)=>{
        // console.log(rank)
        let finals = [];
        // for(const x of rank){
        //     finals.push({
        //         university:x?.Column3,
        //         ranking: this.getRankFormat(x?.Column2)
        //     })
        // }
        // console.log(finals)
        this.props.insertNewData("add-top-uni", {params: JSON.stringify(rank)})
        .then(resp=>{
            // console.log(resp)
        })
    }
  render() {
    // console.log(ranks)
    return (
        <div className='w-full  pl-[27px] pr-[27px]'>
            <Header title="Add ranking" />
            <div className='flex gap-[20px] white-search items-end'>
               
                <button onClick={this.addProgram.bind(this, ranks)}>Add</button>
            </div>
    </div>
    )
  }
}
const mapStateToProps = (state) =>({});
const mapDispatchToProps = {insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(AddRankingAuto)
