import React, { Component } from 'react'

export class BtnRightSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={17}
        height={17}
        viewBox="0 0 24 24"
        fill="#fff"
      >
        <path d="M16.707 18.707a1 1 0 0 1-1.414-1.414L19.586 13H2a1 1 0 0 1 0-2h17.586l-4.293-4.293a1 1 0 0 1 1.414-1.414l6 6a1 1 0 0 1 0 1.414Z" />
      </svg>
    )
  }
}

export default BtnRightSvg
