import React, { Component } from 'react'
import Header from './components/Header'
import Body from './components/Body'
import Footer from './components/Footer'
import ReplyContainer from './components/ReplyContainer'
import { getColor } from '../../actions/getOtherFunctions'
import { connect } from 'react-redux'
import MsgFilePreview from './components/MsgFilePreview'
import MsgRecordAudio from './components/MsgRecordAudio'

export class MessageBox extends Component {
  state={
    replayData:"",
    recordAudio:false
  }
  onReplyBtn=(data)=>{
    this.setState({replayData:data})
  }
  render() {
    const {messagesParams, getAfterChange=()=>{}, user, messageFileSend, student_id, height="h-[75vh]"} = this.props;
    const {replayData, recordAudio} = this.state;
    return (
      <div className={`bg-white rounded-[12px]  ${height} `}>
        <Header messagesParams={messagesParams} />
        <Body h1={height =="h-[95vh]"?"h-[calc(96vh_-_180px)]":undefined} h2={height =="h-[95vh]"?"h-[calc(75vh_-_240px)]":undefined} getAfterChange={()=>getAfterChange()} replayData={replayData} onReply={this.onReplyBtn.bind(this)} messages={messagesParams?.messages} />
        {
          replayData !==""?
          <ReplyContainer color={getColor(replayData?.user?.role)} name={replayData?.user?.id === user?.id? "You":replayData?.user?.full_name} msg={replayData?.text} onClose={()=>this.setState({replayData:''})} />:null
        }
        {!recordAudio? <Footer onClickRecord={()=>this.setState({recordAudio:true})} resetReply={()=>this.setState({replayData:''})} replayData={replayData} getAfterChange={()=>getAfterChange()} messagesParams={messagesParams} />:null}
        {recordAudio? <MsgRecordAudio reply={replayData} getAfterChange={()=>getAfterChange()} onClickRecord={()=>this.setState({recordAudio:false, replayData:''})} group_id={messagesParams?.id} /> :null}
        
        {messageFileSend?.position?<MsgFilePreview group_id={messagesParams?.id} getAfterChange={()=>getAfterChange()} onClose={()=>{this.setState({replayData:''})}} />:null }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  user: state.Data.user,
  messageFileSend: state.Data.messageFileSend,
});
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(MessageBox)
