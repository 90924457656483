import React, { Component } from 'react'

export class DiplomaSvg extends Component {
  render() {
    return (
        <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    fill="none"
  >
    <path
      fill="#6C6C6C"
      d="M13.947 9.997a.393.393 0 0 0-.155-.265c-3.47-2.588-6.942-6.06-9.53-9.53a.394.394 0 0 0-.264-.154C2.524-.128 1.418.178.735.95.121 1.644-.11 2.67.05 4a.394.394 0 0 0 .154.265 47.061 47.061 0 0 1 4.575 3.921c-.003.074-.008.152-.014.232-.645.06-1.358.272-1.733.862a.39.39 0 0 0 .121.537c.243.16.463-.002.537-.12.182-.29.585-.425 1.006-.483-.063.554-.157 1.088-.284 1.34a.386.386 0 0 0 .171.523c.218.099.422.016.524-.171.207-.383.311-1.1.375-1.72.121.006.231.017.322.025a47.214 47.214 0 0 1 3.93 4.58c.063.086.16.14.264.154.27.034 2.034.328 3.263-.898.656-.656.846-1.72.687-3.05Zm-8.962-2.69a47.668 47.668 0 0 0-4.18-3.566c-.097-1.017.074-1.783.514-2.279C1.8.92 2.62.696 3.745.804a47.552 47.552 0 0 0 3.488 4.1c-.609.446-1.777 1.392-2.248 2.403Zm.893.874a13.92 13.92 0 0 0-.287-.284c.223-.863 1.477-1.918 2.185-2.428.243.248.488.493.733.736-.733.363-2.05 1.102-2.631 1.976Zm6.798 4.35c-.48.544-1.3.767-2.425.66A47.777 47.777 0 0 0 6.44 8.75c.33-.7 1.697-1.524 2.665-1.973a47.567 47.567 0 0 0 4.084 3.472c.099 1.02-.075 1.786-.513 2.282Z"
    />
  </svg>
    )
  }
}

export default DiplomaSvg
