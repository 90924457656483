import React, { Component } from 'react'
import img from "../images/dash_img.png";
export class DashAdvImg extends Component {
  render() {
    return (
      <div>
        <img className='w-full h-[167px] rounded-[10px]' src={img} />
      </div>
    )
  }
}

export default DashAdvImg
