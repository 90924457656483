import React, { Component } from 'react'

export class CountrySvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        viewBox="0 0 32 32"
        width={18}
        height={18}
        fill="#585874"
      >
        <path d="M16 31.36C7.53 31.36.64 24.47.64 16S7.53.64 16 .64 31.36 7.53 31.36 16 24.47 31.36 16 31.36zM2.308 21.185C4.406 26.706 9.752 30.64 16 30.64c8.072 0 14.64-6.567 14.64-14.64 0-.901-.081-1.783-.238-2.64H27.15l-1.584 1.584 2.634 1.757c.136.09.193.259.143.414l-1 3a.359.359 0 0 1-.475.22l-5-2a.352.352 0 0 1-.2-.2l-1.854-4.637-1.569.784-3.396 4.852-.489 3.912a.36.36 0 0 1-.196.277l-2 1a.365.365 0 0 1-.316.003.364.364 0 0 1-.197-.247l-1.971-8.87-3.8-2.849H1.818c-.116.453-.211.915-.284 1.383l3.636 1.939a.36.36 0 0 1 .139.503l-3.001 5zm-.285-9.545H6c.078 0 .154.025.216.072l4 3a.363.363 0 0 1 .136.21l1.9 8.55 1.415-.707.476-3.81a.361.361 0 0 1 .062-.161l3.5-5a.347.347 0 0 1 .134-.115l2-1a.36.36 0 0 1 .495.188l1.943 4.856 4.502 1.802.792-2.377L24.8 15.3a.362.362 0 0 1-.055-.554l2-2a.357.357 0 0 1 .255-.105h3.251C28.729 6.182 22.917 1.36 16 1.36c-6.555 0-12.117 4.33-13.977 10.28zm-.587 2.867a14.592 14.592 0 0 0 .567 5.789l2.494-4.156-3.061-1.633zM17 26.36h-2a.36.36 0 0 1-.36-.36v-2a.36.36 0 0 1 .36-.36h1a.36.36 0 0 1 .322.199l1 2a.36.36 0 0 1-.322.521zm-1.64-.72h1.058l-.64-1.279h-.418v1.279z" />
        <path
          d="M0 0h32v32H0z"
          style={{
            fill: "none",
          }}
        />
      </svg>
    )
  }
}

export default CountrySvg
