import React, { Component } from 'react'

export class CardOClockSvg extends Component {
  render() {
    const {color = "#969696"} = this.props;
    return (
        <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={10}
    fill="none"
  >
    <path
      fill={color}
      d="M5 0a5 5 0 1 1 0 10A5 5 0 0 1 5 0Zm0 .698a4.303 4.303 0 1 0 0 8.605A4.303 4.303 0 0 0 5 .697ZM4.535 2.79c.192 0 .348.156.348.349v2.442h2.442a.349.349 0 0 1 0 .697h-2.79a.349.349 0 0 1-.349-.349V3.14a.35.35 0 0 1 .349-.35Z"
    />
  </svg>
    )
  }
}

export default CardOClockSvg
