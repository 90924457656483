import React, { Component } from 'react'

export class UploadSvg2 extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={15}
        height={15}
        fill="none"
      >
        <path
          stroke="#1F2421"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M13.125 9.375v2.5a1.25 1.25 0 0 1-1.25 1.25h-8.75a1.25 1.25 0 0 1-1.25-1.25v-2.5M10.625 5 7.5 1.875 4.375 5M7.5 1.875v7.5"
        />
      </svg>
    )
  }
}

export default UploadSvg2
