import React, { Component } from 'react'
import Header from './components/Header'
import { connect } from 'react-redux';
import { changeStateValue, resetStateValue } from '../../redux/MainReducer';
import Input from './components/Input';
import { getExams, getStudentComplete, insertNewData } from '../../actions/MainAction';
import {mixinAlert} from "../../actions/SweetAlerts";
import ExamInput from './components/ExamInput';
export class StudentExamsModal extends Component {
    closeModal=()=>{
        this.props.resetStateValue({name:'studentExamsModal'})
    }
    componentDidMount(){
        this.props.getExams()
    }

    saveDataBtn=(data)=>{
        if(data?.ielts !=="" || data?.gmat!=="" || data?.gre !=="" || data?.sat !=="" || data?.dim !=="" || data?.toefl !==""  || data?.ielts_ukvi !==""){
            let formData = new FormData();
            for(const property in data){
                formData.append(property, data[property]);
            }
            this.props.insertNewData('student-exams', formData)
            .then(resp=>{
               if(resp == "success"){
                mixinAlert("success", "İmtahan uğurla yadda saxlanıldı");
                data.afterCallFunction(...data?.afterCallParams);
                this.closeModal()
                this.props.getStudentComplete()
               }else{
                mixinAlert("error", "Xəta baş verdi")
               }
            })
        }else{
            mixinAlert("warning", "Ən azı 1 imtahan seçməlisiniz");
        }
    }
  render() {
    const {studentExamsModal, exams} = this.props;
    // console.log(studentExamsModal)
    return (
        <div className='background_shadow flex justify-center items-center'>
            <div className='bg-white w-[700px] max-h-[70vh] rounded-[10px] shadow max-[750px]:ml-[20px] max-[750px]:mr-[20px]'>
                <Header title={studentExamsModal?.modalTitle} closeBtn={this.closeModal.bind(this)} />
                <div style={{ height:'auto', maxHeight: 'calc(70vh - 50px)' }} className='p-[20px] max-[500px]:overflow-auto max-[500px]:max-h-[60vh_!important]'>
                    <div className='grid grid-cols-3 gap-[20px] items-start max-[750px]:grid-cols-2 max-[400px]:grid-cols-1'>
                        {
                            exams?.map((exam, j)=>{
                                return(
                                    <ExamInput key={j} 
                                         bigInp  bg="bg-[#F3F4F6]"
                                        minValue={exam?.min_value} 
                                        maxValue={exam?.max_value} 
                                        step={exam?.step}
                                        name={`studentExamsModal.${exam?.name?.toLowerCase()?.replaceAll(" ", "_")}`}  
                                        value={studentExamsModal[`${exam?.name?.toLowerCase()?.replaceAll(" ", "_")}`]} 
                                        image_name={`studentExamsModal.${exam?.name?.toLowerCase()?.replaceAll(" ", "_")}_file`}
                                        image_value={studentExamsModal[`${exam?.name?.toLowerCase()?.replaceAll(" ", "_")}_full_url`]}
                                        image_value_name={`studentExamsModal.${exam?.name?.toLowerCase()?.replaceAll(" ", "_")}_full_url`}
                                        title={exam?.name}  />
                                )
                            })
                        }
                    </div>
                    <div className='flex justify-end mt-[20px]'>
                      <button onClick={this.saveDataBtn.bind(this, studentExamsModal)} className='h-[48px] w-[142px] p-[0_10px] bg-blue text-white rounded-[5px] font-regular text-[20px] max-[400px]:text-[16px] max-[400px]:h-[30px] max-[400px]:pl-[5px] max-[400px]:pr-[5px] max-[310px]:text-[14px]'>Yadda saxla</button>
                    </div>
                </div>
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    studentExamsModal: state.Data.studentExamsModal,
    exams: state.Data.exams,
});
const mapDispatchToProps = {changeStateValue, resetStateValue, getExams,getStudentComplete, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(StudentExamsModal)
