import React, { Component } from 'react'

export class RecUniSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={20}
        fill="none"
      >
        <path
          fill="#000"
          d="M.111 4.944v3.334H17.89V4.944L9 .5.111 4.944ZM1.222 9.389h3.334v5.555H1.222V9.39ZM7.334 14.944V9.39h3.333v5.555H7.334ZM16.778 9.389h-3.333v5.555h3.333V9.39ZM.111 19.389v-3.333H17.89v3.333H.111Z"
        />
      </svg>
    )
  }
}

export default RecUniSvg
