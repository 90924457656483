import React, { Component } from 'react'

export class ExamCardSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        width={44}
        height={40}
        viewBox="0 0 219 256"
        fill="#B55E5E"
      >
        <path d="M74.497 26.841C74.497 13.122 85.619 2 99.338 2s24.841 11.122 24.841 24.841-11.122 24.841-24.841 24.841S74.497 40.56 74.497 26.841zm119.766 89.2 11.666-60.649-11.088-2.133-10.773 56.007h-28.586c.112-.662.186-1.336.19-2.029.032-6.86-5.503-12.446-12.363-12.478l-49.004-.225-13.399-35.915c-2.479-9.253-8.64-15.714-19.406-15.714-11.473 0-19.169 10.43-23.135 21.196-7.904 21.453-13.549 57.585-11.26 81.05 1.239 10.961 4.18 19.949 11.597 23.958H18.041V93.835H2.234v159.959h15.808v-68.876h74.535l-10.394 51.496c-1.604 7.947 3.538 15.689 11.484 17.293.98.197 1.957.293 2.92.293 6.843 0 12.967-4.811 14.373-11.777l9.152-45.342 12.66 33.953c2.202 5.906 7.802 9.555 13.756 9.555 1.704 0 3.436-.299 5.126-.93 7.596-2.832 11.458-11.286 8.625-18.882l-25.734-69.015a14.677 14.677 0 0 0-13.753-9.55h-38.45l-.018-9.033h118.634v120.816h15.808V116.041h-22.503z" />
      </svg>
    )
  }
}

export default ExamCardSvg
