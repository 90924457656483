import React, { Component } from 'react'
import { getPathConvert, getZeroPad } from '../../../actions/getOtherFunctions';
import { Link } from 'react-router-dom';

export class Card10 extends Component {
  render() {
    const {data} = this.props;
    return (
      <Link to={`/subjects/${getPathConvert(data?.title)}`}  className='flex flex-col items-center'>
        <img className='w-[221px] h-[221px] rounded-full 'src={data?.image_full_url} alt={data?.title} />
        <h3 className='text-[20px] font-regular text-center mt-[25px]'>{data?.title}</h3>
        <p className='text-[rgba(0,0,0,0.5)] text-[16px] font-regular text-center'>{data?.universities_count} Universitet</p>
        {/* <div className='bg-white relative pl-[22px] pr-[22px] pt-[20px] pb-[18px] rounded-b-[15px]'>
            <div className='w-[57px] h-[57px] shadow bg-white rounded-full absolute flex items-center justify-center flex-col right-[5px] top-[-25px] gap-[0px]'>
                <span className='text-[18px] font-semibold text-[#1E398F]'>{getZeroPad(data?.universities_count)}</span>
                <span className='text-[10px] font-regular text-[#1E398F]'>Prog</span>
            </div>
            <h3 className='text-[22px] font-semibold '>{data?.title}</h3>
        </div> */}
      </Link>
    )
  }
}

export default Card10


