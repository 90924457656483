import React, { Component } from 'react'
import logo from "../../../images/logo/celtLogo6.png"
import app_store from "../../../images/app_store_1.png"
import play_store from "../../../images/Google-play-logo.jpg"
import { Link } from 'react-router-dom'
export class Footer extends Component {
  render() {
    return (
        <footer className='bg-white pt-[32px] flex flex-col justify-between'>
            <div className='max-w-[1200px] ml-auto mr-auto max-[1240px]:ml-[20px] max-[1240px]:mr-[20px]'>
                <div className='flex flex-col items-center'>
                    <img src={logo} className='w-[210px] ' alt="Logo" />
                    <nav className='mt-[23px]'>
                        <ul className='flex items-center justify-center gap-[50px] max-[600px]:flex-col max-[600px]:gap-[10px]'>
                            <li>
                                <Link className='text-[14px] font-regular' to='/countries'>Ölkələr</Link>
                            </li>
                            <li>
                                <Link className='text-[14px] font-regular' to='/subjects'>İxtisas sahələri</Link>
                            </li>
                            <li>
                                <Link className='text-[14px] font-regular' to='/accepted-students'>Qəbul olmuş tələbələr</Link>
                            </li>

                            
                        </ul>
                    </nav>
                    {/* <div className='flex items-center justify-center gap-[9px] mt-[39px]'>
                        <a href='#'>
                            <img className='w-[160px]' src={app_store} alt={'App Store'} />
                        </a>
                        <a href='#'>
                            <img  className='w-[160px]' src={play_store} alt={'Play Store'} />
                        </a>
                    </div> */}
                </div>
            </div>
            <div className='flex justify-center h-[35px] bg-gray-100 items-center mt-[67px]'>
                <p className='text-[12px]'>© 2024 All Rights Reserved</p>
            </div>
        </footer>
    )
  }
}

export default Footer
