import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { connect } from 'react-redux'
import { withHooks } from '../../actions/withHooks'
import { getSingleData } from '../../actions/MainAction'
import AddProgramBody from '../../components/programs/AddProgramBody';
import { getEduDegree, getLanguages, getDisciplines, insertNewData, getExams, getHighSchoolExams, getPrograms } from '../../actions/MainAction'
import { getDropValue, getProgramsData } from '../../actions/getOtherFunctions';
import { changeStateValue, resetStateValue } from '../../redux/MainReducer';
import { mixinAlert } from '../../actions/SweetAlerts'
import DropSearch from '../../components/modals/components/DropSearch'
export class AdminAddProgramsAll extends Component {
    state={
        universityData:'',
        countNumber:1,
        programDefaults:{
            ...getProgramsData,
            university_id:this.props.params?.id
        },
        type:"add"
    }
    componentDidMount(){
        this.props.getSingleData(`university/${this.props.params?.id}`)
        .then(resp=>{
            this.setState({universityData:resp})
        });
        this.props.getEduDegree();
        this.props.getLanguages();
        this.props.getDisciplines(1, '', 'all');
        this.props.getExams(1, '');
        this.props.getHighSchoolExams(1, '')
        this.props.resetStateValue({name:"allProgramsAdd"})
        this.setState({
            universityData:'',
            countNumber:1,
            programDefaults:{
                ...getProgramsData,
                university_id:this.props.params?.id
            },
            type:"add"
        })
    }

    addProgramToData=(count)=>{
        if(count !=""){
            let programs = [...this.props.allProgramsAdd]
            for(let i = 0; i <count; i++){
                programs.push({
                    ...getProgramsData,
                    university_id:this.props.params?.id,
                    checked:true
                })
            }
            this.props.changeStateValue({
                name:'allProgramsAdd',
                value: programs
            })
        }
    }
    onChangeParent=(name, value, index)=>{
        this.props.changeStateValue({
            name:`allProgramsAdd.${index}.${name}`,
            value:value
        })
    }
    onChangeAll=(name, value, allProgramsAdd)=>{
        // console.log(name, value, allProgramsAdd)
        let programDefaults = {...this.state.programDefaults}
        if(name.includes('.')){
            if([name.split('.')[1]] != "id"){
                programDefaults= {
                    ...this.state.programDefaults,
                    [name.split('.')[0]]:{
                        ...this.state.programDefaults[name.split('.')[0]],
                        [name.split('.')[1]]:value
                    } 
                }
            }
           
        }else{
            programDefaults= {
                ...this.state.programDefaults,
                [name]: value
            }
        }
        this.setState({
            programDefaults:programDefaults
        });

        let updatedPrograms = [];
        for(let i = 0; i < allProgramsAdd.length; i++){
            let x = {...allProgramsAdd[i]};
                if(allProgramsAdd[i].checked){
                    if(name.includes('.')){
                        if([name.split('.')[1]] != "id"){
                            x= {
                                ...allProgramsAdd[i],
                                [name.split('.')[0]]:{
                                    ...allProgramsAdd[i][name.split('.')[0]],
                                    [name.split('.')[1]]:value
                                } 
                            }
                        }
                       
                    }else{
                        x= {
                            ...allProgramsAdd[i],
                            [name]: value
                        }
                    }
                }
                updatedPrograms.push({
                    ...x,
                    id: allProgramsAdd[i].id,
                    name: allProgramsAdd[i]?.name,
                    university_id: allProgramsAdd[i]?.university_id,
                })
        }
        this.props.changeStateValue({
            name:'allProgramsAdd',
            value: updatedPrograms
        })
    }

    getDisciplineFormat(disciplines){
        let arr = [];
        for(const x of disciplines){
            arr.push(x?.value)
        }
        return arr.join(',')
    }
    addAllPrograms=(allProgramsAdd)=>{
        let programs = [];
        for(const data of allProgramsAdd){
            if(data?.checked){
                programs.push({
                    ...data,
                    disciplines: this.getDisciplineFormat(data?.disciplines)
                })
            }
        }
       
        let formData = new FormData();
        formData.append("programs", JSON.stringify(programs))
        this.props.insertNewData('add-all-programs', formData)
        .then(resp=>{
            if(resp=="success"){
        window.location.reload()
             
                mixinAlert("success", "Programs added successfully");
                this.props.changeStateValue({name:'allProgramsAdd', value:[]});
                this.setState({
                    programDefaults:{
                        ...getProgramsData,
                        university_id:this.props.params?.id
                    }
                })
            }else{
                mixinAlert("error", "Something went wrong.");
            }
        })
    }

    changePage=(type)=>{
        this.setState({type});
        if(type === "edit"){

        }
    }
    getDiscipline(arr){
        let x = [];
        arr.forEach(y=>{
            x.push({
                label: y.discipline?.name,
                value: y.discipline_id
            })
        });
        return x;
    }
    onChangeDegree=(id)=>{
        // console.log(id)
        this.props.changeStateValue({
            name:'allProgramsAdd',
            value:[]
        })
        this.props.getPrograms(20, '', this.props.params.id, id)
        .then(resp=>{
            let progs = [];
            for(const x of resp){
                progs.push({
                    ...x,
                    disciplines: this.getDiscipline(x.disciplines),
                    checked:true
                })
            }
            this.props.changeStateValue({
                name:'allProgramsAdd',
                value:progs
            });
            if(progs[0]){
              this.setState({
                programDefaults: progs[0]
            })  
            }
            
            // console.log(progs)
        })
    }
    selectAll=(value, allProgramsAdd)=>{
        let arr = [];
        for(const x of allProgramsAdd){
            arr.push({
                ...x,
                checked:value
            })
        }
        this.props.changeStateValue({
            name:'allProgramsAdd',
            value:arr
        })
    }
    // programs
  render() {
    const {universityData, countNumber, programDefaults, type} = this.state;
    const {allProgramsAdd, educationDegree} = this.props;
    // console.log(allProgramsAdd)
    return (    
        <div className='w-full  pl-[27px] pr-[27px]'>
            <Header title={universityData?.name} />
            <div className='flex items-center gap-[30px]'>
                <button onClick={this.changePage.bind(this, "add")} className={`${type==="add"? "bg-blue text-white":"bg-white border border-[var(--blue)] txt-blue"}  text-[12px] font-regular p-[2px_4px] rounded-[4px]`}>Add new</button>
                <button onClick={this.changePage.bind(this, "edit")} className={`${type==="edit"? "bg-blue text-white":"bg-white border border-[var(--blue)] txt-blue"}  text-white text-[12px] font-regular p-[2px_4px] rounded-[4px]`}>Edit</button>
            </div>
            {
                type === "edit"?
                <div className='white-search flex mt-[20px] flex-col'>
                    <DropSearch name=""  onChangeParent={(name, value)=>this.onChangeDegree(value)}   placeholder="Select a edu degree" options={getDropValue(educationDegree, "name","id")} title="Select a edu degree"  />
                    <div className='mt-[20px] flex gap-[10px]'>
                        <button onClick={this.selectAll.bind(this, true, allProgramsAdd)}>Select all</button>
                        <button onClick={this.selectAll.bind(this, false, allProgramsAdd)}>Unselect all</button>
                    </div>
                </div>:null
            }
            <div className='flex flex-col gap-[20px] mt-[40px]'>
                {
                      type === "add"?
                      <div className='flex items-center gap-[20px]'>
                        <input value={countNumber} onChange={(e)=>this.setState({countNumber:e.target.value})} className='rounded-[6px] outline-none text-[13px] p-[4px]' type='number' placeholder='Add Number' />
                        <button onClick={this.addProgramToData.bind(this, countNumber)} className='bg-blue text-white text-[12px] p-[4px_7px] rounded-[6px]'>+ Add</button>
                    </div>:null
                }
                
                <div className='mb-[40px]'>
                    <AddProgramBody 
                        type={'default'}
                        data={programDefaults}
                        onChangeParent={(name, value)=>{this.onChangeAll(name, value, allProgramsAdd)}}
                        addAllPrograms={this.addAllPrograms.bind(this, allProgramsAdd)}
                    />
                </div>
                {
                    allProgramsAdd?.map((data, i)=>{
                        return(
                            <AddProgramBody 
                                key={i}
                                data={data}
                                onChangeParent={(name, value)=>{this.onChangeParent(name, value, i)}}
                                checked={data?.checked}
                                onChangeChecked={()=>this.props.changeStateValue({name: `allProgramsAdd.${i}.checked`, value: !data?.checked})}
                            />
                        )
                    })
                }
                
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    allProgramsAdd: state.Data.allProgramsAdd,
    educationDegree: state.Data.educationDegree,
})
const mapDispatchToProps = {getSingleData, getEduDegree, getLanguages, getDisciplines, changeStateValue, insertNewData, getExams, getHighSchoolExams, getPrograms, resetStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(AdminAddProgramsAll))
