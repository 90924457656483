import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue, resetStateValue } from '../../redux/MainReducer';
import Header from './components/Header';
import { getCountries, getStates, getCities, getDisciplines, insertNewData, getStudentComplete } from '../../actions/MainAction';
import MultiSelect from './components/MultiSelect';
import { getDropValue } from '../../actions/getOtherFunctions';
import { mixinAlert } from '../../actions/SweetAlerts';
import WorldSvg from '../svg/WorldSvg';
import CitySvg from '../svg/CitySvg';
import DisciplineSvg from '../svg/DisciplineSvg';
export class StudyDestinationModal extends Component {

    closeModal=()=>{
        this.props.resetStateValue({name:'studyDestinationModal'})
    }

    componentDidMount(){
        this.props.getCountries(3)
        .then(resp=>{
            if(this.props.studyDestinationModal?.countries?.length !=0){
                this.props.getStates("", "","all","",this.getMultiArray(this.props.studyDestinationModal?.countries))
                this.props.getCities("", "","all","","",this.getMultiArray(this.props.studyDestinationModal?.countries))
            }
            if(this.props.studyDestinationModal?.states.length !=0){
                this.props.getCities("", "","all","","",this.getMultiArray(this.props.studyDestinationModal?.countries), this.getMultiArray(this.props.studyDestinationModal?.states))
            }
        })
        this.props.getDisciplines("","", "all");
        
    }
    getMultiArray(array){
        // console.log(array)
        let newArr = [];
        array.forEach(x=>newArr.push(x?.value));
        return newArr?.join(',')
    }
    onChangeCountry=(name, data)=>{
        if(data?.length == 0){
            this.props.changeStateValue({name:'studyDestinationModal.states', value:[]})
            this.props.changeStateValue({name:'studyDestinationModal.cities', value:[]})
        }
        let lastStates = [];
        this.props.getStates("", "","all","",this.getMultiArray(data))
        .then(resp=>{
        
            let states = [...this.props.studyDestinationModal.states];
            let arr1 = [];
            let arr2 = [];
            for(const x of states){
                arr1.push(x?.value)
            }
            for(const x of resp){
                arr2.push(x?.id)
            }
            let commonElements = arr1.filter(element => arr2.includes(element));
           
            for(const x of commonElements){
                lastStates.push({
                    label: resp?.find(y=>y?.id == x)?.name,
                    value: x
                })
            }
            this.props.changeStateValue({name:'studyDestinationModal.states', value:lastStates})
        })
        this.props.getCities("", "","all","","",this.getMultiArray(data), this.getMultiArray(lastStates))
        .then(resp=>{
            let cities = [...this.props.studyDestinationModal.cities];
            let arr1 = [];
            let arr2 = [];
            for(const x of cities){
                arr1.push(x?.value)
            }
            for(const x of resp){
                arr2.push(x?.id)
            }
            let commonElements = arr1.filter(element => arr2.includes(element));
        
            let lastCities = [];
            for(const x of commonElements){
                lastCities.push({
                    label: resp?.find(y=>y?.id == x)?.name,
                    value: x
                })
            }
            this.props.changeStateValue({name:'studyDestinationModal.cities', value:lastCities})
        })
    }
    onChangeState=(name, data)=>{
        if(data?.length == 0){
            this.props.changeStateValue({name:'studyDestinationModal.cities', value:[]})
        }
        this.props.getCities("", "","all","","",this.getMultiArray(this.props.studyDestinationModal.countries), this.getMultiArray(data))
        .then(resp=>{
      
            let cities = [...this.props.studyDestinationModal.cities];
            let arr1 = [];
            let arr2 = [];
            for(const x of cities){
                arr1.push(x?.value)
            }
            for(const x of resp){
                arr2.push(x?.id)
            }
            let commonElements = arr1.filter(element => arr2.includes(element));
            let lastCities = [];
            for(const x of commonElements){
                lastCities.push({
                    label: resp?.find(y=>y?.id == x)?.name,
                    value: x
                })
            }
            this.props.changeStateValue({name:'studyDestinationModal.cities', value:lastCities})
        })
    }

    saveDataBtn=(data)=>{
        if(data?.countries.length !=0 && data?.disciplines.length !=0){
            let formData = new FormData();
            formData.append("student_id", data?.student_id);
            formData.append("countries", this.getMultiArray(data?.countries));
            formData.append("states", this.getMultiArray(data?.states));
            formData.append("cities", this.getMultiArray(data?.cities));
            formData.append("disciplines", this.getMultiArray(data?.disciplines));
            this.props.insertNewData('student-study-destination', formData)
            .then(resp=>{
                if(resp == "success"){
                
                    mixinAlert("success", "Study destination saved successfully");
                    this.closeModal();
                    data.afterCallFunction(...data?.afterCallParams);
                    this.props.getStudentComplete()
                }else{
                    mixinAlert("error", "Xəta baş verdi");
                }
            })
        }else{
            mixinAlert("warning", "Ən azı 1 ölkə və 1 ixtisas seçməlisiniz.");
        }
        
    }
  render() {
    const {studyDestinationModal, countries, states, cities, disciplines} = this.props;
    // console.log(studyDestinationModal)
    return (
        <div className='background_shadow flex justify-center items-center'>
            <div className='bg-white w-[700px] max-h-[70vh] rounded-[10px] shadow max-[750px]:ml-[20px] max-[750px]:mr-[20px] '>
                <Header title={studyDestinationModal?.modalTitle} closeBtn={this.closeModal.bind(this)} />
                <div style={{ height:'auto', maxHeight: 'calc(70vh - 50px)' }} className='p-[20px] max-[500px]:overflow-auto max-[500px]:max-h-[60vh_!important]'>
                    <div className='grid grid-cols-2 gap-[20px] max-[750px]:grid-cols-1'>
                        <MultiSelect reqText='Ən azı 1 ölkə seçməlisiniz.' required maxCount={5} svg={<WorldSvg />} bigInp 
                            selected={studyDestinationModal.countries} 
                            onChangeParent={(name, data)=>this.onChangeCountry(name, data)} 
                            placeholder={"5 ölkə seçə bilərsiniz"} options={getDropValue([...countries]?.filter(a=>a?.university_count !=0), "name","id")} 
                            name="studyDestinationModal.countries" title={"Ölkə seçin "}  />
                        {
                            [...states]?.filter(a =>a.university_count != 0)?.length !=0?
                            <MultiSelect maxCount={5} svg={<CitySvg />} bigInp selected={studyDestinationModal.states} 
                            onChangeParent={(name, data)=>this.onChangeState(name, data)} placeholder={"Ştat seçin"} 
                            options={getDropValue( [...states]?.filter(a =>a.university_count != 0), "name","id")} name="studyDestinationModal.states" title={"Ştat seçin"}  />:null
                        }
                        {
                            [...cities]?.filter(a=>a.university_count !=0)?.length !=0?
                            <MultiSelect maxCount={5} svg={<CitySvg />} bigInp selected={studyDestinationModal.cities}  
                                placeholder={"Şəhər seçin"} options={getDropValue( [...cities]?.filter(a=>a.university_count !=0), "name","id")} 
                                name="studyDestinationModal.cities" title={"Şəhər seçin"}  />:null
                        }
                        <MultiSelect reqText='Ən azı 1 ixtisas seçməlisiniz.' required maxCount={5} svg={<DisciplineSvg />} bigInp selected={studyDestinationModal.disciplines}  
                        placeholder={"5 ixitsas sahəsi seçə bilərsiniz"} options={getDropValue([...disciplines]?.filter(a=>a?.programs_count !=0), "name","id")} 
                        name="studyDestinationModal.disciplines" title={"İxtisas sahəsi seçin"}  />
                    </div>
                    <div className='flex justify-end mt-[20px]'>
                      <button onClick={this.saveDataBtn.bind(this, studyDestinationModal)} className='h-[48px] w-[142px] p-[0_10px] bg-blue text-white rounded-[5px] font-regular text-[20px] max-[400px]:text-[16px] max-[400px]:h-[30px] max-[400px]:pl-[5px] max-[400px]:pr-[5px] max-[310px]:text-[14px]'>Yadda saxla</button>
                    </div>
                </div>
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    studyDestinationModal: state.Data.studyDestinationModal,
    countries: state.Data.countries,
    states: state.Data.states,
    cities: state.Data.cities,
    disciplines: state.Data.disciplines,
});
const mapDispatchToProps = {changeStateValue, resetStateValue, getCountries, getStates,getStudentComplete, getCities, getDisciplines, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(StudyDestinationModal)
