import React, { Component } from 'react'

export class LocationSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={12}
        height={13}
        fill="none"
      >
        <g
          stroke="#7D7D7D"
          strokeLinecap="round"
          strokeLinejoin="round"
          clipPath="url(#a)"
        >
          <path d="M6 6.125a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z" />
          <path d="M9 6.125C7.875 8.75 6 11.75 6 11.75s-1.875-3-3-5.625.75-4.875 3-4.875 4.125 2.25 3 4.875Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 .5h12v12H0z" />
          </clipPath>
        </defs>
      </svg>
    )
  }
}

export default LocationSvg
