import React, { Component } from 'react'
import { getCeltAzApplies, insertNewData } from '../../actions/MainAction';
import { connect } from 'react-redux';
import Header from '../../components/header/Header';
import Card1 from '../../components/cards/Card1';
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts';
import moment from 'moment';

export class CeltAzAppliesPage extends Component {

    componentDidMount(){
        this.props.getCeltAzApplies()
    }

    onClickStatus=(data)=>{
        confirmAlert("warning", "Əminsiniz?", "Statusu dəyişməyinizdən əminsiniz", "Bəli", "Xeyir")
        .then(alertResp=>{
            if(alertResp === "success"){
                let formData = new FormData();
                formData.append("status", data?.status==1?2:1)
                formData.append("_method", "put")
            this.props.insertNewData(`admin-celtaz-applies/${data?.id}`, formData)
            .then(resp=>{
                if(resp == "success"){
                    this.props.getCeltAzApplies();
                    mixinAlert("success", "Uğurla dəyişdirildi.")
                }else{
                    mixinAlert("error","Xəta baş verdi")
                }
            })
            }
        })
    }
  render() {

    const {celtAzApplies} = this.props;
    // console.log(celtAzApplies)
    return (
        <div className='w-full  pl-[27px] pr-[27px]'>
        {/* <Header title="CELT Az applies" /> */}
        <div className='mt-[50px]'>
            <div className='grid-full-250 '>
                {
                    celtAzApplies?.map((data, i)=>{
                        return(
                            <Card1
                                key={i}
                                name={data?.name}
                                phone_number={data?.phone_number}
                                country={data?.country}
                                uni={data?.university}
                                program={data?.program}
                                degree={data?.degree}
                                footer={false}
                                rankStatus={data?.status}
                                rankStatusBtn={true}
                                onClickStatus={this.onClickStatus.bind(this, data)}
                                date={moment(data?.created_at).format("DD/MM/YYYY")}
                            />
                        )
                    })
                }
            </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    celtAzApplies: state.Data.celtAzApplies
});
const mapDispatchToProps = {getCeltAzApplies, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(CeltAzAppliesPage)
