import React, { Component } from 'react'
import ToCard from './ToCard'
import FromCard from './FromCard'
import { connect } from 'react-redux';
import moment from 'moment';
import { getColor, getRole } from '../../../actions/getOtherFunctions';

export class Body extends Component {


  render() {
    const {messages, user, onReply, replayData="", getAfterChange=()=>{}, h1="h-[calc(75vh_-_180px)]", h2="h-[calc(75vh_-_240px)]"} = this.props;
    return (
      <div className={`bg-[#fff] p-[0_12px_12px_12px] rounded-[12px] ${replayData === ""? h1:h2}  overflow-auto flex flex-col-reverse gap-[25px]`}>
        {
          messages?.map((data, i)=>{
            if(data?.user_id === user?.id){
              return(
                <FromCard 
                date={moment(data?.created_at).format("HH:mm")}
                userImg={data?.user?.image_full_url}   
                data={data}
                key={i} 
                onReply={()=>{onReply(data)}}
                msg={data?.text}  
                forward={data?.reply?true:false}
                forwardUser={data?.reply?.message?.user?.full_name}
                forwardMsg={data?.reply?.message?.text}
                getAfterChange={()=>getAfterChange()}
                readStatus={data?.read_users?.filter(x=>x?.user?.role == "student").length!=0?true:false}
                msgImage={data?.image_full_url}
                msgAudio={data?.audio_full_url}
                msgPdf={data?.file_full_url}
                />
              )
            }else{
              return(
                 <ToCard 
                  key={i} 
                  msg={data?.text}
                  userImg={data?.user?.image_full_url}   
                  userName={data?.user?.full_name}
                  role={getRole(data?.user?.role)} 
                  color={getColor(data?.user?.role)}
                  date={moment(data?.created_at).format("HH:mm")}
                  onReply={()=>{onReply(data)}}
                  forward={data?.reply?true:false}
                  forwardUser={data?.reply?.message?.user?.full_name}
                  forwardMsg={data?.reply?.message?.text}
                  data={data}
                  getAfterChange={()=>getAfterChange()}
                  msgImage={data?.image_full_url}
                  msgAudio={data?.audio_full_url}
                  msgPdf={data?.file_full_url}
                  />
              )
            }
          })
        }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  user: state.Data.user
});
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(Body)
