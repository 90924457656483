import React, { Component } from 'react'
import { connect } from 'react-redux';
import { insertNewData } from '../../../../actions/MainAction';
import { getStudentAppliedPrograms } from '../../../../actions/MainAction';
import Card5 from '../../../../components/public_components/cards/Card5';
import Card9 from '../../../../components/public_components/cards/Card9';
import { getStartFrom } from '../../../../actions/getOtherFunctions';
import { withHooks } from '../../../../actions/withHooks';
export class AppliedPrograms extends Component {
    state={
      programs:[],
      btnDisable:false
    }
    componentDidMount(){
      this.props.getStudentAppliedPrograms(this.props.student_id)
      .then(resp=>{
        this.setState({programs: resp})
      })
    }
    redirectPage=(data)=>{
      this.props.navigate(`/program-requirements/${data?.student_id}/${data?.program_id}`)
    }
  render() {
    const {programs, btnDisable} = this.state;
    // console.log(programs)
    let result = programs.reduce(function (r, a) {
      r[a.university_id] = r[a.university_id] || [];
      r[a.university_id].push(a);
      return r;
  }, {});
    return (
      <div >
        <div className='flex flex-col gap-[20px] mt-[20px]'>
          {
             [...Object.values(result)].sort((a,b)=>a[0]?.local_ranking - b[0]?.local_ranking)?.map((data, i)=>{
              return(
                  <Card9 key={i} 
                      data={data} 
                      degree={data?.degree?.name}
                      university={data?.university?.name}
                      btn={true}
                      btnDisable={btnDisable}
                      onRedirectBtn={this.redirectPage.bind(this)}
                  />
              )
              })
          }
          </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({});
const mapDispatchToProps = {insertNewData, getStudentAppliedPrograms}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(AppliedPrograms))


