import React, { Component } from 'react'
import { changeStateValue } from '../../redux/MainReducer';
import {connect} from "react-redux"
import { insertNewData } from '../../actions/MainAction';
import { mixinAlert } from '../../actions/SweetAlerts';
import InputPhone from '../modals/components/InputPhone';
import PhoneInput from 'react-phone-input-2';
import {validatePhoneNumberLength, isValidPhoneNumber, isPossiblePhoneNumber} from 'libphonenumber-js'

export class BookingDetailsComponent extends Component {
  state={
    countryCode:'az'
  }
  changeInpValue=(e)=>{
    this.props.changeStateValue({
      name: e.target.name,
      value: e.target.value
    })
  }

  saveSchBtn=(data)=>{
    if(data?.name !="" && data?.email !="" && data?.phone_number !==""){
      let formData = new FormData();
      for(const property in data){
        if(data[property]){
          formData.append(property, data[property])
        }
      }
      this.props.insertNewData("store-booking-info", formData)
      .then(resp=>{
        mixinAlert("success", "Görüşü təsdiqlədiyiniz üçün təşəkkürlər. Tezliklə sizin mobil nömrənizlə əlaqə saxlanılacaq və görüşün daha yaxşı keçməsi üçün bəzi sualları dəqiqləşdirəcəyik. Study Coach Komandası");
        this.props.changeStateValue({
          name:"bookingLastModal",
          value:true
        })
      })
    }else{
      mixinAlert("warning", "Zəhmət olmasa bütün boşluqları doldurun")
    }
    // console.log(data)
  }
  getValidNumber(phone, code){
    const phoneNumber = isValidPhoneNumber(phone, code.toUpperCase());
    this.props.changeStateValue({name:"phoneValidFormat", value:phoneNumber})
    return phoneNumber
   }
  render() {
    const {bookingUserInfo} = this.props;
    return (
      <div className='p-[20px] w-[60%] max-[900px]:w-full'>
        {/* <h3 className='text-[18px] font-[600]'>Enter Details</h3> */}
        <div className='flex flex-col items-start w-[100%] gap-[7px] mt-[10px] max-[600px]:w-full'>
            <label>Adınız *</label>
            <input onChange={this.changeInpValue.bind(this)} value={bookingUserInfo?.name} name="bookingUserInfo.name" className='border w-full h-[46px] rounded-[8px] p-[7px] font-[300]' placeholder='Adınız *' type='text' />
        </div>
        <div className='flex flex-col items-start w-[100%] gap-[7px] mt-[25px] max-[600px]:w-full'>
            <label>Email *</label>
            <input onChange={this.changeInpValue.bind(this)} value={bookingUserInfo?.email} name="bookingUserInfo.email" className='border w-full h-[46px] rounded-[8px] p-[7px] font-[300]' placeholder='Email *' type='email' />
        </div>
        {/* <InputPhone title="Contact Number *" /> */}
        <div className='flex flex-col items-start w-[100%] gap-[7px] mt-[25px] book-phone max-[600px]:w-full'>
            <label>Mobil nömrə *</label>
            <PhoneInput
                country={"az"}
                enableSearch={true}
                value={bookingUserInfo?.phone_number}
                placeholder='(00) 000 00 00'
                onChange={(phone, country) => {this.props.changeStateValue({name:"bookingUserInfo.phone_number", value:phone});this.setState({countryCode: country?.countryCode}); this.getValidNumber(phone, country?.countryCode)}}
                inputStyle={{ width:'100%'}}
                enableLongNumbers={false}
            />

            {/* <InputPhone title="Contact Number *" /> */}
            {/* <input onChange={this.changeInpValue.bind(this)} value={bookingUserInfo?.phone_number} name="bookingUserInfo.phone_number" className='border w-full h-[46px] rounded-[8px] p-[7px] font-[300]' placeholder='Contact Number *' type='tel' /> */}
        </div>

        <div className='mt-[25px]'>
            <span>Randevunun məqsədi. Öncədən hazırlıq üçün lazım olan məlumat.</span>
            <textarea onChange={this.changeInpValue.bind(this)} value={bookingUserInfo?.content} name="bookingUserInfo.content" className='border mt-[10px] w-[100%] h-[70px]  rounded-[8px] p-[7px] font-[300] text-[14px]'></textarea>
        </div>
        <div  className='mt-[20px]'>
          <button onClick={this.saveSchBtn.bind(this, bookingUserInfo)} className='w-[166px] h-[44px] bg-[#1E398F] rounded-full text-white'>Görüşü təsdiqləyin</button>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  bookingUserInfo: state.Data.bookingUserInfo
});
const mapDispatchToProps = {changeStateValue, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(BookingDetailsComponent)
