import React, { Component } from 'react'
import Header from '../../components/header/Header'
import Search from '../../components/Search'
import Buttons from '../../components/Buttons'
import { connect } from 'react-redux'
import { changeStateValue } from '../../redux/MainReducer';
import { getSubjects } from '../../actions/MainAction';
import Card1 from '../../components/cards/Card1'
import LoadMoreBtn from '../../components/LoadMoreBtn'
export class AdminSubjectsPage extends Component {
  state={
    page:1,
    search:''
  }
  openModal=(data)=>{
    this.props.changeStateValue({
      name:'adminModal',
      value:{
        position: true,
        id: data?.id?data?.id:'',
        title: data?.title?data?.title:'',
        description: data?.description?data?.description:'',
        image: data?.image?data?.image:'',
        image_full_url: data?.image_full_url?data?.image_full_url:'',
        afterCallFunction:this.props.getSubjects,
        afterCallParams:[this.state.page, this.state.search],
        requireds: ["title"],
        url:'subjects',
        modalTitle:`${data?.id?'Edit':'Add'} Subject`
      }
    })
  }

  componentDidMount(){
    this.props.getSubjects(1, '')
  }

  onSearchData=(search)=>{
    this.setState({search})
    this.props.getSubjects(this.state.page, search)
  }
  loadMore=(page)=>{
    this.setState({page})
    this.props.getSubjects(page, this.state.search)
  }

  afterCallFunction=()=>{
    this.props.getSubjects(this.state.page, this.state.search)
  }
  render() {
    const {subjectsData} = this.props;
    // console.log(subjectsData)
    return (
        <div className='w-full  pl-[27px] pr-[27px]'>
            <Header title="Subjects" />
            <div className='flex justify-end mt-[30px]'>
                <Buttons onClick={this.openModal.bind(this, '')} options={[{title:"Add a subject", value:0}]} selected={0} />
            </div>
            <div className='flex justify-center mt-[20px]'>
                <Search onSearchData={this.onSearchData.bind(this)} largWidth={true} />
            </div>

            <div className='grid-full-250 mt-[30px]'>
              {
                subjectsData?.map((data, i)=>{
                  return(
                    <Card1 
                      key={i}
                      image={data?.image_full_url}
                      name={data?.title}
                      onClickEdit={this.openModal.bind(this, data)}
                      deletePath={`subjects/${data?.id}`}
                      afterCallFunction={this.afterCallFunction.bind(this)}
                    //   moreLink={`/admin-discipline/${data?.id}`}
                    />
                  )
                })
              }
            </div>
            {
                subjectsData?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={subjectsData?.length} />:null
            }
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
  subjectsData: state.Data.subjectsData
});
const mapDispatchToProps = {changeStateValue, getSubjects}
export default connect(mapStateToProps, mapDispatchToProps)(AdminSubjectsPage)




