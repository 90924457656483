import React, { Component } from 'react'

export class MesageSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={19}
        height={19}
        fill="none"
      >
        <path
          fill="#000"
          fillRule="evenodd"
          d="M4.75 5.7a.95.95 0 1 0 0 1.9h9.5a.95.95 0 1 0 0-1.9h-9.5Zm0 2.85a.95.95 0 1 0 0 1.9h5.7a.95.95 0 0 0 0-1.9h-5.7ZM4.687 0h9.626c.841 0 1.567 0 2.149.078.622.084 1.22.273 1.703.757.485.485.674 1.081.757 1.703C19 3.12 19 3.846 19 4.688v5.825c0 .841 0 1.567-.078 2.149-.084.622-.273 1.22-.757 1.703-.485.485-1.081.674-1.703.757-.582.078-1.308.078-2.15.078h-1.91l-1.967 3.146a1.101 1.101 0 0 1-1.87 0L6.6 15.2H4.687c-.841 0-1.567 0-2.149-.078-.622-.083-1.22-.272-1.703-.757-.484-.485-.673-1.081-.757-1.703C0 12.08 0 11.354 0 10.512V4.688c0-.841 0-1.567.078-2.149.084-.622.273-1.22.757-1.703C1.32.351 1.916.162 2.538.078 3.12 0 3.846 0 4.688 0Z"
          clipRule="evenodd"
        />
      </svg>
    )
  }
}

export default MesageSvg
