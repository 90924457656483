import React, { Component } from 'react'
import { changeStateValue } from '../../../redux/MainReducer';
import { connect } from 'react-redux';
import Input from '../../../components/modals/components/Input';
import InputPhone from '../../../components/modals/components/InputPhone';
import { mixinAlert } from '../../../actions/SweetAlerts';
import { insertNewDataParams } from '../../../actions/MainAction'; 
import InpUserSvg from '../../../components/svg/InpUserSvg';
import PhoneSvg from '../../../components/svg/PhoneSvg';
import InpEmailSvg from '../../../components/svg/InpEmailSvg';
export class UserInfo extends Component {
   
    changePageBack=()=>{
        this.props.onChangePage("edu_back")
    }
    changePage=(data)=>{
        if(data?.first_name.trim() !="" && data?.last_name.trim() !="" && data?.phone_number.trim() !="" && this.props.phoneValidFormat){
            let formData = new FormData();
            for(const property in data){
                formData.append(property, data[property])
            }
            this.props.insertNewDataParams("send-verify-code", formData)
            .then(resp=>{
                // console.log(resp)
                if(resp?.msg == "success"){
                    this.props.changeStateValue({
                        name:"verifyModalData",
                        value:{
                            position:true,
                            code:'',
                            username: resp?.data?.username
                        }
                    })
                }else{
                    mixinAlert("error", "Something went wrong");
                }
                // console.log(resp)
            })
        }else{
            mixinAlert("warning", "Zəhmət olmasa bütün xanaları doldurun");
        }
    }
  render() {
    const {applyDetails} = this.props;
    // console.log(applyDetails)
    return (
      <div className='mt-[45px]'>
     
        <div className='grid grid-cols-2 gap-[20px] max-[640px]:grid-cols-1'>
            <Input requiredSvg bigInp svg={<InpUserSvg />}
                name={`applyDetails.information.first_name`}  
                value={applyDetails.information?.first_name} 
                title={`Adınız`}  />
            <Input requiredSvg bigInp  svg={<InpUserSvg />}
                name={`applyDetails.information.last_name`}  
                value={applyDetails.information?.last_name} 
                title={`Soyadınız`}  />
            <Input requiredSvg bigInp  svg={<InpEmailSvg />}
                name={`applyDetails.information.email`}  
                value={applyDetails.information?.email} 
                title={`Email`}  />
            <InputPhone requiredSvg bigInp svg={<PhoneSvg />}
                name={`applyDetails.information.phone_number`}  
                value={applyDetails.information?.phone_number} 
                title={`Mobil nömrəniz`}  />
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    applyDetails: state.Data.applyDetails,
    phoneValidFormat: state.Data.phoneValidFormat,
});
const mapDispatchToProps = {changeStateValue, insertNewDataParams}
export default connect(mapStateToProps, mapDispatchToProps)(UserInfo)
