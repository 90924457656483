import React, { Component } from 'react'

export class NavTeacherSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={18}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M20.5 21a2.5 2.5 0 0 0 2.5-2.5c0-2.327-1.952-3.301-4-3.708M15 11a4 4 0 0 0 0-8M3.5 21h11a2.5 2.5 0 0 0 2.5-2.5c0-4.08-6-4-8-4s-8-.08-8 4A2.5 2.5 0 0 0 3.5 21ZM13 7a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z"
        />
      </svg>
    )
  }
}

export default NavTeacherSvg
