import React, { Component } from 'react'
import CardEditSvg from './svg/CardEditSvg'

export class InfoButtton extends Component {
  render() {
    const {headerText="", headerIcon="", svg="", title, btnText="", onClick=()=>{}, activateStatus=true} = this.props
    return (
      <div onClick={activateStatus?()=>onClick():()=>{}} className='bg-white border border-[#E3E5EC] rounded-[8px] pb-[11px] h-[265px] relative'>
        {
          !activateStatus?
          <div className='absolute top-0 left-0 w-full h-full bg-[rgba(255,255,255,0.7)] z-20  rounded-[8px] cursor-not-allowed'></div>:null
        }
        <div className='flex justify-end mt-[8px] mr-[8px] items-center gap-[5px] h-[30px]'>
        {/* {headerIcon} <span className='text-[#464255] text-[15px] font-regular'>{headerText}</span> */}
        <CardEditSvg />
        </div>
        <div className='flex flex-col justify-between items-center'>
           <div className='flex flex-col items-center justify-center w-full h-[150px]'>
            {svg}
            <p className='mt-[11px] text-[464255] text-[18px] font-medium max-w-[50%] text-center '>{title}</p>
           </div>
            {
                btnText !=""?
                <button className='w-[80%] border border-[2px] border-dashed border-green-300 p-[12px_0px] rounded-full mt-[18px] text-[#484848] text-[14px] font-semibold'>
                    {btnText}
                </button>:null
            }
           
        </div>
      </div>
    )
  }
}

export default InfoButtton
