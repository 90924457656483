import React, { Component } from 'react'
import img2 from "../../../images/banner-footer 1.png"
import { Link } from 'react-router-dom'

export class BookConsultingSection extends Component {
  render() {
    return (
        <section className='bg-[#ECEDEF]'>
            <div className='max-w-[1200px] ml-auto mr-auto max-[1240px]:ml-[20px] max-[1240px]:mr-[20px]'>
                <div className='flex justify-between items-center gap-[92px] max-[900px]:flex-col max-[900px]:pb-[30px]'>
                    <div>
                        <img src={img2} alt="consulting image" className='w-[400px] h-[400px] max-[420px]:h-auto'/>
                    </div>
                    <div className='flex flex-col items-start max-[900px]:items-center'>
                        <h3 className='text-[44px] font-semibold max-[900px]:text-center'>Konsultasiya görüşü </h3>
                        <p className='text-[18px] font-[300] mt-[16px] max-w-[564px] max-[900px]:text-center'>Xaricdə təhsil almaq istəyirsinizsə konsultasiya görüşü alın, təhsil planınızı hazırlayaq.</p>
                        <Link to={'/booking'} className='bg-[#1E398F] inline-flex justify-center items-center w-[180px] h-[40px] rounded-full text-[16px] font-medium text-white mt-[27px]'>Randevu al</Link>
                    </div>
                </div>
            </div>
        </section>
    )
  }
}

export default BookConsultingSection
