import React, { Component } from 'react'

export class InpCheckSvg2 extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
      >
        <path
          fill="#BEBEBE"
          d="M14.337 7.231a.588.588 0 0 1 0 .832l-5.49 5.49a.588.588 0 0 1-.831 0L5.663 11.2a.588.588 0 0 1 .831-.831l1.937 1.936 5.075-5.074a.588.588 0 0 1 .831 0ZM20 10A10 10 0 1 1 10 0a10.012 10.012 0 0 1 10 10Zm-1.177 0A8.824 8.824 0 1 0 10 18.823 8.833 8.833 0 0 0 18.823 10Z"
        />
      </svg>
    )
  }
}

export default InpCheckSvg2
