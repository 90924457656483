import React, { Component } from 'react'

export class CitySvg extends Component {
  render() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={17}
            height={13}
            viewBox="0 0 50 50"
            fill="#6C6C6C"
            >
            <path d="M15 5a1 1 0 0 0-1 1v19H7a1 1 0 0 0-1 1v3.717L.049 43.605A1 1 0 0 0 .967 45H49.14a1 1 0 0 0 .906-1.424L44 30.621V22a1 1 0 0 0-1-1h-6v-7a1 1 0 0 0-1-1h-7V6a1 1 0 0 0-1-1H15zm1 2h11v6h-4a1 1 0 0 0-1 1v11h-6V7zm2 2v2h2V9h-2zm5 0v2h2V9h-2zm-5 4v2h2v-2h-2zm6 2h11v6h-4a1 1 0 0 0-1 1v17h-6V15zm-6 2v2h2v-2h-2zm8 0v2h2v-2h-2zm5 0v2h2v-2h-2zm-13 4v2h2v-2h-2zm8 0v2h2v-2h-2zm6 2h10v16H32V23zm-6 2v2h2v-2h-2zm8 0v2h2v-2h-2zm4 0v2h2v-2h-2zM8 27h14v12H8V27zm2 2v2h2v-2h-2zm4 0v2h2v-2h-2zm4 0v2h2v-2h-2zm8 0v2h2v-2h-2zm8 0v2h2v-2h-2zm4 0v2h2v-2h-2zm-28 4v2h2v-2h-2zm4 0v2h2v-2h-2zm4 0v2h2v-2h-2zm8 0v2h2v-2h-2zm8 0v2h2v-2h-2zm4 0v2h2v-2h-2zM6 34.797V40a1 1 0 0 0 1 1h36a1 1 0 0 0 1-1v-4.65L47.57 43H2.484L6 34.797z" />
            </svg>
    )
  }
}

export default CitySvg
