import React, { Component } from 'react'
import SectionHeader from '../../components/public_components/SectionHeader'
import BookingCalendarSection from './sections/BookingCalendarSection'
import { Helmet } from 'react-helmet'

export class BookingPage extends Component {
  componentDidMount(){
    window.scrollTo(0,0)
  }
  render() {
    return (
        <section className='mt-[80px] bg-[#F6F8FB]'>
           <Helmet>
              <title>{"Xaricdə Təhsil üçün Randevu Alın | CELTgo.com - Məsləhət Üçün Görüş Təyin Edin"}</title>
              <meta property="og:locale" content="az_AZ" />
              <meta property="og:type" content="website" />
              <meta property="og:title" content="Xaricdə Təhsil üçün Randevu Alın | CELTgo.com - Məsləhət Üçün Görüş Təyin Edin" />
              <meta property="og:description" content="CELTgo.com ilə xaricdə təhsil məsləhəti üçün randevu alın. Peşəkar təhsil məsləhətçilərimizlə görüş təyin edərək, arzuladığınız universitetə qəbul olunmağınıza kömək edək." />
              <meta name="description" content={"CELTgo.com ilə xaricdə təhsil məsləhəti üçün randevu alın. Peşəkar təhsil məsləhətçilərimizlə görüş təyin edərək, arzuladığınız universitetə qəbul olunmağınıza kömək edək."}/>
              <meta name="keywords" content={"xaricdə təhsil üçün randevu, təhsil məsləhəti üçün görüş, universitet qəbulu məsləhəti, xaricdə təhsil görüşü, CELTgo randevu, təhsil məsləhətçisi ilə görüş, xaricdə təhsil üçün məsləhət"}/>
              <meta property="og:url" content="https://celtgo.com/booking" />
              <meta property="og:site_name" content="CELT Colleges" />
              <meta property="article:publisher" content="https://www.facebook.com/celtscollege" />
              <meta property="article:modified_time" content="2024-06-06T08:56:25+00:00" />
              <meta property="og:image" content="https://celtgo.com/static/media/celtLogo6.05ddd884642befe11a4e.png" />
              <meta property="og:image:width" content="900" />
              <meta property="og:image:height" content="600" />
              <meta property="og:image:type" content="image/jpeg" />
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:title" content="Xaricdə Təhsil üçün Randevu Alın | CELTgo.com - Məsləhət Üçün Görüş Təyin Edin" />
              <meta name="twitter:description" content="CELTgo.com ilə xaricdə təhsil məsləhəti üçün randevu alın. Peşəkar təhsil məsləhətçilərimizlə görüş təyin edərək, arzuladığınız universitetə qəbul olunmağınıza kömək edək." />
              <meta name="twitter:site" content="@celtcolleges" />
              <meta name="twitter:label1" content="Est. reading time" />
              <meta name="twitter:data1" content="7 minutes" />
            </Helmet>
            <div className='pt-[40px]'>
                <SectionHeader text1="Xaricdə Təhsil" text2="üçün Randevu" />
                <div className='max-w-[1200px] ml-auto mr-auto max-[1240px]:ml-[20px] max-[1240px]:mr-[20px] mt-[40px]'>
                  <div className='bg-white p-[20px] rounded-[5px]'>
                      <BookingCalendarSection />
                  </div>
                </div>
            </div>
        </section>
    )
  }
}

export default BookingPage
