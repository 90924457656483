import React, { Component } from 'react'

export class BtnLeftSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={17}
        fill="none"
      >
        <path
          fill="#fff"
          d="M7.846 16.824.186 8.963a.664.664 0 0 1 0-.926L7.847.176a.63.63 0 0 1 .887.016.664.664 0 0 1 .016.91l-6.57 6.743h17.183a.63.63 0 0 1 .451.192.664.664 0 0 1 0 .926.63.63 0 0 1-.451.192H2.179l6.57 6.743a.665.665 0 0 1-.016.91.63.63 0 0 1-.887.016Z"
        />
      </svg>
    )
  }
}

export default BtnLeftSvg
