import React, { Component } from 'react'
import PrAppSvg from './svg/PrAppSvg'
import { Link } from 'react-router-dom'
import CardRightSvg from './svg/CardRightSvg'
import { zeroPadFunction } from '../actions/getOtherFunctions';

export class DashAppliedComp extends Component {
    
  render() {
    const {options=[], title, link=""} = this.props;
    return (
        <div className='bg-white rounded-[4px] bg-white p-[41px_32px_32px_15px]'>
            <h2 className='text-[#303972] text-[24px] font-bold'>{title}</h2>
            <div className='flex flex-col gap-[11px] mt-[30px]'>
                {
                    options?.map((data, i)=>{
                        return(
                            <div key={i} className='flex justify-between items-center'>
                                <div className='flex gap-[16px]'>
                                    <PrAppSvg />
                                    <div>
                                        <h4 className='text-[#18181B] font-bold text-[18px]'>{zeroPadFunction(data?.count)}</h4>
                                        <span className='text-[#52525B] text-[14px] font-regular'>{data?.student?.full_name}</span>
                                    </div>
                                </div>
                                <Link to={`/${link}/${data?.student?.id}`}>
                                    <CardRightSvg />
                                </Link>
                            </div>
                        )
                    })
                }
               
            </div>
        </div>
    )
  }
}

export default DashAppliedComp
