import React, { Component } from 'react'

export class ChatTypeBtn extends Component {
    
  render() {
    const {chatType, onChangeType=()=>{}} = this.props;
    return (
      <div className='flex items-center bg-[#F8FAFC] h-[38px] rounded-[12px] mt-[20px] pl-[4px] pr-[4px] m-[0_20px]'>
        <button onClick={()=>onChangeType("active")} className={` ${chatType == "active"? "bg-white h-[30px] txt-light-black":"text-[#64748B]"} rounded-[12px] w-1/2 text-[12px] font-bold `}>
            Aktiv
        </button>
        <button  onClick={()=>onChangeType("contact")} className={`${chatType == "contact"? "bg-white h-[30px] txt-light-black":"text-[#64748B]"}  rounded-[12px] w-1/2 text-[12px] font-bold `}>
            Əlaqələr
        </button>
      </div>
    )
  }
}

export default ChatTypeBtn
