import React, { Component } from 'react'
import Header from './components/Header'
import { getAllTeachers, insertNewData } from '../../actions/MainAction';
import { changeStateValue, resetStateValue } from '../../redux/MainReducer';
import { connect } from 'react-redux';
import { mixinAlert } from '../../actions/SweetAlerts';

export class ChangeTNModal extends Component {
    state={
        btnDisabled:false
    }
    componentDidMount(){
        this.props.getAllTeachers();
    }

    selectStaff=(id)=>{
        this.props.changeStateValue({
            name:"changeTNModalData.staff_id",
            value: id
        })
    }
    closeModal=()=>{
        this.props.resetStateValue({
            name:"changeTNModalData"
        })
    }

    saveBtn=(data)=>{
        if(data?.staff_id && data?.staff_id !=""){
            this.setState({btnDisabled:true});
            let formData = new FormData();
            formData.append("user_id", data?.user_id);
            formData.append("staff_id", data?.staff_id);
            this.props.insertNewData("change-user-staff", formData)
            .then(resp=>{
                this.setState({btnDisabled:false});
                if(resp == "success"){
                    this.closeModal();
                    this.props.afterCallFunction()
                    mixinAlert("success", "Təhsil nümayəndəsi uğurla dəyişdirildi.")
                }else{
                    mixinAlert("error", "Xəta baş verdi");
                }
            })
        }else{
            mixinAlert("warning", "Zəhmət olmasa təhsil nümayəndəsini seçin.")
        }
    }
  render() {
    const {allTeachers, changeTNModalData} = this.props;
    const {btnDisabled} = this.state;
    console.log(changeTNModalData)
    return (
      <div className='background_shadow flex justify-center items-center'>
        <div className='bg-white w-[600px] rounded-[10px] shadow'>
            <Header closeBtn={this.closeModal.bind(this)} title="Təhsil nümayəndisini dəyiş" />
            <div className='p-[20px]'>
                <div className='flex flex-col max-h-[400px] overflow-auto'>
                    {
                        allTeachers?.map((data, i)=>{
                            return(
                                <div key={i} className='flex items-center gap-[5px] bg-gray-100 border-b border-b-white p-[5px_4px] rounded-[5px]'>
                                    <input onChange={this.selectStaff.bind(this, data?.id)} checked={changeTNModalData?.staff_id == data?.id} id={data?.id} type='checkbox' className='cursor-pointer'/>
                                    <label htmlFor={data?.id} className='text-[14px] cursor-pointer  w-full'>{data?.full_name}</label>
                                </div>
                            )
                        })
                    }
                </div>
                <div className='mt-[20px] flex justify-between items-center'>
                    <button disabled={btnDisabled} onClick={this.closeModal.bind(this)} className='bg-red h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>Bağla</button>
                    <button disabled={btnDisabled} onClick={this.saveBtn.bind(this,changeTNModalData)} className='bg-blue h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>Yadda saxla</button>
                </div>
            </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    allTeachers: state.Data.allTeachers,
    changeTNModalData: state.Data.changeTNModalData,
});
const mapDispatchToProps = {getAllTeachers, changeStateValue, insertNewData, resetStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(ChangeTNModal)
