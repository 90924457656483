import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue, resetStateValue } from '../../redux/MainReducer';
import Header from './components/Header';
import { getEduDegree, getCountries, getHighSchoolExams, getStudentComplete } from '../../actions/MainAction';
import DropSearch from './components/DropSearch';
import { getDropValue } from '../../actions/getOtherFunctions';
import Input from './components/Input';
import { insertNewData } from '../../actions/MainAction';
import { mixinAlert } from '../../actions/SweetAlerts';
import WorldSvg from '../svg/WorldSvg';
import EduSvg from '../svg/EduSvg';
import DiplomaSvg from '../svg/DiplomaSvg';
import GradeSvg from '../svg/GradeSvg';
import InpSvg from '../svg/InpSvg';
export class EducationBackgroundModal extends Component {
    state={
      sub_degrees:[]
    }
    closeModal=()=>{
      this.props.resetStateValue({name:"eduBackModal"})
    }
    componentDidMount(){
      
      this.props.getCountries(2);
      this.props.getHighSchoolExams();
      this.props.getEduDegree().then(resp=>{
        try{
          if(this.props.eduBackModal?.edu_degree_id){
            let subDegrees = this.props.educationDegree?.find(x=>x?.id == this.props.eduBackModal?.edu_degree_id)?.sub_degrees?.filter(y=>y?.country_id == this.props.eduBackModal?.country_id);
            this.setState({sub_degrees:subDegrees});
            for(const x in this.props.eduBackModal){
              if(x == "a_level" || x == "ossd" || x == "ap" || x == "ib" || x == "foundation"){
                if(this.props.eduBackModal[x] !==""){
                  this.props.changeStateValue({  name:'eduBackModal.sub_edu_degree_id', value:x });
                }
              }
            }
          }
        }catch(err){}
      })
    }

    onSelectDegree=(countryId, degrees, degreeId)=>{
      let subDegrees = degrees?.find(x=>x?.id == degreeId)?.sub_degrees?.filter(y=>y?.country_id == countryId);
      this.setState({sub_degrees:subDegrees})
      this.props.changeStateValue({  name:'eduBackModal.sub_edu_degree_id', value:'' });
      this.props.changeStateValue({  name:'eduBackModal.grade', value:'' });
      this.props.changeStateValue({  name:'eduBackModal.a_level', value:'' });
      this.props.changeStateValue({  name:'eduBackModal.ap', value:'' });
      this.props.changeStateValue({  name:'eduBackModal.ib', value:'' });
      this.props.changeStateValue({  name:'eduBackModal.ossd', value:'' });
      this.props.changeStateValue({  name:'eduBackModal.foundation', value:'' });
    }
    onSelectCountry=()=>{
      this.props.changeStateValue({  name:'eduBackModal.edu_degree_id', value:'' });
      this.props.changeStateValue({  name:'eduBackModal.sub_edu_degree_id', value:'' });
      this.props.changeStateValue({  name:'eduBackModal.grade', value:'' });
      this.props.changeStateValue({  name:'eduBackModal.a_level', value:'' });
      this.props.changeStateValue({  name:'eduBackModal.ap', value:'' });
      this.props.changeStateValue({  name:'eduBackModal.ib', value:'' });
      this.props.changeStateValue({  name:'eduBackModal.ossd', value:'' });
      this.props.changeStateValue({  name:'eduBackModal.foundation', value:'' });
    }

    onSelectSubDegree=()=>{
      this.props.changeStateValue({  name:'eduBackModal.grade', value:'' });
      this.props.changeStateValue({  name:'eduBackModal.a_level', value:'' });
      this.props.changeStateValue({  name:'eduBackModal.ap', value:'' });
      this.props.changeStateValue({  name:'eduBackModal.ib', value:'' });
      this.props.changeStateValue({  name:'eduBackModal.ossd', value:'' });
      this.props.changeStateValue({  name:'eduBackModal.foundation', value:'' });
    }

    saveDataBtn=(data)=>{
      let formData = new FormData();
     
      for(const property in data){
        if(property === "sub_edu_degree_id"){
          if(data?.sub_edu_degree_id !== "a_level" && data?.sub_edu_degree_id !== "ap" && data?.sub_edu_degree_id !== "ossd" && data?.sub_edu_degree_id !== "ib" && data?.sub_edu_degree_id !== "foundation"){
            formData.append(property, data[property])
          }
        }else{
          formData.append(property, data[property])
        }
      }
      this.props.insertNewData('student-edu-background', formData)
      .then(resp=>{
        if(resp ==="success"){
          mixinAlert("success", "Məlumatınız uğurla yeniləndi");
          data.afterCallFunction(...data?.afterCallParams);
          this.closeModal();
          this.props.getStudentComplete();
          
        }else{
          mixinAlert("error", "Xəta baş verdi");
        }
      })
    }
    getPlaceholder(name){
      if(name == "IB"){
          return "Yalnız 24 və 45 aralığında qiymət daxil edin.";
      }else if (name == "OSSD"){
          return "Yalnız 50 və 100 aralığında qiymət daxil edin."
      }else if (name == "Foundation"){
          return "Yalnız 40 və 100 aralığında qiymət daxil edin."
      }else{
          return ""
      }
  }
  render() {
    const {eduBackModal, educationDegree, countries, highSchoolExams} = this.props;
    const {sub_degrees} = this.state;
    let countryOption = [];
    for(const proeprty of countries){
      if(proeprty?.id===1 || proeprty?.id === 5){
        countryOption.push({
          label: proeprty?.name,
          value: proeprty?.id
        })
      }
    }
    let subDegrees = [...getDropValue(sub_degrees, "name","id")];
    for(const exam of highSchoolExams){
      subDegrees.push({
        label: exam?.name,
        value: exam?.name?.replaceAll(" ", "_")?.toLowerCase()
      })
    }
    // console.log(eduBackModal)
    return (
        <div className='background_shadow flex justify-center items-center'>
            <div className='bg-white w-[700px] max-h-[70vh] rounded-[10px] shadow max-[750px]:ml-[20px] max-[750px]:mr-[20px] max-[750px]:max-h-[80vh]'>
                <Header title={eduBackModal?.modalTitle} closeBtn={this.closeModal.bind(this)} />
                <div style={{ height:'auto', maxHeight: 'calc(70vh - 50px)' }} className='p-[20px]'>
                  <div className='grid grid-cols-2 gap-[20px] max-[750px]:grid-cols-1'>
                    <DropSearch svg={<WorldSvg />} bigDrop required 
                       name="eduBackModal.country_id"  selectData={this.onSelectCountry.bind(this)} 
                       value={eduBackModal.country_id} placeholder="Oxuduğunuz ölkə" 
                       options={countryOption} title="Oxuduğunuz ölkə" />
                    {
                      eduBackModal?.country_id !=""?
                      <DropSearch  svg={<EduSvg />} bigDrop required 
                        name="eduBackModal.edu_degree_id"  
                        selectData={this.onSelectDegree.bind(this,eduBackModal.country_id, educationDegree, )} 
                        value={eduBackModal.edu_degree_id} placeholder="Ən son təhsil diplomunuz" 
                        reqText={"Təhsil diplomunu seçin"}
                        options={getDropValue(educationDegree?.filter(x=>x.id ==1|| x.id==3 || x.id == 4 || x.id == 5), "name","id")} 
                        title="Ən son təhsil diplomunuz" />:null
                    }
                    {
                      eduBackModal?.edu_degree_id ===1 || eduBackModal?.edu_degree_id === 2?
                      <DropSearch svg={<DiplomaSvg />} bigDrop required 
                        name="eduBackModal.sub_edu_degree_id"  selectData={this.onSelectSubDegree.bind(this)} 
                        value={eduBackModal.sub_edu_degree_id} 
                        placeholder="Məktəb Diplomu" options={subDegrees} 
                        reqText={"Məktəb diplomunu seçin"}
                        title="Məktəb Diplomu" />:null
                    }
                    {
                      highSchoolExams?.map((data, j)=>{
                        if(data?.name?.replaceAll(" ", "_")?.toLowerCase() ===  eduBackModal?.sub_edu_degree_id){
                          if(data?.grades?.length !==0){
                            return(
                              <DropSearch svg={<GradeSvg />} bigDrop  key={j} 
                              required name={`eduBackModal.${eduBackModal?.sub_edu_degree_id}`} 
                              value={eduBackModal[eduBackModal?.sub_edu_degree_id]} 
                              placeholder={data?.name+" "+"qiymətləri seçin"} options={getDropValue(data?.grades, "grade", "grade")} 
                              reqText={data?.name+" "+"qiymətləri seçin"}
                              title={data?.name} />
                            )
                          }else{
                            return(
                              <Input  bigInp svg={<InpSvg />} bg="bg-[#F3F4F6]"  key={j} 
                                  required
                                  minValue={data?.min_value} 
                                  maxValue={data?.max_value} 
                                  step={1}
                                  name={`eduBackModal.${eduBackModal?.sub_edu_degree_id}`}  
                                  value={eduBackModal[eduBackModal?.sub_edu_degree_id]} 
                                  reqText={this.getPlaceholder(data?.name)}
                                  placeholder={data?.name+" "+"balınız (gözlənilən və ya son)"}
                                  title={`${data?.name} (${data?.min_value} - ${data?.max_value})`}   />
                            )
                          }
                          
                        }
                      })
                    }
                    {
                      eduBackModal?.sub_edu_degree_id ===1?
                      <Input 
                        bigInp svg={<InpSvg />} bg="bg-[#F3F4F6]"
                        required
                        minValue={3} 
                        maxValue={5} 
                        step={1}
                        name={`eduBackModal.grade`}  
                        value={eduBackModal?.grade} 
                        placeholder="Məsələn: 3, 3.5, 4, 4.5, 5"
                        reqText="Anketi doldurmaq mütləqdir"
                        title={`Attestat ortalama qiyməti`}  />:null
                    }
                    {
                      eduBackModal?.sub_edu_degree_id ===2?
                      <Input 
                        bigInp svg={<InpSvg />} bg="bg-[#F3F4F6]"
                        required
                        minValue={1} 
                        maxValue={100} 
                        step={1}
                        name={`eduBackModal.grade`}  
                        value={eduBackModal?.grade} 
                        reqText="Anketi doldurmaq mütləqdir"
                        title={`Lise diploması (1 - 100)`}  />:null
                    }
                    {
                       eduBackModal?.edu_degree_id !=="" &&eduBackModal?.edu_degree_id !==1 && eduBackModal?.edu_degree_id !== 2?
                       <Input 
                       bigInp svg={<InpSvg />} bg="bg-[#F3F4F6]"
                       required
                       minValue={1} 
                       maxValue={100} 
                       step={1}
                       name={`eduBackModal.grade`}  
                       value={eduBackModal?.grade} 
                       reqText="Anketi doldurmaq mütləqdir"
                       title={`Hazırki ortalama qiymət (1 - 100)`}  />:null
                    }
                  </div>
                  {
                    eduBackModal?.grade !=="" ||
                    eduBackModal?.a_level !=="" ||
                    eduBackModal?.ossd !=="" ||
                    eduBackModal?.ib !=="" ||
                    eduBackModal?.ap !==""||
                    eduBackModal?.foundation !==""?
                    <div className='flex justify-end mt-[20px]'>
                      <button onClick={this.saveDataBtn.bind(this, eduBackModal)} className='h-[48px] w-[140px] p-[0_10px] bg-blue text-white rounded-[5px] font-regular text-[20px] max-[400px]:text-[16px] max-[400px]:h-[30px] max-[400px]:pl-[5px] max-[400px]:pr-[5px] max-[310px]:text-[14px]'>Yadda saxla</button>
                    </div>:
                     <div className='flex justify-end mt-[20px]'>
                      <button className='h-[48px] w-[140px] p-[0_10px] bg-blue text-white rounded-[5px] font-regular text-[20px] max-[400px]:text-[16px] max-[400px]:h-[30px] max-[400px]:pl-[5px] max-[400px]:pr-[5px] max-[310px]:text-[14px]'>Yadda saxla</button>
                    </div>
                  }
                  
                </div>
            </div>
        
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    eduBackModal: state.Data.eduBackModal,
    educationDegree: state.Data.educationDegree,
    countries: state.Data.countries,
    highSchoolExams: state.Data.highSchoolExams,
});
const mapDispatchToProps = {changeStateValue, getEduDegree, getCountries, getHighSchoolExams, insertNewData, resetStateValue, getStudentComplete}
export default connect(mapStateToProps, mapDispatchToProps)(EducationBackgroundModal)
