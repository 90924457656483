import React, { Component } from 'react'
import {connect} from "react-redux"
import SuccessSvg from '../../../components/public_components/svg/SuccessSvg'
import UserSvg from '../../../components/public_components/svg/UserSvg'
import ScheduleSvg from '../../../components/public_components/svg/ScheduleSvg'
import moment from 'moment'
import { getPublicBookingInfo } from '../../../actions/PublicActions'
export class BookingLastSection extends Component {
    state={
        info:''
    }
    componentDidMount(){
        this.props.getPublicBookingInfo()
        .then(resp=>{
          this.setState({info:resp})
        })
    }
  render() {
    const {bookingUserInfo} = this.props;
    const {info} = this.state;
    return (
      <div className='flex flex-col items-center justify-center w-full pt-[30px] gap-[20px] pb-[30px] max-[600px]:pl-[10px] max-[600px]:pr-[10px]'>
        <img className='w-[165px] h-[165px] rounded-full' src={info?.image_full_url} />
        {/* <h3 className='flex items-center gap-[10px] text-[#1a1a1a] text-[20px] font-[600]'><SuccessSvg /> You are scheduled</h3> */}
        <div>
            {/* <p className=' text-[#1a1a1a] font-[300] text-center'>E-poçt ünvanınıza təqvim dəvəti göndərildi..</p> */}
            <div className='w-[450px] border mt-[25px] p-[10px] rounded-[8px] max-[600px]:w-full '>
                <h3 className='text-[#1a1a1a] text-[17px] font-[600]'>Sizin radevunuz</h3>
                <div className='flex items-center gap-[10px] mt-[10px]'>
                    <UserSvg />
                    <span className='text-[#1a1a1a9c] text-[17px] font-[500] max-[400px]:text-[14px]'>{info?.name}</span>
                </div>
                <div className='flex items-center gap-[10px] mt-[10px]'>
                    <ScheduleSvg />
                    <span className='text-[#1a1a1a9c] text-[17px] font-[500] max-[400px]:text-[14px]'>{bookingUserInfo?.time}, {moment(bookingUserInfo?.day).format("dddd")}, {moment(bookingUserInfo?.day).format("MMM DD, YYYY")}</span>
                </div>
                <p className='mt-[10px] border-t pt-[10px] text-[#1a1a1a] font-[300] flex'>
               Görüşü təsdiqlədiyiniz üçün təşəkkürlər. Tezliklə sizin mobil nömrənizlə əlaqə saxlanılacaq və görüşün daha yaxşı keçməsi üçün bəzi sualları dəqiqləşdirəcəyik. 
                <br />Study Coach Komandası
                </p>
            </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  bookingUserInfo: state.Data.bookingUserInfo,
  bookingInfo: state.Data.bookingInfo,

});
const mapDispatchToProps = {getPublicBookingInfo}
export default connect(mapStateToProps, mapDispatchToProps)(BookingLastSection)
