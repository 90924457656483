import React, { Component } from 'react'
import defImg from "../../images/defImg.jpg"
export class SinglePageHeader extends Component {
  render() {
    const {image="", name} = this.props;
    // console.log(image)
    return (
        <section className='max-w-[1200px] ml-auto mr-auto max-[1240px]:ml-[20px] max-[1240px]:mr-[20px] relative'>
            <img className='w-full rounded-b-[20px] max-h-[60vh]' src={image && image !=""?image:defImg} alt="Country name" />
            <div className='absolute bottom-[30px] flex justify-center w-full'>
                <h2 className='bg-[#F3F4F6] text-[35px] font-semibold rounded-[15px] text-neutral-800 px-9 py-3 max-[500px]:px-[10px] max-[500px]:py-[5px] max-[800px]:text-[25px] max-[600px]:ml-[10px] max-[600px]:mr-[10px] max-[500px]:text-[18px]'>{name}</h2>
            </div>
        </section>
    )
  }
}

export default SinglePageHeader
