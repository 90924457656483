import React, { Component } from 'react'

export class SideLogOutSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={34}
        height={34}
        fill="none"
      >
        <g
          stroke="#EE7100"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          clipPath="url(#a)"
        >
          <path d="m20.34 22.083-4.404 4.075a2 2 0 0 1-2.826-.11l-7.47-8.074a2 2 0 0 1 .11-2.827l8.074-7.47a2 2 0 0 1 2.826.11l4.075 4.404M25.644 13.09l4.074 4.404-4.404 4.075M14.174 16.89l15.544.604" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M17.66.042 33.957 17.66 16.34 33.958.042 16.34z" />
          </clipPath>
        </defs>
      </svg>
    )
  }
}

export default SideLogOutSvg
