import React, { Component } from 'react'
import AdminCountriesPage from '../AdminCountriesPage'
import { withHooks } from '../../../actions/withHooks'
import { getSingleData } from '../../../actions/MainAction';
import { connect } from 'react-redux';

export class SchCountries extends Component {
  state={
    schData:''
  }
  componentDidMount(){
    this.props.getSingleData(`country-scholarship/${this.props.params.id}`)
    .then(resp=>{
      this.setState({schData: resp})
    })
  }
  render() {
    const {schData} = this.state;
    // console.log(schData)
    return (
      <div>
        <AdminCountriesPage header={schData?.name} addSchBtn={true} moreLink={`/sch/${this.props.params?.id}`} />
      </div>
    )
  }
}
const mapStateToProps = (state) =>({});
const mapDispatchToProps = {getSingleData}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(SchCountries))
