import React, { Component } from 'react'
// import logo from "../../images/logo.png"
// import logo from "../../images/logo/celtlogo-03.png"
import logo from "../../images/logo/celtLogo6.png"
import { Link } from 'react-router-dom'
import {  resetStateValue } from '../../redux/MainReducer'
import { connect } from 'react-redux'
import MenuSvg from './svg/MenuSvg'
export class Header extends Component {
    resetRandevu=()=>{
        this.props.resetStateValue({name:"bookingUserInfo"})
        this.props.resetStateValue({name:"bookingLastModal"})
    }

    // componentDidMount(){
    //     window.addEventListener("click", (e)=>{
            // if(window.innerWidth <= 950){
            //     const nav = document.querySelector("#publicNavBtn");
            //     if(nav){
            //         // nav.checked = false
            //         console.log(nav)
            //     }
            // }   
    //     })
    // }
    closeNav=()=>{
        window.scrollTo(0,0)
        if(window.innerWidth <= 950){
            const nav = document.querySelector("#publicNavBtn");
            if(nav){
                nav.checked = false
            }
        }   
    }
  render() {
    return (
      <header className='h-[80px] bg-[#F6F8FB] shadow  fixed w-full z-[990]'>
        <div className='max-w-[1200px] ml-auto mr-auto flex justify-between items-center h-full max-[1240px]:ml-[20px] max-[1240px]:mr-[20px] max-[950px]:justify-center'>
            <Link onClick={this.closeNav.bind(this)}  to="/">
                <img src={logo} className='w-[210px] max-[500px]:w-[150px]' alt="Logo" />
            </Link>
            <input type='checkbox' className='hidden' id="publicNavBtn" />
            <div className='flex items-center gap-[92px] nav_cont'>
            <Link onClick={this.closeNav.bind(this)}  className='min-[950px]:hidden' to="/">
                <img src={logo} className='w-[250px] ' alt="Logo" />
            </Link>
                <nav>
                    <ul className='flex gap-[50px] public_nav max-[980px]:gap-[20px]'>
                        <li>
                            <Link onClick={this.closeNav.bind(this)} className='text-[14px] font-regular' to='/countries'>Ölkələr </Link>
                        </li>
                        <li>
                            <Link  onClick={this.resetRandevu.bind(this)} className='text-[14px] font-regular' to='/booking'>Randevu Al</Link>
                        </li>
                        <li>
                            <Link onClick={this.closeNav.bind(this)}  className='text-[14px] font-regular' to='/accepted-students'>Tələbələr</Link>
                        </li>
                        {/* <li>
                            <Link className='text-[14px] font-regular' to='/city-rankings'>City ranking</Link>
                        </li>
                        <li>
                            <Link className='text-[14px] font-regular' to='/employability-rankings'>Emp ranking</Link>
                        </li> */}
                        <li>
                            <Link onClick={this.closeNav.bind(this)}  className='text-[14px] font-regular' to='/rank-universities'>Universitetlər</Link>
                        </li>
                        <li>
                            <Link onClick={this.closeNav.bind(this)}  className='text-[14px] font-regular' to='/contact'>Əlaqə</Link>
                        </li>
                        {/* <li>
                            <Link className='text-[14px] font-regular' to='/blog'>Blogs</Link>
                        </li> */}
                    </ul>
                </nav>
                <Link  onClick={this.closeNav.bind(this)} className='h-[32px] w-[97px] bg-[#1E398F] text-white rounded-full font-regular text-[14px] text-center inline-flex items-center justify-center' to={'/login'}>
                    Giriş ET
                </Link>
            </div>
            <label htmlFor='publicNavBtn' className='min-[950px]:hidden absolute right-[20px]'>
                <MenuSvg />
            </label>
        </div>
      </header>
    )
  }
}
const mapStateToProps = (state) =>({});
const mapDispatchToProps = {resetStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(Header)
