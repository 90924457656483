import React, { Component } from 'react'
import SvgBackground from './SvgBackground'

export class SvgContainer extends Component {
  render() {
    const {innerSvg, color} = this.props;
    return (
      <div className='relative flex justify-center items-center'>
        <SvgBackground color={color} />
        <div className='absolute'>
            {innerSvg}
        </div>
      </div>
    )
  }
}

export default SvgContainer
