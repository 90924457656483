import React, { Component } from 'react'
import Card2 from '../../../components/public_components/cards/Card2'
import SectionHeader from '../../../components/public_components/SectionHeader'
import { connect } from 'react-redux';
import { getPublicCountries } from '../../../actions/PublicActions';
import ViewAllBtn from '../../../components/public_components/ViewAllBtn';
import LoadMoreBtn from '../../../components/public_components/LoadMoreBtn';
export class CountriesSection extends Component {
    componentDidMount(){
        this.props.getPublicCountries(this.props.page, "true", )
    }

    loadMore=(page)=>{
        this.props.getPublicCountries(page, "true",)
    }
  render() {
    const {publicCountries, viewBtn=false, loadMoreBtn=false, pt="pt-[100px]"} = this.props;
    // console.log(publicCountries)
    return (
        <section className={`bg-white ${pt}`}>
            <div className='mt-[60px]'>
                <SectionHeader text1="İşlədiyimiz" text2="Ölkələr" />
            </div>
            <div className='max-w-[1200px] ml-auto mr-auto mt-[70px] pb-[80px] max-[1240px]:ml-[20px] max-[1240px]:mr-[20px]'>
                <div className='grid-full-220'>
                    {
                        [...publicCountries]?.map((data, i)=>{
                            return(
                                <Card2 
                                    key={i}
                                    data={data}
                                />
                            )
                        })
                    }
                </div>
                {
                    viewBtn?
                    <ViewAllBtn to="/countries" />:null
                }
                {
                    loadMoreBtn && publicCountries?.length >=20?
                    <LoadMoreBtn onLoadData={this.loadMore} count={publicCountries?.length} />:null
                }
            </div>
        </section>
    )
  }
}
const mapStateToProps = (state) =>({
    publicCountries:state.Data.publicCountries
});
const mapDispatchToProps = {getPublicCountries}
export default connect(mapStateToProps, mapDispatchToProps)(CountriesSection)
