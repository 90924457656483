import Swal from "sweetalert2";

export const bigAlert=(title, text, icon, time=2000, btnText="OK")=>{
    Swal.fire({
        title: title,
        html: text,
        icon: icon,
        confirmButtonColor:'var(--blue)',
        timer: time,
        confirmButtonText:btnText
      });
}
export const mixinAlert=(icon, title)=>{
    const Toast = Swal.mixin({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
      });
      Toast.fire({
        icon: icon,
        title:title
      });
}
export const confirmAlert=(icon, title, text, btnText="Yes, delete it!", cancelBtnTxt="Cancel")=>{
    return new Promise((res, rej)=>{
      Swal.fire({
        title:title,
        text: text,
        icon: icon,
        showCancelButton: true,
       
        cancelButtonColor: "var(--red)",
         confirmButtonColor: "var(--blue)",
        confirmButtonText: btnText,
        cancelButtonText:cancelBtnTxt
      }).then((result) => {
        if (result.isConfirmed) {
         res("success")
        }
      });
    })
}

export const imageAlert = (url, size=512)=>{
  Swal.fire({
    imageUrl: url,
    imageWidth:size,
    imageAlt: "A tall image",  
    confirmButtonColor: "#3085d6",
  });
}
