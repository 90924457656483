import React, { Component } from 'react'
import Header from './components/Header'
import Input from './components/Input'
import DropSearch from './components/DropSearch'
import Footer from './components/Footer'
import FileInput from './components/FileInput'
import { connect } from 'react-redux'
import { resetStateValue, changeStateValue } from '../../redux/MainReducer'
import { getCountries, getStates, getCities, getUniType, getUniversities, getEduDegree, getLanguages, getDisciplines } from '../../actions/MainAction'
import { getDropValue, getStudyMode } from '../../actions/getOtherFunctions'
import MultiSelect from './components/MultiSelect'
import MsgDeleteSvg from '../svg/MsgDeleteSvg'
import TextEditor from './components/TextEditor'
import TextArea from './components/TextArea'
import InputPhone from './components/InputPhone'
export class AdminModal extends Component {
    state={
        grade:'',
        schVisible:false,
        requiredVisible:false
    }
    closeModal=()=>{
        this.props.changeStateValue({name:"adminModal", value:{position:false}})
    }
    componentDidMount(){
        if(this.props.adminModal?.country_id || this.props.adminModal?.country_id==""){
            this.props.getCountries(1,'', 'all');
        }
        if(this.props.adminModal?.type_id || this.props.adminModal?.type_id==""){
            this.props.getUniType(1,'', 'all');
        }
        if(this.props.adminModal?.university_id || this.props.adminModal?.university_id==""){
            this.props.getUniversities(1,'', 'all');
        }
        if(this.props.adminModal?.degree_id || this.props.adminModal?.degree_id==""){
            this.props.getEduDegree();
        }
        if(this.props.adminModal?.language_id || this.props.adminModal?.language_id==""){
            this.props.getLanguages();
        }
        if(this.props.adminModal?.disciplines || this.props.adminModal?.disciplines?.length==0){
            this.props.getDisciplines(1, '', 'all');
        }
        if(this.props.adminModal?.state_id || this.props.adminModal?.state_id==""){
            this.props.changeStateValue({name:'states', value:[]})
            if(this.props.adminModal?.country_id && this.props.adminModal?.country_id !=""){
               this.props.getStates(1,'', 'all', this.props.adminModal?.country_id); 
            }
        }
        if(this.props.adminModal?.city_id || this.props.adminModal?.city_id==""){
            this.props.changeStateValue({name:'states', value:[]})
            if(this.props.adminModal?.state_id && this.props.adminModal?.state_id !=""){
                this.props.getCities(1,'', 'all',"", this.props.adminModal?.state_id); 
            }else if(this.props.adminModal?.country_id && this.props.adminModal?.country_id !=""){
               this.props.getCities(1,'', 'all', this.props.adminModal?.country_id); 
            }
           
        }
    }

    onSelectCountry=(id)=>{
        if(this.props.adminModal?.state_id || this.props.adminModal?.state_id==""){
            this.props.getStates(1,'', 'all', id); 
        }
        if(this.props.adminModal?.city_id || this.props.adminModal?.city_id==""){
            this.props.getCities(1,'', 'all', id); 
        }
    }
    onSelectState=(id)=>{
        if(this.props.adminModal?.city_id || this.props.adminModal?.city_id==""){
            this.props.getCities(1,'', 'all',"", id); 
        }
    }
    onSelectBox=(e)=>{
        this.props.changeStateValue({name:e.target.name, value:e.target.checked?'1':'0'})
    }

    addGradeBtn=(grade)=>{
        if(grade.trim() !==""){
            let grades = [...this.props.adminModal?.grades];
            grades.push(grade);
            this.props.changeStateValue({
                name:'adminModal.grades',
                value: grades
            });
            this.setState({grade:''});
        }
       
    }

    onDeleteGrade=(index, grades)=>{
        let arr = [...grades];
        arr.splice(index, 1)
        this.props.changeStateValue({
            name:'adminModal.grades',
            value: arr
        })
    }
  render() {
    const {adminModal, countries, states, cities, uniTypes, universities, educationDegree, languages, disciplines, subjectsData} = this.props;
    const {grade, requiredVisible, schVisible} = this.state;
    console.log(adminModal)
    return (
        <div className='background_shadow flex justify-center items-center'>
            <div className='bg-white w-[700px] max-h-[70vh] rounded-[10px] shadow'>
                <Header title={adminModal?.modalTitle} closeBtn={this.closeModal.bind(this)} />
                <div style={{ height:'auto', maxHeight: 'calc(70vh - 50px)' }} className='p-[20px] overflow-auto '>
                    <div  className='grid grid-cols-2 gap-[20px]'>
                        {adminModal?.first_name || adminModal?.first_name == "" ? <Input required name="adminModal.first_name" value={adminModal?.first_name} title={'Ad'}  />:null}
                        
                        {adminModal?.last_name || adminModal?.last_name == "" ? <Input required name="adminModal.last_name" value={adminModal?.last_name} title={'Soyad'}  />:null}
                        {adminModal?.email || adminModal?.email == "" ? <Input required type="email" name="adminModal.email" value={adminModal?.email} title={'Email'}  />:null}
                        {/* {adminModal?.date_of_birth || adminModal?.date_of_birth == "" ? <Input required type="date" name="adminModal.date_of_birth" value={adminModal?.date_of_birth} title={'Date of birth'}  />:null} */}
                        {adminModal?.phone_number || adminModal?.phone_number == "" ? <InputPhone required type="tel" name="adminModal.phone_number" value={adminModal?.phone_number} title={'Mobil nömrə'}  />:null}
                        {adminModal?.password || adminModal?.password == "" ? <Input type="password" name="adminModal.password" value={adminModal?.password} title={'Şifrə'}  />:null}
                        
                        
                        {adminModal?.title || adminModal?.title == "" ? <Input required name="adminModal.title" value={adminModal?.title} title={'Title'}  />:null}
                        {adminModal?.name || adminModal?.name == "" ? <Input required name="adminModal.name" value={adminModal?.name} title={'Name'}  />:null}
                        {adminModal?.ranking || adminModal?.ranking == "" ? <Input  name="adminModal.ranking" type="number" value={adminModal?.ranking} title={'Ranking'}  />:null}
                        {adminModal?.local_ranking || adminModal?.local_ranking == "" ? <Input  name="adminModal.local_ranking" type="number" value={adminModal?.local_ranking} title={'Local Ranking'}  />:null}
                      


                        {adminModal?.university_id || adminModal?.university_id == "" ? <DropSearch required name="adminModal.university_id"   value={adminModal.university_id} placeholder="Select a University" options={getDropValue(universities, "name","id")} title="Select a University" /> :null}
                        {adminModal?.degree_id || adminModal?.degree_id == "" ? <DropSearch required name="adminModal.degree_id"    value={adminModal.degree_id} placeholder="Select a Degree" options={getDropValue(educationDegree, "name","id")} title="Select a Degree" /> :null}
                        {adminModal?.language_id || adminModal?.language_id == "" ? <DropSearch required name="adminModal.language_id"    value={adminModal.language_id} placeholder="Select a Language" options={getDropValue(languages, "name","id")} title="Select a Language" /> :null}
                        {adminModal?.disciplines? <MultiSelect selected={adminModal.disciplines} placeholder={"Select a discipline"} options={getDropValue(disciplines, "name","id")} name="adminModal.disciplines" title={"Select a discipline"} />:null}
                        {adminModal?.start_date || adminModal?.start_date == "" ? <Input required name="adminModal.start_date" type="date" value={adminModal?.start_date} title={'Start Date'}  />:null}
                        {adminModal?.deadline || adminModal?.deadline == "" ? <Input  name="adminModal.deadline" type="date" value={adminModal?.deadline} title={'Deadline'}  />:null}
                        {adminModal?.duration || adminModal?.duration == "" ? <Input  name="adminModal.duration" type="number" value={adminModal?.duration} title={'Duration'}  />:null}
                        
                        {adminModal?.typeOption || adminModal?.typeOption == [] ? <DropSearch required name="adminModal.type"   value={adminModal.type} placeholder="Select a type" options={adminModal?.typeOption} title="Select a type" /> :null}
                        {adminModal?.min_value || adminModal?.min_value == "" ? <Input required name="adminModal.min_value" type="number" value={adminModal?.min_value} title={'Min value'}  />:null}
                        {adminModal?.max_value || adminModal?.max_value == "" ? <Input required  name="adminModal.max_value" type="number" value={adminModal?.max_value} title={'Max value'}  />:null}
                        {adminModal?.step || adminModal?.step == "" ? <Input required name="adminModal.step" type="number" value={adminModal?.step} title={'Step'}  />:null}
                        {adminModal?.bachelour_start || adminModal?.bachelour_start == "" ? <Input  name="adminModal.bachelour_start" type="text" value={adminModal?.bachelour_start} title={'Bachelour Start'}  />:null}
                        {adminModal?.master_start || adminModal?.master_start == "" ? <Input  name="adminModal.master_start" type="text" value={adminModal?.master_start} title={'Master Start'}  />:null}
                        {adminModal?.pre_master_start || adminModal?.pre_master_start == "" ? <Input  name="adminModal.pre_master_start" type="number" value={adminModal?.pre_master_start} title={'Pre Master Start'}  />:null}
                        {adminModal?.phd_start || adminModal?.phd_start == "" ? <Input  name="adminModal.phd_start" type="number" value={adminModal?.phd_start} title={'PHD Start'}  />:null}



                        {adminModal?.user_name || adminModal?.user_name == "" ? <Input required name="adminModal.user_name" value={adminModal?.user_name} title={'User name'}  />:null}
                        {adminModal?.data_position || adminModal?.data_position == "" ? <Input required name="adminModal.data_position" value={adminModal?.data_position} title={'User position'}  />:null}
                        {adminModal?.time || adminModal?.time == "" ? <Input required type="number" name="adminModal.time" value={adminModal?.time} title={'Time'}  />:null}


                        {adminModal?.city || adminModal?.city == "" ? <Input required type="text" name="adminModal.city" value={adminModal?.city} title={'City'}  />:null}
                        {adminModal?.university || adminModal?.university == "" ? <Input required type="text" name="adminModal.university" value={adminModal?.university} title={'University'}  />:null}
                      
                        {adminModal?.subject_id || adminModal?.subject_id == [] ? <DropSearch required name="adminModal.subject_id"   value={adminModal.subject_id} placeholder="Select a subject" options={getDropValue(subjectsData, "title", "id")} title="Select a subject" /> :null}

                        {adminModal?.scholarship_option || adminModal?.scholarship_option == "" ? 
                        // <Input  name="adminModal.scholarship_option" type="checkbox" value={adminModal?.scholarship_option} title={'Scholarship option'}  />
                        <div className='mt-[20px] flex items-center gap-[10px]'>
                            <input  checked={adminModal.scholarship_option=='0'?false:true} name="adminModal.scholarship_option" onChange={this.onSelectBox.bind(this)} type='checkbox' id={'adminModal.scholarship_option'} />
                            <label className='cursor-pointer' htmlFor='adminModal.scholarship_option'>Scholarship option</label>
                        </div>
                        :null}
                        
                        {adminModal?.country_id || adminModal?.country_id == "" ? <DropSearch required name="adminModal.country_id" selectData={this.onSelectCountry.bind(this)}  value={adminModal.country_id} placeholder="Select a Country" options={getDropValue(countries, "name","id")} title="Select a Country" /> :null}
                        {adminModal?.state_id || adminModal?.state_id == "" ? <DropSearch name="adminModal.state_id"   selectData={this.onSelectState.bind(this)} value={adminModal.state_id} placeholder="Select a State" options={getDropValue(states, "name","id")} title="Select a State" /> :null}
                        {adminModal?.city_id || adminModal?.city_id == "" ? <DropSearch required name="adminModal.city_id"  value={adminModal.city_id} placeholder="Select a City" options={getDropValue(cities, "name","id")} title="Select a City" /> :null}
                        {adminModal?.type_id || adminModal?.type_id == "" ? <DropSearch required name="adminModal.type_id"  value={adminModal.type_id} placeholder="Select a type" options={getDropValue(uniTypes, "name","id")} title="Select a type" /> :null}
                        {adminModal?.image ||  adminModal?.image == "" ? <FileInput type="image" name="adminModal.image" title="Şəkil" valueName={'adminModal.image_full_url'} value={adminModal?.image_full_url} />:null}
                        {adminModal?.flag ||  adminModal?.flag == "" ? <FileInput type="image" name="adminModal.flag" title="Flag" valueName={'adminModal.flag_full_url'} value={adminModal?.flag_full_url} />:null}
                  
                        {adminModal?.study_mode || adminModal?.study_mode == "" ? <DropSearch required name="adminModal.study_mode"  value={adminModal.study_mode} placeholder="Select a study mode" options={getStudyMode()} title="Select a study mode" /> :null}
                    </div>
                    {
                        adminModal?.requirements?
                        <div className='border p-[10px] rounded-[20px] mt-[20px]'>
                        <span onClick={()=>this.setState({requiredVisible: !requiredVisible})} className='text-[16px]  inline-block w-full cursor-pointer'>Requiements</span>
                        {
                            requiredVisible?
                            <div  className='grid grid-cols-2 gap-[20px]'>
                            {adminModal?.requirements?.required_education_degree_id || adminModal?.requirements?.required_education_degree_id == "" ? <DropSearch  name="adminModal.requirements.required_education_degree_id"    value={adminModal?.requirements?.required_education_degree_id} placeholder="Select a required Degree" options={getDropValue(educationDegree, "name","id")} title="Select a required Degree" /> :null}
                            {
                                adminModal?.requirements?.required_education_degree_id===1 || adminModal?.requirements?.required_education_degree_id===2?
                                <>
                                    {adminModal?.requirements?.gpa || adminModal?.requirements?.gpa == "" ? <Input type="number" name="adminModal.requirements.gpa" value={adminModal?.requirements?.gpa} title={'Atestat'}  />:null}
                                    {adminModal?.requirements?.ib || adminModal?.requirements?.ib == "" ? <Input type="number" name="adminModal.requirements.ib" value={adminModal?.requirements?.ib} title={'IB'}  />:null}
                                    {adminModal?.requirements?.a_level || adminModal?.requirements?.a_level == "" ? <Input  name="adminModal.requirements.a_level" value={adminModal?.requirements?.a_level} title={'A Level'}  />:null}
                                    {adminModal?.requirements?.ap || adminModal?.requirements?.ap == "" ? <Input type="number" name="adminModal.requirements.ap" value={adminModal?.requirements?.ap} title={'AP'}  />:null}
                                    {adminModal?.requirements?.ossd || adminModal?.requirements?.ossd == "" ? <Input type="number" name="adminModal.requirements.ossd" value={adminModal?.requirements?.ossd} title={'OSSD'}  />:null}
                                    {adminModal?.requirements?.foundation || adminModal?.requirements?.foundation == "" ? <Input type="number" name="adminModal.requirements.foundation" value={adminModal?.requirements?.foundation} title={'Foundation'}  />:null}
                                </>:
                                <>
                                {adminModal?.requirements?.gpa || adminModal?.requirements?.gpa == "" ? <Input type="number" name="adminModal.requirements.gpa" value={adminModal?.requirements?.gpa} title={'GPA'}  />:null}
                                </>
                            }
                           
                            {adminModal?.requirements?.ielts || adminModal?.requirements?.ielts == "" ? <Input type="number" name="adminModal.requirements.ielts" value={adminModal?.requirements?.ielts} title={'IELTS'}  />:null}
                            {adminModal?.requirements?.toefl || adminModal?.requirements?.toefl == "" ? <Input type="number" name="adminModal.requirements.toefl" value={adminModal?.requirements?.toefl} title={'TOEFL'}  />:null}
                            {adminModal?.requirements?.gre || adminModal?.requirements?.gre == "" ? <Input type="number" name="adminModal.requirements.gre" value={adminModal?.requirements?.gre} title={'GRE'}  />:null}
                            {adminModal?.requirements?.gmat || adminModal?.requirements?.gmat == "" ? <Input type="number" name="adminModal.requirements.gmat" value={adminModal?.requirements?.gmat} title={'GMAT'}  />:null}
                            {adminModal?.requirements?.sat || adminModal?.requirements?.sat == "" ? <Input type="number" name="adminModal.requirements.sat" value={adminModal?.requirements?.sat} title={'SAT'}  />:null}
                            {adminModal?.requirements?.dim || adminModal?.requirements?.dim == "" ? <Input type="number" name="adminModal.requirements.dim" value={adminModal?.requirements?.dim} title={'DIM'}  />:null}
                            {adminModal?.requirements?.ielts_ukvi || adminModal?.requirements?.ielts_ukvi == "" ? <Input type="number" name="adminModal.requirements.ielts_ukvi" value={adminModal?.requirements?.ielts_ukvi} title={'IELTS UKVI'}  />:null}
                        </div>:null
                        }
                       
                        </div>:null
                    }
                    {
                        adminModal?.scholarship?
                        <div className='border p-[10px] rounded-[20px] mt-[20px]'>
                        <span onClick={()=>this.setState({schVisible: !schVisible})} className='text-[16px] inline-block w-full cursor-pointer'>Scholarship</span>
                        {
                            schVisible?
                               <div  className='grid grid-cols-2 gap-[20px]'>
                            {adminModal?.scholarship?.required_education_degree_id || adminModal?.scholarship?.required_education_degree_id == "" ? <DropSearch  name="adminModal.scholarship.required_education_degree_id"    value={adminModal?.scholarship?.required_education_degree_id} placeholder="Select a required Degree" options={getDropValue(educationDegree, "name","id")} title="Select a required Degree" /> :null}
                            {adminModal?.scholarship?.amount || adminModal?.scholarship?.amount == "" ? <Input  name="adminModal.scholarship.amount" value={adminModal?.scholarship?.amount} title={'Amount'}  />:null}
                            {
                                adminModal?.scholarship?.required_education_degree_id===1 || adminModal?.scholarship?.required_education_degree_id===2?
                                <>
                                    {adminModal?.scholarship?.gpa || adminModal?.scholarship?.gpa == "" ? <Input type="number" name="adminModal.scholarship.gpa" value={adminModal?.scholarship?.gpa} title={'Atestat'}  />:null}
                                    {adminModal?.scholarship?.ib || adminModal?.scholarship?.ib == "" ? <Input type="number" name="adminModal.scholarship.ib" value={adminModal?.scholarship?.ib} title={'IB'}  />:null}
                                    {adminModal?.scholarship?.a_level || adminModal?.scholarship?.a_level == "" ? <Input  name="adminModal.scholarship.a_level" value={adminModal?.scholarship?.a_level} title={'A Level'}  />:null}
                                    {adminModal?.scholarship?.ap || adminModal?.scholarship?.ap == "" ? <Input type="number" name="adminModal.scholarship.ap" value={adminModal?.scholarship?.ap} title={'AP'}  />:null}
                                    {adminModal?.scholarship?.ossd || adminModal?.scholarship?.ossd == "" ? <Input type="number" name="adminModal.scholarship.ossd" value={adminModal?.scholarship?.ossd} title={'OSSD'}  />:null}
                                    {adminModal?.scholarship?.foundation || adminModal?.scholarship?.foundation == "" ? <Input type="number" name="adminModal.scholarship.foundation" value={adminModal?.scholarship?.foundation} title={'Foundation'}  />:null}
                                </>:
                                <>
                                {adminModal?.scholarship?.gpa || adminModal?.scholarship?.gpa == "" ? <Input type="number" name="adminModal.scholarship.gpa" value={adminModal?.scholarship?.gpa} title={'GPA'}  />:null}
                                </>
                            }
                           
                            {adminModal?.scholarship?.ielts || adminModal?.scholarship?.ielts == "" ? <Input type="number" name="adminModal.scholarship.ielts" value={adminModal?.scholarship?.ielts} title={'IELTS'}  />:null}
                            
                            {adminModal?.scholarship?.toefl || adminModal?.scholarship?.toefl == "" ? <Input type="number" name="adminModal.scholarship.toefl" value={adminModal?.scholarship?.toefl} title={'TOEFL'}  />:null}
                            {adminModal?.scholarship?.gre || adminModal?.scholarship?.gre == "" ? <Input type="number" name="adminModal.scholarship.gre" value={adminModal?.scholarship?.gre} title={'GRE'}  />:null}
                            {adminModal?.scholarship?.gmat || adminModal?.scholarship?.gmat == "" ? <Input type="number" name="adminModal.scholarship.gmat" value={adminModal?.scholarship?.gmat} title={'GMAT'}  />:null}
                            {adminModal?.scholarship?.sat || adminModal?.scholarship?.sat == "" ? <Input type="number" name="adminModal.scholarship.sat" value={adminModal?.scholarship?.sat} title={'SAT'}  />:null}
                            {adminModal?.scholarship?.dim || adminModal?.scholarship?.dim == "" ? <Input type="number" name="adminModal.scholarship.dim" value={adminModal?.scholarship?.dim} title={'DIM'}  />:null}
                            {adminModal?.scholarship?.ielts_ukvi || adminModal?.scholarship?.ielts_ukvi == "" ? <Input type="number" name="adminModal.scholarship.ielts_ukvi" value={adminModal?.scholarship?.ielts_ukvi} title={'IELTS UKVI'}  />:null}
                        </div>:null
                        }
                     
                        </div>:null
                    }
                    <div className='mt-[20px]'>
                        {
                            adminModal?.text || adminModal?.text == ""? <TextEditor title="Description" name="adminModal.text" value={adminModal?.text} />:null
                        }
                    </div>
                    <div className='mt-[20px]'>
                        {
                            adminModal?.review || adminModal?.review == ""? <TextEditor title="Description" name="adminModal.review" value={adminModal?.review} />:null
                        }
                    </div>
                    <div className='mt-[20px]'>
                        {
                            adminModal?.description || adminModal?.description == ""? <TextArea title="Description" name="adminModal.description" value={adminModal?.description} />:null
                        }
                    </div>
                    <div>
                        {
                            adminModal?.grades || adminModal?.grades == []?
                            <div className='mt-[20px]'>
                                <div className='flex gap-[20px]'>
                                    <input className='bg-gray-100 p-[3px] h-[25px] rounded-[5px] text-[13px] outline-none' placeholder='grade' value={grade} onChange={(e)=>this.setState({grade:e.target.value})} />
                                    <button onClick={this.addGradeBtn.bind(this,grade )} className='bg-blue h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>+ Add</button>
                                </div>
                                <ul className='mt-[20px] flex flex-col gap-[10px]'>
                                {
                                    adminModal?.grades?.map((item, j)=>{
                                        return(
                                            <li className='text-[14px] flex gap-[10px]' key={j}>{item} <button onClick={this.onDeleteGrade.bind(this, j, adminModal?.grades)}><MsgDeleteSvg /></button></li>
                                        )
                                    })
                                }
                                </ul>
                            </div>:null
                        }
                    </div>
                    <Footer closeBtn={this.closeModal.bind(this)} data={adminModal} />
                </div>
            </div>
        </div>
    )
  }
}

const mapStateToProps = (state) =>({
    adminModal: state.Data.adminModal,
    countries: state.Data.countries,
    states: state.Data.states,
    uniTypes: state.Data.uniTypes,
    cities: state.Data.cities,
    universities: state.Data.universities,
    educationDegree: state.Data.educationDegree,
    languages: state.Data.languages,
    disciplines: state.Data.disciplines,
    subjectsData: state.Data.subjectsData,
});
const mapDispatchToProps = {changeStateValue, resetStateValue, getCountries, getStates, getCities, getUniType, getUniversities, getEduDegree, getLanguages, getDisciplines}
export default connect(mapStateToProps, mapDispatchToProps)(AdminModal)
