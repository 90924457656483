import React, { Component } from 'react'
import Header from '../../components/header/Header'
import Search from '../../components/Search'
import Buttons from '../../components/Buttons'
import { connect } from 'react-redux'
import { changeStateValue } from '../../redux/MainReducer';
import { getSTAF } from '../../actions/MainAction';
import Card1 from '../../components/cards/Card1'
import LoadMoreBtn from '../../components/LoadMoreBtn'
import defUserImg from "../../images/def_user.png"

export class Teachers extends Component {
  state={
    page:1,
    search:''
  }
  openModal=(data)=>{
    this.props.changeStateValue({
      name:'adminModal',
      value:{
        position: true,
        id: data?.id?data?.id:'',
        first_name: data?.first_name?data?.first_name:'',
        last_name: data?.last_name?data?.last_name:'',
        email: data?.email?data?.email:'',
        password: data?.password?data?.password:'',
        date_of_birth: data?.date_of_birth?data?.date_of_birth:'',
        phone_number: data?.phone_number?data?.phone_number:'',
        image: data?.image?data?.image:'',
        role: 'teacher',
        image_full_url: data?.image_full_url?data?.image_full_url:'',
        afterCallFunction:this.props.getSTAF,
        afterCallParams:[this.state.page, this.state.search, "teacher"],
        requireds: ["first_name", "last_name", "email"],
        url:'users',
        modalTitle:`${data?.id?'Məlumatları yenilə':'Yeni təhsil nümayəndəsi'}`
      }
    })
  }

  componentDidMount(){
    this.props.getSTAF(1, '', 'teacher')
  }

  onSearchData=(search)=>{
    this.setState({search})
    this.props.getSTAF(this.state.page, search, 'teacher')
  }
  loadMore=(page)=>{
    this.setState({page})
    this.props.getSTAF(page, this.state.search, 'teacher')
  }

  afterCallFunction=()=>{
    this.props.getSTAF(this.state.page, this.state.search, 'teacher')
  }
  render() {
    const {staff} = this.props;
    return (
        <div className='w-full  pl-[27px] pr-[27px]'>
            <Header title="Təhsil Nümayəndəsi" />
            <div className='flex justify-end mt-[30px]'>
                <Buttons onClick={this.openModal.bind(this, '')} options={[{title:"Yeni Təhsil Nümayəndəsi", value:0}]} padding={true} selected={0} />
            </div>
            <div className='flex justify-center mt-[20px]'>
                <Search onSearchData={this.onSearchData.bind(this)} largWidth={true} />
            </div>

            <div className='grid-full-250 mt-[30px]'>
              {
                staff?.map((data, i)=>{
                  return(
                    <Card1 
                      key={i}
                      image={data?.image_full_url? data?.image_full_url:defUserImg}
                      name={data?.full_name}
                      onClickEdit={this.openModal.bind(this, data)}
                      // deletePath={`users/${data?.id}`}
                      afterCallFunction={this.afterCallFunction.bind(this)}
                      username={"username: "+data?.username}
                      moreLink={`/teacher/${data?.id}`}
                    />
                  )
                })
              }
            </div>
            {
                staff?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={staff?.length} />:null
            }
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
  staff: state.Data.staff
});
const mapDispatchToProps = {changeStateValue, getSTAF}
export default connect(mapStateToProps, mapDispatchToProps)(Teachers)






