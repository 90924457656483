import React, { Component } from 'react'
import CheckSvg from '../../../../components/svg/CheckSvg'
import CheckSvg2 from '../../../../components/public_components/svg/CheckSvg2'
import ProfileCheckSvg from '../../../../components/svg/ProfileCheckSvg'

export class TrackingCard extends Component {
  render() {
    const {title, options=[], onChangeStatus=()=>{}} = this.props;
    return (
      <div className='bg-white p-[20px] shadow rounded-[10px]'>
        <h2 className='text-[var(--blue)] text-[20px] font-semibold'>{title}</h2>
        <div className='mt-[20px] flex flex-col max-h-[400px] overflow-auto'>
            {
                options?.map((data, i)=>{
                    return(
                        <div key={i} >
                             <button onClick={()=>onChangeStatus(data?.id)} className='flex items-center gap-[5px]'>
                                <ProfileCheckSvg color={data?.student_tracking? "#16803D":"gray"} />
                                <span className={`font-[500] text-[14px] ${data?.student_tracking? "text-green-700":"text-gray-500"} text-start`}>{data?.title}</span>
                            </button>
                            {
                                i !== options.length-1?
                                <span className={`h-[30px] w-[7px] ${data?.student_tracking?"bg-green-100":"bg-[#ededed]"} inline-block ml-[4.5px] mt-[-2px] mb-[-10px]`}></span>:null
                            }
                           
                        </div>
                    )
                })
            }
        </div>
      </div>
    )
  }
}

export default TrackingCard
