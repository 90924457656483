import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue } from '../../../redux/MainReducer';
import { getStudentExpences, deleteData } from '../../../actions/MainAction';
import CardEditSvg from '../../../components/svg/CardEditSvg';
import DeleteSvg from '../../../components/svg/DeleteSvg';
import { confirmAlert, mixinAlert } from '../../../actions/SweetAlerts';
export class StudentExpences extends Component {
    addBtn=(data, e)=>{
        this.props.changeStateValue({
            name:"studentExpences",
            value:{
                position: true,
                id:data?.id?data?.id:'',
                currency_id:data?.currency_id?data?.currency_id:'',
                category_id:data?.category_id?data?.category_id:'',
                apply_id:'',
                amount:data?.amount?data?.amount:'',
                student_id: this.props.student_id,
                program_id: this.props.program_id,
                modalTitle:`${data?.id?"Edit":"Add"} Expences`
            }
        })
    }

    componentDidMount(){
        this.props.getStudentExpences(this.props.student_id)
    }

    getTotal(data){
        let total = 0;
        for(const x of data){
            total+= +x.amount;
        }
        return total;
    }

    deleteBtn=(id)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, delete it")
        .then(alertResp=>{
          if(alertResp === "success"){
            this.props.deleteData(`student-applied-expences/${id}`)
            .then(resp=>{
              if(resp == "success"){
                this.props.getStudentExpences(this.props.student_id)
                mixinAlert("success", "Data deleted successfully")
              }else{
                mixinAlert("error", "Something went wrong")
              }
            })
          }
        })
    }
  render() {
    const { user, studentExpencesData} = this.props;
    // console.log(studentExpencesData)
    return (
        <div className='mt-[20px] bg-white p-[20px] shadow rounded-[10px]'>
            {
                user?.role === "teacher"?
                <div className='flex justify-end'>
                    <button onClick={this.addBtn.bind(this, "")} className='bg-blue-700 p-[2px_5px] text-[12px] text-white rounded-[5px]'>Add</button>
                </div> :null
            }
           
            <table className='w-full mt-[10px]'>
                <thead className='border'>
                    <tr>
                        <th className='text-start border-r w-1/3 p-[10px_20px]'>Expences category</th>
                        <th className='text-start border-r w-1/3 p-[10px_20px]'>Amount</th>
                        <th className='text-start border-r w-1/3 p-[10px_20px]'>Currency</th>
                        <th className='text-start border-r w-1/3 p-[10px_20px]'>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        studentExpencesData?.map((data, i)=>{
                            return(
                                <tr key={i}>
                                    <td className='border p-[10px_20px]'>{data?.category?.title}</td>
                                    <td className='border p-[10px_20px]'>{data?.amount}</td>
                                    <td className='border p-[10px_20px]'>{data?.currency?.name}</td>
                                    <td className='border p-[10px_20px] inline-flex justify-center w-full gap-[10px]'>
                                        <button onClick={this.addBtn.bind(this, data)} className='bg-blue-100 w-[25px] h-[25px] inline-flex justify-center items-center rounded-[5px]'>
                                            <CardEditSvg />
                                        </button>
                                        <button onClick={this.deleteBtn.bind(this, data?.id)} className='bg-red-100 w-[25px] h-[25px] inline-flex justify-center items-center rounded-[5px]'>
                                            <DeleteSvg />
                                        </button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            <div className='flex justify-end mt-[10px]'>
                <h3 className='font-semibold'>Total: {this.getTotal(studentExpencesData)}</h3>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    studentExpences: state.Data.studentExpences,
    studentExpencesData: state.Data.studentExpencesData,
    user: state.Data.user,
});
const mapDispatchToProps = {changeStateValue, getStudentExpences, deleteData}
export default connect(mapStateToProps, mapDispatchToProps)(StudentExpences)
