import React, { Component } from 'react'

export class ExclamationSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        width={17}
        height={17}
        viewBox="0 0 512 512"
        fill="#DC3545"
      >
        <path d="M256 0C114.609 0 0 114.609 0 256s114.609 256 256 256 256-114.609 256-256S397.391 0 256 0zm0 472c-119.297 0-216-96.703-216-216S136.703 40 256 40s216 96.703 216 216-96.703 216-216 216z" />
        <path d="M240 234.656 246.234 320h19.547L272 233.875V128h-32zM240 352h32v32h-32z" />
      </svg>
    )
  }
}

export default ExclamationSvg
