import React, { Component } from 'react'

export class InpCheckSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={17}
        height={17}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="#198754"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M4 12.611 8.923 17.5 20 6.5"
        />
      </svg>
    )
  }
}

export default InpCheckSvg
