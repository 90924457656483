import React, { Component } from 'react'
import RankingSvg from '../svg/RankingSvg'
import LocationSvg from '../svg/LocationSvg'
import CardRightSvg from '../svg/CardRightSvg'
import { Link } from 'react-router-dom'
import CardDegreeSvg from '../svg/CardDegreeSvg'
import { changeStateValue } from '../../../redux/MainReducer';
import { connect } from 'react-redux'
import UploadFileSvg from '../../svg/UploadFileSvg'
import OpenFileSvg from '../../svg/OpenFileSvg'
import MsgDeleteSvg from '../../svg/MsgDeleteSvg'
import defImg from "../../../images/defImg.jpg"
export class Card5 extends Component {

  openTextModal=(text)=>{
    this.props.changeStateValue({
      name:'seeTextModalData',
      value:{
          position:true,
          text:text
      }
  })
  }
  checkApplyAvailable=(data, registerType)=>{
  
    if(this.props.type == "program"){
      let disciplines = [];
      for(const x of data?.disciplines){
        disciplines.push(x?.discipline_id)
      }
      const params = {
        page:"edu_back",
        countries:[data?.university?.country_id],
        disciplines:disciplines,
        educationBackground:{
            country_id:'1',
            edu_degree_id:'',
            sub_edu_degree_id:'',
            grade:'',
            a_level:'',
            ap:'',
            ib:'',
            ossd:'',
            foundation:''
        },
        information:{
            first_name:'',
            last_name:'',
            phone_number:''
        },
        registerType:registerType
      }
      this.props.changeStateValue({name:'applyDetails', value:params})
    }else{
      const params = {
        page:"discipline",
        countries:[data?.country_id],
        disciplines:[],
        educationBackground:{
            country_id:'1',
            edu_degree_id:'',
            sub_edu_degree_id:'',
            grade:'',
            a_level:'',
            ap:'',
            ib:'',
            ossd:'',
            foundation:''
        },
        information:{
            first_name:'',
            last_name:'',
            phone_number:''
        },
        registerType:registerType
      }
      this.props.changeStateValue({name:'applyDetails', value:params})
    }
    this.props.changeStateValue({name:'applyDetails.registerType', value:registerType})
    this.props.changeStateValue({name:'admissionModal.position', value:true})
  }
  render() {
    const {data, path, image="",ranking="", name="", 
          country="", city="", degree="", university="", 
            view=false, btn=false, onClickBtn=()=>{}, 
            btnText="", btnDisable=false, blank=false, 
            footer=true, text="", applyStatus="", 
            addFilesBtn=false, oploadFile=()=>{}, files=[],
            onDeleteFile=()=>{}, user, acceptRejectBtn=()=>{}, 
            acceptRejectBtnVisible=false, index, start_fee=0, start_fee_position=false, registerType="normal", rankTxt="Ölkə üzrə"} = this.props;
    //  console.log(image)
    return (
      <div  className=' p-[15px] rounded-[10px] border'>
        <Link to={path} target={blank?'_blank':''} className='relative'>
            {/* <span className='bg-[#1E398F] p-[5px_12px] rounded-[10px] h-[30px] text-[14px] text-white font-[300] absolute top-[14px] left-[14px]'>59,225 USD</span> */}
           {
            image!=""?
            <img className='w-[221px] h-[221px] rounded-full ml-auto mr-auto' src={image? image:defImg}/>:null
           }
           {
            applyStatus===0||applyStatus !=""?
            <div className='flex'>
              <p className={` text-[12px] p-[0_4px] rounded-[4px] ${applyStatus===0?"bg-orange-300 text-orange-700": applyStatus===1?"bg-green-300 text-green-700": applyStatus===2?"bg-red-300 text-red-700":"bg-indigo-300 text-indigo-700"}`}>{applyStatus===0?"Missing": applyStatus===1?"Completed": applyStatus===2?"Not accepted":"Reviewing"}</p>
            </div>
            :null
           }
        </Link>
        <div>
            <Link to={path} target={blank?'_blank':''}><h3 className='text-[15px] font-semibold mt-[13px] text-center'>{name}</h3></Link>
            {
              text !=""?
              <div className='flex justify-between gap-[10px]'>
                <p className='text-[12px] text-gray-600 h-[20px] overflow-hidden' dangerouslySetInnerHTML={{ __html:  text}}></p>
                <button className='text-[13px] min-w-[80px] text-blue-400' onClick={this.openTextModal.bind(this, text)}>Daha çox</button>
              </div>:null
            }
            
            <div className='flex items-center justify-center gap-[15px] mt-[17px]'>
              {
                ranking!=""?
                <span className='inline-flex gap-[9px] text-[11px] text-[#7D7D7D] font-[300]'>
                    <RankingSvg />
                    {rankTxt} : {ranking}
                </span> :null
              }
              {
                degree!=""?
                <span className='inline-flex gap-[9px] text-[11px] text-[#7D7D7D] font-[300]'>
                    <CardDegreeSvg />
                    {degree}
                </span> :null
              }
                {
                  country !="" && city!=""?
                  <span className='inline-flex gap-[9px] text-[11px] text-[#7D7D7D] font-[300]'>
                    <LocationSvg />
                    {country} / {city}
                </span>:null
                }
                {
                 university!=""?
                  <span className='inline-flex gap-[9px] text-[11px] text-[#7D7D7D] font-[300]'>
                    <LocationSvg />
                    {university}
                </span>:null
                }
                {
                 start_fee_position?
                  <span className='inline-flex gap-[9px] text-[11px] text-[#7D7D7D] font-[300]'>İllik təhsil haqqı: {start_fee}</span>:null
                }
            </div>
            {
              addFilesBtn?
              <div className='flex items-center justify-between'>
                  <div className='flex items-start gap-[6px]'>
                    {
                      files.map((item, i)=>{
                        return(
                          <div key={i} className='group' >
                              <a download={true} target={'_blank'} href={item?.file_full_url} >
                                <OpenFileSvg />
                              </a>
                             {
                             ( user?.role === "teacher" ||  user?.role === "student") && applyStatus===3?
                              <button onClick={()=>onDeleteFile(item)} className='hidden group-hover:inline-block cursor-pointer'>
                              <MsgDeleteSvg />
                             </button>:null
                             } 
                          </div>
                        )
                      })
                    }
                  </div>
                  {
                   ( user?.role === "teacher" ||  user?.role === "student") &&( applyStatus===3 ||  applyStatus===0)?
                    <>
                    <label htmlFor={`StudentCardUploadFile${index}`} className='cursor-pointer'><UploadFileSvg /> </label>
                    <input multiple accept='image/png, image/jpg, image/jpeg, image/webp, application/pdf' onChange={(e)=>oploadFile(e)} className='hidden' type='file' id={`StudentCardUploadFile${index}`} />
                    </>:null
                  }
              </div>:null
            }
            {
              acceptRejectBtnVisible && user?.role === 'xtm'?
              <div className='flex items-center justify-between mt-[10px]'>
                {
                  applyStatus !==1?
                  <button onClick={()=>acceptRejectBtn("1")} className='bg-green-700 text-[12px] p-[2px_5px] text-white rounded-[5px]'>Accept</button>:null
                }
                {
                  applyStatus !==2?
                  <button onClick={()=>acceptRejectBtn("2")} className='bg-red-700 text-[12px] p-[2px_5px] text-white rounded-[5px]'>Reject</button>:null
                }
                
              </div>:null
            }
            
            {
              footer?
              <>
              <div className='border-b border-dashed border-black mt-[16px]'></div>
              <div className='mt-[13px] flex justify-between items-center'>
                  <span className='text-[16px] font-medium'>Starting from: {start_fee}</span>
                
                  <div className='flex items-center gap-[10px]'>
                    {
                      btn?
                      <button disabled={btnDisable} onClick={()=>onClickBtn()} className='bg-[#1E398F] text-[10px] text-white rounded-full font-regular p-[5px_10px]'>{btnText}</button>:null
                    }
                    {
                      view?
                      <Link to={path} target={blank?'_blank':''}  className='inline-flex w-[20px] h-[20px] rounded-full justify-center items-center bg-[#1E398F]'>
                        <CardRightSvg />
                      </Link>:null
                    }
                  </div>
              </div>
              </>:null
            }
            <div className='flex justify-center'>
              <button onClick={this.checkApplyAvailable.bind(this, data, registerType)} className='bg-[var(--blue)] mt-[20px] h-[30px] text-[12px] w-[140px] rounded-full text-white font-semibold'>Qəbul şansını yoxla</button>
            </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  user: state.Data.user
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(Card5)
