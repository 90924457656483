import React, { Component } from 'react'
import { connect } from 'react-redux';

export class HeaderBtns extends Component {
  state={
    selected:'available_programs'
  }
  changePage=(page) => {
    this.props.onChangePage(page);
    this.setState({selected:page})
  }
  render() {
    const {selected} = this.state;
    const {user} = this.props;
    return (
      <div className='flex gap-[20px] items-center overflow-auto'>
        <button onClick={this.changePage.bind(this, "available_programs")} className={`bg-white min-w-[140px] text-[14px] font-regular text-gray-800 p-[8px_14px] shadow rounded-[8px] ${selected === "available_programs"?"border border-blue-700 text-blue-900":""}`}>Təklif olunanlar</button>
        <button onClick={this.changePage.bind(this, "applied_programs")} className={`bg-white min-w-[150px] text-[14px] font-regular text-gray-800 p-[8px_14px] shadow rounded-[8px] ${selected === "applied_programs"?"border border-blue-700 text-blue-900":""}`}>Müraciət olunanlar</button>
        <button onClick={this.changePage.bind(this, "wish_list")} className={`bg-white min-w-[140px] text-[14px] font-regular text-gray-800 p-[8px_14px] shadow rounded-[8px] ${selected === "wish_list"?"border border-blue-700 text-blue-900":""}`}>Program sorğusu</button>
        <button onClick={this.changePage.bind(this, "message")} className={`bg-white min-w-[110px] text-[14px] font-regular text-gray-800 p-[8px_14px] shadow rounded-[8px] ${selected === "message"?"border border-blue-700 text-blue-900":""}`}>Mesajlaşma</button>
        {
          user?.role == "xtm" || user?.role == "manager"?
          <button onClick={this.changePage.bind(this, "info")} className={`bg-white min-w-[140px] text-[14px] font-regular text-gray-800 p-[8px_14px] shadow rounded-[8px] ${selected === "info"?"border border-blue-700 text-blue-900":""}`}>Ümumi məlumat</button>:null
        }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  user: state.Data.user
})
export default connect(mapStateToProps)(HeaderBtns)
