import React, { Component } from 'react'
import NewsLetterSection from './sections/NewsLetterSection'
import Footer from './sections/Footer'
import SectionHeader from '../../components/public_components/SectionHeader'
import { connect } from 'react-redux'
import { getBlogs } from '../../actions/MainAction'
import MostRecentBlogSection from './sections/MostRecentBlogSection'
export class BlogPage extends Component {
    componentDidMount(){
        this.props.getBlogs();
        window.scrollTo(0,0)
    }
  render() {
    const {blogs} = this.props;
    // console.log(blogs)
    return (
        <div className='mt-[40px]  bg-[#F6F8FB]'>
            <div className='pb-[30px] pt-[40px] bg-white'>
                <div className='mt-[40px]'>
                    <SectionHeader text1="Our" text2="Blogs" />
                </div>
               <MostRecentBlogSection blogs={blogs} />
            </div>
            {/* <NewsLetterSection /> */}
            <Footer />
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    blogs: state.Data.blogs
});
const mapDispatchToProps = {getBlogs}
export default connect(mapStateToProps, mapDispatchToProps)(BlogPage)
