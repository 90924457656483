import React, { Component } from 'react'
import Header from './components/Header'
import { resetStateValue } from '../../redux/MainReducer';
import { connect } from 'react-redux';

export class StudenMoreInfoModal extends Component {

    closeBtn=()=>{
        this.props.resetStateValue({
            name:"studentMoreInfoModalData"
        })
    }
  render() {
    const {studentMoreInfoModalData} = this.props;
    // console.log(studentMoreInfoModalData)
    return (
      <div className='background_shadow flex justify-center items-center'>
        <div className='w-[400px] bg-white rounded-[10px]'>
            <Header closeBtn={this.closeBtn.bind(this)} title="Student info" />
            <div className='p-[10px]'>
                <ul className='flex flex-col gap-[8px]'>
                    <li className='flex items-center gap-[6px]'>
                        <span className='font-[600]'>Email:</span>
                        <span>{studentMoreInfoModalData?.email}</span>
                    </li>
                    <li className='flex items-center gap-[6px]'>
                        <span className='font-[600]'>Phone number:</span>
                        <span>{studentMoreInfoModalData?.phone_number}</span>
                    </li>
                    <li className='flex items-center gap-[6px]'>
                        <span className='font-[600]'>Register type:</span>
                        <span>{studentMoreInfoModalData?.register_type}</span>
                    </li>
                    <li className='flex items-center gap-[6px]'>
                        <span className='font-[600]'>Register date:</span>
                        <span>{studentMoreInfoModalData?.registerDate}</span>
                    </li>
                </ul>
            </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    studentMoreInfoModalData: state.Data.studentMoreInfoModalData
});
const mapDispatchToProps = {resetStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(StudenMoreInfoModal)
