export const getDropValue = (array, label, value) => {
    let options = [];
    for(const data of array){
        options.push({
            label: data[label],
            value: data[value]
        })
    }
    return options;
}

export const getProgramsData={
    id:'',
    name: '',
    university_id: '',
    degree_id: '',
    language_id:'',
    start_date: '',
    deadline: '',
    duration:'',
    scholarship_option: '0',
    study_mode:'1',
    disciplines: [],
    requirements:{
        program_id:'',
        required_education_degree_id: '',
        gpa:'',
        ib: '',
        a_level: '',
        ap:'',
        ielts: '',
        toefl: '',
        gre: '',
        gmat: '',
        sat:'',
        dim:'',
        ossd:'',
        ielts_ukvi:'',
        foundation:''
    },
    scholarship:{
        program_id:'',
        amount: '',
        req_edu_degree_id: '',
        gpa:'',
        ib: '',
        a_level: '',
        ap: '',
        ielts: '',
        toefl: '',
        gre: '',
        gmat: '',
        sat:'',
        dim: '',
        ossd: '',
    },
}

export const getZeroPad=(num)=>{
    if(num<10){
        return `0${num}`;
    }else{
        return num;
    }
}

export const getColor=(role)=>{
    if(role == 'student'){
        return '#06cf9c';
    }else if(role == 'teacher' || role == 'head_teacher'){
        return 'text-[#c89631]';
    }else if(role == 'parent'){
        return 'text-[#7f66ff]';
    }else if(role == 'company_head'){
        return 'text-[#53bdeb]';
    }else if(role == 'office_manager'){
        return 'text-[#007bfc]';
    }else {
        return 'text-[#ee7100]';
    }
}
export const getRole=(role)=>{
    if(role == 'student'){
        return 'Student';
    }else if(role == "teacher"){
        return 'Teacher';
    }
}

export const getStartFrom = (degree_id, uni)=>{
    if(degree_id ==3){
        return uni?.bachelour_start
    }else if(degree_id == 4){
        return uni?.pre_master_start
    }else if(degree_id == 5){
        return uni?.master_start
    }else if(degree_id == 6){
        return uni?.phd_start
    }
}

export const getStudyMode=()=>{
    return [{label:"Full time",value:1}, {label:"Online", value:2}, {label:"Part time", value:3}]
}

export const getStartFee = (phd_start, master_start, pre_master_start, bachelour_start) =>{
    let fee = [];
    if(phd_start && phd_start !=null){  fee.push(phd_start) }
    if(master_start && master_start !=null){   fee.push(master_start)   }
    if(pre_master_start && pre_master_start !=null){  fee.push(pre_master_start)  }
    if(bachelour_start && bachelour_start !=null){ fee.push(bachelour_start)   }
    if(fee.length !=0){
      return Math.min(...fee)
    }else{
      return 0
    }
    
  }

  export const getMoneyFormat=(amount)=>{
    if(amount){
        var parts = amount?.toString().split(".");
        parts[0]=parts[0].replace(/\B(?=(\d{3})+(?!\d))/g,",");
        return parts.join(",");
   }
  }

  export const zeroPadFunction=(num) =>{
    if(num<10){
        return `0${num}`;
    }else{
        return num;
    }
  }

  export const getPathConvert = (title="") => {
    let lower = title.toLowerCase();
    let str = lower.replaceAll(" ", "_");
    return str;
  }