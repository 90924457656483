import React, { Component } from 'react'
import SectionHeader from '../../../components/public_components/SectionHeader'
import Card3 from '../../../components/cards/Card3'
import Top20Img from "../../../images/top20Uni.jpeg"
import Top3000Img from "../../../images/top300Uni.jpeg"
import TopSubject from "../../../images/subjectUni.jpeg"
export class UniRankSection extends Component {
  render() {
    return (
      <div className=' '>
         <section className='pt-[1px]'>
                <div className='mt-[30px]'>
                    <SectionHeader text1="Universitetlər" text2="" />
                </div>
                
            </section>
            <section className='max-w-[1200px] ml-auto mr-auto max-[1240px]:ml-[20px] max-[1240px]:mr-[20px] pb-[50px]'>
                <div className='grid  grid-full-320 gap-[70px] mt-[20px]'>
                    <Card3
                        title="Dünyanın ən yaxşı 20 universiteti" 
                        btnText="Qəbul şansını yoxla" 
                        img={Top20Img}
                        url="/top-20-universities" />
                    <Card3 
                        title="Dünyanın ən yaxşı 300 universiteti" 
                        btnText="Qəbul şansını yoxla" 
                        img={Top3000Img}
                        url="/top-300-universities" />
                    <Card3 
                        title="Dünyanın ixtisaslar üzrə ən yaxşı universitetləri " 
                        btnText="İxtisasını seç və şansını yoxla" 
                        img={TopSubject}
                        url="/subjects" />
                </div>
                
            </section>
      </div>
    )
  }
}

export default UniRankSection
