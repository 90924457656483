import React, { Component } from 'react'
import Header from '../../components/header/Header'
import Search from '../../components/Search'
import Buttons from '../../components/Buttons'
import { connect } from 'react-redux'
import { changeStateValue } from '../../redux/MainReducer';
import { getHighSchoolExams } from '../../actions/MainAction';
import Card1 from '../../components/cards/Card1'
import { getExams } from '../../actions/MainAction';
export class AdminHighSchoolExamPage extends Component {
  state={
    page:1,
    search:''
  }

  getGrades=(grades)=>{
    let arr=[];
    if(grades){
        for(const item of grades){
        arr.push(item?.grade)
    }
    }
    
    return arr;
  }
  openModal=(data)=>{
    this.props.changeStateValue({
      name:'adminModal',
      value:{
        position: true,
        id: data?.id?data?.id:'',
        name: data?.name?data?.name:'',
        min_value: data?.min_value?data?.min_value:'',
        max_value: data?.max_value?data?.max_value:'',
        grades:this.getGrades(data?.grades),
        afterCallFunction:this.props.getHighSchoolExams,
        afterCallParams:[],
        requireds: ["name"],
        url:'high-school-exams',
        modalTitle:`${data?.id?'Edit':'Add'} High School exam`
      }
    })
  }

  componentDidMount(){
    this.props.getHighSchoolExams(1, '')
    // this.props.getExams()
  }

  afterCallFunction=()=>{
    this.props.getHighSchoolExams()
  }
  render() {
    const {highSchoolExams} = this.props;
    // console.log(highSchoolExams)
    return (
        <div className='w-full  pl-[27px] pr-[27px]'>
            <Header title="High School exam" />
            <div className='flex justify-end mt-[30px]'>
                <Buttons padding={true} onClick={this.openModal.bind(this, '')} options={[{title:"Add a high school exam", value:0}]} selected={0} />
            </div>
          
            <div className='grid-full-250 mt-[30px]'>
              {
                highSchoolExams?.map((data, i)=>{
                  return(
                    <Card1 
                      key={i}
                      name={data?.name}
                      onClickEdit={this.openModal.bind(this, data)}
                      deletePath={`high-school-exams/${data?.id}`}
                      afterCallFunction={this.afterCallFunction.bind(this)}
                    />
                  )
                })
              }
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
  highSchoolExams: state.Data.highSchoolExams
});
const mapDispatchToProps = {changeStateValue, getHighSchoolExams, getExams}
export default connect(mapStateToProps, mapDispatchToProps)(AdminHighSchoolExamPage)