import React, { Component } from 'react'
import Header from '../../components/header/Header'
import Search from '../../components/Search'
import Buttons from '../../components/Buttons'
import { connect } from 'react-redux'
import { changeStateValue } from '../../redux/MainReducer';
import { getAdvertisings } from '../../actions/MainAction';
import Card1 from '../../components/cards/Card1'
import LoadMoreBtn from '../../components/LoadMoreBtn'
export class AdminAdvertising extends Component {
  state={
    page:1,
    search:''
  }
  openModal=(data)=>{
    this.props.changeStateValue({
      name:'adminModal',
      value:{
        position: true,
        id: data?.id?data?.id:'',
        image: data?.image?data?.image:'',
        image_full_url: data?.image_full_url?data?.image_full_url:'',
        afterCallFunction:this.props.getAdvertisings,
        afterCallParams:[this.state.page, this.state.search],
        requireds: [],
        url:'admin-advertising',
        modalTitle:`${data?.id?'Edit':'Add'} Avertising`
      }
    })
  }

  componentDidMount(){
    this.props.getAdvertisings(1, '')
  }

  onSearchData=(search)=>{
    this.setState({search})
    this.props.getAdvertisings(this.state.page, search)
  }
  loadMore=(page)=>{
    this.setState({page})
    this.props.getAdvertisings(page, this.state.search)
  }

  afterCallFunction=()=>{
    this.props.getAdvertisings(this.state.page, this.state.search)
  }
  render() {
    const {advertisingData} = this.props;
  
    return (
        <div className='w-full  pl-[27px] pr-[27px]'>
            <Header title="Avertising" />
            <div className='flex justify-end mt-[30px]'>
                <Buttons onClick={this.openModal.bind(this, '')} options={[{title:"Add a Avertising", value:0}]} selected={0} />
            </div>
          
            <div className='grid-full-250 mt-[30px]'>
              {
                advertisingData?.map((data, i)=>{
                  return(
                    <Card1 
                      key={i}
                      image={data?.image_full_url}
                      name={data?.name}
                      onClickEdit={this.openModal.bind(this, data)}
                      deletePath={`admin-advertising/${data?.id}`}
                      afterCallFunction={this.afterCallFunction.bind(this)}
                    />
                  )
                })
              }
            </div>
            {
                advertisingData?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={advertisingData?.length} />:null
            }
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
  advertisingData: state.Data.advertisingData
});
const mapDispatchToProps = {changeStateValue, getAdvertisings}
export default connect(mapStateToProps, mapDispatchToProps)(AdminAdvertising)


