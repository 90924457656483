import React, { Component } from 'react'
import { withHooks } from '../../../../actions/withHooks';
import { changeStateValue } from '../../../../redux/MainReducer';
import { connect } from 'react-redux';
import { deleteData, getApplyRequirements, getStudentRequirements, insertNewData } from '../../../../actions/MainAction';
import { confirmAlert, mixinAlert } from '../../../../actions/SweetAlerts';
import Card5 from '../../../../components/public_components/cards/Card5';
import CheckSvg from '../../../../components/svg/CheckSvg';

export class Documents extends Component {
    componentDidMount(){
        this.props.getStudentRequirements(this.props.params.student_id, this.props.params.program_id);
        this.props.getApplyRequirements(this.props.params.student_id, this.props.params.program_id)
    }
    applyBtn=(data)=>{
        confirmAlert("warning","Are you sure?", `Do you want to ${data?.student_requirements?.length===0?"apply":"remove"} requirement`, `Yes, ${data?.student_requirements?.length===0?"apply":"remove"} it!`)
        .then(respAlert=>{
            if(respAlert === "success"){
                this.props.insertNewData("add-student-requirement", {requirement_id: data?.id, user_id: this.props?.params.student_id, program_id: this.props.params.program_id, status:'0'})
                .then(resp=>{
                    if(resp === 'success'){
                        this.props.getApplyRequirements(this.props.params.student_id, this.props.params.program_id);
                        this.props.getStudentRequirements(this.props.params.student_id, this.props.params.program_id);
                        mixinAlert("success", `Requirement ${data?.student_requirements?.length===0?"applied":"removed"} successfully`)
                    }else{
                        mixinAlert("error", "Something went wrong");
                    }
                })
            }
        })
    }
    oploadFile=(id, e)=>{
        // console.log(e.target.files.length)
        let formData = new FormData();
        for(let i = 1; i <=e.target.files.length; i++){
            formData.append(`image${i}`, e.target.files[i-1])
        }
        formData.append("requirement_id", id);
        formData.append("image_count", e.target.files.length);
        this.props.insertNewData("student-file-upload", formData)
        .then(resp=>{
            if(resp === "success"){
                mixinAlert("success", "Files upload successfully");
                this.props.getStudentRequirements(this.props.params.student_id, this.props.params.program_id);
            }else{
                mixinAlert("success", "Something went wrong");
            }
        })
    }
    onDeleteFile=(data)=>{
        confirmAlert("warning","Are you sure?", `Do you want to delete file`, `Yes, delete it!`)
        .then(respAlert=>{
            if(respAlert === "success"){
                this.props.deleteData(`student-file-delete/${data?.id}`)
                .then(resp=>{
                    if(resp === "success"){
                        mixinAlert("success", "File deleted successfully");
                        this.props.getStudentRequirements(this.props.params.student_id, this.props.params.program_id);
                    }else{
                        mixinAlert("success", "Something went wrong");
                    }
                })
            }
        })
    }
    acceptRejectBtn=(id, status)=>{
        confirmAlert("warning","Are you sure?", `Do you want to change file status`, `Yes, change it!`)
        .then(respAlert=>{
            if(respAlert === "success"){
                let url = `student-file-put/${id}`;
                let formData = new FormData();
                formData.append("status", status);
                formData.append("_method", "put");
                this.props.insertNewData(url, formData)
                .then(resp=>{
                    if(resp === "success"){
                        mixinAlert("success", "Status changed successfully");
                        this.props.getStudentRequirements(this.props.params.student_id, this.props.params.program_id);
                    }else{
                        mixinAlert("success", "Something went wrong");
                    }
                })
            }
        })
    }
    addNewRequirement(program_id, student_id){
        this.props.changeStateValue({
            name:"programRequirementModal",
            value:{
                position: true,
                program_id,
                student_id,
                title:'',
                text:'',
                status:'0'
            }
        })
        // console.log(program_id, student_id)
    }
  render() {
    const {studentRequirements, applyRequirements, user} = this.props;
    // console.log(studentRequirements)
    return (
      <div className='w-full'>
        {
            studentRequirements?.length == 0?
            <div className='bg-white shadow rounded-[10px] w-full h-[80vh] mt-[20px] flex justify-center items-center'>
                <h2 className='txt-blue text-[24px] font-semibold max-[450px]:text-[16px] max-[450px]:text-center'>Sənədləriniz hazırlanır. Zəhmət olmasa sonra yoxlayın</h2>
            </div>:null
        }
             {
                user?.role === "xtm"?
                <div className='mt-[20px] flex flex-wrap gap-[20px] bg-white p-[10px] rounded-[10px]'>
                    {
                        applyRequirements?.map((data, i)=>{
                            return(
                                <button onClick={this.applyBtn.bind(this, data)} className={`${data?.student_requirements?.length ===0? "border-blue-600 text-blue-800":"border-green-600 text-green-800"} border text-[12px] font-regular p-[3px_6px] rounded-[5px] border-[1px]  flex items-center`} key={i}>
                                    {data?.title}
                                    { data?.student_requirements?.length !=0?  <CheckSvg />:null }
                                </button>
                            )
                        })
                    }
                     <button onClick={this.addNewRequirement.bind(this, this.props.params?.program_id, this.props.params.student_id)} className=' border text-[12px] font-regular p-[3px_6px] rounded-[5px] border-[1px]  flex items-center' >
                      Add new +
                    </button>
                </div>:null
            }
              <div className='grid-full-380 mt-[20px] items-start'>
                {
                    studentRequirements?.map((data, i)=>{
                        return(
                            <Card5
                                key={i}
                                name={data?.requirements?.title}
                                text={data?.requirements?.text}
                                footer={false}
                                applyStatus={data?.status}
                                addFilesBtn={true}
                                oploadFile={this.oploadFile.bind(this, data?.id)}
                                files={data?.files}
                                onDeleteFile={this.onDeleteFile.bind(this)}
                                acceptRejectBtnVisible={data?.status !=0}
                                acceptRejectBtn={this.acceptRejectBtn.bind(this, data?.id)}
                                index={i}
                            />
                        )
                    })
                }
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    studentRequirements: state.Data.studentRequirements,
    applyRequirements: state.Data.applyRequirements,
    user: state.Data.user,
});
const mapDispatchToProps = {changeStateValue, getStudentRequirements, getApplyRequirements, insertNewData, deleteData}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(Documents))
