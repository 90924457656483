import moment from "moment";
export const initialState = {
    user:'',
    loader:false,
    loaderLong:false,
    phoneValidFormat:true,
    notFoundModal:false,
    adminModal:{
        position:false
    },
    userModal:{
        position: false
    },
    errorPageData:{
        position:false,
        code:''
    },
    eduBackModal:{
        position:false,
        student_id:'',
        country_id:'',
        edu_degree_id:'',
        sub_edu_degree_id:'',
        grade:'',
        a_level:'',
        ap:'',
        ib:'',
        ossd:'',
        foundation:''
    },
    studyDestinationModal:{
        position:false,
        student_id:'',
        countries:[],
        states:[],
        cities:[],
        disciplines:[]
    },
    studentExamsModal:{
        position:false,
        student_id:'',
        ielts:'',
        toefl:'',
        sat:'',
        gre:'',
        gmat:'',
        dim:'',
        ielts_ukvi:'',
        ielts_file:'',
        toefl_file:'',
        sat_file:'',
        gre_file:'',
        gmat_file:'',
        dim_file:'',
        ielts_ukvi_file:'',
    },
    messageFileSend:{
        position:false,
        file:'',
        preview:'',
        type:'',
        replied_id:''
    },
    seeTextModalData:{
        position:false,
        text:''
    },
    studentWishListInfo:{
        country_id:'',
        degree_id:'',
        discipline_id:'',
        student_id:'',
        description:''
    },
    studentWishListData:[],
    staff:[],
    applyRequirements:[],
    countries:[],
    states:[],
    cities:[],
    uniTypes:[],
    universities:[],
    disciplines:[],
    educationDegree:[],
    languages:[],
    programs:[],
    allProgramsAdd:[],
    exams:[],
    highSchoolExams:[],
    studentRequirements:[],
    expencesCategories:[],
    currencies:[],
    studentExpences:{
        position: false,
        id:'',
        currency_id:'',
        category_id:'',
        apply_id:'',
        amount:''
    },
    studentExpencesData:[],
    bookingInfo:'',
    bookingDates:[],
    bookingUserInfo:{
        day:'',
        time:'',
        name:'',
        email:'',
        phone_number:"",
        category:[],
        content:''
    },
    bookingLastModal:false,
    
    // public states
    acceptedStudents:[],
    publicCountries:[],
    publicDisciplines:[],
    publicUniversities:[],
    publicPrograms:[],
    degreeByUniversity:[],


    costumerReview:[],
    blogs:[],

    applyDetails:{
        type:"phone",
        page:"country",
        countries:[],
        disciplines:[],
        educationBackground:{
            country_id:'1',
            edu_degree_id:'',
            sub_edu_degree_id:'',
            grade:'',
            a_level:'',
            ap:'',
            ib:'',
            ossd:'',
            foundation:''
        },
        information:{
            first_name:'',
            last_name:'',
            phone_number:'',
            email:''
        }
    },
    publicEducationDegree:[],
    publicHighSchoolExams:[],
    verifyModalData:{
        position:false,
        username:"",
        code:""
    },
    admissionModal:{
        position:false
    },
    programRequirementModal:{
        position:false,
        title:'',
        text:'',
        status:'',
        program_id:'',
        student_id:''
    },
    edubackground:'',
    studyDestination:{
      countries:[],
      states:[],
      cities:[],
      disciplines:[]
    },
    studentExams:'',
    worldCountries:[],
    studentAddressData:{
        position:false,
        id:'',
        student_id:'',
        country:'',
        city:'',
        address:''
    },
    studentCvModal:{
        position:false,
        student_id:'',
        id:'',
        cv:'',
        cv_full_url:'',
        cv_type:''
    },
    studentPersonalInformation:{
        info:'',
        passport:[]
    },
    studentPassportModalData:{
        position:false,
        image_full_url:"",
        image:'',
        file_type:""
    },
    checkStudentApply:false,

    adminBookings:[],

    cityRankings:[],
    universityRankings:[],
    employRankings:[],
    subjectRankings:[],
    subjectsData:[],
    publicSections:[],
    contactInfo:{
        name:"",
        email:'',
        phone_number:'',
        description:''
    },
    rankingApplyModal:{
        position:false,
        name:'',
        phone_number:'',
        id:'',
        rank_property:''
    },
    rankingApplyConfirmModal:{
        position:false,
        name:'',
        phone_number:'',
        id:'',
        rank_property:'',
        code:''
    },
    applyRankingsData:[],
    profileComplete:0,

    applicationTrackings:[],
    accomadationTrackings:[],
    visaTrackings:[],
    studentMainExpences:[],
    expencessModalData:{
        position:false,
        id:'',
        title:'',
        student_id:'',
        amount:'',
        file:'',
        type:'',
        file_full_url:'',
        file_type:''
    },

    messagesActiveUsers:[],
    messagesContacts:[],
    top300Rankings:[],
    top20Rankings:[],
    teacherProfileComplete:{
        complete_percentage:0,
        total_amount:0,
        earned_amount:0,
    },
    teacherApplicationStatus:{
        applied_students:[],
        accepted_students:[],
    },
    advertisingData:[],
    celtAzApplies:[],
    studentDashData:{
        applied_programs:[],
        available_programs:[],
        requirements:[],
    },

    allTeachers:[],
    changeTNModalData:{
        position:false,
        user_id:'',
        staff_id:''
    },
    teacherStudents:[],

    dashAppliesData:{
        booking:0,
        celt_az:0,
        ranking:0
    },

    countryScholarship:[],
    educationPlanModalData:{
        position:false,
        page:"country",
        countries:[],
        disciplines:[],
        educationBackground:{
            country_id:'1',
            edu_degree_id:'',
            sub_edu_degree_id:'',
            grade:'',
            a_level:'',
            ap:'',
            ib:'',
            ossd:'',
            foundation:'',
            finish_year:''
        },
        exams:{
            ielts: {selected:false, value:''},
            ielts_ukvi: {selected:false, value:''},
            toefl: {selected:false, value:''},
            gre: {selected:false, value:''},
            gmat: {selected:false, value:''},
            sat: {selected:false, value:''},
            dim: {selected:false, value:''},
        },
        information:{
            first_name:'',
            last_name:'',
            phone_number:'',
            email:'',
        }
    },
    confirmEmailWhatsaapModal:false,
    publicExams:[],
    programInfoModal:{
        position: false
    },

    countryBySch:[],
    uniBySch:[],
    programBySch:[],
    emptyUniversities:[],
    forgotPassword:'',
    incorrectModalData:{
        position:false,
        message:''
    },
    studentMoreInfoModalData:{
        position: false
    },

    notFeeUni:[]
    
}
