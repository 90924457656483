import React, { Component } from 'react'
import Header from '../../components/header/Header'
import Search from '../../components/Search'
import Buttons from '../../components/Buttons'
import { connect } from 'react-redux'
import { changeStateValue } from '../../redux/MainReducer';
import { getCostumerReview } from '../../actions/MainAction';
import Card1 from '../../components/cards/Card1'
export class AdminCostumerReviewPage extends Component {

  openModal=(data)=>{
    // console.log(data)
    this.props.changeStateValue({
      name:'adminModal',
      value:{
        position: true,
        id: data?.id?data?.id:'',
        user_name: data?.user_name?data?.user_name:'',
        image: data?.image?data?.image:'',
        image_full_url: data?.image_full_url?data?.image_full_url:'',
        data_position: data?.position?data?.position:'',
        description: data?.description?data?.description:'',
        afterCallFunction:this.props.getCostumerReview,
        afterCallParams:[],
        requireds: ["user_name", "position", "description"],
        url:'costumer-review',
        modalTitle:`${data?.id?'Edit':'Add'} Costumer review`
      }
    })
  }

  componentDidMount(){
    this.props.getCostumerReview(1, '')
  }

  afterCallFunction=()=>{
    this.props.getCostumerReview()
  }
  render() {
    const {costumerReview} = this.props;
  
    return (
        <div className='w-full  pl-[27px] pr-[27px]'>
            <Header title="Costumer Review" />
            <div className='flex justify-end mt-[30px]'>
                <Buttons onClick={this.openModal.bind(this, '')} padding={true} options={[{title:"Add a Costumer review", value:0}]} selected={0} />
            </div>
           

            <div className='grid-full-250 mt-[30px]'>
              {
                costumerReview?.map((data, i)=>{
                  return(
                    <Card1 
                      key={i}
                      image={data?.image_full_url}
                      name={data?.user_name}
                      onClickEdit={this.openModal.bind(this, data)}
                      deletePath={`costumer-review/${data?.id}`}
                      afterCallFunction={this.afterCallFunction.bind(this)}
                    />
                  )
                })
              }
            </div>
           
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
  costumerReview: state.Data.costumerReview
});
const mapDispatchToProps = {changeStateValue, getCostumerReview}
export default connect(mapStateToProps, mapDispatchToProps)(AdminCostumerReviewPage)



