import moment from 'moment';
import React, { Component } from 'react'
import { Link } from 'react-router-dom';

export class MessageChatUser extends Component {
  render() {
    const {data} = this.props;
    return (
      <Link to={'/staff-messages'} className='flex justify-between border-b pt-[10px] pb-[10px]'>
        <div className='flex gap-[15px] items-center'>
            <img src="https://cdn-icons-png.flaticon.com/512/149/149071.png" className='w-[52px] h-[52px] min-w-[52px] rounded-full' />
            <div className='h-[52px] flex flex-col justify-between pb-[5px] pt-[5px]'>
                <h4 className='text-[14px] text-[#303972] font-semibold'>{data?.student?.full_name}</h4>
                <p className='text-[#A098AE] text-[13px] font-regular max-w-[200px] overflow-hidden h-[20px]'>{data?.last_message}</p>
            </div>
        </div>
        <div className='h-[52px] flex flex-col justify-between pb-[5px] pt-[5px] items-end'>
            <span className='text-[12px] font-regular text-[#A098AE]'>{moment(data?.last_date).format("HH:mm")}</span>
            {/* <span className='inline-flex justify-center items-center w-[20px] h-[20px] bg-[#FB7D5B] text-[10px] text-white rounded-full'>2</span> */}
        </div>
      </Link>
    )
  }
}

export default MessageChatUser
