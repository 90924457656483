import React, { Component } from 'react'
import { changeStateValue } from '../../../redux/MainReducer';
import { connect } from 'react-redux';
export class TextArea extends Component {
    getInpValue=(e)=>{
        this.props.changeStateValue({
            name: this.props.name,
            value: e.target.value
        })
    }
  render() {
    const {value, title='', required=false, lang, bigArea=false, svg=""} = this.props
    return (
        <div className='flex flex-col'>
        {title !== ""? <label className={bigArea?"text-[#6C6C6C] text-[12px] font-regular inline-flex items-center gap-[5px]":`text-[14px] txt-light-black`}>{svg} {title}</label>:null}
        <textarea placeholder={title} className={`${bigArea?"text-[12px] text-[#6C6C6C]":""} border rounded-[6px] h-[150px] p-[7px] outline-none mt-[4px]`} value={value} onChange={this.getInpValue.bind(this)} />
        {
            required && value===""?
            <span className='text-[11px] text-red-700'>{lang?.field_required}</span>:null
        }
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  lang: state.Data.lang
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(TextArea)
