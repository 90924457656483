import React, { Component } from 'react'

export class PassportCardSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={44}
        height={40}
        fill="none"
        viewBox="0 -0.5 25 25"
      >
        <path
          stroke="#81ad21"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M3.5 7v10a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-14a2 2 0 0 0-2 2Z"
          clipRule="evenodd"
        />
        <path
          stroke="#81ad21"
          strokeLinecap="round"
          strokeWidth={1.5}
          d="M15.5 10h3M15.5 13h3"
        />
        <path
          stroke="#81ad21"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M11.5 10a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
          clipRule="evenodd"
        />
        <path
          stroke="#81ad21"
          strokeLinecap="round"
          strokeWidth={1.5}
          d="M5.5 16c2.783-3.137 6.052-2.151 8 0"
        />
      </svg>
    )
  }
}

export default PassportCardSvg
