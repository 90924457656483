import React, { Component } from 'react'

export class InpUserSvg extends Component {
  render() {
    return (
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width={14}
      height={14}
      viewBox="0 0 32 32"
      fill="#6C6C6C"
    >
      <path d="M15.992 2c3.396 0 6.998 2.86 6.998 4.995v4.997c0 1.924-.8 5.604-2.945 7.293a2 2 0 0 0 .402 3.389l8.703 4.127c.068.031.834.16.834 1.23l.001 1.952-27.984.002v-2.029c0-.795.596-1.045.835-1.154l8.782-4.145a1.997 1.997 0 0 0 .416-3.376c-2.078-1.668-3.046-5.335-3.046-7.287V6.997c.001-2.089 3.638-4.995 7.004-4.995zm0-2c-4.416 0-9.004 3.686-9.004 6.996v4.997c0 2.184.997 6.601 3.793 8.847l-8.783 4.145S0 25.875 0 26.984v3.001c0 1.105.895 1.999 1.998 1.999h27.986a1.998 1.998 0 0 0 1.999-1.999v-3.001c0-1.175-1.999-1.999-1.999-1.999l-8.703-4.127c2.77-2.18 3.708-6.464 3.708-8.865V6.996c0-3.31-4.582-6.995-8.998-6.995z" />
    </svg>
       
    )
  }
}

export default InpUserSvg
