import React, { Component } from 'react'
import { withHooks } from '../../actions/withHooks'

export class RedirectSch extends Component {
    componentDidMount(){
        try{
            let url = this.props.params.id;
             window.location.href = `/scholarships/${url}`
        }catch(err){}
    }
  render() {
    return (
      <div>
        
      </div>
    )
  }
}

export default withHooks(RedirectSch)
