import React, { Component } from 'react'
import { getEmtpyUniversities } from '../../actions/MainAction';
import { connect } from 'react-redux';
import Header from '../../components/header/Header';
import Card1 from '../../components/cards/Card1';
import LoadMoreBtn from '../../components/LoadMoreBtn';

export class AdminEmptyUniversities extends Component {
    state={
        page:1
    }
    componentDidMount(){
        this.props.getEmtpyUniversities(1)
    }
    afterCallFunction=()=>{
        this.props.getEmtpyUniversities(this.state.page)
    }
    loadMore=(page)=>{
        this.setState({page});
        this.props.getEmtpyUniversities(page)
    }
  render() {
    const {emptyUniversities, user} = this.props;
    // console.log(emptyUniversities)s
    return (
        <div className='w-full  pl-[27px] pr-[27px]'>
            <Header title="Empty Universities" />
            <div className='grid-full-250 mt-[30px]'>
              {
                emptyUniversities?.map((data, i)=>{
                  return(
                    <Card1
                      key={i}
                      image={data?.image_full_url}
                      name={data?.name}
                      country={data?.country?.name}
                      type={data?.type?.name}
                    //   onClickEdit={this.openModal.bind(this, data)}
                      deletePath={user?.role == "admin"? `university/${data?.id}`:""}
                      afterCallFunction={this.afterCallFunction.bind(this)}
                      moreLink={`/add-programs/${data?.id}`}
                      userLink={`/univerisy-programs/${data?.id}`}
                    />
                  )
                })
              }
            </div>
            {
                emptyUniversities?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={emptyUniversities?.length} />:null
            }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    emptyUniversities: state.Data.emptyUniversities,
    user: state.Data.user,
});
const mapDispatchToProps = {getEmtpyUniversities}
export default connect(mapStateToProps, mapDispatchToProps)(AdminEmptyUniversities)
