import React, { Component } from 'react'

export class ScheduleSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        data-id="details-item-icon"
        viewBox="0 0 20 20"
        width={20}
        height={20}
      >
        <path
          fill="#1a1a1a9c"
          fillRule="evenodd"
          d="M6 1a1 1 0 1 0-2 0v1H3a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V5a3 3 0 0 0-3-3h-1V1a1 1 0 1 0-2 0v1H6V1Zm12 6V5a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v2h16ZM2 9h16v8a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V9Z"
          clipRule="evenodd"
        />
      </svg>
    )
  }
}

export default ScheduleSvg
