import React, { Component } from 'react'
import DeleteSvg from './svg/DeleteSvg';

export class ProgDisciplineCard extends Component {

   
  render() {
    const {data, onDeleteDiscipline=()=>{}, onDeleteProgram=()=>{}, openModal=()=>{}} = this.props;
    return (
      <div className='border p-[10px] bg-white rounded-[10px] flex justify-between'>
        <div className='flex items-center gap-[20px]'>
            <h3>{data?.name} / {data?.university?.name} / {data?.degree?.name}</h3>
            <button onClick={()=>onDeleteProgram(data?.id)} className='bg-red p-[5px_10px] rounded-full text-white text-[12px]'>
                Delete
            </button>
        </div>
        <div className='flex gap-[20px]'>
            <button onClick={()=>openModal(data?.id, data?.name)} className='bg-blue text-[12px] text-white p-[4px_8px] rounded-full'>
                Add +
            </button>
            {
                data?.disciplines?.map((item, j)=>{
                    return(
                        <div key={j} className='flex items-center gap-[5px] border-r pr-[10px]'>
                            <span>{item?.discipline?.name}</span>
                            <button onClick={()=>onDeleteDiscipline(item?.id)}>
                                <DeleteSvg />
                            </button>
                        </div>
                    )
                })
            }
        </div>
      </div>
    )
  }
}

export default ProgDisciplineCard
