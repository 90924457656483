import React, { Component } from 'react'
import Footer from './sections/Footer'
import EmployabilityRankingSection from './sections/EmployabilityRankingSection'
import { Helmet } from 'react-helmet'

export class EmployabilityRankingPage extends Component {
  componentDidMount(){
    window.scrollTo(0,0)
  }
  render() {
    return (
        <div className='bg-[#F5F5F5] mt-[-40px]'>
          <Helmet>
            <title>{"Ən Yaxşı Məzunları Olan Universitetlər | CELTgo.com - Uğurlu Məzunlarla Tanınan Universitetlər"}</title>
            <meta property="og:locale" content="az_AZ" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Ən Yaxşı Məzunları Olan Universitetlər | CELTgo.com - Uğurlu Məzunlarla Tanınan Universitetlər" />
            <meta property="og:description" content="Dünyanın ən yaxşı məzunlarına sahib olan universitetləri kəşf edin. CELTgo.com ilə məzunları qlobal uğurlara imza atan universitetlərə qəbul olun." />
            <meta name="description" content={"Dünyanın ən yaxşı məzunlarına sahib olan universitetləri kəşf edin. CELTgo.com ilə məzunları qlobal uğurlara imza atan universitetlərə qəbul olun."}/>
            <meta name="keywords" content={"ən yaxşı məzunları olan universitetlər, uğurlu məzun universitetləri, məzunları uğur qazanan universitetlər, ən yaxşı universitetlər, xaricdə təhsil universitetləri, global uğur universitetləri, CELTgo universitet seçimləri"}/>
            <meta property="og:url" content="https://celtgo.com/employability-rankings" />
            <meta property="og:site_name" content="CELT Colleges" />
            <meta property="article:publisher" content="https://www.facebook.com/celtscollege" />
            <meta property="article:modified_time" content="2024-06-06T08:56:25+00:00" />
            <meta property="og:image" content="https://celtgo.com/static/media/celtLogo6.05ddd884642befe11a4e.png" />
            <meta property="og:image:width" content="900" />
            <meta property="og:image:height" content="600" />
            <meta property="og:image:type" content="image/jpeg" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="Ən Yaxşı Məzunları Olan Universitetlər | CELTgo.com - Uğurlu Məzunlarla Tanınan Universitetlər" />
            <meta name="twitter:description" content="Dünyanın ən yaxşı məzunlarına sahib olan universitetləri kəşf edin. CELTgo.com ilə məzunları qlobal uğurlara imza atan universitetlərə qəbul olun." />
            <meta name="twitter:site" content="@celtcolleges" />
            <meta name="twitter:label1" content="Est. reading time" />
            <meta name="twitter:data1" content="7 minutes" />
          </Helmet>
            <EmployabilityRankingSection />
            <Footer />
        </div>
    )
  }
}

export default EmployabilityRankingPage
