import React, { Component } from 'react'
import TrackingCard from './TrackingCard'
import { getAccomadationTracking, getApplicationTracking, getVisaTracking, insertNewData } from '../../../../actions/MainAction';
import { connect } from 'react-redux';
import { withHooks } from '../../../../actions/withHooks';
import { confirmAlert, mixinAlert } from '../../../../actions/SweetAlerts';

export class Trackings extends Component {
    componentDidMount(){
        this.props.getApplicationTracking(this.props.params.student_id, this.props.params.program_id)
        this.props.getAccomadationTracking(this.props.params.student_id, this.props.params.program_id)
        this.props.getVisaTracking(this.props.params.student_id, this.props.params.program_id)
    }
    onChangeStatus=(url, tracking_id)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, change it")
        .then(alertResp=>{
            if(alertResp === "success"){
            this.props.insertNewData(url, {
                student_id: this.props.params.student_id,
                program_id: this.props.params.program_id,
                track_id: tracking_id,
            })
            .then(resp=>{
                if(resp == "success"){
                    if(url === "student-application-tracking"){
                        this.props.getApplicationTracking(this.props.params.student_id, this.props.params.program_id)
                    }else if(url === "student-accomadation-tracking"){
                        this.props.getAccomadationTracking(this.props.params.student_id, this.props.params.program_id)
                    }else if(url === "student-visa-tracking"){
                        this.props.getVisaTracking(this.props.params.student_id, this.props.params.program_id)
                    }
                    mixinAlert("success", "Data changed successfully")
                }else{
                    mixinAlert("error", "Something went wrong")
                }
            })
            }
        })
    }
  render() {
    const {applicationTrackings, accomadationTrackings, visaTrackings, user} = this.props;
    return (
      <div className='grid grid-cols-3 mt-[20px] gap-[20px] max-[1350px]:grid-cols-2 max-[980px]:grid-cols-1'>
        <TrackingCard 
            title="Ərizə izləmə"
            options={applicationTrackings}
            onChangeStatus={user?.role == "xtm"?this.onChangeStatus.bind(this, "student-application-tracking"):()=>{}}
        />
        <TrackingCard 
            title="Yataqxana izləmə"
            options={accomadationTrackings}
            onChangeStatus={user?.role == "xtm"?this.onChangeStatus.bind(this, "student-accomadation-tracking"):()=>{}}
        />
        <TrackingCard 
            title="Viza izləmə"
            options={visaTrackings}
            onChangeStatus={user?.role == "xtm"?this.onChangeStatus.bind(this, "student-visa-tracking"):()=>{}}
        />
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    applicationTrackings: state.Data.applicationTrackings,
    accomadationTrackings: state.Data.accomadationTrackings,
    visaTrackings: state.Data.visaTrackings,
    user: state.Data.user,
});
const mapDispatchToProps = {getApplicationTracking, getAccomadationTracking, getVisaTracking, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(Trackings))
