import React, { Component } from 'react'
import DegreeSvg from '../../svg/DegreeSvg';
import PaySvg from '../svg/PaySvg';
import DeadlineSvg from '../svg/DeadlineSvg';
import LangSvg from '../svg/LangSvg';
import SignSvg from '../svg/SignSvg';
import TimeSvg2 from '../svg/TimeSvg2';
import AppliedSvg from '../svg/AppliedSvg';
import moment from 'moment';
import AutoComplate from '../../AutoComplate';
import CheckSvg2 from '../svg/CheckSvg2';
import { Link } from 'react-router-dom';
import { getMoneyFormat, getStartFrom } from '../../../actions/getOtherFunctions';
import DollarSvg from '../svg/DollarSvg';

export class Card9 extends Component {
  render() {
    const {data, name="", image="", degree="", university="",onClickBtn=()=>{}, 
            path="",view=false, duration="", deadline="", start_from="", 
            language="", btnText="", btnDisable=false, onRedirectBtn=()=>{}} = this.props;
    // console.log(data)
    return (
      <div className='bg-white shadow  rounded-[8px]'>
          <div>
            <div>
            <h3 className='text-[18px] font-semibold pb-[0px] p-[10px] text-center max-[540px]:text-[15px]'>{data[0]?.country_name} / {data[0]?.city_name}</h3>
            <h3 className='text-[18px] font-semibold pb-[0px] text-center max-[540px]:text-[15px]'>{data[0]?.university_name}</h3>
           <div className='flex justify-center items-center gap-[10px]'>
           {
              data[0]?.local_ranking && data[0]?.local_ranking <=500?
               <h3 className='text-[18px] font-semibold text-center  max-[540px]:text-[15px]'>Ölkə üzrə: {data[0]?.local_ranking} ,</h3>:null
            }
            {
              data[0]?.ranking && data[0]?.ranking <=500?
              <h3 className='text-[18px] font-semibold  text-center  max-[540px]:text-[15px]'>Dünya üzrə: {data[0]?.ranking}</h3>:null
            }
           </div>
            {
              getStartFrom(data[0]?.degree_id,  data[0])?
              <h3 className='text-[18px] font-semibold  text-center  gap-[0px]  max-[540px]:text-[15px]'>İllik ${getMoneyFormat(getStartFrom(data[0]?.degree_id,  data[0]))}  başlayır <p className='text-[10px] font-[300]'>Qeyd: Qiymətlər təxminidir. Dəqiq təhsil haqqı üçün universitetin saytından yoxlanılmalıdır</p></h3>:null
            }
            
           
            </div>
            <div className='mt-[10px] overflow-x-auto table_x_overflow'>
              <table className='w-full overflow-x-auto'>
                <thead className='overflow-x-auto'>
                  <tr className='overflow-x-auto'>
                    <th className=' border-t  h-[35px] pl-[10px] pr-[10px] text-left min-w-[200px] w-[40%]'>İxtisas</th>
                    <th className=' border-t border-l h-[35px] pl-[10px] pr-[10px] text-left w-[12%] min-w-[130px]'>Dərəcə</th>
                    <th className=' border-t border-l h-[35px] pl-[10px] pr-[10px] text-left w-[12%] min-w-[130px]'>Son müraciət</th>
                    <th className=' border-t border-l h-[35px] pl-[10px] pr-[10px] text-left w-[12%] min-w-[130px]'>Dil</th>
                    <th className=' border-t border-l h-[35px] pl-[10px] pr-[10px] text-left w-[12%] min-w-[130px]'>Müddət</th>
                    <th className=' border-t border-l h-[35px] pl-[10px] pr-[10px] text-left w-[12%] min-w-[130px]'>Müraciət</th>
                  </tr>
                </thead>
                <tbody className='overflow-x-auto'>
                  {
                    data?.map((item, i)=>{
                      return (
                        <tr key={i}>
                          <td className='border-t  h-[35px] pl-[10px] pr-[10px] text-[14px] font-regular'>{item?.program_name}</td>
                          <td className='border-t border-l h-[35px] pl-[10px] pr-[10px] text-[14px] font-regular'>{item?.degree}</td>
                          <td className='border-t border-l h-[35px] pl-[10px] pr-[10px] text-[14px] font-regular'>{moment(item?.deadline).format("DD MMMM")}</td>
                          <td className='border-t border-l h-[35px] pl-[10px] pr-[10px] text-[14px] font-regular'>{item?.language}</td>
                          <td className='border-t border-l h-[35px] pl-[10px] pr-[10px] text-[14px] font-regular'>{item?.duration} il</td>
                          <td className='border-t border-l h-[35px] pl-[10px] pr-[10px] text-[14px] font-regular'>
                            <button onClick={item?.applied_status==1?()=>onRedirectBtn({program_id: item?.program_id, student_id: item?.student_id}):()=>onClickBtn(item?.program_id)} className='bg-blue text-[12px] text-white font-regular p-[2px_8px] rounded-[6px]'>{item?.applied_status===1?"Yoxla":"İndi müraciət et"}</button>
                          </td>
                        </tr>
                      )
                    })
                  }
                
                </tbody>
              </table>
            </div>
          </div>
      </div>
    )
  }
}

export default Card9
