import React, { Component } from 'react'
import Card4 from '../../../components/public_components/cards/Card4'
import { connect } from 'react-redux';
import { getPublicDisciplines } from '../../../actions/PublicActions';
import ViewAllBtn from '../../../components/public_components/ViewAllBtn';
import LoadMoreBtn from '../../../components/public_components/LoadMoreBtn';
export class DisciplinesSection extends Component {
  componentDidMount(){
    this.props.getPublicDisciplines(this.props.page)
  }
  loadMore=(page)=>{
    this.props.getPublicDisciplines(page)
  }
  render() {
    const {publicDisciplines, viewBtn=false, loadMoreBtn=false} = this.props;
      // console.log(publicDisciplines)
    return (
        <section className='bg-[#1E398F] pt-[50px] pb-[70px]'>
            <div className='max-w-[1200px] ml-auto mr-auto max-[1240px]:ml-[20px] max-[1240px]:mr-[20px]'>
                <h3 className='text-white text-[44px] font-semibold max-[450px]:text-[30px]'>Popular Disciplines</h3>
                <div className='mt-[60px] grid-full-250 gap-[70px]'>
                  {
                    [...publicDisciplines]?.sort((a,b) => b?.programs_count - a?.programs_count)?.map((data, i)=>{
                      return(
                        <Card4 key={i} data={data} />
                      )
                    })
                  }
                </div>
                {
                    viewBtn?
                    <ViewAllBtn to="/disciplines" />:null
                }
                {
                    loadMoreBtn && publicDisciplines?.length >=20?
                    <LoadMoreBtn onLoadData={this.loadMore} count={publicDisciplines?.length} />:null
                }
            </div>
        </section>
    )
  }
}
const mapStateToProps = (state) =>({
  publicDisciplines: state.Data.publicDisciplines
});
const mapDispatchToProps = {getPublicDisciplines}
export default connect(mapStateToProps, mapDispatchToProps)(DisciplinesSection)
