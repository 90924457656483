import React, { Component } from 'react'
import { mixinAlert } from '../../../actions/SweetAlerts';
import { connect } from 'react-redux';
import { insertNewData } from '../../../actions/MainAction';
export class Footer extends Component {
    state={
        btnDisabled:false
    }
    saveBtn=(data)=>{
      let permission=true;
      for(const req of data?.requireds){
        if(!data[req] || data[req] == ''){
          permission = false;
        }
      }

      if(permission){
        this.setState({btnDisabled:true})
        let formData = new FormData();
        let url = data?.url;
        for(const property in data){
          if(data[property]){
            formData.append(property, data[property]);
          }
        }
        if(data?.id && data?.id !=""){
          url = `${data?.url}/${data?.id}`;
          formData.append("_method", "put");
        }

        if(data?.disciplines){
          let arr = [];
          for(const x of data?.disciplines){
            arr.push(x?.value)
          }
          formData.append("disciplines", arr.join(','));
        }else{
          formData.append("disciplines",'');
        }
        if(data?.requirements){
          let req = {};
          for(const property in data?.requirements){
            if(data?.requirements[property]){
              req[property] = data?.requirements[property];
            }else{
              req[property] = null;
            }
          }
          formData.append("requirements", JSON.stringify(req))
        }
        if(data?.scholarship){
          let req = {};
          for(const property in data?.scholarship){
            if(data?.scholarship[property]){
              req[property] = data?.scholarship[property];
            }else{
              req[property] = null;
            }
          }
          formData.append("scholarship", JSON.stringify(req))
        }
        if(data?.grades){
          formData.append("grades", JSON.stringify(data?.grades))
        }
        if(data?.data_position){
          formData.append("position", data?.data_position)
        }
        // console.log(data)
        this.props.insertNewData(url, formData)
        .then(resp=>{
          this.setState({btnDisabled:false})
          if(resp == "success"){
            data.afterCallFunction(...data?.afterCallParams);
            this.props.closeBtn()
            mixinAlert("success", `${data?.id && data?.id !== ""?"Məlumat uğurla yeniləndi":"Məlumat uğurla əlavə edildi"}`)
          }else{
            mixinAlert("error", "Xəta baş verdi");
          }
        })
      }else{
        mixinAlert("warning", "Zəhmət olmasa bütün xanaları doldurun");
      }
    }
  render() {
    const {btnDisabled} = this.state;
    const {closeBtn=()=>{}, data} = this.props;
    return (
        <div className='flex mt-[20px] items-center justify-between'>
            <button disabled={btnDisabled} onClick={()=>{closeBtn()}} className='bg-red h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>Bağla</button>
            <button disabled={btnDisabled} onClick={this.saveBtn.bind(this,data)} className='bg-blue h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>Yadda saxla</button>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({});
const mapDispatchToProps = {insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(Footer)
