import React, { Component } from 'react'
import { insertNewData, insertNewDataParams } from '../../actions/MainAction';
import { connect } from 'react-redux';

export class AdminSeoGenerate extends Component {
    state={
        count:0,
        btnDisable:false
    }

    onClickBtn=()=>{
        this.setState({btnDisable:true});
        this.props.insertNewDataParams("seo-keywords", {})
        .then(resp=>{
            console.log(resp);
            this.setState({btnDisable:false, count: this.state.count+1});
            setTimeout(()=>{
                this.onClickBtn();
            }, 30000)
        })
    }
  render() {
    const {count, btnDisable} = this.state;
    return (
        <div className='w-full  pl-[27px] pr-[27px]'>
            <button onClick={this.onClickBtn.bind(this)}>Start</button>
        <h3>Count: {count}</h3>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({});
const mapDispatchToProps = {insertNewDataParams}
export default connect(mapStateToProps, mapDispatchToProps)(AdminSeoGenerate)
