import React, { Component } from 'react'
import Header from '../../components/header/Header'
import Search from '../../components/Search'
import Buttons from '../../components/Buttons'
import { connect } from 'react-redux'
import { changeStateValue } from '../../redux/MainReducer';
import { getDisciplines } from '../../actions/MainAction';
import Card1 from '../../components/cards/Card1'
import LoadMoreBtn from '../../components/LoadMoreBtn'
export class AdminDisciplinePage extends Component {
  state={
    page:1,
    search:''
  }
  openModal=(data)=>{
    this.props.changeStateValue({
      name:'adminModal',
      value:{
        position: true,
        id: data?.id?data?.id:'',
        name: data?.name?data?.name:'',
        image: data?.image?data?.image:'',
        image_full_url: data?.image_full_url?data?.image_full_url:'',
        afterCallFunction:this.props.getDisciplines,
        afterCallParams:[this.state.page, this.state.search],
        requireds: ["name"],
        url:'discipline',
        modalTitle:`${data?.id?'Edit':'Add'} Discipline`
      }
    })
  }

  componentDidMount(){
    this.props.getDisciplines(1, '')
  }

  onSearchData=(search)=>{
    this.setState({search})
    this.props.getDisciplines(this.state.page, search)
  }
  loadMore=(page)=>{
    this.setState({page})
    this.props.getDisciplines(page, this.state.search)
  }

  afterCallFunction=()=>{
    this.props.getDisciplines(this.state.page, this.state.search)
  }
  render() {
    const {disciplines} = this.props;
  
    return (
        <div className='w-full  pl-[27px] pr-[27px]'>
            <Header title="disciplines" />
            <div className='flex justify-end mt-[30px]'>
                <Buttons onClick={this.openModal.bind(this, '')} options={[{title:"Add a discipline", value:0}]} selected={0} />
            </div>
            <div className='flex justify-center mt-[20px]'>
                <Search onSearchData={this.onSearchData.bind(this)} largWidth={true} />
            </div>

            <div className='grid-full-250 mt-[30px]'>
              {
                disciplines?.map((data, i)=>{
                  return(
                    <Card1 
                      key={i}
                      image={data?.image_full_url}
                      name={data?.name}
                      onClickEdit={this.openModal.bind(this, data)}
                      deletePath={`discipline/${data?.id}`}
                      afterCallFunction={this.afterCallFunction.bind(this)}
                      moreLink={`/admin-discipline/${data?.id}`}
                    />
                  )
                })
              }
            </div>
            {
                disciplines?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={disciplines?.length} />:null
            }
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
  disciplines: state.Data.disciplines
});
const mapDispatchToProps = {changeStateValue, getDisciplines}
export default connect(mapStateToProps, mapDispatchToProps)(AdminDisciplinePage)


