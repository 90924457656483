import React, { Component } from 'react'
import CardReadSvg from '../svg/CardReadSvg';
import moment from 'moment';
import { Link } from 'react-router-dom';

export class Card7 extends Component {
  render() {
    const {data, type} = this.props;
    return (
      <Link to={`/blog/${data?.id}`} className={`flex gap-[20px] ${type==="column"?"flex-col":""}`}>
        <img className={`rounded-[10px] ${type!=="column"?"w-[140px]":""}`} src={data?.image_full_url} />
        <div className='flex flex-col gap-[10px] w-full p-[5px]'>
            {/* <div className='flex items-center gap-[7px]'>
                <CardReadSvg />
                <span className='text-[12px]'>Read count {data?.read_count}</span>
            </div> */}
            <h4 className='text-[20px] '>{data?.title}</h4>
            <div className='flex justify-start'>
                <span className='text-gray-500 text-[14px]'>{moment(data?.created_at).format("DD MMM YYYY")}</span>
            </div>
        </div>
      </Link>
    )
  }
}

export default Card7
