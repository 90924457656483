import React, { Component } from 'react'
import { bigAlert, mixinAlert } from '../../../actions/SweetAlerts';
import { connect } from 'react-redux';
import { insertNewDataParams } from '../../../actions/MainAction';
import { withHooks } from '../../../actions/withHooks';

export class UserModalFooter extends Component {
    state={
        btnDisabled:false
    }
    saveBtn=(data)=>{
      let permission=true;
      for(const req of data?.requireds){
        if(!data[req] || data[req] == ''){
          permission = false;
        }
      }

      if(permission){
        this.setState({btnDisabled:true})
        let formData = new FormData();
        let url = data?.url;
        for(const property in data){
          if(data[property]){
            formData.append(property, data[property]);
          }
        }
        if(data?.id && data?.id !=""){
          url = `${data?.url}/${data?.id}`;
          formData.append("_method", "put");
        }
        this.props.insertNewDataParams(url, formData)
        .then(resp=>{
          this.setState({btnDisabled:false})
          if(resp?.msg === "success"){
          
            data.afterCallFunction(...data?.afterCallParams);
            if(this.props.user?.role !="student"){
              if(data?.insertType=="student"){
                this.props.navigate(`/students/${resp?.data?.data?.id}`);
              }
            }
           
            this.props.closeBtn()
            bigAlert( "",`${data?.id && data?.id !== ""?"Məlumatınız uğurla yeniləndi":"Tələbə uğurla əlavə edildi"}`, "success")
          }else{
            mixinAlert("error", "Xəta baş verdi");
          }
        })
      }else{
        mixinAlert("warning", "Bütün xanaları doldurun zəhmət olmasa");
      }
    }
  render() {
    const {btnDisabled} = this.state;
    const {closeBtn=()=>{}, data} = this.props;
    return (
        <div className='grid gap-[20px]  grid-cols-2  mt-[20px] items-center '>
            <button disabled={btnDisabled} onClick={()=>{closeBtn()}} className='bg-orange h-[48px] pl-[10px] pr-[10px]  text-[20px] rounded-[5px] text-white font-regular max-[400px]:text-[16px] max-[400px]:h-[30px] max-[400px]:pl-[5px] max-[400px]:pr-[5px] max-[310px]:text-[14px]'>Ləğv et</button>
            <button disabled={btnDisabled} onClick={this.saveBtn.bind(this,data)} className='bg-blue h-[48px] pl-[10px] pr-[10px]  text-[20px] rounded-[5px] text-white font-regular max-[400px]:text-[16px] max-[400px]:h-[30px] max-[400px]:pl-[5px] max-[400px]:pr-[5px] max-[310px]:text-[14px]'>Yadda saxla</button>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
  user: state.Data.user
});
const mapDispatchToProps = {insertNewDataParams}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(UserModalFooter))


