import React, { Component } from 'react'

export class WishListSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={16}
        fill="none"
      >
        <g filter="url(#a)">
          <path
            fill="#000"
            d="M15.387.863c-2.3-1.568-5.14-.836-6.672.958C7.182.027 4.342-.714 2.042.863A4.766 4.766 0 0 0 .004 4.6c-.122 3.38 2.874 6.09 7.448 10.244l.087.079a1.739 1.739 0 0 0 2.343-.009l.096-.087C14.55 10.68 17.539 7.97 17.426 4.59A4.764 4.764 0 0 0 15.387.863Z"
          />
        </g>
        <defs>
          <filter
            id="a"
            width={17.43}
            height={15.372}
            x={0}
            y={0}
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx={0.5} dy={0.5} />
            <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
            <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
            <feBlend in2="shape" result="effect1_innerShadow_200_1930" />
          </filter>
        </defs>
      </svg>
    )
  }
}

export default WishListSvg
