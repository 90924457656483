import React, { Component } from 'react'
import LoadRightSvg from './svg/LoadRightSvg';

export class LoadMoreBtn extends Component {
    state={
        page:1,
        prevData: 0,
        loadMore:true,
        showLess:false
      }
    
      loadMoreBtn( page, count, prevData){
        let inc = page;
        if(count !== prevData){
          inc++;
          this.setState({prevData:count, page:inc});
        }else{
          if(count === this.props.defCount){
            this.setState({loadMore:false})
          }else{
            this.setState({showLess:true})
          }
        }
    
        this.props.onLoadData(inc);
      }
    
      showLessBtn(){
        this.setState({
          page:1,
          prevData: 0,
          loadMore:true,
          showLess:false
        });
        this.props.onLoadData(1);
      }
  render() {
    const {count, defCount=20} = this.props;
    const {page, prevData, showLess, loadMore} = this.state;
    return (
        <div className='flex justify-center items-center mt-[30px]' >
             {
                 count % defCount ===0 && loadMore && !showLess?
                <button onClick={this.loadMoreBtn.bind(this, page, count, prevData )} className='border border-[#545454] w-[187px] inline-flex justify-center items-center h-[40px] rounded-full bg-white text-[#545454] text-[15px] font-[300] gap-[10px]'>
                  Daha çox
                    <LoadRightSvg />
                </button>:null
            }
            {
            (count >defCount && count % defCount !== 0)?
            <button onClick={this.showLessBtn.bind(this)} className='border border-[#545454] w-[187px] inline-flex justify-center items-center h-[40px] rounded-full bg-white text-[#545454] text-[15px] font-[300] gap-[10px]'>
              Daha az
                <LoadRightSvg />
            </button>:null
            }
        </div>
    )
  }
}

export default LoadMoreBtn
