import React, { Component } from 'react'

export class InfoSvg extends Component {
  render() {
    return (
        <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={25}
    height={25}
    style={{  marginLeft:'-3px' }}

    transform="scale(-1 1)"
    viewBox="0 0 31.771 31.771"
  >
    <path d="M31.771 20.319c0-.474-.279-.87-.676-1.066V11.9a.224.224 0 0 0 .145-.214.225.225 0 0 0-.149-.21L15.92 6.125a.898.898 0 0 0-.601 0L.15 11.477a.226.226 0 0 0-.006.424l15.148 5.896a.913.913 0 0 0 .655 0l14.102-5.492v6.947a1.19 1.19 0 0 0-.675 1.065c0 .492.3.919.729 1.102-.429.847-.729 2.585-.729 3.081a1.198 1.198 0 0 0 2.395 0c0-.496-.301-2.234-.729-3.081.43-.181.731-.608.731-1.1z" />
    <path d="m4.888 14.87.002 4.009c0 3.158 4.753 5.729 10.73 5.89 5.976-.161 10.729-2.729 10.729-5.89 0-.315 0-2.312.002-4.009l-10.406 4.051a.903.903 0 0 1-.655 0L4.888 14.87z" />
  </svg>
    )
  }
}

export default InfoSvg
