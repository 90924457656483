import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { changeStateValue } from '../../redux/MainReducer';
import { getBookings, insertNewData } from '../../actions/MainAction';
import { connect } from 'react-redux';
import moment from 'moment';
import ProfileCheckSvg from '../../components/svg/ProfileCheckSvg';
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts';

export class AdminBookingDate extends Component {

    componentDidMount(){
        this.props.getBookings()
    }

    onClickStatus=(id, status)=>{
        confirmAlert("warning", "Əminsiniz?", "Statusu dəyişməyinizdən əminsiniz", "Bəli", "Xeyir")
        .then(alertResp=>{
            if(alertResp === "success"){
                let formData = new FormData();
                formData.append("status", status==1?0:1)
                formData.append("_method", "put")
            this.props.insertNewData(`change-booking-info/${id}`, formData)
            .then(resp=>{
                if(resp == "success"){
                    this.props.getBookings()
                    mixinAlert("success", "Uğurla dəyişdirildi.")
                }else{
                    mixinAlert("error", "Xəta baş verdi")
                }
            })
            }
        })
    }
  render() {
    const {adminBookings} = this.props;
    // console.log(adminBookings)
    // get-booking-info
    return (
        <div className='w-full  pl-[27px] pr-[27px]'>
            {/* <Header title="Booking" /> */}
            <div className='grid-full-320 grid mt-[50px]'>
                {
                    adminBookings?.map((data, i)=>{
                        return (
                            <div key={i} className='bg-white p-[10px] rounded-[10px]'>
                                <ul className='flex flex-col gap-[7px]'>
                                    <li className='text-[14px] inline-flex items-center gap-[5px]'><span className='font-[600]'>Name: </span>{data?.name}</li>
                                    <li className='text-[14px] inline-flex items-center gap-[5px]'><span className='font-[600]'>Email: </span>{data?.email}</li>
                                    <li className='text-[14px] inline-flex items-center gap-[5px]'><span className='font-[600]'>Phone number: </span>{data?.phone_number}</li>
                                    <li className='text-[14px] inline-flex items-center gap-[5px]'><span className='font-[600]'>Date: </span>{moment(data?.day).format("DD/MM/YYYY")}</li>
                                    <li className='text-[14px] inline-flex items-center gap-[5px]'><span className='font-[600]'>Time: </span>{data?.time}</li>
                                    <li  className='text-[14px] inline-flex items-center gap-[5px]'> <button onClick={this.onClickStatus.bind(this, data?.id, data?.status)} className='mb-[20px] flex  items-center gap-[10px]'>Status: <ProfileCheckSvg color={data?.status==0?"gray":"green"} /> </button></li>
                                </ul>
                                <div className='border-t mt-[5px] pt-[5px]'>
                                    <span className='inline-block w-full text-center text-[14px] font-semibold'>Content</span>
                                    <p className='text-[12px]'>{data?.content}</p>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    adminBookings: state.Data.adminBookings
});
const mapDispatchToProps = {changeStateValue, getBookings, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(AdminBookingDate)
