import React, { Component } from 'react'
import Header from '../../components/header/Header'
import SubjectRankings from '../../components/sections/SubjectRankings'
import Buttons from '../../components/Buttons'
import { deleteData, getSubjectRankings, getUniversities } from '../../actions/MainAction'
import { connect } from 'react-redux'
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts'
import { changeStateValue } from '../../redux/MainReducer'
import { getDropValue } from '../../actions/getOtherFunctions'

export class SubjectRankingPage extends Component {
    componentDidMount(){
        // this.props.getUniversities('', '', 'all')
    }
    state={
        subject_id:1,
        page:1
    }
    openModal=(data)=>{
        this.props.changeStateValue({
            name:'adminModal',
            value:{
              position: true,
              id: data?.id?data?.id:'',
              ranking: data?.ranking?data?.ranking:'',
              university_id: data?.university_id?data?.university_id:'',
              subject_id: data?.subject_id?data?.subject_id:this.state.subject_id,
              afterCallFunction:this.props.getSubjectRankings,
              afterCallParams:[this.state.page, this.state.subject_id],
              requireds: ["ranking","university_id", "subject_id"],
              url:'subject-rankings',
              modalTitle:`${data?.id?'Edit':'Add'} Subject ranking`,
            }
          })
    }

    onDelete=(id)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, delete it")
        .then(alertResp=>{
            if(alertResp === "success"){
            this.props.deleteData(`subject-rankings/${id}`)
            .then(resp=>{
                if(resp == "success"){
                    this.props.getSubjectRankings(this.props.page,this.state.subject_id);
                    mixinAlert("success", "Data deleted successfully")
                }else{
                    mixinAlert("error", "Something went wrong")
                }
            })
            }
        })
    }
  render() {
    return (
        <div className='w-full  pl-[27px] pr-[27px]'>
            <Header title="Subject Rankings" />
            <div className='flex justify-end mt-[30px]'>
                <Buttons padding onClick={this.openModal.bind(this, '')} options={[{title:"Add a subject rankings", value:0}]} selected={0} />
            </div>
            <div className='mt-[50px]'>
                <SubjectRankings onChangePage={(page)=>this.setState({page})} onChangeSubject={(id)=>this.setState({subject_id:id})} onDelete={this.onDelete.bind(this)} onEdit={this.openModal.bind(this)} />
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    universities: state.Data.universities
});
const mapDispatchToProps = {getSubjectRankings, deleteData, changeStateValue, getUniversities}
export default connect(mapStateToProps, mapDispatchToProps)(SubjectRankingPage)
