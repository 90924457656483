import React, { Component } from 'react'
import { changeStateValue } from '../../redux/MainReducer';
import {connect} from "react-redux"
export class DateBtn extends Component {
    state={
        visible:true
    }

    selectTime(time){
        this.props.changeStateValue({
            name:'bookingUserInfo.time',
            value: time
        })
    }
  render() {
    const {visible} = this.state;
    const {time} = this.props;
    return (
        <div className={`${visible?'flex gap-[10px] items-center w-[170px]':"grid grid-cols-2 gap-[10px] w-[170px]"} max-[600px]:w-full`}>
            {
                visible?
                <button onClick={()=>this.setState({visible:false})} className='h-[50px] w-[170px] border border-[#0169FF] text-[#0169FF] rounded-[7px] font-[600] max-[600px]:w-full'>{time}</button>:
                <>
                 <button  className='h-[50px]  border bg-[#717171] text-[#fff] rounded-[7px] font-[600] max-[600px]:w-full'>{time}</button>
                 <button onClick={this.selectTime.bind(this, time)} className='h-[50px]  border bg-[#0169FF] text-[#fff] rounded-[7px] font-[600] max-[600px]:w-full'>Növbəti</button>
                </>
            }
           
        </div>
    )
  }
}
const mapStateToProps = (state) =>({});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(DateBtn)
