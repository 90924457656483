import React, { Component } from 'react'
import StudentsSection from './sections/StudentsSection'
import NewsLetterSection from './sections/NewsLetterSection'
import Footer from './sections/Footer'
import { Helmet } from 'react-helmet'

export class AcceptedStudents extends Component {
  componentDidMount(){
    window.scrollTo(0,0)
  }
  render() {
    return (
        <div className='mt-[40px] bg-[#F6F8FB]'>
            <Helmet>
              <title>{"Qəbul Olmuş Tələbələr | CELTgo.com - Uğur Hekayələri"}</title>
              <meta property="og:locale" content="az_AZ" />
              <meta property="og:type" content="website" />
              <meta property="og:title" content="Qəbul Olmuş Tələbələr | CELTgo.com - Uğur Hekayələri" />
              <meta property="og:description" content="CELTgo.com ilə xaricdə təhsil arzusunu gerçəkləşdirən tələbələrimizin uğur hekayələrini kəşf edin. Onların təcrübələri və nailiyyətləri ilə tanış olun." />
              <meta name="description" content={"CELTgo.com ilə xaricdə təhsil arzusunu gerçəkləşdirən tələbələrimizin uğur hekayələrini kəşf edin. Onların təcrübələri və nailiyyətləri ilə tanış olun."}/>
              <meta name="keywords" content={"qəbul olmuş tələbələr, uğur hekayələri, xaricdə təhsil uğurları, universitetə qəbul, tələbə təcrübələri, xaricdə təhsil nailiyyətləri, qəbul olma hekayələri"}/>
              <meta property="og:url" content="https://celtgo.com/accepted-students" />
              <meta property="og:site_name" content="CELT Colleges" />
              <meta property="article:publisher" content="https://www.facebook.com/celtscollege" />
              <meta property="article:modified_time" content="2024-06-06T08:56:25+00:00" />
              <meta property="og:image" content="https://celtgo.com/static/media/celtLogo6.05ddd884642befe11a4e.png" />
              <meta property="og:image:width" content="900" />
              <meta property="og:image:height" content="600" />
              <meta property="og:image:type" content="image/jpeg" />
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:title" content="Qəbul Olmuş Tələbələr | CELTgo.com - Uğur Hekayələri" />
              <meta name="twitter:description" content="CELTgo.com ilə xaricdə təhsil arzusunu gerçəkləşdirən tələbələrimizin uğur hekayələrini kəşf edin. Onların təcrübələri və nailiyyətləri ilə tanış olun." />
              <meta name="twitter:site" content="@celtcolleges" />
              <meta name="twitter:label1" content="Est. reading time" />
              <meta name="twitter:data1" content="7 minutes" />
            </Helmet>
            <div className='pb-[30px] pt-[40px] bg-white'>
                <StudentsSection loadMoreBtn={true} page="1" />
            </div>
            {/* <NewsLetterSection /> */}
            <Footer />
        </div>
    )
  }
}

export default AcceptedStudents
