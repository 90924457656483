import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { connect } from 'react-redux'
import { getMesages } from '../../actions/MainAction'
import MessageBox from '../messages/MessageBox'
export class StudentMessage extends Component {
    state={
        messages:[]
      }
      componentDidMount(){
        this.props.getMesages({page:1, message_type:"student_box", student_id:this.props.student_id})
        .then(resp=>{
          this.setState({messages:resp})
        })
      }
      getAfterChange=()=>{
        this.props.getMesages({page:1, message_type:"student_box", student_id:this.props.student_id})
        .then(resp=>{
          this.setState({messages:resp})
        })
      }
  render() {
    const {user} = this.props;
    const {messages} = this.state;

    return (
        <div className='w-full  pl-[27px] pr-[27px] max-[450px]:pl-[10px] max-[450px]:pr-[10px]'>
        {/* <Header title={"Messages"} /> */}
        <div className='mt-[70px]'>
            <MessageBox student_id={user?.id} getAfterChange={this.getAfterChange.bind(this)} messagesParams={messages} />
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    user: state.Data.user
});
const mapDispatchToProps = {getMesages}
export default connect(mapStateToProps, mapDispatchToProps)(StudentMessage)
