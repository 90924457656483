import React, { Component } from 'react'

export class PhoneSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={14}
        height={14}
        fill="none"
      >
        <path
          stroke="#6C6C6C"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12.091 9.073c-1.004-.99-2.144.322-2.897 1.066-2.272.448-6.361-3.589-5.452-5.383.752-.743 2.083-1.868 1.079-2.859C3.817.907 2.393.706 1.64 1.45c-.626.616-1.08 1.962.056 4.877 1.136 2.916 2.953 4.71 6.058 5.98 3.106 1.271 4.166.524 4.79-.093.753-.743.55-2.149-.454-3.14Z"
        />
      </svg>
    )
  }
}

export default PhoneSvg
