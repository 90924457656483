import React, { Component } from 'react'

export class InpEmailSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        width={17}
        height={17}
        viewBox="0 0 512 512"
      >
        <path
          d="M510.678 112.275a70.823 70.823 0 0 0-14.662-31.054c-1.518-1.915-3.104-3.63-4.823-5.345-12.755-12.818-30.657-20.814-50.214-20.814H71.021c-19.557 0-37.395 7.996-50.21 20.814-1.715 1.715-3.301 3.43-4.823 5.345-7.203 8.788-12.358 19.428-14.602 31.054A68.69 68.69 0 0 0 0 126.087V385.92c0 9.968 2.114 19.55 5.884 28.203 3.497 8.26 8.653 15.734 14.926 22.001a77.4 77.4 0 0 0 4.892 4.494 70.957 70.957 0 0 0 45.319 16.32h369.958c17.18 0 33.108-6.145 45.323-16.384 1.718-1.386 3.305-2.844 4.891-4.43 6.27-6.267 11.425-13.741 14.994-22.001v-.064c3.769-8.653 5.812-18.171 5.812-28.138V126.087a72.986 72.986 0 0 0-1.321-13.812zM46.509 101.571c6.345-6.338 14.866-10.175 24.512-10.175h369.958c9.646 0 18.242 3.837 24.512 10.175a34.402 34.402 0 0 1 3.112 3.637L274.696 274.203c-5.348 4.687-11.954 7.002-18.696 7.002-6.674 0-13.276-2.315-18.695-7.002L43.472 105.136c.858-1.25 1.915-2.436 3.037-3.565zM36.334 385.92V142.735L176.658 265.15 36.405 387.435c-.071-.464-.071-.986-.071-1.515zm404.645 34.677H71.021c-6.281 0-12.158-1.651-17.174-4.552l147.978-128.959 13.815 12.018c11.561 10.046 26.028 15.134 40.36 15.134a61.64 61.64 0 0 0 40.432-15.134l13.808-12.018 147.92 128.959c-5.023 2.901-10.9 4.552-17.181 4.552zm34.687-34.677c0 .529 0 1.051-.068 1.515L335.346 265.221 475.666 142.8v243.12z"
          style={{
            fill: "gray",
          }}
        />
      </svg>
    )
  }
}

export default InpEmailSvg
