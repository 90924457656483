import React, { Component } from 'react'

export class TrackingLine extends Component {
  render() {
    const {page=1} = this.props;
    return (
        <div className='flex justify-between items-center'>
            <span className='inline-flex max-w-[25px] min-w-[25px] h-[25px] rounded-full bg-[#264EA9] text-white font-medium text-[14px] justify-center items-center'>
                1
            </span>
            <span className='bg-[#264EA9] h-[2px] inline-block w-full'>

            </span>
            <span className={`inline-flex max-w-[25px] min-w-[25px] h-[25px] rounded-full ${page>1? 'bg-[#264EA9]': 'bg-[#DCDCDC]' } text-white font-medium text-[14px] justify-center items-center`}>
                2
            </span>
            <span  className={`${page>1? 'bg-[#264EA9]': 'bg-[#DCDCDC]' }  h-[2px] inline-block w-full`}>

            </span>
            <span className={`inline-flex max-w-[25px] min-w-[25px] h-[25px] rounded-full ${page>2? 'bg-[#264EA9]': 'bg-[#DCDCDC]' } text-white font-medium text-[14px] justify-center items-center`}>
                3
            </span>
            <span  className={`${page>2? 'bg-[#264EA9]': 'bg-[#DCDCDC]' }  h-[2px] inline-block w-full`}>

            </span>
            <span className={`inline-flex max-w-[25px] min-w-[25px] h-[25px] rounded-full ${page>3? 'bg-[#264EA9]': 'bg-[#DCDCDC]' } text-white font-medium text-[14px] justify-center items-center`}>
                4
            </span>
        </div>
    )
  }
}

export default TrackingLine
