import React, { Component } from 'react'
import Header from './components/Header'
import UploadFileSvg from '../svg/UploadFileSvg'
import UploadExamSvg from '../svg/UploadExamSvg'
import { changeStateValue, resetStateValue } from '../../redux/MainReducer'
import { connect } from 'react-redux'
import PdfSvg from '../svg/PdfSvg'
import Pdf2Svg from '../svg/Pdf2Svg'
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts'
import { getPersonalInformation, getStudentComplete, insertNewData } from '../../actions/MainAction'

export class StudentCvModal extends Component {
  state={
    btnDisable:false
  }
  closeBtn=()=>{
    this.props.resetStateValue({name:'studentCvModal'})
  }
  getBase64(file){
    return new Promise((res, rej)=>{
        let reader = new FileReader();
        reader.onload = () => {
            res(reader.result);
        }
        reader.readAsDataURL(file)
    })
  }

  uploadFile(e){
    let formData = new FormData();
    formData.append("cv", e.target.files[0])
    formData.append("cv_type", e.target.files[0]?.type)
    formData.append("student_id", this.props.studentCvModal?.student_id)
    formData.append("id", this.props.studentPersonalInformation?.info?.id?this.props.studentPersonalInformation?.info?.id:"")
    this.props.insertNewData("student-personal-information", formData)
    .then(resp=>{
      this.setState({btnDisable:false});
      if(resp === "success"){
        mixinAlert("success", "CV uğurla yükləndi")
        this.props.getPersonalInformation( this.props.studentCvModal?.student_id);
        this.props.getStudentComplete()
        // this.closeBtn();
      }else{
        mixinAlert("error", "Xəta baş verdi");
      }
    })
  }

 
  removeCVBtn=(data)=>{
    confirmAlert("warning", "Əminsiniz?","Sildikdən sonra geri qaytarmaq olmayacaq", "Bəli", "Xeyir")
    .then(alertResp=>{
        if(alertResp === "success"){
          if(data?.id && data?.id !=""){
            this.props.insertNewData("student-personal-information", {cv:"delete", id:data?.id, student_id:  this.props.studentCvModal?.student_id})
            .then(resp=>{
              this.setState({btnDisable:false});
              if(resp === "success"){
                mixinAlert("success", "CV uğurla silindi")
                this.props.getPersonalInformation( this.props.studentCvModal?.student_id);
                this.props.getStudentComplete()
              
              }else{
                mixinAlert("error", "Xəta baş verdi");
              }
            })
          }else{
          }
        }
    })
  }
  render() {
    const {studentCvModal, studentPersonalInformation} = this.props;
    const {btnDisable} = this.state;
    // console.log(studentPersonalInformation)
    return (
      <div className='background_shadow flex justify-center items-center '>
        <div className='bg-white rounded-[10px] w-[600px] max-[750px]:ml-[20px] max-[750px]:mr-[20px]'>
          <Header title="CV"  closeBtn={this.closeBtn.bind(this)} />
          <div className='p-[20px] flex flex-col justify-center items-center w-full'>
              <label htmlFor='cvFileUpload' className='cursor-pointer bg-gray-100 inline-flex w-[80px] h-[80px] justify-center items-center rounded-full'>
                <UploadExamSvg />
              </label>
              <input onChange={this.uploadFile.bind(this)} id="cvFileUpload" type='file' accept='image/*, application/pdf' className='hidden' />
              {
                studentPersonalInformation?.info?.cv_type ==="application/pdf"?
                <a className='mt-[20px] inline-block' target='_blank' href={studentPersonalInformation?.info?.cv_full_url}>
                  <Pdf2Svg />
                </a>:
                <a  className='mt-[20px] inline-block' target='_blank'  href={studentPersonalInformation?.info?.cv_full_url}>
                  <img src={studentPersonalInformation?.info?.cv_full_url} className='max-w-[200px] mt-[20px]' />
                </a>
                
              }
              {
                studentPersonalInformation?.info?.cv ?
                <button disabled={btnDisable} onClick={this.removeCVBtn.bind(this, studentPersonalInformation?.info)} className='mt-[20px] bg-orange text-[12px] text-white h-[30px] p-[0_10px] rounded-full'>
                  Sil
                </button>:null
              }
            
          </div>
         
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  studentCvModal: state.Data.studentCvModal,
  studentPersonalInformation: state.Data.studentPersonalInformation,
});
const mapDispatchToProps = {changeStateValue, resetStateValue, insertNewData,getStudentComplete, getPersonalInformation}
export default connect(mapStateToProps, mapDispatchToProps)(StudentCvModal)
