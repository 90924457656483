import React, { Component } from 'react'
import ReplySvg from '../../../components/svg/ReplySvg';
import PinSvg from '../../../components/svg/PinSvg';
import EraseSvg from '../../../components/svg/EraseSvg';
import { connect } from 'react-redux';
import { insertNewData, deleteData } from '../../../actions/MainAction';
import { confirmAlert, mixinAlert } from '../../../actions/SweetAlerts';
export class Tooltip extends Component {
    onClickPin=(data)=>{
        this.props.insertNewData('messages-pin', {message_id: data?.id})
        .then(resp=>{
            this.props.getAfterChange();
            mixinAlert("success", "Mesaj uğurla sabitləndi");
            this.props.onClose()
        })
    }
    deleteData=(data)=>{
        confirmAlert("warning", "Əminsiniz?", "Sildikdən sonra geri qaytara bilmiyəcəksiniz!")
        .then(alertResp=>{
            if(alertResp === "success"){
                this.props.deleteData(`messages/${data?.id}`)
                .then(resp=>{
                    this.props.getAfterChange();
                    mixinAlert("success", "Mesaj uğurla silindi");
                    this.props.onClose()
                })
            }
        })
    }
  render() {
    const {left, onReply=()=>{}, user, data, lang, msgBoxInfo} = this.props;
    return (
        <div className={`w-[80px] p-[5px] gap-[6px] rounded-[10px] shadow bg-white absolute ${left? 'right-[20px] top-[-15px] max-[650px]:right-[-80px]':'right-[-78px] top-[-15px]' }  flex flex-col z-50`}>
            <button onClick={()=>onReply()} className='flex items-center gap-[5px] text-[13px] txt-light-black font-[300]'>
                <ReplySvg />
                <span>Cavabla</span>
            </button>
            <button onClick={this.onClickPin.bind(this, data, msgBoxInfo)} className='flex items-center gap-[5px] text-[13px] txt-light-black font-[300]'>
                <PinSvg />
                <span>Sabitlə</span>
            </button>
            <button onClick={this.deleteData.bind(this, data)} className='flex items-center gap-[5px] text-[13px] txt-light-black font-[300]'>
                <EraseSvg />
                <span>Sil</span>
            </button>
        
        </div>
    )
  }
}
const mapStateToProps = (state) =>({});
const mapDispatchToProps = {insertNewData, deleteData}
export default connect(mapStateToProps, mapDispatchToProps)(Tooltip)
