import React, { Component } from 'react'
import Header from '../../components/header/Header'
import Search from '../../components/Search'
import Buttons from '../../components/Buttons'
import { connect } from 'react-redux'
import { changeStateValue } from '../../redux/MainReducer';
import { getCountrySch } from '../../actions/MainAction';
import Card1 from '../../components/cards/Card1'
import LoadMoreBtn from '../../components/LoadMoreBtn'
export class AdminCountryScholarship extends Component {
  
  openModal=(data)=>{
    this.props.changeStateValue({
      name:'adminModal',
      value:{
        position: true,
        id: data?.id?data?.id:'',
        name: data?.name?data?.name:'',
        text: data?.text?data?.text:'',
        afterCallFunction:this.props.getCountrySch,
        afterCallParams:[],
        requireds: ["name"],
        url:'country-scholarship',
        modalTitle:`${data?.id?'Edit':'Add'} Country Scholarship`
      }
    })
  }

  componentDidMount(){
    this.props.getCountrySch(1, '')
  }

 

  afterCallFunction=()=>{
    this.props.getCountrySch()
  }
  render() {
    const {countryScholarship, user} = this.props;
  
    return (
        <div className='w-full  pl-[27px] pr-[27px]'>
            <Header title="Country Scholarships" />
            <div className='flex justify-end mt-[30px]'>
                <Buttons padding onClick={this.openModal.bind(this, '')} options={[{title:"Add a Country Scholarship", value:0}]} selected={0} />
            </div>
           
            <div className='grid-full-250 mt-[30px]'>
              {
                countryScholarship?.map((data, i)=>{
                  return(
                    <Card1 
                      key={i}
                      name={data?.name}
                      onClickEdit={this.openModal.bind(this, data)}
                      deletePath={user?.role == "admin"? `country-scholarship/${data?.id}`:""}
                      afterCallFunction={this.afterCallFunction.bind(this)}
                      moreLink={`/sch/${data?.id}`}
                    />
                  )
                })
              }
            </div>
           
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
  countryScholarship: state.Data.countryScholarship,
  user: state.Data.user,
});
const mapDispatchToProps = {changeStateValue, getCountrySch}
export default connect(mapStateToProps, mapDispatchToProps)(AdminCountryScholarship)







