import React, { Component } from 'react'
import { getSubjectRankings, getSubjects } from '../../actions/MainAction';
import { connect } from 'react-redux';
import LoadMoreBtn from '../LoadMoreBtn';

export class SubjectRankings extends Component {
    state={
        subject_id:1,
        page:1
    }
    componentDidMount(){
        this.props.getSubjectRankings(1,1);
        this.props.getSubjects(1);
    }
    getData=(id)=>{
        this.setState({subject_id:id})
        this.props.getSubjectRankings(this.state.page, id);
        this.props.onChangeSubject(id)
    }
    loadMore=(page)=>{
        this.setState({page});
        this.props.onChangePage(page)
        this.props.getSubjectRankings(page, this.state.subject_id);
    }
  render() {
    const {subjectRankings, user, onDelete=()=>{}, onEdit=()=>{}, subjectsData} = this.props;
    const {subject_id} = this.state;
    // console.log(subjectRankings)
    // onChangePage
    return (
      <div className='w-full'>
        <div className='flex gap-[20px] flex-wrap'>
            {
                subjectsData?.map((data, i) => {
                    return (
                        <button onClick={this.getData.bind(this, data?.id)} key={i} className={`text-[12px] p-[5px_9px] rounded-[10px] border border-[var(--blue)] ${subject_id == data?.id? "bg-blue text-white ":"text-blue "}`}>{data?.title}</button>
                    )
                })
            }
        </div>
        <table className='w-full bg-white rounded-[10px] mt-[20px]'>
            <thead>
                <tr>
                    <th className='w-1/4 border-r text-[18px] font-semibold text-center h-[40px]'>Ranking</th>
                    <th className='w-1/4 border-r text-[18px] font-semibold text-center h-[40px]'>University</th>
                    <th className='w-1/4 border-r text-[18px] font-semibold text-center h-[40px]'>Subject</th>
                    <th className='w-1/4 text-[18px] font-semibold text-center h-[40px]'>Country</th>
                    {
                        user?.role == "admin"?
                        <th className='w-1/4 text-[18px] font-semibold text-center h-[40px] border-l'>Action</th>:null
                    }
                    
                </tr>
            </thead>
            <tbody>
                {
                    subjectRankings?.map((data, i) => {
                        return(
                            <tr className='border-t  h-[40px]' key={i}>
                                <td className='text-center border-r text-[14px]'>{data?.ranking}</td>
                                <td className='text-center border-r text-[14px]'>{data?.university?.name}</td>
                                <td className='text-center border-r text-[14px]'>{data?.subject?.title}</td>
                                <td className='text-center text-[14px]'>{data?.university?.country?.name}</td>
                                
                                {
                                    user?.role == "admin"?
                                    <td className='text-center text-[14px] flex items-center gap-[20px] pr-[20px] border-l h-[40px] pl-[20px]'>
                                        <button onClick={()=>onEdit(data)} className='bg-blue text-white text-[12px] p-[4px_8px] rounded-[10px]'>Edit</button>
                                        <button onClick={()=>onDelete(data?.id)} className='bg-red text-white text-[12px] p-[4px_8px] rounded-[10px]'>Delete</button>
                                    </td>:null
                                }
                                
                            </tr>
                        )
                    })
                }
               
            </tbody>
        </table>
        {
                subjectRankings?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={subjectRankings?.length} />:null
            }
        
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
    subjectRankings: state.Data.subjectRankings,
    user: state.Data.user,
    subjectsData: state.Data.subjectsData,
});
const mapDispatchToProps  = {getSubjectRankings, getSubjects}
export default connect(mapStateToProps, mapDispatchToProps)(SubjectRankings)


