import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import LoadRightSvg from './svg/LoadRightSvg';

export class ViewAllBtn extends Component {
  render() {
    const {to} = this.props;
    return (
      <div className='flex justify-center items-center mt-[30px]' >
        <Link to={to} className='border border-[#545454] w-[187px] inline-flex justify-center items-center h-[40px] rounded-full bg-white text-[#545454] text-[15px] font-[300] gap-[10px]'>
        Daha çox
            <LoadRightSvg />
        </Link>
      </div>
    )
  }
}

export default ViewAllBtn
