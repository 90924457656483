import React, { Component } from 'react';
import BookingInfoComponent from '../../../components/public_components/BookingInfoComponent';
import BookingCalendarComponent from '../../../components/public_components/BookingCalendarComponent';
import BookingDateComponent from '../../../components/public_components/BookingDateComponent';
import BookingDetailsComponent from '../../../components/public_components/BookingDetailsComponent';
import {connect} from "react-redux"
import BookingLastSection from './BookingLastSection';
export class BookingCalendarSection extends Component {
  render() {
    const {bookingUserInfo, bookingLastModal} = this.props;
    return (
      <div className='flex items-start shadow rounded-[10px] max-[900px]:flex-col'>
        {
          !bookingLastModal?
          <>
             <BookingInfoComponent />
            {
              bookingUserInfo?.time === ""?
              <BookingCalendarComponent />:
              <BookingDetailsComponent /> 
            }
          </>:
          <>
            <BookingLastSection />
          </>
        }
         
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  bookingUserInfo: state.Data.bookingUserInfo,
  bookingLastModal: state.Data.bookingLastModal,
})
export default connect(mapStateToProps)(BookingCalendarSection)
