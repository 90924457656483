import React, { Component } from 'react'
import { connect } from 'react-redux';
import DropSearch from './components/DropSearch';
import Input from './components/Input';
import { getDropValue } from '../../actions/getOtherFunctions';
import { getCurrencies, getExpencesCategories, insertNewData, getStudentExpences } from '../../actions/MainAction';
import { changeStateValue, resetStateValue } from '../../redux/MainReducer';
import { mixinAlert } from '../../actions/SweetAlerts';
import Header from './components/Header';
export class StudentExpencesModal extends Component {
    state={
        btnDisable:false
    }
    componentDidMount(){
        this.props.getCurrencies();
        this.props.getExpencesCategories();
    }

    closeModal=()=>{
        this.props.resetStateValue({
            name:"studentExpences"
        })
    }

    addData=(data)=>{
        if(data?.currency_id !=='' && data?.category_id !=="" && data?.amount !==""){
            let formData = new FormData();
            for(const property in data){
                if(data[property])
                formData.append(property, data[property]);
            }
            this.setState({btnDisable:true})
            let url = 'student-applied-expences';
            if(data?.id && data?.id !==""){
                formData.append("_method", "put");
                url = `student-applied-expences/${data?.id}`
            }
            this.props.insertNewData(url, formData)
            .then(resp=>{
                this.closeModal()
                this.setState({btnDisable:false})
                if(resp === 'success'){
                    this.props.getStudentExpences(data.student_id)
                    mixinAlert("success", "Added successfully");
                }else{
                    mixinAlert("error", "Something went wrong");
                }
            })
        }else{
            mixinAlert("warning", "Please fill the all required inputs.")
        }
    }
  render() {
    const {studentExpences, expencesCategories, currencies} = this.props;
    const {btnDisable} = this.state;
    return (
        <div className='background_shadow flex justify-center items-center'>
            <div className='bg-white w-[700px] max-h-[70vh] rounded-[10px] shadow'>
                <Header title={studentExpences?.modalTitle} closeBtn={this.closeModal.bind(this)} />
                <div style={{ height:'auto', maxHeight: 'calc(70vh - 50px)' }} className='p-[20px]'>
                    <div className='grid grid-cols-2 gap-[20px]'>
                        <DropSearch options={getDropValue(expencesCategories, "title", "id")} placeholder="Select a category" title="Select a category" value={studentExpences.category_id} name="studentExpences.category_id" />
                        <DropSearch options={getDropValue(currencies, "name", "id")} placeholder="Select a currency" title="Select a currency" value={studentExpences.currency_id} name="studentExpences.currency_id" />
                        <Input required name="studentExpences.amount" type="number" value={studentExpences?.amount} title={'Amount'}  />
                    </div>
                    <div className='flex justify-end mt-[20px]'>
                    <button disabled={btnDisable} onClick={this.addData.bind(this, studentExpences)} className='h-[25px] p-[0_10px] bg-blue text-white rounded-[5px] text-[12px]'>Save</button>
                </div>
                </div>
               
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    studentExpences: state.Data.studentExpences,
    expencesCategories: state.Data.expencesCategories,
    currencies: state.Data.currencies,
});
const mapDispatchToProps = {getExpencesCategories, getCurrencies, changeStateValue, resetStateValue, insertNewData, getStudentExpences}
export default connect(mapStateToProps, mapDispatchToProps)(StudentExpencesModal)
