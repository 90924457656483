import React, { Component } from 'react'
import MsgSearchSvg from '../../../components/svg/MsgSearchSvg'

export class ChatSearch extends Component {
    state={
        search:''
    }
    searchBtn=(search, e)=>{
        e.preventDefault();
        this.props.onSearch(search)
    }
  render() {
    const {search} = this.state;
    return (
      <form onSubmit={this.searchBtn.bind(this, search)} className='flex items-center bg-[#F8FAFC] h-[38px] rounded-[12px] mt-[20px] pl-[4px]  m-[0_20px] pr-[10px] gap-[4px]'>
        <input value={search} onChange={(e)=>{this.setState({search: e.target.value}); if(e.target.value == ""){this.props.onSearch("")}}} placeholder='Axtar' className='text-[14px] txt-light-gray w-full bg-[#F8FAFC] outline-none' type='text'  />
        <button>
            <MsgSearchSvg />
        </button>
      </form>
    )
  }
}

export default ChatSearch
