import React, { Component } from 'react'

export class CardRightSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={5}
        height={8}
        fill="none"
      >
        <path
          fill="#fff"
          d="M1.071 7.75a.322.322 0 0 1-.25-.113.384.384 0 0 1 0-.532l2.964-3.113L.821.887a.384.384 0 0 1 0-.532.342.342 0 0 1 .507 0l3.207 3.382a.384.384 0 0 1 0 .533L1.321 7.637a.35.35 0 0 1-.25.113Z"
        />
      </svg>
    )
  }
}

export default CardRightSvg
