import React, { Component } from 'react'
import CountriesSection from './sections/CountriesSection'
import NewsLetterSection from './sections/NewsLetterSection'
import Footer from './sections/Footer'
import { Helmet } from 'react-helmet'

export class CountriesPage extends Component {
  componentDidMount(){
    window.scrollTo(0,0)
  }
  render() {
    return (
        <div className='bg-[#fff] mt-[-40px]'>
          <Helmet>
            <title>{"Xaricdə Təhsil Üçün Ən Yaxşı Ölkələr | CELTgo.com"}</title>
            <meta property="og:locale" content="az_AZ" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Xaricdə Təhsil Üçün Ən Yaxşı Ölkələr | CELTgo.com" />
            <meta property="og:description" content="Xaricdə təhsil üçün ölkə seçimi edərkən doğru qərarı verin. CELTgo.com olaraq sizə ABŞ, Kanada, İngiltərə, Avstraliya, Almaniya və digər ölkələrdə təhsil imkanlarını təqdim edirik." />
            <meta name="description" content={"Xaricdə təhsil üçün ölkə seçimi edərkən doğru qərarı verin. CELTgo.com olaraq sizə ABŞ, Kanada, İngiltərə, Avstraliya, Almaniya və digər ölkələrdə təhsil imkanlarını təqdim edirik."}/>
            <meta name="keywords" content={"xaricdə təhsil ölkələri, xaricdə təhsil üçün ən yaxşı ölkələr, təhsil üçün ölkə seçimi, ABŞ-da təhsil, Kanadada təhsil, İngiltərədə təhsil, Almaniyada təhsil, Avstraliyada təhsil, xaricdə təhsil proqramları"}/>
            <meta property="og:url" content="https://celt.az/" />
            <meta property="og:site_name" content="CELT Colleges" />
            <meta property="article:publisher" content="https://www.facebook.com/celtscollege" />
            <meta property="article:modified_time" content="2024-06-06T08:56:25+00:00" />
            <meta property="og:image" content="https://celtgo.com/static/media/celtLogo6.05ddd884642befe11a4e.png" />
            <meta property="og:image:width" content="900" />
            <meta property="og:image:height" content="600" />
            <meta property="og:image:type" content="image/jpeg" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="Xaricdə Təhsil Üçün Ən Yaxşı Ölkələr | CELTgo.com" />
            <meta name="twitter:description" content="Xaricdə təhsil üçün ölkə seçimi edərkən doğru qərarı verin. CELTgo.com olaraq sizə ABŞ, Kanada, İngiltərə, Avstraliya, Almaniya və digər ölkələrdə təhsil imkanlarını təqdim edirik." />
            <meta name="twitter:site" content="@celtcolleges" />
            <meta name="twitter:label1" content="Est. reading time" />
            <meta name="twitter:data1" content="7 minutes" />
          </Helmet>
            <CountriesSection loadMoreBtn={false} page={'5'} />
            {/* <NewsLetterSection /> */}
            <Footer />
        </div>
    )
  }
}

export default CountriesPage
