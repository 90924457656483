import React, { Component } from 'react'

export class DOBSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={13}
        height={14}
        fill="none"
      >
        <path
          fill="#6C6C6C"
          d="M11.813 1.125h-1.594V.594a.531.531 0 0 0-1.063 0v.531H3.844V.594a.531.531 0 1 0-1.063 0v.531H1.187A1.062 1.062 0 0 0 .125 2.188v10.624a1.063 1.063 0 0 0 1.063 1.063h10.624a1.062 1.062 0 0 0 1.063-1.063V2.188a1.062 1.062 0 0 0-1.063-1.063ZM2.78 2.188v.53a.531.531 0 1 0 1.063 0v-.53h5.312v.53a.531.531 0 1 0 1.063 0v-.53h1.594v2.124H1.187V2.188h1.594Zm9.031 10.624H1.188V5.375h10.624v7.438ZM7.298 7.766a.797.797 0 1 1-1.594 0 .797.797 0 0 1 1.594 0Zm2.922 0a.797.797 0 1 1-1.595 0 .797.797 0 0 1 1.595 0Zm-5.844 2.656a.797.797 0 1 1-1.594 0 .797.797 0 0 1 1.594 0Zm2.922 0a.797.797 0 1 1-1.594 0 .797.797 0 0 1 1.594 0Zm2.922 0a.797.797 0 1 1-1.595 0 .797.797 0 0 1 1.595 0Z"
        />
      </svg>
    )
  }
}

export default DOBSvg
