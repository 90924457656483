import React, { Component } from 'react'
import { getPublicExams } from '../../../actions/PublicActions';
import { connect } from 'react-redux';
import { changeStateValue } from '../../../redux/MainReducer';
import InpSvg from '../../../components/svg/InpSvg';
import Input from '../../../components/modals/components/Input';
import CardEditSvg from '../../../components/svg/CardEditSvg';
import DeleteSvg from '../../../components/svg/DeleteSvg';

export class Exams extends Component {
    state={
        modalPosition:false,
        examData:""
    }
    componentDidMount(){
        this.props.getPublicExams();
    }

    onSelectExam=(exam)=>{
        this.setState({
            modalPosition: true,
            examData: exam
        })
        // let name = exam?.toLowerCase()?.replaceAll(" ", "_");
        // let system_exam = this.props.educationPlanModalData?.exams[name];
        // if(system_exam?.selected){
        //     this.props.changeStateValue({
        //         name:`educationPlanModalData.exams.${name}`,
        //         value:{selected:false, value:''}
        //     })
        // }else{
        //     this.props.changeStateValue({
        //         name:`educationPlanModalData.exams.${name}.selected`,
        //         value: true
        //     })
        // }
        // console.log(name)
    }

    closeGradeModal=(e)=>{
        if(typeof e.target.className == "string" && e.target.className?.includes("background_shadow")){
            this.setState({  
                modalPosition:false,
                examData:""
            })
            if(!this.props.educationPlanModalData.exams[this.state.examData?.name?.toLowerCase()?.replaceAll(" ", "_")]?.selected){
                this.props.changeStateValue({
                    name:`educationPlanModalData.exams.${this.state.examData?.name?.toLowerCase()?.replaceAll(" ", "_")}`,
                    value:{selected: false, value:''}
                })
            }
            
        }
    }

    onSaveScore=()=>{
        this.props.changeStateValue({
            name:`educationPlanModalData.exams.${this.state.examData?.name?.toLowerCase()?.replaceAll(" ", "_")}.selected`,
            value: true
        })
        this.setState({  
            modalPosition:false,
            examData:""
        })
    }
  render() {
    const {publicExams, educationPlanModalData} = this.props;
    const {modalPosition, examData} = this.state;
    console.log(educationPlanModalData)
    return (
     <div>
        <div className='grid grid-cols-3 max-[500px]:grid-cols-2 max-[400px]:grid-cols-1 gap-[20px] mt-[20px]'>
        {
            publicExams?.map((data, i)=>{
                return(
                    <button onClick={this.onSelectExam.bind(this, data)} 
                    className={`border border-[1px] border-[var(--blue)] h-[40px]  font-semibold rounded-[8px] ${educationPlanModalData?.exams[data?.name?.toLowerCase()?.replaceAll(" ", "_")]?.selected? "bg-blue text-white":"txt-blue"} `} key={i}>
                        {data?.name}
                    </button>
                )
            })
        }
        </div>

        {
              educationPlanModalData?.exams?.ielts?.selected ||
              educationPlanModalData?.exams?.ielts_ukvi?.selected ||
              educationPlanModalData?.exams?.toefl?.selected ||
              educationPlanModalData?.exams?.gre?.selected ||
              educationPlanModalData?.exams?.gmat?.selected ||
              educationPlanModalData?.exams?.sat?.selected ||
              educationPlanModalData?.exams?.dim?.selected?
              <div className='border border-dashed mt-[20px] border-[var(--blue)]'>
                
              </div>:null
        }

        <div className='grid grid-cols-3 max-[500px]:grid-cols-2 max-[400px]:grid-cols-1 gap-[20px] mt-[30px]'>
            {
                educationPlanModalData?.exams?.ielts?.selected?
                <div className='relative '>
                    <button onClick={()=>this.props.changeStateValue({name:"educationPlanModalData.exams.ielts", value:{selected:false, value:''}})} className='absolute right-[-10px] top-[-13px] bg-white border rounded-full p-[2px] border-red-700'>
                        <DeleteSvg />
                    </button>
                    <div className='border border-[var(--blue)] flex gap-[15px] items-center justify-center h-[35px] rounded-[8px]'>
                        <h4 className='txt-blue font-semibold'>IELTS</h4>
                        <span className='txt-blue font-semibold'>{ educationPlanModalData?.exams?.ielts?.value}</span>
                        <button onClick={()=>this.setState({modalPosition:true, examData: publicExams?.find(x=>x.name == "IELTS")})}>
                            <CardEditSvg />
                        </button>
                    </div>
                </div>:null
            }
            {
                educationPlanModalData?.exams?.gmat?.selected?
                <div className='relative '>
                    <button onClick={()=>this.props.changeStateValue({name:"educationPlanModalData.exams.gmat", value:{selected:false, value:''}})} className='absolute right-[-10px] top-[-13px] bg-white border rounded-full p-[2px] border-red-700'>
                        <DeleteSvg />
                    </button>
                    <div className='border border-[var(--blue)] flex gap-[15px] items-center justify-center h-[35px] rounded-[8px]'>
                        <h4 className='txt-blue font-semibold'>GMAT</h4>
                        <span className='txt-blue font-semibold'>{ educationPlanModalData?.exams?.gmat?.value}</span>
                        <button onClick={()=>this.setState({modalPosition:true, examData: publicExams?.find(x=>x.name == "GMAT")})}>
                            <CardEditSvg />
                        </button>
                    </div>
                </div>:null
            }
            {
                educationPlanModalData?.exams?.ielts_ukvi?.selected?
                <div className='relative '>
                    <button onClick={()=>this.props.changeStateValue({name:"educationPlanModalData.exams.ielts_ukvi", value:{selected:false, value:''}})} className='absolute right-[-10px] top-[-13px] bg-white border rounded-full p-[2px] border-red-700'>
                        <DeleteSvg />
                    </button>
                    <div className='border border-[var(--blue)] flex gap-[15px] items-center justify-center h-[35px] rounded-[8px]'>
                        <h4 className='txt-blue font-semibold'>IELTS UKVI</h4>
                        <span className='txt-blue font-semibold'>{ educationPlanModalData?.exams?.ielts_ukvi?.value}</span>
                        <button onClick={()=>this.setState({modalPosition:true, examData: publicExams?.find(x=>x.name == "IELTS UKVI")})}>
                            <CardEditSvg />
                        </button>
                    </div>
                </div>:null
            }
            {
                educationPlanModalData?.exams?.toefl?.selected?
                <div className='relative '>
                    <button onClick={()=>this.props.changeStateValue({name:"educationPlanModalData.exams.toefl", value:{selected:false, value:''}})} className='absolute right-[-10px] top-[-13px] bg-white border rounded-full p-[2px] border-red-700'>
                        <DeleteSvg />
                    </button>
                    <div className='border border-[var(--blue)] flex gap-[15px] items-center justify-center h-[35px] rounded-[8px]'>
                        <h4 className='txt-blue font-semibold'>TOEFL</h4>
                        <span className='txt-blue font-semibold'>{ educationPlanModalData?.exams?.toefl?.value}</span>
                        <button onClick={()=>this.setState({modalPosition:true, examData: publicExams?.find(x=>x.name == "TOEFL")})}>
                            <CardEditSvg />
                        </button>
                    </div>
                </div>:null
            }
            {
                educationPlanModalData?.exams?.gre?.selected?
                <div className='relative '>
                    <button onClick={()=>this.props.changeStateValue({name:"educationPlanModalData.exams.gre", value:{selected:false, value:''}})} className='absolute right-[-10px] top-[-13px] bg-white border rounded-full p-[2px] border-red-700'>
                        <DeleteSvg />
                    </button>
                    <div className='border border-[var(--blue)] flex gap-[15px] items-center justify-center h-[35px] rounded-[8px]'>
                        <h4 className='txt-blue font-semibold'>GRE</h4>
                        <span className='txt-blue font-semibold'>{ educationPlanModalData?.exams?.gre?.value}</span>
                        <button onClick={()=>this.setState({modalPosition:true, examData: publicExams?.find(x=>x.name == "GRE")})}>
                            <CardEditSvg />
                        </button>
                    </div>
                </div>:null
            }
            {
                educationPlanModalData?.exams?.sat?.selected?
                <div className='relative '>
                    <button onClick={()=>this.props.changeStateValue({name:"educationPlanModalData.exams.sat", value:{selected:false, value:''}})} className='absolute right-[-10px] top-[-13px] bg-white border rounded-full p-[2px] border-red-700'>
                        <DeleteSvg />
                    </button>
                    <div className='border border-[var(--blue)] flex gap-[15px] items-center justify-center h-[35px] rounded-[8px]'>
                        <h4 className='txt-blue font-semibold'>SAT</h4>
                        <span className='txt-blue font-semibold'>{ educationPlanModalData?.exams?.sat?.value}</span>
                        <button onClick={()=>this.setState({modalPosition:true, examData: publicExams?.find(x=>x.name == "SAT")})}>
                            <CardEditSvg />
                        </button>
                    </div>
                </div>:null
            }
            {
                educationPlanModalData?.exams?.dim?.selected?
                <div className='relative '>
                    <button onClick={()=>this.props.changeStateValue({name:"educationPlanModalData.dim.sat", value:{selected:false, value:''}})} className='absolute right-[-10px] top-[-13px] bg-white border rounded-full p-[2px] border-red-700'>
                        <DeleteSvg />
                    </button>
                    <div className='border border-[var(--blue)] flex gap-[15px] items-center justify-center h-[35px] rounded-[8px]'>
                        <h4 className='txt-blue font-semibold'>DIM</h4>
                        <span className='txt-blue font-semibold'>{ educationPlanModalData?.exams?.dim?.value}</span>
                        <button onClick={()=>this.setState({modalPosition:true, examData: publicExams?.find(x=>x.name == "DIM")})}>
                            <CardEditSvg />
                        </button>
                    </div>
                </div>:null
            }
        </div>
        {
            modalPosition?
            <div className='background_shadow items-center justify-center flex' onClick={this.closeGradeModal.bind(this)}>
                <div className='bg-white w-[300px] rounded-[10px] p-[20px]'>
                    <h3 className='text-center txt-blue text-[17px] font-semibold mb-[10px]'>{examData?.name}</h3>
                    <Input 
                        bigInp svg={<InpSvg />} bg="bg-[#F3F4F6]"
                        required
                        minValue={examData?.min_value} 
                        maxValue={examData?.max_value} 
                        step={examData?.step}
                        name={`educationPlanModalData.exams.${examData?.name?.toLowerCase()?.replaceAll(" ", "_")}.value`}  
                        value={educationPlanModalData.exams[examData?.name?.toLowerCase()?.replaceAll(" ", "_")]?.value} 
                        title={`${examData?.name} qiyməti`}  
                        placeholder={`min: ${examData?.min_value} - max: ${examData?.max_value}`}
                        reqText="Qiyməti yazmaq mütləqdir"/>
                    <div className='mt-[10px] flex justify-center'>
                        <button onClick={this.onSaveScore.bind(this)} className='bg-blue text-white h-[35px] w-[110px] rounded-[8px] pl-[10px] pr-[10px] text-[14px]'>
                           Yadda saxla
                        </button>
                    </div>
                </div>
            </div>:null
        }
        
     </div>
    )
  }
}
const mapStateToProps = (state) =>({
    publicExams: state.Data.publicExams,
    educationPlanModalData: state.Data.educationPlanModalData,
});
const mapDispatchToProps = {getPublicExams, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(Exams)
