import React, { Component } from 'react'

export class LoadRightSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={7}
        height={10}
        fill="none"
      >
        <path
          stroke="#545454"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m1.125.5 4.5 4.5-4.5 4.5"
        />
      </svg>
    )
  }
}

export default LoadRightSvg
