import React, { Component } from 'react'
import PinSvg from '../../../components/svg/PinSvg'
import MessagePinnedModal from '../../../components/modals/MessagePinnedModal'

export class Header extends Component {
  state={
    pinnedMessages:[]
  }
  render() {
    const {pinnedMessages} = this.state;
    const {messagesParams} = this.props;
    return (
      <div  className='flex items-center justify-between h-[65px] border-b-[#E4E4E7] border-b mb-[20px] pr-[12px] pl-[12px]'>
        <div></div>
        <div className='flex items-center gap-[20px] max-[500px]:gap-[10px]'>
            <button onClick={()=>this.setState({pinnedMessages: messagesParams?.pins})} className='bg-[#F8FAFC] max-[500px]:w-[30px] max-[500px]:h-[25px] max-[500px]:min-h-[25px] w-[58px] min-h-[44px] h-[44px] mt-[-5px] inline-flex items-center justify-center rounded-[10px]'>
                <PinSvg color="var(--blue)" size={20} />
            </button>
        </div>
        {
          pinnedMessages.length !=0?
          <MessagePinnedModal closeModalBtn={()=>this.setState({pinnedMessages:[]})} messages={pinnedMessages} />:null
        }
        
      </div>
    )
  }
}

export default Header
