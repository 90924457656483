import React, { Component } from 'react'
import { insertNewData } from '../../actions/MainAction';
import { connect } from 'react-redux';
import { mixinAlert } from '../../actions/SweetAlerts';

export class AddAboutAuto extends Component {
    state={
        btnDIsable:false,
        count:0
    }

    addAbout(){
        this.setState({btnDIsable:true});
        this.props.insertNewData("uni-about", {})
        .then(resp=>{
            this.setState({btnDIsable:false, count: this.state.count+1});
            mixinAlert("success", "Data addded successfully");
            setTimeout(()=>{
              this.addAbout();
            },60000)
        })
    }
  render() {
    const {btnDIsable, count} = this.state;
    return (
      <div>
        <button disabled={btnDIsable} onClick={this.addAbout.bind(this)} className='bg-blue text-white text-[20px] mt-[20px] p-[4px_10px] rounded-[10px]'>Start</button>
        <h2>Count: {count}</h2>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({

});
const mapDispatchToProps = {insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(AddAboutAuto)
