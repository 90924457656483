import React, { Component } from 'react'
import { changeStateValue } from '../../../redux/MainReducer';
import { connect } from 'react-redux';

export class RankingTable extends Component {

    applyRank=(id, rank_property)=>{
        this.props.changeStateValue({
            name:"rankingApplyModal",
            value:{
                position:true,
                id:id,
                rank_property:rank_property,
                name:'',
                phone_number:'',
            }
        })
    }
  render() {
    const {options=[], column1Title="", column2Title="",column3Title="", column1Property="", column2Property="", column3Property="", count, rank_property=""} = this.props;
    return (
      <div className='min-[800px]:bg-white min-[800px]:shadow min-[800px]:rounded-[20px] min-[800px]:p-[10px]'>
        <div className='hidden max-[800px]:flex flex-col gap-[30px]'>
            {
                options?.map((data, i)=>{
                    return(
                        <div key={i} className='bg-white p-[20px]  shadow-[rgba(100, 100, 111, 0.2)_0px_7px_29px_0px] rounded-[10px] relative'>
                            <div className='w-[35px] h-[35px] rounded-full absolute bg-white left-[-15px] top-[-20px] flex justify-center items-center'>
                                <span className='text-[12px] font-semibold'>{data?.ranking}</span>
                            </div>
                            <ul className='flex flex-col gap-[15px]'>
                                <li className='text-[14px] font-semibold'>{data[column2Property]}</li>
                                {column3Property !=""? <li className='text-[14px] font-semibold'>{data[column3Property]["name"]}</li>:null}
                               
                                <li className='flex justify-center'> <button onClick={this.applyRank.bind(this, data?.id, rank_property)} className='bg-[var(--blue)] h-[20px] text-[12px] w-[130px] rounded-full text-white font-semibold'>Qəbul şansını yoxla</button></li>
                            </ul>
                        </div>
                    )
                })
            }
        </div>
        <table className='w-full max-[800px]:hidden'>
            <thead>
                <tr className='bg-[#F6F6F6] h-[45px] '>
                    {column1Title !="" ? <th className={`text-left rounded-l-[10px] pl-[10px] ${count==2?"w-1/2":count==3?"w-1/3":""}`}>{column1Title}</th> : null}
                    {column2Title !="" ? <th className={`text-left  pl-[10px] ${count==2?"w-1/2":count==3?"w-1/3":""}`}>{column2Title}</th> : null}
                    {column3Title !="" ? <th className={`text-left  pl-[10px] ${count==2?"w-1/2":count==3?"w-1/3":""}`}>{column3Title}</th> : null}
                    <th className={`text-left rounded-r-[10px] pl-[10px] w-1/3`}>{"Müraciət"}</th> 
                </tr>
            </thead>
            <tbody>
                {
                    options?.map((data, i)=>{
                        return(
                            <tr key={i} className={`h-[45px] ${i%2===0? "":"bg-[#F6F6F6]"}`}>
                                {column1Property !="" ? <td className={`text-left rounded-l-[10px] pl-[10px] ${count==2?"w-1/2":count==3?"w-1/3":""} text-[13px] font-semibold`}>{data[column1Property]}</td> : null}
                                {column2Property !="" ? <td className={`text-left pl-[10px] ${count==2?"w-1/2":count==3?"w-1/3":""} text-[13px] font-semibold`}>{data[column2Property]}</td> : null}
                                {column3Property !="" ? <td className={`text-left pl-[10px] ${count==2?"w-1/2":count==3?"w-1/3":""} text-[13px] font-semibold`}>{data[column3Property]["name"]}</td> : null}
                                <td className={`text-left rounded-r-[10px] pr-[10px] ${count==2?"w-1/2":count==3?"w-1/3":""}`}>
                                    <button onClick={this.applyRank.bind(this, data?.id, rank_property)} className='bg-[var(--blue)] h-[20px] text-[12px] w-[130px] rounded-full text-white font-semibold'>Qəbul şansını yoxla</button>
                                </td>
                            </tr>
                        )
                    })
                }
                
            </tbody>
        </table>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({

});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(RankingTable)
