import React, { Component } from 'react'

export class CirclePercentage extends Component {
  render() {
    const {prcntg = 0} = this.props;
    return (
        <div className="circular">
            <div className="circular-progress" style={{ background: `conic-gradient(${prcntg> 90? '#3B82F6':'#3B82F6'} calc(${360*prcntg/100}deg), #f5f5f5 0deg)` }}>
                <span> {prcntg<=100? prcntg: '100'}%</span>
            </div>
        </div>
    )
  }
}

export default CirclePercentage
