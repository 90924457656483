import React, { Component } from 'react'
import DisciplinesSection from './sections/DisciplinesSection'
import NewsLetterSection from './sections/NewsLetterSection'
import Footer from './sections/Footer'
import SubjectSection from './sections/SubjectSection'
import { Helmet } from 'react-helmet'

export class SubjectPages extends Component {
  componentDidMount(){
    window.scrollTo(0,0)
  }
  render() {
    return (
        <div className='mt-[80px] bg-[#fff]'>
           <Helmet>
              <title>{"Ən Yaxşı İxtisaslar | CELTgo.com - Karyera Üçün Ən Yaxşı Təhsil İxtisasları"}</title>
              <meta property="og:locale" content="az_AZ" />
              <meta property="og:type" content="website" />
              <meta property="og:title" content="Ən Yaxşı İxtisaslar | CELTgo.com - Karyera Üçün Ən Yaxşı Təhsil İxtisasları" />
              <meta property="og:description" content="CELTgo.com ilə karyera üçün ən perspektivli və tələb olunan ixtisasları kəşf edin. İxtisas seçimi edərkən doğru qərar verin və uğurlu gələcək üçün ilk addımı atın." />
              <meta name="description" content={"CELTgo.com ilə karyera üçün ən perspektivli və tələb olunan ixtisasları kəşf edin. İxtisas seçimi edərkən doğru qərar verin və uğurlu gələcək üçün ilk addımı atın."}/>
              <meta name="keywords" content={"ən yaxşı ixtisaslar, tələb olunan ixtisaslar, karyera üçün ixtisaslar, perspektivli ixtisaslar, xaricdə təhsil ixtisasları, ən perspektivli təhsil ixtisasları, gələcək üçün ixtisas seçimi, uğurlu karyera üçün ixtisaslar"}/>
              <meta property="og:url" content="https://celtgo.com/subjects" />
              <meta property="og:site_name" content="CELT Colleges" />
              <meta property="article:publisher" content="https://www.facebook.com/celtscollege" />
              <meta property="article:modified_time" content="2024-06-06T08:56:25+00:00" />
              <meta property="og:image" content="https://celtgo.com/static/media/celtLogo6.05ddd884642befe11a4e.png" />
              <meta property="og:image:width" content="900" />
              <meta property="og:image:height" content="600" />
              <meta property="og:image:type" content="image/jpeg" />
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:title" content="Ən Yaxşı İxtisaslar | CELTgo.com - Karyera Üçün Ən Yaxşı Təhsil İxtisasları" />
              <meta name="twitter:description" content="CELTgo.com ilə karyera üçün ən perspektivli və tələb olunan ixtisasları kəşf edin. İxtisas seçimi edərkən doğru qərar verin və uğurlu gələcək üçün ilk addımı atın." />
              <meta name="twitter:site" content="@celtcolleges" />
              <meta name="twitter:label1" content="Est. reading time" />
              <meta name="twitter:data1" content="7 minutes" />
            </Helmet>
            <SubjectSection loadMoreBtn={true} page="1" />
            {/* <NewsLetterSection /> */}
            <Footer />
        </div>
    )
  }
}

export default SubjectPages


