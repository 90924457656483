import React, { Component } from 'react'
import { NavLink as Nav } from 'react-router-dom'
export class NavLink extends Component {
  render() {
    const {svg, title, path="/"} = this.props;
    return (
        <li className='flex '>
            <Nav to={path} className='flex gap-[8px] items-center w-full p-[13px] border-l border-l-[6px] border-l-white'>
                {svg}
                <span className='text-black text-[14px] font-semibold'>{title}</span>
            </Nav>
        </li>
    )
  }
}

export default NavLink
