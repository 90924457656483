import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue } from '../../redux/MainReducer';
import Logo from './Logo';
import Navigation from './Navigation';
import UserInfo from './UserInfo';
export class SideBar extends Component {
    closeNav=(e)=>{
        if(typeof e.target.className == "string" && e.target.className?.includes("sideBrBackground")){
       
          const nav  =document.querySelector('#navBtnCheckox');
          if(nav){
          
            nav.checked = false;
            this.props.changeStateValue({name:'navBtn', value:true})
          }
        }
      }
  render() {
    const {user} = this.props
    return (
     <>
      <input type='checkbox' className='hidden'  id='navBtnCheckox' />
        <div onClick={this.closeNav.bind(this)} className='sideBrBackground fixed' >
            <div className='bg-white w-[345px] min-w-[345px]  sidebar'>
                <Logo />
                <UserInfo user={user} />
                <Navigation />
            </div>
        </div>
     </>
    )
  }
}
const mapStateToProps = (state) =>({
  user: state.Data.user
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(SideBar)
